/**                                                                                                                 by Paul Hardy 7/20/22
 *    Forgot_2_Send.js  ------  Public Module route destination/page.
 *          -  STEP 2/5 of the Password Recovery Process.  User presented choice of contact methods(if stored) to receive a One-Time-Passcode.
 *          -  User presented choice of contact methods(if stored) to receive a One-Time-Passcode.  If found, on the STEP 3 (Forgot_2_Verify.js)
 *          -  When contact methods found, the App's store auth.forgot state is populated with data found, and access tokens that the next step.
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Container, Spinner, Alert } from "react-bootstrap";
import {
    HelmetHtmlTitle,
    Button,
    CountdownTimer,
    Form,
    RadioButtonGroup,
    SubmitButton,
} from "_core/components/core-library";

import { PUBLIC_PATHS } from "_core/config";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { isArrayEqual } from "_core/helpers/data-types/arrays";


const ForgotSend = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
    const navigate = useNavigate();

    // STATE HOOKS
    const [loading, setLoading] = useState(false); // for spinner
    const [sendError, setSendError] = useState(null); // for error message on form
    const [timedOut, setTimedOut] = useState(false); //
    const [radioOptions, setRadioOptions] = useState([]); // for radioset
    // const [method, setMethod] = useState(null); // for selected auth method

    // AUTH & AUTH-STATE HOOKS
    const { access_token, access_expires, forgot, forgot_send } = useAuth();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        control,
        trigger,    // eslint-disable-line no-unused-vars
        setValue,   // eslint-disable-line no-unused-vars
        formState: { isValid },
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

    React.useEffect(() => {
        function initRadioGroup() {
            const { methods } = forgot?.find; // Get state refs
            let new_opts = buildRadioOptions(methods); // Bail if they are equal already, otherwise adn endless render loop
            if (isArrayEqual(new_opts, radioOptions)) return;
            else setRadioOptions(new_opts);
        }
        initRadioGroup();
    }, [forgot, radioOptions]);

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */
    const onSubmit = async (formdata) => {
        // init UI
        setLoading(true);
        setSendError(null);

        // Attach token received from the FIND process success
        formdata.token = access_token;

        //console.log('SUBMITTING', formdata)

        // // Call Auth func to send the code to user's device.
        await forgot_send(formdata).then((result) => {
            if (result.success) {
                resetForm();
                navigate(PUBLIC_PATHS.FORGOT + "/verify", {
                    replace: true,
                });
            } else if (result.error) {
                setSendError("ERROR sending code! -- " + result.error);
            }
            setLoading(false);
        });
    };

    const validateThisField = (e) => {
        // call useForm.trigger, with id passed in, to validate just this field
        //  const id = e.target.id;
        //  trigger(id);
    };

    const buildRadioOptions = (methods) => {
        let count =
            methods !== undefined && methods !== null
                ? Object.keys(methods).length
                : 0;
        const { phone, email } = methods;
        let opts = [];
        if (phone !== undefined && phone !== null) {
            opts.push({
                value: "phone",
                checked: count === 1,
                label: "Send a text message to my phone at " + phone,
            });
        }
        if (email !== undefined && email !== null) {
            opts.push({
                value: "email",
                checked: count === 1,
                label: "Send an email to my email address at " + email,
            });
        }
        return opts;
    };

    const handleTimeout = async () => {
        setTimedOut(true);
        navigate(PUBLIC_PATHS.LOGIN, { replace: true });
    };

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <HelmetHtmlTitle pageName="Forgot My Password" />

            <Form
                className="form-forgot text-center mt-5 ms-auto me-auto"
                style={{ maxWidth: "500px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <h1 className="h4 mb-3 font-weight-normal">
                    AUTHORIZATION REQUIRED
                </h1>
                <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column">
                        {radioOptions && radioOptions.length > 0 && (
                            <div className="timer-msg d-flex flex-column mb-4 ">

                                <div>{forgot?.find?.message}</div>

                                <CountdownTimer
                                    autostart
                                    timestamp_utc={access_expires}
                                    label={
                                        "Choose a method below before the time expires."
                                    }
                                    labelExpired="Time Expired!"
                                    format={"mm:ss"}
                                    className={"my-3"}
                                    labelClass={"me-1"}
                                    timeClass={"fw-bold"}
                                    onTimeout={() => {
                                        handleTimeout();
                                    }}
                                />
                                <div className="d-flex flex-row justify-content-center">
                                    <RadioButtonGroup
                                        control={control}
                                        label={""}
                                        name="method"
                                        disabled={timedOut}
                                        value={"phone"}
                                        options={radioOptions}
                                        //    horizontal={false}
                                        reversedButton={true}
                                        onBlur={validateThisField}
                                        onChange={validateThisField}
                                        rules={{
                                            required: true,
                                            validate: (val) => {
                                                return val !== "";
                                            },
                                        }}
                                        feedback="You must choose a contact method!"
                                        feedbackClass="text-start"
                                    />
                                </div>
                            </div>
                        )}
                        {(!radioOptions || radioOptions.length <= 0) && (
                            <p className="text-center">
                                We found your account but no methods to verify
                                your identity. Contact your system administrator
                                to change your password!
                            </p>
                        )}
                    </div>
                </div>

                {sendError && (
                    <Alert
                        variant={"warning"}
                        onClose={() => setSendError(null)}
                        dismissible
                    >
                        <div className="fst-italic small">{sendError}</div>
                    </Alert>
                )}

                {loading && <Spinner animation="border" />}
                {!loading && radioOptions && radioOptions.length > 0 && (
                    <SubmitButton
                        className="mt-3"
                        disabled={!isValid || timedOut}
                    >
                        Send Code
                    </SubmitButton>
                )}

                <Container className="mt-3 d-flex justify-content-evenly">
                    <Button to={PUBLIC_PATHS.LOGIN} variant="link">
                        cancel recovery
                    </Button>
                </Container>
            </Form>
        </>
    );
};
export default ForgotSend;