import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { NotFound } from '_core/components/pages/public';

/*
* This is the route utility component used for generating
* routes and child routes it only requires routes array and basePath
*/
const MapRoutes = ({ routes, basePath, isAddNotFound}) => {
	//const match = useLocation(basePath);

	// RECURSIVE -  A function that calls itself until it doesn't, to display tge routes and their children.
	const recursive = (array, isChild) => {
		return array
		// filter the array for only routes that have a component
		.filter((route) => (route.component!==undefined&&route.component!=null&&route.component!==''))
		// map the array to return the routes
		.map( (route, key) => {

			// Important to breakout the props from the App's route object so that 
			// we can get ""..rest" which should be only React router <Route> props.  We'll pass them into it.
			const { 
			//	link, 		// Menu link.  Used for the menu.
				title, 		// Title of the route.  Used for the menu.
				permission, // Array of Role Permissions.
				children,	// Array of Child route objects, children of this route.
				path, 		// URL path.  Used for the menu and Routing.
				component: TheComponent,
				...rest 	// <-- Should be only react-router <Route> other props.
			} = route;

			return (
				<React.Fragment key={'f-'+key}>
					<Route 
						key={'r-'+key} 
						{...rest} 
						path={path} 
						element={<TheComponent key={'c-'+key} />} 
					/>
					{	// Check for Children, and if so, recursively traverse and render them.
						(route?.children!==undefined&&route?.children!=null&&route?.children.length>0)
						&&
						recursive(route.children, true) 
					}
				</React.Fragment>
			)
		})
	}

	return (
		<Routes>
			{recursive(routes)}
			{isAddNotFound && <Route element={<NotFound />}/>}
		</Routes>
	)
}

export default MapRoutes;