import React, { useState } from "react";
import { useSelector } from "react-redux";

import { nestedFilterArray, isArray, getUniquePropertyValuesFromObjects } from "_core/helpers/data-types";

//import { IGDBBrowseResultsTable } from 'components/app-library'

import { useErrorHandler } from "_core/hooks/provider-hooks/useErrorHandler.provider";

import FilterSelector from "./parts/FilterSelector";

import ModalMessage from "./parts/ModalMessage";
import SelectManufacturer from "./parts/SelectManufacturer";
import SelectColor from "./parts/SelectColor";
//import Instructions from './parts/instructions';

const IGDB_BROWSER = () => {
    //const { user: current_user } = useSelector((state) => state.auth);
    //if (!current_user) {return <Navigate to={ROUTES.LOGIN} />}
    //const { access_token } = useSelector((state) => state.auth);

    // ERROR HANDLER HOOKS
    // eslint-disable-next-line
    const { handleInternalError, showError } = useErrorHandler();

    // MODAL Handling
    // eslint-disable-next-line
    const [showModal, setShowModal] = useState(false);
    // eslint-disable-next-line
    const [modalMessage, setModalMessage] = useState(null);

    const [manufacturers, setManufacturers] = useState([]);
    // eslint-disable-next-line
    const [thicknesses, setThicknesses] = useState([]);
    const [colors, setColors] = useState([]);

    const { products } = useSelector((state) => state.igdb);
    // eslint-disable-next-line
    const [filteredProducts, setFilteredProducts] = useState([...products]); // default set of filtered data. A copy from State products.

    const defaultAvailableFilters = [
        { id: "manuf", label: "By Manufacturer" }, // values = array of values to filter by (can have multiple values)
        { id: "thick", label: "By Thickness" },
        { id: "color", label: "By Color (Appearance)" },
        { id: "vlt", label: "By VLT" },

        // preset:{
        //     type: 'ranges',
        //     ranges: [
        //         {low:0.00, high:0.00, label:"0% VLT"},
        //         {low:0.00, high:0.0499999999, label:"0% to 9.9%"},
        //         {low:0.10, high:0.1999999999, label:"10% to 19.9% VLT"},
        //         {low:0.20, high:0.2999999999, label:"20% to 29.9% VLT"},
        //         {low:0.30, high:0.3999999999, label:"30% to 39.9% VLT"},
        //         {low:0.40, high:0.4999999999, label:"40% to 49.9% VLT"},
        //         {low:0.50, high:0.5999999999, label:"50% to 59.9% VLT"},
        //         {low:0.60, high:0.6999999999, label:"60% to 69.9% VLT"},
        //         {low:0.70, high:0.7999999999, label:"70% to 79.9% VLT"},
        //         {low:0.80, high:0.8999999999, label:"80% to 89.9% VLT"},
        //         {low:0.90, high:0.9999999999, label:"90% to 100% VLT"}
        //     ],

        // }
    ];

    // current Available filters.  We'll show these available to choose at all times. They start as clone of Default Filters.
    // eslint-disable-next-line
    const [availableFilters, setAvailableFilters] = useState(defaultAvailableFilters);

    // Selected Filter -  The filter the user clicked on. Null when not chosen.
    const [selectedFilter, setSelectedFilter] = useState(null); // the filter the user selected.
    // eslint-disable-next-line
    const [selectedFilterValues, setSelectedFilterValues] = useState(null); // the unique, distinct values found for the column.  A list of selections fo the user, once they choose a filter.

    // Applied Filters

    // eslint-disable-next-line
    const [appliedFilters, setAppliedFilters] = useState([]);

    function _handle_filter_select_button(filterID) {
        if (availableFilters === undefined || availableFilters == null || availableFilters.length <= 0) return handleInternalError("There are no available filters!");

        if (filterID === undefined || filterID == null) return handleInternalError("filterID is required!");

        // make sure it exists in the available filters, and retrieve the object.
        const found = availableFilters.filter((obj) => {
            return obj.id === filterID;
        });
        const filter = isArray(found) ? found[0] : null;
        if (filter === undefined || filter == null || filter.id !== filterID) return handleInternalError("That filter doesn't exist!  [" + filterID + "]");

        // // IF NOT VLT filter
        // if (filter.id!=='vlt') {
        //     // Get the Unique Values in the Data for this filter's column. We'll present this list tit eh user for them to choose a value.
        //     console.log('Searching for uniqueVals. Column = '+filter.column)
        //     let uniqueVals = getUniquePropertyValuesFromObjects(filteredProducts, filter.column)
        //     console.log('uniqueVals',uniqueVals)
        //     setSelectedFilterValues(uniqueVals.sort());
        // } else {
        //     setSelectedFilterValues(filter.preset?.ranges);  // pass ranges to VLT instead of unique values.
        // }

        // Get the Unique Values in the Data. We'll present this list to the user for them to choose a value.

        if (filter.id === "manuf") {
            const uniqueManufacturerNames = getUniquePropertyValuesFromObjects(filteredProducts, "manufacturer_name", true);

            // convert array of manufacturer name strings to array of manufacturer objects.
            const manufacturers = uniqueManufacturerNames.sort().map(function (value) {
                return { name: value };
            });
            // set to state.
            setManufacturers(manufacturers);
        }
        if (filter.id === "color") {
            const uniqueColorNames = getUniquePropertyValuesFromObjects(filteredProducts, "appearance", true);
            // convert array of color name strings to array of manufacturer objects.
            const colors = uniqueColorNames.sort().map(function (value) {
                return { name: value };
            });
            // set to state.
            setColors(colors);
        }

        // set the object to state.
        setSelectedFilter(filter);
    }
    // eslint-disable-next-line
    function _handle_filterValue_select_button(filterValue) {
        console.log("filterValue", filterValue);
    }
    // eslint-disable-next-line
    function _handle_filterValueRange_select_button(filterValueRange) {
        console.log("filterValueRange", filterValueRange);
    }
    // eslint-disable-next-line
    function applyChosenFilter(filterID, value) {
        // let newAvailableFilters = removeObjectsFromArrayByKey(availableFilters, "id", filterID);
        //  setAvailableFilters(newFilters);
    }
    // eslint-disable-next-line
    function filterProducts(column, value) {
        let data = [...products];

        /*
        var nestedFilter={id:["3"],term_id:["6"],type:["car","bike"]};
        */

        let nestedFilter = {};

        let result = nestedFilterArray(data, nestedFilter); // var nestedFilter={id:["3"],term_id:["6"],type:["car","bike"]};  <-- filter needs ot be like this format
        return result;
    }

    return (
        <>
            {
                // NO FILTER SELECTED  - DEFAULT VIEW
                !selectedFilter && (
                    <>
                        {/* <Instructions content={""} /> */}
                        <FilterSelector filteredProducts={filteredProducts} availableFilters={availableFilters} onselect={_handle_filter_select_button} />
                    </>
                )
            }

            {/* { // NO FILTER SELECTED  - DEFAULT VIEW 
            (selectedFilter && selectedFilter.id !== 'vlt') 
                &&
                <ValueSelector 
                    valueList={selectedFilterValues} 
                    onselect={_handle_filterValue_select_button} 
                />
            } */}
            {selectedFilter && selectedFilter.id === "manuf" && (
                <SelectManufacturer
                    manufacturers={manufacturers}
                    oncancel={() => {
                        setSelectedFilter(null);
                    }}
                />
            )}
            {selectedFilter && selectedFilter.id === "color" && (
                <SelectColor
                    colors={colors}
                    oncancel={() => {
                        setSelectedFilter(null);
                    }}
                />
            )}
            {/* { (selectedFilter && selectedFilter.id === 'vlt') 
                &&
                <Container className='d-grid gap-4 p-0'>
                    {selectedFilterValues.map(function(rangeValue, i){
                        return <Button key={i} variant={"primary-outline"} size={"lg"} className={"fw-bold"} text={rangeValue.label} onClick={() => {
                            // console.log('Run action for : '+filterValue);
                            _handle_filterValueRange_select_button(rangeValue);//
                        }}/>;
                    })}
                </Container>
            } */}

            {/* <IGDBBrowseResultsTable products={filteredProducts} /> */}

            <ModalMessage show={showModal} message={modalMessage} />
        </>
    );
};
export default IGDB_BROWSER;
