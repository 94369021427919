import React from 'react';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { PRIVATE_PATHS } from "_core/config";
import { Container } from 'react-bootstrap';

const ADMIN_Global_Themes_ColorBrowser = () => {
	return (
		<PrivatePageTemplate 
			pageTitle="Color Browser"
			breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
                { caption: "Global Admin", to: PRIVATE_PATHS.GLOBAL.HOME },
				{ caption: "Theme Settings", to: PRIVATE_PATHS.GLOBAL.THEMES.HOME },
            ]}
		>
			<Container>
				<div className='h4'>BUTTONS:</div>
				<Container>
					<div className='h5'>btn-?</div>
					<Container>
						<button type="button" className="btn btn-primary">Primary</button>
						<button type="button" className="btn btn-secondary">Secondary</button>
						<button type="button" className="btn btn-success">Success</button>
						<button type="button" className="btn btn-danger">Danger</button>
						<button type="button" className="btn btn-warning">Warning</button>
						<button type="button" className="btn btn-info">Info</button>
						<button type="button" className="btn btn-light">Light</button>
						<button type="button" className="btn btn-dark">Dark</button>
						<button type="button" className="btn btn-link">Link</button>
					</Container>
					<div className='h5'>btn-outline-?</div>
					<Container>
						<button type="button" className="btn btn-outline-primary">Primary</button>
						<button type="button" className="btn btn-outline-secondary">Secondary</button>
						<button type="button" className="btn btn-outline-success">Success</button>
						<button type="button" className="btn btn-outline-danger">Danger</button>
						<button type="button" className="btn btn-outline-warning">Warning</button>
						<button type="button" className="btn btn-outline-info">Info</button>
						<button type="button" className="btn btn-outline-light">Light</button>
						<button type="button" className="btn btn-outline-dark">Dark</button>
						</Container>
				</Container>
			</Container>
			

		</PrivatePageTemplate>
	);
}
export default ADMIN_Global_Themes_ColorBrowser;