/*
            useFuseSearch - a fuze.js search tool.                                  Paul H -  10/12/2023

            See:
                https://www.fusejs.io/ 

            Borrowed Hook idea from :
                https://dev.to/noclat/using-fuse-js-with-react-to-build-an-advanced-search-with-highlighting-4b93


            TODO: Add highlighting features, by using Fuse.js option 'includeMatches', Fuse returns a matches object alongside each item:  See: https://dev.to/noclat/using-fuse-js-with-react-to-build-an-advanced-search-with-highlighting-4b93  

            HOW TO USE:


            const { searchResults, searchTerm, setSearchTerm, handleSearchInputOnChange } = useFuseSearch(dataSourceOriginal, {
                keys: ['name', 'manufacturer_name'],
                matchAllOnEmptyQuery: false,
            });

            <Form.Control 
                autoFocus={props?.searchAutofocus}
                size="lg"
                type="text"
                style={{ padding: 5 }}
                value={(searchTerm===null ? '' : searchTerm)}  
                onChange={handleSearchInputOnChange} 
                placeholder="Enter search term..."
            />

            <div>
                <p>Results for "{searchTerm}":</p>
                <ol>
                    {searchResults.map(result => (   // results are useFuseSearch (fuse.js) result objects. See docs.  Has Data an index.. a search score. etc..
                        <li key={result.refIndex}>
                            {result.item.name} 
                        </li>
                    ))}
                </ol>
            </div>
*/

import Fuse from "fuse.js";
import { useCallback, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";





export const useFuseSearch = (list, options) => {

    // Text Input Search Values - defining our searchTerm state in there directly
    const [searchTerm, updateSearchTerm] = useState("");
    const [debouncedSearchTerm] = useDebounce(searchTerm.trim(), 1000);

    // removing custom options from Fuse options object
    // NOTE: `limit` is actually a `fuse.search` option, but we merge all options for convenience
    const { debounceMs, limit, matchAllOnEmptyQuery, ...fuseOptions } = options;

    // let's memoize the fuse instance for performances
    const fuse = useMemo(() => new Fuse((list===null?[]:list), fuseOptions), [list, fuseOptions]);

    //memoize searchResultsFuse whenever the searchTerm or options change
    // const searchResultsFuse = useMemo(
    //     // if searchTerm is empty and `matchAllOnEmptyQuery` is `true` then return all list
    //     // NOTE: we remap the searchResults to match the return structure of `fuse.search()`
    //     () =>
    //         !debouncedSearchTerm && matchAllOnEmptyQuery
    //             ? fuse
    //                   .getIndex()
    //                   .docs.slice(0, limit)
    //                   .map((item, refIndex) => ({ item, refIndex }))
    //             : fuse.search(debouncedSearchTerm, { limit }),
    //     [fuse, limit, matchAllOnEmptyQuery, debouncedSearchTerm]
    // );

    const searchResultsFuse = useMemo(
        // if searchTerm is empty and `matchAllOnEmptyQuery` is `true` then return all list
        // NOTE: we remap the searchResults to match the return structure of `fuse.search()`
        () => {
            return !debouncedSearchTerm && matchAllOnEmptyQuery
                ? fuse
                      .getIndex()
                      .docs.slice(0, limit)
                      .map((item, refIndex) => ({ item, refIndex }))
                : fuse.search(debouncedSearchTerm, { limit })},
        [fuse, limit, matchAllOnEmptyQuery, debouncedSearchTerm]
    );

    // pass a handling helper to speed up implementation
    const handleSearchInputOnChange = useCallback((e) => {
        updateSearchTerm(e.target.value)
    }, [updateSearchTerm]);

    

    const clearSearch = useCallback(() => {
        updateSearchTerm('')
    }, [updateSearchTerm]);



    // still returning `setSearchTerm` for custom handler implementations
    return {
        handleSearchInputOnChange,
        clearSearch,    
        searchResultsFuse,                                             // Export the Fuse Search result object
        searchResults: searchResultsFuse.map( item => (item.item) ),   // Export the search results in format passed in (remap into array of records - item=record). 
        searchTerm, 
        searchLimit: limit,
    };
};
