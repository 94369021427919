/**                                                                                                                 by Paul Hardy 7/20/22
 *    Forgot_4_NewPass.js  ------  Public Module route destination/page.
 *          -  STEP 4/5 of the Password Recovery Process.
 *          -  User presented two inputs to enter a New Password, and also Confirm it.  If passwords meet criteria and match, on the STEP 5 (Forgot_5_Success.js)
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Spinner, Alert } from "react-bootstrap";
import {
    Input,
    Button,
    SubmitButton,
    Form,
    HelmetHtmlTitle,
    CountdownTimer,
    Icon
} from "_core/components/core-library";

import { PUBLIC_PATHS } from "_core/config";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import {
    validatePassword,
    default_password_criteria
} from "_core/helpers/form-validation";

const ForgotNewPass = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */
    
    // ROUTING HOOKS
    const navigate = useNavigate();

    // STATE HOOKS
    const [loading, setLoading] = useState(false); // for spinner
    const [passValid, setPassValid] = useState(false); // A flag that confirmPass uses.
    const [passError, setPassError] = useState(null); // for error message on form
    const [timedOut, setTimedOut] = useState(false);  //
    const [passCriteria, setPassCriteria] = useState({ ...default_password_criteria }); // grab our default_pass_crit object from our form password helper

    // AUTH & AUTH-STATE HOOKS
    const { access_token, access_expires, forgot_pass } = useAuth(); // our Auth hook tools

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        getValues,
        control,
        trigger,
        formState: { isValid },
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

        //  future useEffect / onmount funcs here


    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */
    const validateFieldByEvent = async (e) => {
        return await trigger(e.target.id); // call react-hook-form's useForm.trigger, with event passed in, to validate just this field
    };
    const validateFieldById = async (id) => {
        return await trigger(id); // call react-hook-form's useForm.trigger, with id passed in, to validate just this field
    };

    const validatePasswordConfirmation = async (val) => {
        let pass = getValues("password");

        let valid = true;

        //console.log("pass= " + pass);
        //console.log("cfrm= " + val);

        if (
            pass === undefined ||
            pass === null ||
            pass === "" ||
            pass !== val
        ) {
            valid = false;
        }
        return valid;
    };

    const handlePassOnChange = async (e) => {
        await validateFieldByEvent(e);
        let x = await validateFieldById("password_confirm");
        console.log("x=" + x);
        let value = e.target.value;

        // check conditon 1
        let snapshot = { ...passCriteria };
        let newCriteria = { ...snapshot };

        newCriteria.pc1.ok = newCriteria.pc1.pattern.test(value);
        newCriteria.pc2.ok = newCriteria.pc2.pattern.test(value);
        newCriteria.pc3.ok = newCriteria.pc3.pattern.test(value);
        newCriteria.pc4.ok = newCriteria.pc4.pattern.test(value);
        newCriteria.pc5.ok = newCriteria.pc5.pattern.test(value);

        setPassCriteria(newCriteria);
    };
    const handlePassConfirmOnChange = async (e) => {
        await validateFieldByEvent(e);
        // let value = e.target.value;
        // console.log("value is=" + value);
    };

    const onSubmit = async (formdata) => {
        formdata.token = access_token; // use token received for verify

        setLoading(true);
        setPassError(null);

        await forgot_pass(formdata).then((result) => {
            if (result.success) {
                resetForm();
                navigate(PUBLIC_PATHS.FORGOT + "/success", {
                    replace: true,
                });
            } else if (result.error) {
                setPassError(result.error);
            }
            setLoading(false);
        });
    };

    
    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <HelmetHtmlTitle pageName="Forgot My Password - Verification" />

            <Form
                className="form-forgot text-center mt-5 ms-auto me-auto"
                style={{ maxWidth: "500px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <h1 className="h4 mb-3 font-weight-normal">
                    CREATE NEW PASSWORD
                </h1>
                <p className="text-start">
                    Choose a new password before your temporary account access
                    expires. The password requirements are:
                </p>

                <div className="d-flex justify-content-center">
                    <ul style={{ textAlign: "left" }} className="fa-ul ms-0">
                        {Object.keys(passCriteria).map((key, index) => {
                            let crit = passCriteria[key];
                            return (
                                <li key={"pCrit_" + index}>
                                    {crit.ok === true ? (
                                        <Icon
                                            name="check"
                                            className="text-success me-1"
                                            fixedWidth={true}
                                        />
                                    ) : (
                                        <Icon
                                            name="exclamationTriangle"
                                            className="text-danger me-1"
                                            fixedWidth={true}
                                        />
                                    )}
                                    {crit.text}
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <CountdownTimer
                    autostart
                    timestamp_utc={ access_expires }
                    label={"Account access to set password expires in"}
                    labelExpired="Access Expired!  Try again"
                    labelExpiredClass="text-danger"
                    format={"mm:ss"}
                    className={"mb-3"}
                    labelClass={"me-1"}
                    timeClass={"fw-bold"}
                    onTimeout={() => {
                        setTimedOut(true);
                        setPassError(
                            "The temporary account access has expired. Try again."
                        );
                    }}
                />

                <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column justify-content-center" style={{maxWidth:"300px"}}>
                        <Input
                            name={"password"}
                            control={control}
                            label={"New Password"}
                            floatingLabel={true}
                            helpText={null}
                            className={"mb-1"}
                            type={"password"}
                            size={"lg"}
                            autoFocus={true}
                            onKeyUp={handlePassOnChange}
                            onBlur={handlePassOnChange}
                            disabled={timedOut}
                            rules={{
                                required: true,
                                validate: (val) => {
                                    let valid = validatePassword(val);
                                    setPassValid(valid); // set the state flag confimr uses to enable/disable
                                    return valid;
                                },
                            }}
                            feedback="Please enter a new password that meets the criteria above."
                            feedbackClass="text-start"
                        />

                        <Input
                            name={"password_confirm"}
                            control={control}
                            label={"Confirm New Password"}
                            floatingLabel={true}
                            helpText={null}
                            className={"mb-1"}
                            type={"password"}
                            size={"lg"}
                            autoFocus={false}
                            onKeyUp={handlePassConfirmOnChange}
                            onBlur={handlePassConfirmOnChange}
                            disabled={timedOut || !passValid}
                            rules={{
                                required: true,
                                validate: (val) => {
                                    return validatePasswordConfirmation(val);
                                },
                            }}
                            feedback="Please confirm the new password you entered above."
                            feedbackClass="text-start"
                        />

                        {passError && (
                            <Alert
                                variant={"warning"}
                                onClose={() => setPassError(null)}
                                dismissible
                            >
                                <div className="fst-italic small">
                                    {passError}
                                </div>
                            </Alert>
                        )}
                    </div>
                </div>

                {loading && <Spinner animation="border" />}

                {timedOut && (
                    <Container className="mt-3 d-flex justify-content-evenly">
                        <Button
                            to={PUBLIC_PATHS.LOGIN}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            to={PUBLIC_PATHS.FORGOT}
                            variant="primary"
                        >
                            Start Over
                        </Button>
                    </Container>
                )}

                {!timedOut && (
                    <>
                        {!loading && (
                            <SubmitButton
                                className="mt-3"
                                disabled={!isValid || timedOut}
                            >
                                Submit
                            </SubmitButton>
                        )}

                        <Container className="mt-3 d-flex justify-content-evenly">
                            <Button
                                to={PUBLIC_PATHS.LOGIN}
                                variant="link"
                            >
                                cancel
                            </Button>
                        </Container>
                    </>
                )}
            </Form>
        </>
    );
};
export default ForgotNewPass;
