/**                                                                                                                 by Paul Hardy 7/20/22
 *    Forgot_1_Find.js  ------  Public Module route destination/page.
 *          -  STEP 1/5 of the Password Recovery Process included at the login screen.  User clicks "forgot".
 *          -  Let's unknown user enter criteria to seacrh for thier account.  If found, on the STEP 2 (Forgot_2_Send.js)
 *          -  When account found, the App's store auth.forgot state is populated with data found, and access tokens that the next step(sending one-time passcode) requires to execute.
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Container, Spinner, Alert } from "react-bootstrap";

import { PUBLIC_PATHS } from "_core/config";
import { HelmetHtmlTitle, Button, Form, Input, SubmitButton } from "_core/components/core-library";
import { validatePhoneOrEmail } from "_core/helpers/form-validation";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";

const Forgot = (props) => {
    
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
    const navigate = useNavigate(); // Get navigate func from router hook.

    // STATE HOOKS
    const [loading, setLoading] = useState(false); // for spinner
    const [findError, setFindError] = useState(null); // for error message on form

    // AUTH & AUTH-STATE HOOKS
    const { forgot_reset, forgot_find } = useAuth();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        control,
        trigger,
        formState: { isValid },
        setFocus, // eslint-disable-line no-unused-vars
        setValue, // eslint-disable-line no-unused-vars
        getValues, // eslint-disable-line no-unused-vars
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

    // CREATE ONLOAD HANDLER - run once
    useOnMountRunOnce(() => {
        forgot_reset();
    });

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */
    const validateThisField = (e) => {
        // call useForm.trigger, with id passsd in, to validate just this field
        const id = e.target.id;
        trigger(id);
    };

    const onSubmit = async (formdata) => {
        //console.log('formdata sent:', formdata)

        setLoading(true);
        setFindError(null);
        await forgot_find(formdata).then((result) => {
            // console.log('Find result '+JSON.stringify(result)
            if (result?.success) {
                resetForm();
                navigate(PUBLIC_PATHS.FORGOT + "/send", {
                    replace: true,
                });
            } else if (result?.error) {
                setFindError(result.error); // error object has 'message'
            } else {
                setFindError('Internal communication error!'); 
            }
            setLoading(false);
        });
    };

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <HelmetHtmlTitle pageName="Forgot My Password" />

            <Form
                className="form-forgot text-center mt-5 ms-auto me-auto"
                style={{ maxWidth: "300px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <h1 className="h4 mb-3 font-weight-normal">
                    PASSWORD RECOVERY
                </h1>
                <div className="text-start mb-4">
                    To begin the password recovery process, enter your{" "}
                    <u>email or mobile phone number</u> associated with this
                    account.
                </div>

                {loading && <Spinner animation="border" />}

                <Input
                    name={"username"}
                    control={control}
                    label={"Email or Mobile#"}
                    floatingLabel={true}
                    autoComplete={"email phone mobile"}
                    helpText={null}
                    className={"mb-1 text-center"}
                    type={"text"}
                    size={"lg"}
                    autoFocus={false}
                    onKeyPress={validateThisField}
                    onKeyUp={validateThisField}
                    onBlur={validateThisField}
                    rules={{
                        required: true,
                        validate: (val) => {
                            return validatePhoneOrEmail(val);
                        },
                    }}
                    feedback={"A valid email address or mobile # is required."}
                    feedbackClass="text-start"
                />

                {findError && (
                    <Alert
                        variant={"warning"}
                        onClose={() => setFindError(null)}
                        dismissible
                    >
                        <div className="fst-italic small">{findError}</div>
                    </Alert>
                )}

                <SubmitButton className="mt-3" disabled={!isValid} />

                <Container className="mt-3 d-flex justify-content-evenly">
                    <Button to={PUBLIC_PATHS.LOGIN} variant="link">
                        cancel
                    </Button>
                </Container>
            </Form>
        </>
    );
};
export default Forgot;
