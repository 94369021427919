import React from "react";
import PropTypes from "prop-types";
import { Navbar } from "react-bootstrap";

import { CONSTANTS } from "_core/config";

function AppFooterCopyright(props) {
    const version = "v" + process.env.REACT_APP_VERSION;
    return (
        <>
            <Navbar.Text className="app-footer-copyright">{CONSTANTS.APP_COPYRIGHT}</Navbar.Text>
            
            {(props?.showVersion) 
            ?
                <>
                    <Navbar.Text className="mx-2">{" | "}</Navbar.Text>
                    <Navbar.Text className="app-footer-version">{version}</Navbar.Text>
                </>
            : 
                <></>
            }
        </>
    );
}
export default AppFooterCopyright;
AppFooterCopyright.propTypes = {
    showVersion: PropTypes.bool,
};
AppFooterCopyright.defaultProps = {
    showVersion: true,
};
