import React from "react"
import PropTypes from "prop-types";
import { useModal, Modal } from '_core/components/core-library';

const ModalMessage = ({message}) => {
    // MODAL HOOKS
    const { modalVisible, /*toggleModal, showModal,*/ hideModal } = useModal(false);

    return <>
        <Modal
            size={"md"}
            title={"MODAL PROMPT"}
            visible={modalVisible}
            closeButton={false}
            handleClose={() => hideModal()}
            showConfirm={false}
            confirmButtonText={""}
            handleConfirm={() => hideModal()}
        >
            <>
                {message}
            </>
        </Modal>
    </>
}
export default ModalMessage;
ModalMessage.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string
};
ModalMessage.defaultProps = {
    show: false,
    message: "Default Value"
};