import React, { useMemo, useState } from "react";
//import PropTypes from "prop-types";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

export const TEST_DATA_BarTwoLine = () => {
    const data = [];
    for (let i = 1; i <= 12; i++) {
        const item = {
            name: Intl.DateTimeFormat("en", { month: "short" }).format(new Date(i.toString())),
            uv: Math.floor(Math.random() * 1000),
            pv: Math.floor(Math.random() * 1000),
            //  amt: Math.floor(Math.random() * 1000),
        };
        data.push(item);
    }
    return data;
};

const BarChartTwoLine = ({ width = 500, height = 300, style, data, xAxisLabelKey = "name", barOneDataKey = "pv", barTwoDataKey = "uv" }) => {
    const [chartData, setChartData] = useState(data);
    useMemo(() => {
        setChartData(data);
    }, [data]);

    return (
        <ResponsiveContainer width={width} height={height} style={style}>
            <BarChart
                width={width}
                height={height}
                data={chartData}
                margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisLabelKey} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey={barOneDataKey} fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                <Bar dataKey={barTwoDataKey} fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
            </BarChart>
        </ResponsiveContainer>
    );
};
BarChartTwoLine.propTypes = {};
BarChartTwoLine.defaultProps = {};
export default BarChartTwoLine;
