/**
 *  This Service is responsible for calculating our App's real time window dimensions and
 *  Breakpoint that is currently active. 
 * 
 *  The service is called from the UI Redux Slice during Resize events when the useUI provider is in play (all the time)).
 *  The data returned form the service is put into the redux State store "UI" 
 */
class WindowDimensionService {
    
    get_dims() {
        //console.log('get_dims running')

        // WINDOW WIDTH & HEIGHT CALCS
        let window_width = parseInt(document.documentElement.clientWidth);
        let window_height = parseInt(document.documentElement.clientHeight);

        // BODY WIDTH & HEIGHT CALCS
        let body = document.body;
        let html = document.documentElement;
        let body_width = Math.max( body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth );
        let body_height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );

        //BREAK POINT CALCS
        const breakpoints = this.__check_breakpoints();

        return {
            success:true,
            data: {
                window: {
                    width: window_width,
                    height: window_height,
                },
                body: {
                    width: body_width,
                    height: body_height,
                },
                breakpoint : {
                    name: breakpoints?.name,
                    descript: breakpoints?.descript,
                    xs: breakpoints?.xs,
                    sm: breakpoints?.sm,
                    md: breakpoints?.md,
                    lg: breakpoints?.lg,
                    xl: breakpoints?.xl,
                    xxl: breakpoints?.xxl
                }
            }   
        }
    }
    __check_breakpoints() {
        /* Func queries our embedded html for bootstrap breakpoint detection  */
        const xs = this.__checkIfBlock('.breakpoint-check .xs');
        const sm = this.__checkIfBlock('.breakpoint-check .sm');
        const md = this.__checkIfBlock('.breakpoint-check .md');
        const lg = this.__checkIfBlock('.breakpoint-check .lg');
        const xl = this.__checkIfBlock('.breakpoint-check .xl');
        const xxl = this.__checkIfBlock('.breakpoint-check .xxl');
        let name='', descript='';

        if (xs===true) {
            descript = 'xs - <576px';
            name = 'xs';
        } else if (sm===true) {
            descript = 'sm - ≥576px';
            name = 'sm';
        } else if (md===true) {
            descript = 'md - ≥768px';
            name = 'md';
        } else if (lg===true) {
            descript = 'lg - ≥992px';
            name = 'lg';
        } else if (xl===true) {
            descript = 'xl - ≥1200px';
            name = 'xl';
        } else if (xxl===true) {
            descript = 'xxl - ≥1400px';
            name = 'xxl';
        } 

        return { xs, sm, md, lg, xl, xxl, name, descript }
    };
    __checkIfBlock(s) {
        /* Func queries our embedded html for bootstrap breakpoint detection  */
        let bpc_div = document.querySelector(s);
        if (bpc_div===undefined || bpc_div===null) return false;

        const bpc_div_style = window.getComputedStyle(bpc_div, null);
        const display = bpc_div_style.display;
        if (display===undefined || display===null) return false;
        return (bpc_div_style.display==='block') ? true: false
    };
   
}
const newWindowDimensionService  = new WindowDimensionService();
export default newWindowDimensionService;