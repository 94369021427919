import React, { useState, useEffect  } from "react";
import MyDataGrid from "_core/components/core-library/tables/MyDataGrid/MyDataGrid";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import { useAdminGlobal } from "_core/hooks/data-hooks/useAdminGlobal";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";
import { useModal, Modal, DataField, JSONPretty, Button } from "_core/components/core-library";
import { Accordion, Col, Container, Row, Table } from "react-bootstrap";



const defaultGridWrapperStyle={
    borderRadius: 4,
   // backgroundColor: '$APP-colors-info',
}
const defaultGridStyle={
   // backgroundColor: defaultGridWrapperStyle.backgroundColor,
}

const GlobalUsersTable = ({style}) => {
    const { breakpoints} = useUI();


    // GET PRIMARY  DATA for TABLE:
    const { users } = useAdminGlobal();
    useOnMountRunOnce(() => users.fetch());


    // TABLE COLUMNS DEFINITIONS
    const columns = [
        // {
        //     id: "mobile",
        //     header: "RESULTS",
        //     type: "number",
        //     textAlign: "start",
        //     headerAlign: "center",
        //     visible: breakpoints.xs === true,
        //     showColumnMenuTool: false,
        //     flex: 1,
        //     render: ({ data }) => {
        //         return (
        //             <div>
        //                 <div className="d-flex justify-content-between small">
        //                     <div className="text-start text-uppercase">{data?.manufacturer_name}</div>
        //                     <div>NFRC #{data?.nfrc_id}</div>
        //                 </div>
        //                 <div style={{ textAlign: "center" }}>{data?.name}</div>
        //                 <div className="d-flex justify-content-between small">
        //                     <div>VLT: {format_percent(data?.vlt, "unk", 0)}</div>
        //                     <div>{data?.subtype}</div>
        //                     <div>{data?.appearance}</div>
        //                     <div>{format_number(data?.thick_exact_mm, "unk", 1," mm")}</div>
        //                 </div>
        //             </div>
        //         );
        //     },
        // },
    
        {
            name: "displayname",
            header: "Name",
            type: "string",
            textAlign: "start",
            headerAlign: "start",
            visible: breakpoints.xs !== true,
            flex: 1,
            showColumnMenuTool: false,
            render: ({ value }) => {
                return <span className="fw-bold">{value}</span>;
            },
        },
        
       

        { name: "id", defaultWidth: 55, header: "uid", type: "number", visible: true },
        { name: "status", defaultWidth: 60, header: "Status", type: "string", visible: true },
        { name: "email", header: "Email", type: "string", visible: true },
        { name: "mobile", header: "Mobile Ph.", type: "string", visible: true },
        { name: "role", defaultWidth: 75, header: "Role", type: "string", visible: true },
        { name: "timezone_php", header: "Timezone", type: "string", visible: true },
        { name: "session_timeout_secs", defaultWidth: 65, header: "Timeout", type: "number", visible: true },
        { name: "lastlogin", header: "Last Login", type: "string", visible: true },
    ];


    const defaultSortInfo = {
        name: "displayname",
        dir: -1,
    };

    const [selected, setSelected] = useState(null);                 /* eslint-disable-line no-unused-vars */      
    const [selectedRowIDs, setSelectedRowIds] = useState(null);     /* eslint-disable-line no-unused-vars */
    const [selectedData, setSelectedData] = useState(null);         /* eslint-disable-line no-unused-vars */

    const [userData, setUserData] = useState(null); 
   

    const { modalVisible, showModal, hideModal } = useModal(false);


    const handleOnSelectionChange = ({selected, selectedRowIDs, selectedData}) => {
        //console.log('handleOnSelectionChange triggered');console.log('selected',selected);console.log('selectedRowIDs',selectedRowIDs);console.log('selected data',selectedData);
        setSelected(selected);
        setSelectedRowIds(selectedRowIDs);
        setSelectedData(selectedData);

        if (selectedRowIDs!=null && selectedRowIDs.length===1 && selectedData!==undefined && selectedData!==null) {
            setUserData(selectedData)
            showModal();
        }
    };
    const handleRenderRowContextMenu = (menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Row ' + rowProps.rowIndex
            },
            {
                label: 'Want to visit ' + rowProps.data?.displayname + '?'
            }
        ]
    }

    const [isLoading, setIsLoading] = useState(true);   
    useEffect( () => setIsLoading(users?.isFetching),[users?.isFetching] );

    return (
        <>
            <MyDataGrid
                className="global-admin-users-datagrid"

                // Debugging
                debug={false}
                showGridAsList={false}
                showGridAsListColumn={"displayname"}
                showGridAsListJSON={false}

                // Data & Loading
                loading={isLoading}
                //loading={true}
                dataSource={users?.data}
                idProperty="id"
                
                // Style
                style={{...defaultGridWrapperStyle, ...style}}
                gridStyle={{...defaultGridStyle}}
                gridAutoHeight={true}
                gridAutoHeightOffset={-70}

                // Columns & Groups
                columns={columns}
                enableColumnAutosize={true}
                groups={null}

                // Rows
                rowHeight={75}  //{breakpoints.xs === true ? 75 : 75}

                // Sort / Filter / Search
                defaultSortInfo={defaultSortInfo}
                enableFiltering={false}
                defaultFilterValue={null}

                searchFiltersEnabled={false}
                searchFiltersVisible={false}
                // searchFilters={[
                //     {column:'displayname', label:'FILTER BY GLAZING TYPE:', type:'multiSelect'},
                //     {column:'manufacturer_name', label:'FILTER BY MANUFACTURER:', type:'multiSelect'},
                //     {column:'appearance', label:'FILTER BY APPEARANCE/COLOR:', type:'multiSelect'},
                // ]}
                
                searchBoxEnabled={true}
                searchBoxAutofocus={false}

                searchRules={{                              //<-- Rules are fuse.js search options.  See https://fusejs.io/api/options.html
                    keys: ["displayname", "lastname", "firstname", "id"],
                    matchAllOnEmptyQuery: true,
                    threshold: 0.15,                        //<--  A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
                    // limit: 9999999,                      //<--  Limits records shown. 
                }}

                // Labels
                labels = {{
                    records: 'users',
                }}
                
                // Paging  
                pagination={false}
                perPage={50}
                livePagination={false}
                scrollThreshold={0.7}

                // Indexing and Selecting
                activeIndex={null}
                enableSelection={true}
                defaultSelected={null}      // Ex) 24, or '24', or {24:true,25:true}
                checkboxColumn={true}
                multiSelect={true}
                    
                onGridLoad={(gridRef) => {}}
                onGridSelectionChange={handleOnSelectionChange}

                onGridRenderRowContextMenu={handleRenderRowContextMenu}
            />
            <Modal 
                className="global-admin-users-datagrid-modal" 
                title={"USER ACCOUNT PREVIEW"}
                size={"md"}
                visible={modalVisible} 
                closeButton={true} 
                handleClose={()=>hideModal()} 
                showConfirm={false} 
                confirmButtonText={"?"} 
                handleConfirm={()=>hideModal()}

                footerButtons={<Button variant="primary">Close</Button>}
            >
                <Container fluid>
                    <div className="text-center ">UID # {userData?.id}</div>
                    <div className="h5 text-center">{userData?.displayname}</div>
                    <div className="text-center">{userData?.companyname}</div>
    
                    <hr />
                    <Table striped responsive size="sm" className="m-0">
                        <tbody>
                            <tr><th>Username:</th><td>{userData?.username}</td></tr>
                            <tr><th>Email:</th><td>{userData?.email}</td></tr>
                            <tr><th>Mobile Ph:</th><td>{userData?.mobile}</td></tr>
                            <tr><th>Role:</th><td>{userData?.role}</td></tr>
                            <tr><th>Last Login:</th><td>{userData?.lastlogin}</td></tr>
                            <tr><th>Status:</th><td>{userData?.status}</td></tr>
                        </tbody>
                    </Table>
                
                    
                    
                    
                    <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>All DATA</Accordion.Header>
                            <Accordion.Body>
                                <JSONPretty json={userData} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>     
                    <hr />
                    <Row>
                        <Col>
                            <DataField label="Created">
                                {userData?.created}
                                {(userData?.created_by ? " by " + userData?.created_by : "")}
                            </DataField>
                        </Col>
                        <Col>
                            <DataField label="Modified">
                                {userData?.modified}
                                {(userData?.modified_by ? " by " + userData?.modified_by : "")}
                            </DataField>
                        </Col>
                    </Row>
                   
                </Container>
            </Modal>
        </>
    );
};
export default GlobalUsersTable;
