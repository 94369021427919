import React, { useState } from 'react';


import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { Button }from '_core/components/core-library';
import { useUI } from '_core/hooks/provider-hooks/useUI.provider';
import { useOnMountRunOnce } from '_core/hooks/useOnMount';

import inventoryDataService from '_custom/services/inventory-data.service';

const InventoryHome = () => {
	//const { user: current_user } = useSelector((state) => state.auth);
	//if (!current_user) {return <Navigate to={ROUTES.LOGIN} />}
	
	// eslint-disable-next-line
	const [grid_data, setGridData] = useState([]);

	const { toast } = useUI();

	//setGridData([]);

	 useOnMountRunOnce( async () => {
		const response = await inventoryDataService.fetch_inventory(null,null);
        console.log('RESPONSE RECEIVED:',response);
    });

	return (
		<PrivatePageTemplate 
			pageTitle="Inventory Dashboard"
			breadcrumbs={[
                { caption: "My Home", to: "/my" },
            ]}
		>
            <Button onClick={() => {toast.show('test',toast.types.info)}} >Test Toast</Button>

		</PrivatePageTemplate>
	);
}
export default InventoryHome;
