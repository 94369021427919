import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { Form, Input, Button, SubmitButton } from "_core/components/core-library";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";

import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import {
    validateFirstName,
    validateLastName,
    validateEmail,
    validatePhone,
} from "_core/helpers/form-validation";
import { PRIVATE_PATHS, ROUTES } from "_core/config";  /* eslint-disable-line no-unused-vars */

const ProfileUpdate = () => {
    
    // UI HOOKS - we'll use ths to update user here
    const { toast } = useUI();

    // ROUTING HOOKS
    const navigate = useNavigate(); // Get navigate func from router hook.

    // STATE HOOKS
    const [loading, setLoading] = useState(false); // For spinner.
    const [saveError, setSaveError] = useState(null); // For error message on form.

    // AUTH HOOKS - we'll use ths to update user here
    const { current_user, update_current_user, access_token } = useAuth();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        control,
        trigger,
        formState: { isValid }, // eslint-disable-line no-unused-vars       
        setFocus, // eslint-disable-line no-unused-vars
        setValue,   // eslint-disable-line no-unused-vars
        getValues,  // eslint-disable-line no-unused-vars
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */

    //  FUNC:  validateThisField - called
    const validateThisField = (e) => {
        // call useForm.trigger, with id passed in, to validate just this field
        const id = e.target.id;
        trigger(id);
    };

    const onSubmit = async (oFormFields) => {
        setLoading(true);
        setSaveError(null);

        await update_current_user(oFormFields, access_token).then((error) => {
            console.log("update_current_user return " + JSON.stringify(error));
            if (error) {
                if (typeof error == 'object') {
                    error = JSON.stringify(error);
                }
                setSaveError(error); // error object has 'message'
                resetForm();
            } else {
                toast.show(
                    "Your profile was successfully updated!",
                    toast.types.success,    
                    {
                        autoClose: 1500,
                    }
                );
                // // gracefully delay transition to next page.
                setTimeout( () => {
                //    navigate(PRIVATE_PATHS.MY.PROFILE);
                },500) 
                navigate(PRIVATE_PATHS.MY.PROFILE);
                
            }
            setLoading(false);
        });
    };

    return (
        <PrivatePageTemplate 
			pageTitle="Update My Profile"
            breadcrumbs={null}
		>
            <Form
                    className="form-update-my-profile text-center mt-5 ms-auto me-auto"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ maxWidth: "500px" }}
                    disabled={true}
                >   
                    {loading && <Spinner animation="border" />}
                <fieldset disabled={loading}>

                    <Row className="mb-4">
                        <Col sm={true}>
                            <Input
                                value={ (current_user?.firstname ? current_user.firstname : '') }
                                name={"firstname"}
                                control={control}
                                label={"First Name *"}
                                floatingLabel={true}
                                autoComplete={"your first name"}
                                helpText={null}
                                className={"text-start"}
                                type={"text"}
                                size={"lg"}
                                autoFocus={false}
                                onKeyPress={validateThisField}
                                onKeyUp={validateThisField}
                                onBlur={validateThisField}
                                rules={{
                                    required: true,
                                    validate: (val) => {
                                        return validateFirstName(val);
                                    },
                                }}
                                feedback={"A valid first name is required."}
                                feedbackClass="text-start"
                            />
                        </Col>
                        <Col sm={true}>
                            <Input
                                value={ (current_user?.lastname ? current_user.lastname : '') }
                                name={"lastname"}
                                control={control}
                                label={"Last Name *"}
                                floatingLabel={true}
                                autoComplete={"your last name"}
                                helpText={null}
                                className={"text-start"}
                                type={"text"}
                                size={"lg"}
                                autoFocus={false}
                                onKeyPress={validateThisField}
                                onKeyUp={validateThisField}
                                onBlur={validateThisField}
                                rules={{
                                    required: true,
                                    validate: (val) => {
                                        return validateLastName(val);
                                    },
                                }}
                                feedback={"A valid last name is required."}
                                feedbackClass="text-start"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Input
                                value={ (current_user?.email ? current_user.email : '') }
                                name={"email"}
                                control={control}
                                label={"Email *"}
                                floatingLabel={true}
                                autoComplete={"your email address"}
                                helpText={null}
                                className={"text-start"}
                                type={"text"}
                                size={"lg"}
                                autoFocus={false}
                                onKeyPress={validateThisField}
                                onKeyUp={validateThisField}
                                onBlur={validateThisField}
                                rules={{
                                    required: true,
                                    validate: (val) => {
                                        return validateEmail(val);
                                    },
                                }}
                                feedback={"A valid email is required."}
                                feedbackClass="text-start"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Input
                                value={ (current_user?.mobile ? current_user.mobile : '') }
                                name={"mobile"}
                                control={control}
                                label={"Mobile Phone #"}
                                floatingLabel={true}
                                autoComplete={"your mobile phone number"}
                                helpText={null}
                                className={"text-start"}
                                type={"tel"}
                                size={"lg"}
                                autoFocus={false}
                                onKeyPress={validateThisField}
                                onKeyUp={validateThisField}
                                onBlur={validateThisField}
                                rules={{
                                    required: true,
                                    validate: (val) => {
                                        return validatePhone(val);
                                    },
                                }}
                                feedback={"A valid phone # is required."}
                                feedbackClass="text-start"
                            />
                        </Col>
                    </Row>
                    </fieldset>
                    {saveError && (
                        <Alert
                            variant={"warning"}
                            onClose={() => setSaveError(null)}
                            dismissible
                        >
                            <div className="fst-italic small">{saveError}</div>
                        </Alert>
                    )}

                    <Container className="justify-content-md-center">
                        <Button text="Cancel" to={PRIVATE_PATHS.MY.PROFILE} variant="secondary" className="me-4" />
                        <SubmitButton text="Save" disabled={loading}  />
                    </Container>
                   
                </Form>
        </PrivatePageTemplate>
    );
};
export default ProfileUpdate;
