import React from "react";
import { CONSTANTS } from "_core/config";
import FadingBackgroundImage from "../../_common/FadingBackgroundImage";
import FloatingGlassCenteredDiv from "../../_common/FloatingGlassCenteredDiv";
import SignupForm from "./SignupForm";

const RegisterPanel = () => {
    return (
        <>
            <FadingBackgroundImage />

            <FloatingGlassCenteredDiv glassOpacity={0.7} rgb={[204,238,255]}>
                <h1 className="display-6 fw-normal text-primary">Sign up for {CONSTANTS.APP_NAME}!</h1>
                <div className="d-flex flex-row justify-content-center">
                    
                    <p className="text-start" style={{maxWidth:"500px"}}>
                        Register to be notified when {CONSTANTS.APP_NAME} launches! 
                        When we launch, we'll email and/or text you to be sure you one of the 
                        first to try out this new helpful service!
                    </p>
                </div>
                <SignupForm />
            </FloatingGlassCenteredDiv>
        </>
    );
};
export default RegisterPanel;
