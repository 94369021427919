import React, { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";

const GridTotalRecords = (props) => {

    const [totalRecords, setTotalRecords] = useState('test');
    useEffect(() => setTotalRecords(props?.dataSource?.length),[props?.dataSource]);

    const [labels, setLabels] = useState(props?.labels);
    useEffect(() => setLabels(props?.labels),[props?.labels]);

    return (
        <Container fluid className="mdg-meta-text mdg-total-records-text my-0 p-1 text-center small">
            {totalRecords} total {labels?.records}.
        </Container>
    );
}
export default GridTotalRecords;
