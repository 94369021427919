import { getUniquePropertyValuesFromObjects, sortArrayOfObjects } from "_core/helpers/data-types/arrays";

export const prepare_grid_columns = (cols, dataSource) => {
    cols.map((col) => {

        // filterEditorProps - defaults.
        if (col?.filterEditorProps !== undefined && col?.filterEditorProps?.dataSource !== undefined && col?.filterEditorProps.dataSource === "distinct_values") {
            col.filterEditorProps.dataSource = get_filterEditor_select_datasource_distinct_values(col.name, dataSource);
        }

        // showColumnMenuTool  - show column menu default to off
        if (col.showColumnMenuTool===undefined||col.showColumnMenuTool===null) {
            col.showColumnMenuTool=false;
        }
       
        return true;
    });
    return cols;
};

export const get_filterEditor_select_datasource_distinct_values = (column_name, dataSource) => {
    dataSource = dataSource===undefined||dataSource===null ? [] : dataSource;
    let uniqueItemsObjectArray = [];
    getUniquePropertyValuesFromObjects(dataSource, column_name).map((item) => {
        if (item !== undefined && item !== null && item !== "" && item.length > 0) {
            return uniqueItemsObjectArray.push({
                id: item,
                label: item,
            });
        } else {
            return false;
        }
    });
    // return sorted, alphabetically
    return sortArrayOfObjects(uniqueItemsObjectArray, "label");
};