import { useEffect, useState, useCallback } from "react";
import { useNavigate, useOutlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";

import { AppHeaderNavbarPrivate, AppFooterNavbarPrivate } from "_core/components/layouts";
import { HelmetNoSearchEngines, InactivityTimer } from "_core/components/core-library";

import { getAllowedPrivateRoutes } from "_core/config/PrivateRoutes.core.config";
import { LOGOUT_PATH } from "_core/config";
import { PullToRefreshProvider } from "_core/hooks/provider-hooks/usePullToRefresh.provider";
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";
import { Helmet } from "react-helmet-async";

// import { useGlazing } from "hooks/app-hooks/useGlazing";
// import { useGlazingNomThicknesses } from "hooks/app-hooks/useGlazingNomThicknesses";

export const PrivateLayout = () => {
    const outlet = useOutlet();

    // AUTH HOOKS
    const { current_user, verify_user } = useAuth(); /* eslint-disable-line no-unused-vars */
    //  const { isOnline } = useNetworkStatus();

    // ROUTING HOOKS
    const navigate = useNavigate(); // Get navigate func from router hook.

    // THEME HOOKS
    const { THEME } = useTheme();

    // USER CHECK - Bail if no current_user.
    useEffect(() => {
        //  Redirect if no user there.  This must reside in a useEffect() or errors.
        if (!current_user) {
            console.log("current_user not found!. logging OUT!");
            navigate(LOGOUT_PATH);
        }
    }, [current_user, navigate]);

    // FUNC to check the user, or redirect. Must be in a React useCallback Wrapper because we call it from useEffect()
    const verify_the_current_user = useCallback(() => {
        verify_user().then((verified) => {
            console.log("verify_user() returns " + JSON.stringify(verified));
            if (!verified || verified === false) {
                console.log("verify failed. logging OUT! to ", LOGOUT_PATH);
                navigate(LOGOUT_PATH);
            }
        });
    }, [verify_user, navigate]);
    //  USER CHECK on Load - VERIFY AUTH ON PAGE LOAD AND REFRESHES.  useEffect doesn't capture a browser refresh. So this watch is added too.
    useOnMountRunOnce(() => {
        verify_the_current_user();
    });

    // USER CHECK on Route Change - VERIFY AUTH ON ROUTE CHANGES - Watch for Route Changes after mount, and try verifying user at server.  So we can kick out immediately if revoked and page isn't literally refreshed.
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(JSON.stringify(location));
    useEffect(() => {
        // Verify User on Route Changes
        let triggered_location = JSON.stringify(location);
        if (currentLocation == null || currentLocation !== triggered_location) {
            // runs on location, i.e. route, change
            setCurrentLocation(triggered_location); // Mark the state flag, which prevents this from running more than once, on state changes.

            verify_the_current_user();
        }
    }, [location, currentLocation, verify_the_current_user]);

    /*  required background data checks */

    //GET GLAZING PRODUCTS Data loaded to redux store.
    // const { products, fetch_products, isFetching: isFetchingProducts, isError: isErrorProducts } = useGlazing();
    // useOnMountRunOnce( () => {
    //     // if (
    //     //     isFetchingProducts!==true &&
    //     //     isErrorProducts!==true &&
    //     //     (products===undefined||products==null)
    //     // ) {
    //        fetch_products();
    //     //}
    // },[products,fetch_products,isFetchingProducts,isErrorProducts]);

    // GET NOMINAL SIZING DATA loaded to redux store.

    // const { nominal_glass_thicknesses, fetch_nominal_thicknesses, isFetching:isFetching_thickness, isError:isError_thickness } = useGlazingNomThicknesses();
    // useOnMountRunOnce( () => {
    //     if (
    //         isFetching_thickness!==true &&
    //         isError_thickness!==true &&
    //         (nominal_glass_thicknesses===undefined||nominal_glass_thicknesses==null||nominal_glass_thicknesses.length===0)
    //     ) {
    //         fetch_nominal_thicknesses();
    //     }
    // },[nominal_glass_thicknesses, fetch_nominal_thicknesses, isError_thickness, isFetching_thickness]);

    // ------------------------------------------------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------------------------------------------------
    //   RENDER
    // ------------------------------------------------------------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------------------------------------------------------------

    // Get the user's allowed Routes to pass into AppHeaderNavbarPrivate.

    const { UI_DEBUGGER_PANEL } = useUI();

    const allowedRoutes = getAllowedPrivateRoutes();

    return (
        <>
            <HelmetNoSearchEngines />

            <Helmet>
                <meta name="theme-color" content={THEME?.meta?.themeColor}/>
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
            </Helmet>

            <div className={"theme-wrapper " + THEME?.wrapper?.className}>
                <header>
                    <AppHeaderNavbarPrivate routes={allowedRoutes} className="bg-white" />
                </header>

                <section>
                    <UI_DEBUGGER_PANEL />
                    <PullToRefreshProvider>
                        <Container fluid className="px-0">
                            {outlet}
                        </Container>
                    </PullToRefreshProvider>
                    {/* <div className="pseudo-footer" style={{height:"100px"}}></div> */}
                </section>

                <footer>
                    <AppFooterNavbarPrivate />
                    <InactivityTimer debug={false} minutes={parseInt(current_user?.settings?.inactivity_timer_minutes)} warn_minutes={parseInt(current_user?.settings?.inactivity_timer_warn_minutes)} />
                </footer>
            </div>
        </>
    );
};
export default PrivateLayout;
