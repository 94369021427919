import React from "react";
import PrivatePageTemplate from "_core/components/pages/private/_templates/PrivatePageTemplate";
import MyHomeDashboard from "./dash/MyHomeDashboard";

const MyHome = () => {
    return (
        <PrivatePageTemplate pageTitle="My Home Dashboard" showTitle={false} 
            className={"p-0 m-0"}
        >
            <MyHomeDashboard/>
        </PrivatePageTemplate>
    );
};
export default MyHome;
