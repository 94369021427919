import React, { useRef, useEffect } from "react";
import { isTreeScrollable } from "../isScrollable";
import RefreshingContent from "./refreshing-content";
import PullingContent from "./pulling-content";
import { DIRECTION } from "../types";
import "../styles/main.scss";

const PullToRefresh = ({
    isPullable = true,
    canFetchMore = false,
    onRefresh,
    onFetchMore,
    refreshingContent = <RefreshingContent />,
    pullingContent = <PullingContent />,
    children,
    pullDownThreshold = 117, //67,
    fetchMoreThreshold = 150, //100,
    maxPullDownDistance = 145, //95, // max distance to scroll to trigger refresh
    resistance = 2,
    backgroundColor,
    className = "",
}) => {
    const containerRef = useRef(null);
    const childrenRef = useRef(null);
    const pullDownRef = useRef(null);
    const fetchMoreRef = useRef(null);

    const pullToRefreshThresholdBreached = useRef(false);  
    const fetchMoreThresholdBreached = useRef(false); // if true, fetchMore loader is displayed
    const isDragging = useRef(false);
    const startY = useRef(0)
    const currentY = useRef(0);

    useEffect(() => {
        if (!isPullable || !childrenRef || !childrenRef.current) return;
        const childrenEl = childrenRef.current;

        const onTouchMove = (e) => {
            if (!isDragging.current) {
                return;
            }

            if (window.TouchEvent && e instanceof TouchEvent) {
                currentY.current = e.touches[0].pageY;
            } else {
                currentY.current = e.pageY;
            }

            containerRef.current.classList.add("ptr--dragging");

            if (currentY.current < startY.current) {
                isDragging.current = false;
                return;
            }

            if (e.cancelable) {
                e.preventDefault();
            }

            const yDistanceMoved = Math.min((currentY.current - startY.current) / resistance, maxPullDownDistance);

            // Limit to trigger refresh has been breached
            if (yDistanceMoved >= pullDownThreshold) {
                isDragging.current = true;
                pullToRefreshThresholdBreached.current = true;
                containerRef.current.classList.remove("ptr--dragging");
                containerRef.current.classList.add("ptr--pull-down-threshold-breached");
            }

            // maxPullDownDistance breached, stop the animation
            if (yDistanceMoved >= maxPullDownDistance) {
                return;
            }
            pullDownRef.current.style.opacity = (yDistanceMoved / 65).toString();
            childrenRef.current.style.overflow = "visible";
            childrenRef.current.style.transform = `translate(0px, ${yDistanceMoved}px)`;
            pullDownRef.current.style.visibility = "visible";
        };

        const onTouchStart = (e) => {
            isDragging.current = false;
            if (e instanceof MouseEvent) {
                startY.current = e.pageY;
            }
            if (window.TouchEvent && e instanceof TouchEvent) {
                startY.current = e.touches[0].pageY;
            }
            currentY.current = startY.current;
            // Check if element can be scrolled
            if (e.type === "touchstart" && isTreeScrollable(e.target, DIRECTION.UP)) {
                return;
            }
            // Top non visible so cancel
            if (childrenRef.current.getBoundingClientRect().top < 0) {
                return;
            }
            isDragging.current = true;
        };

        const onScroll = (e) => {
            /**
             * Check if component has already called onFetchMore
             */
            if (fetchMoreThresholdBreached.current) return;
            /**
             * Check if user breached fetchMoreThreshold
             */
            if (canFetchMore && getScrollToBottomValue() < fetchMoreThreshold && onFetchMore) {
                fetchMoreThresholdBreached.current = true;
                containerRef.current.classList.add("ptr--fetch-more-threshold-breached");
                onFetchMore().then(initContainer).catch(initContainer);
            }
        };

        const onEnd = () => {
            isDragging.current = false;
            startY.current = 0;
            currentY.current = 0;

            // Container has not been dragged enough, put it back to it's initial state
            if (!pullToRefreshThresholdBreached.current) {
                if (pullDownRef.current) pullDownRef.current.style.visibility = "hidden";
                initContainer();
                return;
            }

            if (childrenRef.current) {
                childrenRef.current.style.overflow = "visible";
                childrenRef.current.style.transform = `translate(0px, ${pullDownThreshold}px)`;
            }

            onRefresh().then(initContainer).catch(initContainer);
        };

        childrenEl.addEventListener("touchstart", onTouchStart, { passive: true });
        childrenEl.addEventListener("mousedown", onTouchStart);
        childrenEl.addEventListener("touchmove", onTouchMove, { passive: false });
        childrenEl.addEventListener("mousemove", onTouchMove);
        window.addEventListener("scroll", onScroll);
        childrenEl.addEventListener("touchend", onEnd);
        childrenEl.addEventListener("mouseup", onEnd);
        document.body.addEventListener("mouseleave", onEnd);

        return () => {
            childrenEl.removeEventListener("touchstart", onTouchStart);
            childrenEl.removeEventListener("mousedown", onTouchStart);
            childrenEl.removeEventListener("touchmove", onTouchMove);
            childrenEl.removeEventListener("mousemove", onTouchMove);
            window.removeEventListener("scroll", onScroll);
            childrenEl.removeEventListener("touchend", onEnd);
            childrenEl.removeEventListener("mouseup", onEnd);
            document.body.removeEventListener("mouseleave", onEnd);
        };
    }, [children, isPullable, onRefresh, pullDownThreshold, maxPullDownDistance, canFetchMore, fetchMoreThreshold, onFetchMore, resistance]);

    /**
     * Check onMount / canFetchMore becomes true
     *  if fetchMoreThreshold is already breached
     */
    useEffect(() => {
        /**
         * Check if it is already in fetching more state
         */
        if (!containerRef?.current) return;
        const isAlreadyFetchingMore = containerRef.current.classList.contains("ptr--fetch-more-threshold-breached");
        if (isAlreadyFetchingMore) return;
        /**
         * Proceed
         */
        if (canFetchMore && getScrollToBottomValue() < fetchMoreThreshold && onFetchMore) {
            containerRef.current.classList.add("ptr--fetch-more-threshold-breached");
            fetchMoreThresholdBreached.current = true;
            onFetchMore().then(initContainer).catch(initContainer);
        }
    }, [canFetchMore, children, fetchMoreThreshold, onFetchMore]);

    /**
     * Returns distance to bottom of the container
     */
    const getScrollToBottomValue = () => {
        if (!childrenRef || !childrenRef.current) return -1;
        const scrollTop = window.scrollY; // is the pixels hidden in top due to the scroll. With no scroll its value is 0.
        const scrollHeight = childrenRef.current.scrollHeight; // is the pixels of the whole container
        return scrollHeight - scrollTop - window.innerHeight;
    };

    const initContainer = () => {
        requestAnimationFrame(() => {
            /**
             * Reset Styles
             */
            if (childrenRef.current) {
                childrenRef.current.style.overflowX = "hidden";
                childrenRef.current.style.overflowY = "auto";
                childrenRef.current.style.transform = `unset`;
            }
            if (pullDownRef.current) {
                pullDownRef.current.style.opacity = "0";
            }
            if (containerRef.current) {
                containerRef.current.classList.remove("ptr--pull-down-threshold-breached");
                containerRef.current.classList.remove("ptr--dragging");
                containerRef.current.classList.remove("ptr--fetch-more-threshold-breached");
            }

            if (pullToRefreshThresholdBreached.current) pullToRefreshThresholdBreached.current = false;
            if (fetchMoreThresholdBreached.current) fetchMoreThresholdBreached.current = false;
        });
    };

    return (
        <div className={`ptr ${className}`} style={{ backgroundColor }} ref={containerRef}>
            <div className="ptr__pull-down" ref={pullDownRef}>
                <div className="ptr__loader ptr__pull-down--loading">{refreshingContent}</div>
                <div className="ptr__pull-down--pull-more">{pullingContent}</div>
            </div>
            <div className="ptr__children" ref={childrenRef}>
                {children}
                <div className="ptr__fetch-more" ref={fetchMoreRef}>
                    <div className="ptr__loader ptr__fetch-more--loading">{refreshingContent}</div>
                </div>
            </div>
        </div>
    );
};

export default PullToRefresh;
