import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

function GridShowAsList({searchTerm, searchResults, columnToDisplay, showAsJSON }) {
    return (
        <Container fluid className="InovuaReactDataGrid--theme-default-light my-0 p-1">
            {searchTerm !== undefined && searchTerm !== null && searchTerm !== "" && searchResults !== undefined && searchResults !== null && (
                <div>
                    <p>Results for "{searchTerm}":</p>
                    <ol>
                        {searchResults.map( (result,idx) => (
                            <li key={idx}>
                                {(showAsJSON===true)
                                ?
                                    JSON.stringify(result)
                                :
                                    result?.[columnToDisplay]
                                }
                            </li>
                        ))}
                    </ol>
                </div>
            )}
        </Container>
    );
}
GridShowAsList.defaultProps = {
	searchTerm: '',
    searchResults: null,
	columnToDisplay: 0,
    showAsJSON: false,
}
GridShowAsList.propTypes = {
	searchTerm: PropTypes.string,
    searchResults: PropTypes.any,
	columnToDisplay: PropTypes.any,
    showAsJSON: PropTypes.bool,
}
export default GridShowAsList;
