import React from "react";
import {Helmet} from "react-helmet-async";

const HelmetNoSearchEngines = () => {
    return (
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
  
    );
};
export default HelmetNoSearchEngines;
