import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// initial state of our Network Status Monitor
const initialState = {
    isMonitoring: false,
    online: true,   // we'll default to being 'online'
    offline: false,
    offline_toast: {
        visible: false,
        message: 'You have lost your internet connection!',
    },
    online_toast: {
        visible: false,
        message: 'Your internet connection is restored!',
    }
};

export const start_network_monitor = createAsyncThunk( "network/monitor/start", async () => { return true; }); 
export const stop_network_monitor = createAsyncThunk( "network/monitor/tops", async () => { return true; }); 

export const set_network_offline  = createAsyncThunk( "network/offline",     async () => { return true; });
export const set_network_online   = createAsyncThunk( "network/online",      async () => { return true; });

const networkSlice = createSlice({
    name: "network",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            // Start Monitor
            .addCase(start_network_monitor.fulfilled, (state, action) => {
                state.isMonitoring = true;
            })

            // Stop Monitor
            .addCase(stop_network_monitor.fulfilled, (state, action) => {
                state.isMonitoring = false;
            })

            // ONLINE
            .addCase(set_network_online.fulfilled, (state, action) => {
                state.online = true;
                state.offline = false;
            })
         
            // OFFLINE
            .addCase(set_network_offline.fulfilled, (state, action) => {
                state.online = false;
                state.offline = true;
            })

            /****************************************************** */
            /*  DEFAULT                                            */
            /****************************************************** */
            .addDefaultCase((state) => {
                // Do something when no actions identified.
            });
    },
});
export default networkSlice;
