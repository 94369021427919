import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ThemeService from "_core/services/ui_theme.service";
import {get_default_theme_definition} from "_core/config/Theme.config";
// initial state of UI
const initialState = {
   
    error:      null,       // error string if occurs
    fetching:   false,    // fetch/load flag
    name:       "default",           // current theme name
    def:        get_default_theme_definition(), // current theme definition object
    default:    "default",         // App Instances's default theme name
   
};


export const set_theme = createAsyncThunk( "theme/set",  async (theme, thunkAPI) => {                                
    try {
        const response = await ThemeService.getTheme( theme );   
        if (response?.success) {
            return (response?.data);
        } else {
            return thunkAPI.rejectWithValue(response?.data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);     
    }
});

const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder

            // THEME
            .addCase(set_theme.pending, (state, action) => {
                state.fetching = true;
            })
            .addCase(set_theme.fulfilled, (state, action) => {
                state.fetching = false;
                state.name = action?.payload?.name;
                state.def = action?.payload?.def;
            })
            .addCase(set_theme.rejected, (state, action) => {
                state = { ...initialState }; 
                state.error = action?.payload?.error;
            })

            /****************************************************** */
            /*  DEFAULT                                            */
            /****************************************************** */
            .addDefaultCase((state) => {
                // Do something when no actions identified.
            });
    },
});
export default themeSlice;
