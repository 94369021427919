import React from "react";
import PublicPageTemplate from "_core/components/pages/public/_templates/PublicPageTemplate";
import { CONSTANTS } from "_core/config";
import RegisterPanel from "./parts/RegisterPanel";

const PublicHome = () => {
    return (
        <PublicPageTemplate pageTitle={"Sign up for " + CONSTANTS.APP_NAME+"!"} breadcrumbs={null} showTitle={false} showBreadcrumbs={false} showLine={false}>
           <RegisterPanel />
        </PublicPageTemplate>
    );
};
export default PublicHome;
