/**                                                         
 *    a REDUX SLICE for:
 * 
 *      IGDB Items Data  (local to the System)
 * 
 *      - handles the response from our service that gathers IGDB Items Data from our API. 
 * 
 *      - Puts IGDB data in the Redux Store
 * 
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isArray } from "_core/helpers/data-types/arrays";

import IGDBDataService from "_custom/services/igdb-data.service";

// initial state of Message
const initialState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    
    products: [],
    search_filters: null,
    products_found:    0,
    products_total:  0,
};

export const fetch_igdb = createAsyncThunk(
    "igdb/fetch",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await IGDBDataService.get_products( search_filters , access_token ); 
            //console.log('IGDB-FETCH  - SLICE - RESPONSE RECEIVED:',response);

            if (response.status === 200 &&  response.data?.success) {
                //console.log('IGDB-FETCH  - VALID SUCCESS - DATA SHOULD BE THERE!');
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const igdbDataSlice = createSlice({
    name: "igdb",
    initialState,
    reducers: {
        clearState: (state) => {
            state = JSON.parse(JSON.stringify(initialState));   // deep copy initialState t state. 
            return state;
        },
    },

    extraReducers: (builder) => {
        builder
            /****************************************************** */
            /*  fetch_igdb products                                  */
            /****************************************************** */
            .addCase(fetch_igdb.pending, (state, action) => {
                if (state.products===undefined || !isArray(state.products)) {
                    state.products = initialState.products
                }
                state.isFetching = true;
            })
            .addCase(fetch_igdb.fulfilled, (state, action) => {
                const result = action.payload;                            /// this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;  
                //console.log('data',data);
                state.products          = data?.products;
                state.products_found    = data?.products_found;
                state.products_total    = data?.products_total;

                state.isFetching        = false;
                state.isSuccess         = true;
                state.isError           = false;
                state.errorMessage      = 'No errors.';
            })
            .addCase(fetch_igdb.rejected, (state, action) => {
                //console.log('IGDB fetch payload', action.payload);
                state.isFetching        = false;
                state.isSuccess         = false;
                state.isError           = true;
                state.errorMessage      = 'IGDB Item fetch failed.';
    
                state.products          = initialState.products;
                state.products_found    = initialState.products_found;
                state.products_total    = initialState.products_total;
            })
            /****************************************************** */
            /*  DEFAULT                                            */
            /****************************************************** */
            .addDefaultCase((state) => {
                // Do something when no actions identified.
                state.isFetching        = false;
            });
    },
});
export default igdbDataSlice;

export const { clearState } = igdbDataSlice.actions;