import React from "react";
import PropTypes from "prop-types";

import { Button, useModal, Modal, Icon } from "_core/components/core-library";

const HelpButton = ({ className, children, btnText, iconSize, content, modalSize, modalTitle }) => {
    // MODAL HOOKS
    const { modalVisible, showModal, hideModal } = useModal(false);

    return (
        <div className={className}>
            <Button onClick={showModal} variant="link" className="p-0 m-0">
                <Icon name="help" size={iconSize} />
                {btnText}
                {children}
            </Button>

            <Modal size={modalSize} title={modalTitle} visible={modalVisible} closeButton={false} handleClose={() => hideModal()} showConfirm={true} confirmButtonText={"Close"} handleConfirm={() => hideModal()}>
                <>{content}</>
            </Modal>
        </div>
    );
};
HelpButton.propTypes = {
    className: PropTypes.string,
    btnText: PropTypes.string,
    iconSize: PropTypes.string,
    modalSize: PropTypes.string,
    modalTitle: PropTypes.any,
};
HelpButton.defaultProps = {
    className: '',
    btnText: null,
    iconSize: "1x", //  font awesome icon size
    modalSize: "md",
    modalTitle: "HELP",
};
export default HelpButton;
