import React from "react";
import { Route, Routes } from "react-router-dom";

import { PrivateLayout, PublicLayout } from "_core/components/layouts";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

const AppRouter = () => {
    return (
        <Routes>
            <Route element={<PublicLayout />}>
                { /* include array of route components  - must be array of <Route /> components.  See https://stackoverflow.com/a/62539211 */
                   PublicRoutes
                    // note: tried to use aa <Route /> component here, but it didn't work.  So we use the array of <Route /> components instead.
                    // otherwise logout routing was an endless loop..
                }
                {/* <Route path="/*" element={<PublicRoutes />} /> */}
            </Route>
            <Route element={<PrivateLayout />}>
                <Route path="/*" element={<PrivateRoutes />} />
            </Route>
        </Routes>
    );
};
export default AppRouter;
