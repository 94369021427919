import React from "react";
import PrivatePageTemplate from "_core/components/pages/private/_templates/PrivatePageTemplate";
import { Row, Col, Container, Table } from "react-bootstrap";
import { DataField, Button, CodeFormatter, Tabs, Tab } from "_core/components/core-library";
import { PRIVATE_PATHS } from "_core/config";
import { DataContainer, DataDisplayField } from "_core/components/core-library/data-field/DataDisplay";
import { DataDisplayTable, DataRow } from "_core/components/core-library/data-field/DataDisplayTable";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";

const DEV_DataFieldTest = () => {
    const { current_user } = useAuth();

    const { THEME } = useTheme();

    return (
        <PrivatePageTemplate
            pageTitle="DEV: Data Fields Component Test"
            breadcrumbs={[
                { caption: "My Home", to: "/my" },
                { caption: "Developer Home", to: "/dev" },
            ]}
        >
            <Container className="">
                <Row className="mb-5">
                    <h3>DataContainer / DataDisplayField</h3>
                    <DataContainer>
                        <DataDisplayField label="Name" data={current_user?.displayname} />
                        <DataDisplayField label="Email" data={current_user?.email} />
                        <DataDisplayField label="Mobile Phone" data={current_user?.mobile} />
                    </DataContainer>
                </Row>
                <Row className="mb-5">
                    <h3>DataDisplayTable DataRow</h3>
                    <DataDisplayTable title="My Account" className="" striped={true}>
                        <DataRow label="Name" data={current_user?.displayname} />
                        <DataRow label="Email" data={current_user?.email} />
                        <DataRow label="Mobile Phone" data={current_user?.mobile} />
                    </DataDisplayTable>
                </Row>
                <Row className="mb-5">
                    <h3>Standard Bootstrap Table</h3>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th colSpan="2">My Account</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{current_user?.displayname}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{current_user?.email}</td>
                            </tr>
                            <tr>
                                <td>Mobile Phone</td>
                                <td>{current_user?.mobile}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
                <Row className="mb-5">
                    <h3>Container Row Col DataField</h3>
					<Tabs defaultActiveKey="rendered">
						<Tab title="Rendered" eventKey="rendered">
							<Container>
								<Row>
									<Col>
										<DataField label="Name" data={current_user?.displayname + "  /  " + current_user?.email} />
									</Col>
								</Row>
								<Row>
									<Col>
										<DataField label="uid" data={current_user?.uid} />
									</Col>
									<Col>
										<DataField label="cid" data={current_user?.cid} />
									</Col>
									<Col>
										<DataField label="pcid" data={current_user?.pcid} />
									</Col>
								</Row>
							</Container>
						</Tab>
						<Tab title="Code" eventKey="code">
							<CodeFormatter showLineNumbers={false}>
{`
	<Container>
		<Row>
			<Col>
				<DataField label="Name" data={current_user?.displayname + "  /  " + current_user?.email} />
			</Col>
		</Row>
		<Row>
			<Col>
				<DataField label="uid" data={current_user?.uid} />
			</Col>
			<Col>
				<DataField label="cid" data={current_user?.cid} />
			</Col>
			<Col>
				<DataField label="pcid" data={current_user?.pcid} />
			</Col>
		</Row>
	</Container>
`}
							</CodeFormatter>
						</Tab>
					</Tabs>
                    

                    
                </Row>
            </Container>
        </PrivatePageTemplate>
    );
};
export default DEV_DataFieldTest;
