/**
 *      Native Input Function
 * 
 *      set_input_value()  - use to set the inputs value. versus astraight .value call. 
 *              - So an onchange event is triggered when value set.
 *              - Otherwise React swallows these events
 *              - Ueful for custom components with native <input> tags.   Example )  See : our OTP_Input.js component. 
 * 
 * 
 *      Idea Borrowed from:     https://stackoverflow.com/questions/42550341/react-trigger-onchange-if-input-value-is-changing-by-state
 *              "In short, when we change the value of input by changing state and then dispatch a change event then React will register 
 *               both the setState and the event and consider it a duplicate event and swallow it. The solution is to call native value 
 *               setter on input (See setNativeValue function in following code"
 *          
 */

export function set_input_value_by_id(inputId, value) {
    if (!document.getElementById(inputId)) {
        alert('Error! -  input not found!');
        return;
    }
    set_input_value( document.getElementById(inputId),value);
}

export function set_input_value(inputElement, value) {
    setNativeValue(inputElement, value);            // set the value of the input usng 
   // inputElement.dispatchEvent(new Event('input', { bubbles: true }));

    const event = new InputEvent('change', {
        view: window,
        bubbles: true,
        cancelable: true
    });
    inputElement.dispatchEvent(event);

}

// THIS FUNCTION CALLS NATIVE VALUE SETTER
function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}