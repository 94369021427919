import React from "react";
import PropTypes from "prop-types";
import MyMultiSelect from "./_my_modules/my-multi-select";

const MultiSelect = ({ options, defaultValue, value, onChange, closeMenuOnSelect }) => {
    return (
        <MyMultiSelect 
            closeMenuOnSelect={closeMenuOnSelect} 
            options={options} 
            defaultValue={defaultValue} 
            value={value} 
            onChange={onChange} 
        />
    );
};
MultiSelect.defaultProps = {
    value: [],
    defaultValue: [],

    closeMenuOnSelect: false,
};
MultiSelect.propTypes = {
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.object),
    defaultValue: PropTypes.arrayOf(PropTypes.object),

    closeMenuOnSelect: PropTypes.bool,
};

export default MyMultiSelect;
