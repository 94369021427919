import React from "react";
//import { useNavigate } from "react-router-dom";

import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { Row, Col,Container, Table } from "react-bootstrap";
import { DataField, Button, CardGrid } from "_core/components/core-library";
import { PRIVATE_PATHS } from "_core/config";
import { DataContainer, DataDisplayField } from "_core/components/core-library/data-field/DataDisplay";
import { DataDisplayTable, DataRow } from "_core/components/core-library/data-field/DataDisplayTable";
import { useNavigate } from "react-router-dom";
//import { utc_date_string_to_local_date_string } from "helpers/datetime"; 
//import { CONSTANTS } from "_core/config"; 


const Profile = () => {
    // AUTH HOOKS
    const { current_user } = useAuth();          
                   
    // ROUTING HOOKS
    const navigate = useNavigate();                                                                    
    
    const card_data = [
        {
            title: "Profile",
            description:null,
            content:(
                <DataDisplayTable className="" labelClass="fw-bold" striped={true}>
                    <DataRow label="Name" data={current_user?.displayname} />
                    <DataRow label="Email" data={current_user?.email} />
                    <DataRow label="Mobile Phone" data={current_user?.mobile} />
                </DataDisplayTable>
            ),
            showButton: true,
            btnVariant: 'outline-primary',
			btnText:'Manage My Profile', 
			btnOnClick:() => navigate(PRIVATE_PATHS.MY.PROFILE)
        },
        {
            title: "Subscription",
            description:null,
            content:(
                <DataDisplayTable className="" labelClass="fw-bold" striped={true}>
                    <DataRow label="Account Status" data={current_user?.active} />
                    <DataRow label="Subscription" data={current_user?.subscription} />
                    <DataRow label="Expires" data={current_user?.expires} />
                    
                </DataDisplayTable>
            ),
            showButton: true,
            btnVariant: 'outline-primary',
			btnText:'Manage My Subscription', 
			btnOnClick:() => navigate(PRIVATE_PATHS.MY.SUBSCRIPTION)
        },
    ];

    return (
        <PrivatePageTemplate 
			pageTitle="My Account"
			breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
            ]}
		>
            <Container fluid className="d-flex justify-content-center">
                <CardGrid 
                    data={card_data}
                    cols_sm={1} 
                    cols_md={2} 
                    cols_lg={2} 
                    cols_xl={2}         
                />
            </Container>

		</PrivatePageTemplate>
    );
};
export default Profile;
