import { SECURE_API } from "_core/services/_API_bridge";
import { verify_FormData } from "_core/helpers/FormData/FormData-verify";

class IGSDBDataService {  
    get_settings = async (search_filters=null, access_token) => {
        try {
            //search_filters = {columns:['api_key']};
            search_filters = verify_FormData(search_filters);                                     // call our helper that converts object data to FormData if needed.
            let response = await SECURE_API( "POST" , "igsdb/settings/get/" , search_filters, access_token ); 
          //  console.log("IGSDB get_settings response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    test_connection = async (search_filters=null, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     // call our helper that converts object data to FormData if needed.
            console.log("IGDB test_connection search_filters:", search_filters);
            let response = await SECURE_API( "POST" , "igsdb/test/" , search_filters, access_token ); 
           console.log("IGDB test_connection response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    synchronize_igsdb = async (search_filters=null, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     
            let response = await SECURE_API( "POST" , "igsdb/sync/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
    delete_local_glazing_products = async (productIDs, access_token) => {
        try {
            console.log('deleting ids: ', productIDs)
            const search_filters = verify_FormData({productIDs});                                     
            let response = await SECURE_API( "POST" , "igsdb/local/glazing/delete/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
    get_local_glazing_products = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     
            let response = await SECURE_API( "POST" , "igsdb/local/glazing/read/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
    get_local_shading_products = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);
            let response = await SECURE_API( "POST" , "igsdb/local/shading/read/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
    get_remote_glazing_products = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters); 
            let response = await SECURE_API( "POST" , "igsdb/remote/glazing/read/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
    get_remote_shading_products = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                    
            let response = await SECURE_API( "POST" , "igsdb/remote/shading/read/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
   
}
const newIGSDBDataService  = new IGSDBDataService();
export default newIGSDBDataService;