import moment from "moment";


/**
 * utc_date_string_to_local_date_string -  This function used for parsing Auth Token dates mainly.  Do not alter without testing ramifications. 
 *  
 * @param {string} utc_date_string - a datetime string in utc. The format MUST be YYYY-MM-DD HH:mm:ss
 * @returns {string} - local date string. Default return is 'YYYY-MM-DD HH:mm:ss'  (same as in). 
 */
export const utc_date_string_to_local_date_string = (utc_date_string, return_format='YYYY-MM-DD HH:mm:ss') => {
    let local =  moment(utc_date_string + '+00:00').local().format(return_format)
    return local;
};