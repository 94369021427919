/**                                                                                     By: Paul Hardy 7/1/2022
 *      <CountdownTimer /> COMPONENT
 *      -  Countdown timer for display or values on page.
 * 
 *      Example)
 *              
 *              <CountdownTimer autostart 
                    timestamp={forgot.send.expiresLOCAL}    // enter on or other
                    timestamp_utc={forgot.send.expiresUTC} 

                    label={"The authorization code expires in"}
                    format={"mm:ss"}

                    className={"mb-3"}
                    labelClass={"me-1"}
                    timeClass={"fw-bold"}

                    onTimeout={timeout_handler}
                />    
 * 
 */

import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { useOnMountRunOnce } from "_core/hooks/useOnMount";

/**
 * useInterval - custom func for this component that creates a interval loop callback.  
 * @param {func} callback - func/code to run at every interval cycle
 * @param {number} delay -  pass in millisends value of interval size (default 1 second), or null to stop the cycle. 
 */
function useInterval(callback, delay=1000) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}


/**
 * STATUS Types for the timer's status state
 */
const STATUS = {
    STARTED: "Started",
    STOPPED: "Stopped",
};

/**
 * CountdownTimer Component - Displays Timer on Page.
 * @param {object} props -  See CountdownTimer.propTypes definition at end.
 * @returns 
 */
export const CountdownTimer = (props) => {
    
    // Seconds State management.
    const [seconds, setSeconds] = useState(null);
    
    // Timer start/stop status, based on props.
    const getInitialStart = () => {
        const { autostart } = props; 
        return autostart===true ? STATUS.STARTED : STATUS.STOPPED;
    }
    const [status, setStatus] = useState(getInitialStart);

    // START/STOP HANDLES - could use these handles for buttons. 
    const handleStart = () => {             /* eslint-disable-line no-unused-vars */
        setStatus(STATUS.STARTED);
    };
    const handleStop = () => {              /* eslint-disable-line no-unused-vars */
        setStatus(STATUS.STOPPED);
    };

    // runs at on Mount and Runs Once
    useOnMountRunOnce( () => {
        // Returns time left between now and a timestamp target
        const getTimestampDiff = (timestamp, unit='seconds') => {
           let endTime = moment(timestamp, props.ts_format);
           let startTime = moment();
           let diff = endTime.diff(startTime, unit);
           //console.log('diff = '+diff)
           return (diff<0 ? 0 : diff);
       }
       const getInitialSeconds = () => {
           if (props?.seconds) return parseInt(props.seconds);
           else if (props?.timestamp) return getTimestampDiff(props.timestamp);
           else if (props?.timestamp_utc) {
                // convert  UTC time string (2023-04-23 10:29:40) to local
                let local_timestamp = moment.utc(props.timestamp_utc).local().format('YYYY-MM-DD HH:mm:ss');
                return getTimestampDiff(local_timestamp);
           }
           else return 0;
       }

       // first get normal start seconds. 
       let startSeconds = getInitialSeconds();

       // calculate any offset seconds optionally passed in. 
       if (props?.offset_seconds && parseInt(props.offset_seconds)!==0) {
            startSeconds += parseInt(props.offset_seconds);
       } 

       // Set seconds initial value to State
       setSeconds(startSeconds);
   });

    useInterval(
        () => {
            if (seconds > 0) {
                
                setSeconds( seconds - 1 );

               // console.log('tick tock ='+seconds);
               
            } else {
                setStatus(STATUS.STOPPED);
    
                if (props.onTimeout!==undefined && props.onTimeout!==null) {
                    props.onTimeout()
                };
            }
        },
        (status === STATUS.STARTED ? 1000 : null)  // passing null to useInterval stops the interval
    );

    const {visible, label, labelExpired, className, labelClass, timeClass, labelExpiredClass} = props;
    
    if (!visible) {
        return null;
    }

    return (
        <div className={className}>
            {(seconds>0) &&
                <>
                    <span className={labelClass} >{label}</span>
                    <span className={timeClass} >{seconds}</span>
                </>
            }
            {(seconds<=0) &&
                <span className={labelExpiredClass} >{labelExpired}</span>
            }
        </div>
    );
}
CountdownTimer.propTypes = {
    seconds:      PropTypes.number,    // Pass in seconds to have timer use that value to countdown from.  OR ...
    timestamp:    PropTypes.string,    // Format: "YYYY-MM-DD hh:mm:ss" in LOCAL Optional pass timestamp in props, to start countdown to that timestamp passed in
    timestamp_utc:PropTypes.string,    // Format: "YYYY-MM-DD hh:mm:ss" in UTC Optional pass timestamp in props, to start countdown to that timestamp passed in
    offset_seconds: PropTypes.number,  // seconds to add or subtract to the timestamp to alter the target time. 


    visible:      PropTypes.bool,      // visible on screen?

    autostart:    PropTypes.bool,      // Automatically start the timer at Render?   
    onTimeout:    PropTypes.func,      // Function or call back when timer's time ends. 
    label:        PropTypes.string,    // An optional string label pre output
    labelExpired: PropTypes.string,    // An optional string label that replaces label when timeout occurs. 

    ts_format:  PropTypes.string,    // The moment format of the timestamp passed in. 

    className:  PropTypes.string,    // Class of outer <div> wrapper
    labelClass: PropTypes.string,    // Class of label <span>
    labelExpiredClass: PropTypes.string,    // Class of label <span>
    timeClass:  PropTypes.string,    // Class of time output <span>
};
CountdownTimer.defaultProps = {
    timestamp:  null,
    timestamp_utc:  null,
    offset_seconds:  null,
    seconds:    null,

    visible:    true,
    
    autostart:  true,
    onTimeout:  null,
    label:      null,
    labelExpired: null,

    ts_format:  "YYYY-MM-DD hh:mm:ss",

    className:  null,
    labelClass: "small fw-bolder fst-italic",
    labelExpiredClass: "text-danger",
    timeClass:  null,
}
export default CountdownTimer;