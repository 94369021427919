
import { AllRoles } from "_core/config/Roles.config";
import { CUSTOM_PUBLIC_PATHS, CUSTOM_PRIVATE_PATHS } from "./PATHS.config";
import { MyHome, IGSDB_Admin, IGSDB_Admin_Settings, IGSDB_Admin_LocalDataManager, InventoryHome } from "_custom/components/pages-private"
import { IGDB_Browse, IGDB_Home } from "_custom/components/pages-private";
import { CustomPublicHome } from "_custom/components/pages-public";

export const CUSTOM_PUBLIC_ROUTES = [
    {
		title: "Home",
        description: "Coming Soon",
        path: CUSTOM_PUBLIC_PATHS.HOME,
        component: CustomPublicHome,
    },
];
export const CUSTOM_PRIVATE_ROUTES = [
	{
        title: "My Home",
        description: "User's home landing page.",
		dev_notes: "This is the default landing page for the app. We are overriding the core MyHome component with our own custom component.",
        icon: "nav-my-home",
        path: CUSTOM_PRIVATE_PATHS.MY.HOME,
        component: MyHome,
        permission: AllRoles(),
    },
	{
		title: 'Inventory',
		description: 'Inventory Home',
		icon: "list",
		path: CUSTOM_PRIVATE_PATHS.INVENTORY,
		component: InventoryHome,
	},
	{
		title: 'IGDB Home',
		description: 'IGDB Management Home',
		icon: "list",
		path: CUSTOM_PRIVATE_PATHS.IGDB,
		component: IGDB_Home,
		permission: AllRoles(),
	},
	{
		title: 'IGDB Browse',
		description: 'IGDB Browse Utility',
		icon: "list",
		path: CUSTOM_PRIVATE_PATHS.IGDB_BROWSE,
		component: IGDB_Browse,
		permission: AllRoles(),
	},
	{
		title: 'IGSDB Admin',
		description: 'IGSDB Admin Home',
		icon: "list",
		path: CUSTOM_PRIVATE_PATHS.GLOBAL.IGSDB.HOME,
		component: IGSDB_Admin,
		permission: AllRoles(),
		children: [
			{
				title: 'IGSDB Settings',
				description: 'IGSDB Connectivity Settings Management',
				icon: "list",
				path: CUSTOM_PRIVATE_PATHS.GLOBAL.IGSDB.SETTINGS,
				component: IGSDB_Admin_Settings,
			},
			{
				title: 'IGSDB Data Manager',
				description: 'IGSDB Local Data Management',
				icon: "list",
				path: CUSTOM_PRIVATE_PATHS.GLOBAL.IGSDB.MANAGER,
				component: IGSDB_Admin_LocalDataManager,
			},
		]
	},
	
];