import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import IGDB_BROWSER from './IGDB_BROWSER';

const IGDB_Browse = () => {
	return (
		<PrivatePageTemplate 
			pageTitle="Browse the IGDB"
			breadcrumbs={[
				{ caption: "My Home", to: "/my" },
			]}
		>
            <IGDB_BROWSER />

		</PrivatePageTemplate>
	);
}
export default IGDB_Browse;