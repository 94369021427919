import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ToastService from "_core/services/ui_toast.service";
import WindowDimensionService from "_core/services/ui_dimension.service";

// initial state of UI
const initialState = {

    onresize: {
        onresize_timer  : null,      // throttles onresize function calls for better performance. id holder.
        onresize_delay    : 250,     //  milliseconds to lag before running on resize func calls.  (throttle time)
        onresize_calls    : 0, 
    },

    dimensions: {
        WINDOW_WIDTH :  window.innerHeight,       // the window's width in pixels (calculated). - default is getting value from browser innerHeight
        WINDOW_HEIGHT : window.innerWidth ,       // the window's height in pixels (calculated).   - default is getting value from browser innerWidth
        BODY_WIDTH :  null,         // the doc body's width in pixels (calculated). 
        BODY_HEIGHT : null,         // the doc body's height in pixels (calculated). 
    },
    breakpoint: {
        name : null,     // Ex) 'lg'  string code of current calculated rendered breakpoint.
        descript : null, // Ex) 'lg = ≥1200px'  ( a full description  of the current breakpoint) (calculated)

        xs : null,                  // Bootstrap breakpoint (boolean) (calculated).
        sm : null,                  // Bootstrap breakpoint (boolean) (calculated).
        md: null,                   // Bootstrap breakpoint (boolean) (calculated).
        lg : null,                  // Bootstrap breakpoint (boolean) (calculated).
        xl : null,                  // Bootstrap breakpoint (boolean) (calculated).
        xxl : null,                 // Bootstrap breakpoint (boolean) (calculated).
    },

    main_menu: {
        visible: false,
        title: 'Main Menu',
        type:'offcanvas',    // todo: implement switch to 'sidebar' type
        items: [],
    },
    user_menu: {
        visible: false,
        title: 'User Menu',
        type:'offcanvas',    // todo: implement switch to 'sidebar' type
        items: [],
    },

    message_menu: {
        visible: false,
        title: 'Message Menu',
        type:'offcanvas',    // todo: implement switch to 'sidebar' type
        items: [],
    },

    toast: {       
        last:null,     
        shown:[],
    },


    debugger_panel: {
        visible: false,
        float: false,
        floatClass: 'ui-debugger-panel-floating',
        floatClassApplied: '',
    },

    debugger_modal: {
        visible: false,
    },
 
};

export const calculate_ui_dimensions = createAsyncThunk( "ui/dimensions/calculate",  async (dimension, thunkAPI) => {                                
    try {
        const response = await WindowDimensionService.get_dims();   
        //console.log('dims response',response);
        if (response?.success) {
            return (response);
        } else {
            return thunkAPI.rejectWithValue(response);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);     
    }
});

export const reset_all        = createAsyncThunk( "ui/reset/all",           async () => { return true; });
export const reset_menus      = createAsyncThunk( "ui/reset/menus",         async () => { return true; });

// main menu  (flyout offcanvas menu) visibility
export const main_menu_reset  = createAsyncThunk( "ui/menu/main/reset",     async () => { return true; });
export const main_menu_show   = createAsyncThunk( "ui/menu/main/show",      async () => { return true; });
export const main_menu_hide   = createAsyncThunk( "ui/menu/main/hide",      async () => { return true; });
export const main_menu_toggle = createAsyncThunk( "ui/menu/main/toggle",    async () => { return true; });

// user profile menu  (flyout offcanvas menu) visibility
export const user_menu_reset  = createAsyncThunk( "ui/menu/user/reset",      async () => { return true; });
export const user_menu_show   = createAsyncThunk( "ui/menu/user/show",      async () => { return true; });
export const user_menu_hide   = createAsyncThunk( "ui/menu/user/hide",      async () => { return true; });
export const user_menu_toggle = createAsyncThunk( "ui/menu/user/toggle",    async () => { return true; });

// messages menu  (flyout offcanvas menu) visibility
export const message_menu_reset  = createAsyncThunk( "ui/menu/message/reset",  async () => { return true; });
export const message_menu_show   = createAsyncThunk( "ui/menu/message/show",   async () => { return true; });
export const message_menu_hide   = createAsyncThunk( "ui/menu/message/hide",   async () => { return true; });
export const message_menu_toggle = createAsyncThunk( "ui/menu/message/toggle", async () => { return true; });

 export const toast_dismissAll = createAsyncThunk( "ui/toast/dismiss/all",  async () => {                                
    try {
        const response = await ToastService.dismissAllToast();   
        if (response?.success) {
            return (response?.data);
        } 
    } catch (error) {
        return error    
    }
});
export const toast_show = createAsyncThunk( "ui/toast/show",  async (toastobject, thunkAPI) => {                                
    try {
        const response = await ToastService.show( toastobject );   
        if (response?.success) {
            return (response?.data);
        } else {
            return thunkAPI.rejectWithValue(response?.data);
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error);     
    }
});


// DEBUGGER visibility - Div Panel
export const debugger_panel_show = createAsyncThunk( "ui/debug/panel/show",   async () => { return true; });
export const debugger_panel_hide = createAsyncThunk( "ui/debug/panel/hide",   async () => { return true; });
export const debugger_panel_toggle=createAsyncThunk( "ui/debug/panel/toggle", async () => { return true; });
export const debugger_panel_float_toggle=createAsyncThunk( "ui/debug/panel/float_toggle", async () => { return true; });
// DEBUGGER visibility - Modal
export const debugger_modal_show = createAsyncThunk( "ui/debug/modal/show",   async () => { return true; });
export const debugger_modal_hide = createAsyncThunk( "ui/debug/modal/hide",   async () => { return true; });
export const debugger_modal_toggle=createAsyncThunk( "ui/debug/modal/toggle", async () => { return true; });


const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder

            // Reset ALL
            .addCase(reset_all.fulfilled, (state, action) => {
                state = { ...initialState };
            })

            // Reset Menu 
            .addCase(reset_menus.fulfilled, (state, action) => {
                state.main_menu = { ...initialState.main_menu };    // Main Menu - Reset
                state.user_menu = { ...initialState.user_menu };    // User Menu - Reset
                state.message_menu = { ...initialState.message_menu };    // Messages Menu - Reset
            })

            // DIMENSIONS
            .addCase(calculate_ui_dimensions.pending, (state, action) => {
               
            })
            .addCase(calculate_ui_dimensions.fulfilled, (state, action) => {
                const result = action.payload;                            
                const data = result?.data;  

                state.dimensions.WINDOW_WIDTH = data?.window.width;
                state.dimensions.WINDOW_HEIGHT = data?.window.height;
                state.dimensions.BODY_WIDTH = data?.body.width;
                state.dimensions.BODY_HEIGHT = data?.body.height;

                state.breakpoint.name = data?.breakpoint?.name;
                state.breakpoint.descript = data?.breakpoint?.descript;
                state.breakpoint.xs = data?.breakpoint?.xs;
                state.breakpoint.sm = data?.breakpoint?.sm;
                state.breakpoint.md = data?.breakpoint?.md;
                state.breakpoint.lg = data?.breakpoint?.lg;
                state.breakpoint.xl = data?.breakpoint?.xl;
                state.breakpoint.xxl = data?.breakpoint?.xxl;
                

            })
            .addCase(calculate_ui_dimensions.rejected, (state, action) => {
                const result = action.payload;                            
                //const data = result?.data; 

                state.dimensions = { ...initialState.dimensions }; 
                state.dimensions.error = result?.error;

                state.breakpoint = { ...initialState.breakpoint }; 
                state.breakpoint.error = result?.error;
            })

            
           
            // LEFT SIDEBAR - MAIN MENU
            .addCase(main_menu_reset.fulfilled, (state, action) => {
                state.main_menu = { ...initialState.main_menu };
            })
            .addCase(main_menu_show.fulfilled, (state, action) => {
                state.main_menu.visible = true;
            })
            .addCase(main_menu_hide.fulfilled, (state, action) => {
                state.main_menu.visible = false;
            })
            .addCase(main_menu_toggle.fulfilled, (state, action) => {
                state.main_menu.visible = !state.main_menu?.visible;
            })

            // RIGHT SIDEBAR - USER MENU
            .addCase(user_menu_reset.fulfilled, (state, action) => {
                state.main_menu = { ...initialState.user_menu };
            })
            .addCase(user_menu_show.fulfilled, (state, action) => {
                state.user_menu.visible = true;
            })
            .addCase(user_menu_hide.fulfilled, (state, action) => {
                state.user_menu.visible = false;
            })
            .addCase(user_menu_toggle.fulfilled, (state, action) => {
                state.user_menu.visible = !state.user_menu?.visible;
            })


            // TOP OFFCANVAS - MESSAGES MENU
            .addCase(message_menu_reset.fulfilled, (state, action) => {
                state.message_menu = { ...initialState.message_menu };
            })
            .addCase(message_menu_show.fulfilled, (state, action) => {
                state.message_menu.visible = true;
            })
            .addCase(message_menu_hide.fulfilled, (state, action) => {
                state.message_menu.visible = false;
            })
            .addCase(message_menu_toggle.fulfilled, (state, action) => {
                state.message_menu.visible = !state.message_menu?.visible;
            })
           

             // TOAST
             .addCase(toast_show.fulfilled, (state, action) => {
                const payload = action?.payload;
                state.toast.last = payload?.toast;
                state.toast.response = payload?.response;
                state.toast.shown.push(payload?.toast); 
            })


            // DEBUGGER Panel Visibility
            .addCase(debugger_panel_show.fulfilled, (state, action) => {
                state.debugger_panel.visible = true;
            })
            .addCase(debugger_panel_hide.fulfilled, (state, action) => {
                state.debugger_panel.visible = false;
            })
            .addCase(debugger_panel_toggle.fulfilled, (state, action) => {
                state.debugger_panel.visible = !state.debugger_panel?.visible;
            })
            .addCase(debugger_panel_float_toggle.fulfilled, (state, action) => {
                state.debugger_panel.float = !state.debugger_panel?.float;
                state.debugger_panel.floatClassApplied = state.debugger_panel.float===true ? initialState.debugger_panel.floatClass : '';
            })
            // DEBUGGER Modal Visibility
            .addCase(debugger_modal_show.fulfilled, (state, action) => {
                state.debugger_modal.visible = true;
            })
            .addCase(debugger_modal_hide.fulfilled, (state, action) => {
                state.debugger_modal.visible = false;
            })
            .addCase(debugger_modal_toggle.fulfilled, (state, action) => {
                state.debugger_modal.visible = !state.debugger_modal?.visible;
            })
          
            
            /****************************************************** */
            /*  DEFAULT                                            */
            /****************************************************** */
            .addDefaultCase((state) => {
                // Do something when no actions identified.
            });
    },
});
export default uiSlice;
