import React from 'react';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';

const DEV_ReactHookFormTest = () => {
	return (
		<PrivatePageTemplate 
			pageTitle="DEV: React Hook Forms Test"
			breadcrumbs={[
				{ caption: "My Home", to: "/my" },
                { caption: "Developer Home", to: "/dev" },
			]}
		>
			<div className="d-flex justify-content-center">
				
			</div>

		</PrivatePageTemplate>
	);
}
export default DEV_ReactHookFormTest;