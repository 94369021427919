import React, {
    createContext,
    useContext,
    useMemo,
    useState,
    useEffect,
} from "react";

import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';

import {
    start_network_monitor,
    stop_network_monitor,
    set_network_online,
    set_network_offline,
} from "_core/redux/slices/network-redux-slice";

import { useOnMountRunOnce } from "_core/hooks/useOnMount";
import { useTheme } from "./useTheme.provider";

const NetworkContext = createContext();

export const NetworkProvider = ({ children }) => {
    const dispatch = useDispatch();

    const { THEME } = useTheme();

    const [monitorEnabled, setMonitorEnabled] = useState(false); // local state flag for this context
    
    const [offlineToastId, setOfflineToastId] = useState(null); // for storing the id of the offline toast.  SO we can close it when back online. 

    const { isMonitoring,  offline, online_toast, offline_toast } = useSelector(
        (state) => state.network
    );

    useOnMountRunOnce( ()=> {
        setMonitorEnabled(true);    // turn on the monitor at component load. 
    });


    // OUR POLLING INTERVAL MECHANISM
    const [seconds, setSeconds] = useState(1);
    useEffect(() => {
        const timer = setInterval(() => {
            if(monitorEnabled!==true) return;
            setSeconds(seconds + 1);                    // console.log("seconds = "+(seconds+1));
            poll();                                     // <-- call polling here
        }, 1000);
        // clearing interval
        return () => clearInterval(timer);
    });


    const poll = React.useCallback( () => {
        try {
            // console.log("polling..");
            // console.log("offline.. = " + offline);

            // if the isMonitoring flag is off, turn it on since we are polling
            if (isMonitoring !== true) {
                dispatch(start_network_monitor());
            }

            if (navigator.onLine && offline === true) {
               // console.log("dispatching ONLINE..");
             

                toast.dismiss(offlineToastId);
                
                dispatch(set_network_online()); // dispatch to the store

                toast.success(online_toast.message, {
                    containerId: "network",  // specify the ToastContainer to use.   
                    autoClose: 4000, 
                    hideProgressBar: false,
                    closeOnClick: true, pauseOnHover: false, 
                    draggable: true, 
                    progress: undefined
                });

                
            
            } else if (navigator.onLine===false && offline===false) {
                
                //console.log("dispatching OFFLINE..");

                dispatch(set_network_offline());

                const toastId = toast.error(offline_toast.message, {
                    containerId: "network",  // specify the ToastContainer to use.   
                    autoClose: false, 
                    hideProgressBar: true,
                    closeOnClick: false, 
                    draggable: true, 
                    progress: undefined,
                    closeButton: false,
                });
                setOfflineToastId(toastId);
            }
        } catch (err) {
            let error ="Network Status Polling Error!! Error = " + JSON.stringify(err);
            console.log(error);
            return { error };
        }
    }, [ isMonitoring, dispatch, offline, offline_toast, online_toast,offlineToastId ]);


    
    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    const value = useMemo(
        () => ({
            start_network_monitor: async () => {
                setMonitorEnabled(true);
                return dispatch(start_network_monitor());
            },
            stop_network_monitor: async () => {
                setMonitorEnabled(false);
                return dispatch(stop_network_monitor());
            },
        }),
        [dispatch]
    );

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    return (
        <NetworkContext.Provider value={value}>
            {children}
            <ToastContainer
                enableMultiContainer={true} 
                containerId={'network'} 
                limit={0}
                position={toast.POSITION.BOTTOM_CENTER}
                theme={THEME?.toast?.theme}
            />
        </NetworkContext.Provider>
    );
};
export const useNetwork = () => {
    return useContext(NetworkContext);
};
