/**
 *  Made from:
 *  https://betterprogramming.pub/create-a-custom-usemodal-react-hook-449b5909cc09
 *  https://github.com/CITGuru/custom-react-modal-hook
 *
 */

import React, {useEffect,useState }from "react";
import PropTypes from "prop-types";
import { Modal as RbsModal, Button } from "react-bootstrap";
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";


const Modal = ({
    className,
    visible,
    children,
    title,
    closeButton,
    handleClose,
    showConfirm,
    confirmButtonText,
    handleConfirm,
    size,
    fullscreen,
    backdrop,
    centered,
    keyboard,
    footerButtons, 
}) => {
    const { THEME } = useTheme();

    const [theFooterButtons,setTheFooterButtons] = useState();
    useEffect(()=>{
        setTheFooterButtons(footerButtons)
    },[footerButtons])
    return (
        <RbsModal
            
            show={visible}
            onHide={handleClose}
            size={size}
            fullscreen={fullscreen}
            backdrop={backdrop}
            keyboard={keyboard}
            centered={centered}
            contentClassName={THEME?.wrapper?.className + ' '+ className}
        >
            {/* 
                SINCE the Bootstrap Modal actually renders outside the REACT DOM, We HAVE to wrap 
                the Modals guts in a Div that represents our THEME app content wrapper so that out scss compiles and 
                applies to the modal when themes are triggered.  Even the default theme.  
            
            */}
            

                <RbsModal.Header closeButton={closeButton}>
                    <RbsModal.Title>{title}</RbsModal.Title>
                </RbsModal.Header>
                <RbsModal.Body>{children}</RbsModal.Body>
                <RbsModal.Footer>
                    {theFooterButtons}
                    {closeButton  && <Button variant="secondary" onClick={handleClose}>Close</Button> }
                    {showConfirm && showConfirm === true && (
                        <Button
                            variant="primary"
                            className="ModalButton"
                            onClick={handleConfirm}
                        >
                            {confirmButtonText}
                        </Button>
                    )}
                </RbsModal.Footer>

           

        </RbsModal>
    );
};
Modal.defaultProps = {
    className: null,
    showConfirm: false,             
     
    size: 'md',
    fullscreen: false,
    keyboard: false,     
    backdrop: true,      
    centered: true,         

    footerButtons: null,
};
Modal.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,

    visible: PropTypes.bool.isRequired,

    closeButton: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,

    showConfirm: PropTypes.bool,

    size: PropTypes.string,                     // ('sm' | 'lg' | 'xl')  -- Render a large, extra large or small modal. When not provided, the modal is rendered with medium (default) size.
    fullscreen: PropTypes.any,                  // (true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down') -- Renders a fullscreen modal. Specifying a breakpoint will render the modal as fullscreen below the breakpoint size.
    keyboard: PropTypes.bool,                   // (true | false)  Close the modal when escape key is pressed
    backdrop: PropTypes.any,                    // ('static' | true | false) -- Include a backdrop component. Specify 'static' for a backdrop that doesn't trigger an "onHide" when clicked.
    centered: PropTypes.bool,                   // (true | false)  -- Vertically center the Dialog in the window

    footerButtons: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func
    ]),
};


export default Modal;
