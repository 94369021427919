

import { CUSTOM_CONSTANTS } from "_custom/config/CONSTANTS.config";
import APP_LOGO_PNG from "_core/assets/img/site-logo/logo.svg";
import { mergeObjectsDeep } from "_core/helpers/data-types";
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
const CORE_CONSTANTS = {
    APP_NAME: "MY APP",
    APP_HEADER_LOGO: <img src={APP_LOGO_PNG} alt="MY APP" />,
    APP_COPYRIGHT: "\u00A9 2023",

    AUTH_BASE_PATH: "/my", // The route path to use behind all authentication protected areas of the app (password protected)
    PUBLIC_HOME_PATH: "/home", // Default "home" route for unauthenticated access.

    REGISTER_ENABLED: false, // Enable App's User Registration System?

    INACTIVITY_TIMER: true, // Inactivity Timer Enabled?
    INACTIVITY_TIMER_TIMEOUT: 10, // minutes of inactivity before auto logout.   Set
    INACTIVITY_TIMER_PROMPT: true, // will prompt before logout.
};
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
// EXPORTS - combine core and custom constants and routes into single objects:
export const CONSTANTS = mergeObjectsDeep(CORE_CONSTANTS, CUSTOM_CONSTANTS);