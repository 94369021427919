/**                                                         
 *    a REDUX SLICE for:
 * 
 *      IGSDB Remote Data Retrieval  (through eh igsdb API)
 * 
 *      - handles the response from API that gathers IGSDB Items from the remote server. 
 * 
 *      - Puts IGSDB remote data in the Redux Store for comparing, or importing to local DB. 
 * 
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isArray } from "_core/helpers/data-types/arrays";

import IGSDBDataService from "_custom/services/igsdb-data.service";

const initialState = {
    glazing_products : {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        data: [],
    },
    shading_products : {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        data: [],
    },
};

export const fetch_remote_igsdb_glazing_products = createAsyncThunk(
    "igsdb/remote/glazing/fetch",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await IGSDBDataService.get_remote_glazing_products( search_filters , access_token ); 
            //console.log('fetch_remote_igsdb_glazing_products  - SLICE - RESPONSE RECEIVED:',response);

            if (response.status === 200 &&  response.data?.success) {
                //console.log('fetch_remote_igsdb_glazing_products-FETCH  - VALID SUCCESS - DATA SHOULD BE THERE!');
                return response.data;     // valid response 
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const fetch_remote_igsdb_shading_products = createAsyncThunk(
    "igsdb/remote/shading/fetch",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await IGSDBDataService.get_remote_shading_products( search_filters , access_token ); 
            //console.log('fetch_remote_igsdb_shading_products  - SLICE - RESPONSE RECEIVED:',response);

            if (response.status === 200 &&  response.data?.success) {
                //console.log('fetch_remote_igsdb_shading_products-FETCH  - VALID SUCCESS - DATA SHOULD BE THERE!');
                return response.data;     // valid response 
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const igsdbRemoteDataSlice = createSlice({
    name: "igsdbRemote",
    initialState,
    reducers: {
        clearState: (state) => {
            state = JSON.parse(JSON.stringify(initialState));   // deep copy initialState t state. 
            return state;
        },
    },

    extraReducers: (builder) => {
        builder
            /************************************************************************************************************************************************************ */
            /*  fetch_remote_igsdb_glazing_products                                   */
            /************************************************************************************************************************************************************ */
            .addCase(fetch_remote_igsdb_glazing_products.pending, (state, action) => {
                if (state.glazing_products.data===undefined || !isArray(state.glazing_products.data)) {
                    state.glazing_products.data = initialState.glazing_products.data
                }
                state.glazing_products.isFetching = true;
            })
            .addCase(fetch_remote_igsdb_glazing_products.fulfilled, (state, action) => {
                const result = action.payload;   //- this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;  
                //console.log('data',data);
                state.glazing_products.data           = data?.products;
                state.glazing_products.count_total    = data?.count_total;
                state.glazing_products.isFetching     = false;
                state.glazing_products.isSuccess      = true;
                state.glazing_products.isError        = false;
                state.glazing_products.errorMessage   = 'No errors.';
            })
            .addCase(fetch_remote_igsdb_glazing_products.rejected, (state, action) => {
                //console.log('fetch_local_igsdb_glazing_products fetch payload', action.payload);
                //const result = action.payload;                           
                //const data = result?.data;  
                state.glazing_products.isFetching     = false;
                state.glazing_products.isSuccess      = false;
                state.glazing_products.isError        = true;
                state.glazing_products.errorMessage   = 'IGSDB Glazing Products remote fetch failed.';
                state.glazing_products.data           = initialState.glazing_products.data;
                state.glazing_products.count_total    = initialState.glazing_products.count_total;
            })
           
            /************************************************************************************************************************************************************ */
            /*  fetch_remote_igsdb_shading_products                                   */
            /************************************************************************************************************************************************************ */
            .addCase(fetch_remote_igsdb_shading_products.pending, (state, action) => {
                if (state.glazing_products.data===undefined || !isArray(state.glazing_products.data)) {
                    state.glazing_products.data   = initialState.glazing_products.data
                }
                state.glazing_products.isFetching = true;
            })
            .addCase(fetch_remote_igsdb_shading_products.fulfilled, (state, action) => {
                const result = action.payload;   //- this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;  
                //console.log('data',data);
                state.shading_products.data           = data?.products;
                state.shading_products.count_total    = data?.count_total;
                state.shading_products.isFetching     = false;
                state.shading_products.isSuccess      = true;
                state.shading_products.isError        = false;
                state.shading_products.errorMessage   = 'No errors.';
            })
            .addCase(fetch_remote_igsdb_shading_products.rejected, (state, action) => {
                //console.log('fetch_local_igsdb_shading_products fetch payload', action.payload);
                //const result = action.payload;                           
                //const data = result?.data;  
                state.shading_products.isFetching     = false;
                state.shading_products.isSuccess      = false;
                state.shading_products.isError        = true;
                state.shading_products.errorMessage   = 'IGSDB Shading Products remote fetch failed.';
                state.shading_products.data           = initialState.shading_products.data;
                state.shading_products.count_total    = initialState.shading_products.count_total;
            })
            /************************************************************************************************************************************************************ */
            /*  DEFAULT case       -  Do something when no actions identified.                                                                                             */
            /************************************************************************************************************************************************************ */
            .addDefaultCase((state) => {
                state.glazing_products.isFetching = false;
                state.shading_products.isFetching = false;
            });
    },
});
export default igsdbRemoteDataSlice;

export const { clearState } = igsdbRemoteDataSlice.actions;