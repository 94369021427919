/**
    CONVERTS OBJECTS WITH DAT TO FormData

    HOW TO USE:

            FormData.verify()

            Will check a data object before passing into method that requires data in a FormData browser object. 
            Pass in an standard object with form data values, or pass in an already made FormData.
            Will accept either object. 

            Utilizes(depends on) script in same directory called FormData.serializer.js

 */
import { serialize } from './FormData-serializer';


export function verify_FormData(dataObject, existingFormData=null) {
    //console.log("verify_FormData IN", dataObject);

    if (dataObject instanceof FormData) {
        console.log("verify_FormData already FormData OUT", dataObject);
        return dataObject;
    } else {
        //console.log("verify_FormData OUT", dataObject);
        //console.log("verify_FormData OUT serialized", serialize( dataObject ));
        
        return serialize( dataObject );;    //  TODO: does'nt work with objects    return serialize( dataObject )


        // TODO: Do we need to use any options nn serialize?

        // var form_data = new FormData();
        // for ( var key in dataObject ) {
        //     form_data.append(key, dataObject[key]);
        // }
        // return dataObject;

    }
}

