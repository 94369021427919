import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import AppRouter from "./routing/AppRouter";
import { NetworkProvider } from "_core/hooks/provider-hooks/useNetwork.provider";
import { AuthProvider } from "_core/hooks/provider-hooks/useAuth.provider";
import { MessagingProvider } from "./hooks/provider-hooks/useMessaging.provider";
import { ErrorHandlerProvider } from "_core/hooks/provider-hooks/useErrorHandler.provider";
import { UiProvider } from "_core/hooks/provider-hooks/useUI.provider";
import { ThemeProvider } from "_core/hooks/provider-hooks/useTheme.provider";

import { CONSTANTS } from "./config";

function App() {
    return (
        <>
            <Helmet>
                <title>{CONSTANTS.APP_NAME}</title>
            </Helmet>
            <BrowserRouter>
                <ThemeProvider>
                    <UiProvider>
                        <ErrorHandlerProvider>
                            <MessagingProvider>
                                <AuthProvider>
                                    <NetworkProvider>
                                        <AppRouter />
                                    </NetworkProvider>
                                </AuthProvider>
                            </MessagingProvider>
                        </ErrorHandlerProvider>
                    </UiProvider>
                </ThemeProvider>
            </BrowserRouter>
        </>
    );
}
export default App;
