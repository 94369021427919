import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Col, Container, FloatingLabel } from "react-bootstrap";
import { Button, MultiSelect } from "_core/components/core-library";
import { getUniquePropertyValuesFromObjects } from "_core/helpers/data-types/arrays";

/*
    filterValues = [
        {filterId:'',filterValue:any},
        {filterId:'',filterValue:any},
    ]

*/

const SearchFilters = ({ dataSource, filters, onChange, visible, onToggleVisibility, disabled }) => {

    const [isDisabled, setIsDisabled] = useState(disabled);     // State Var for showing loading progress in THIS component,
    useEffect(() => {
        setIsDisabled(disabled)
    }, [disabled]);    
    
    const [filterValues, setFilterValues] = useState([]);

    const [isVisible, setIsVisible] = useState(visible);
    useEffect(() => setIsVisible(visible), [visible]);


    const handleFilterMultiSelectChange = (filterId, selectedValue, action) => {
        //console.log('filterId',filterId); console.log('selectedValue',selectedValue); console.log('action',action)
        
        const otherFilters = [ ...filterValues.filter( fv => (fv.filterId!==filterId) ) ]
        let newFilterValues;
        if (selectedValue===undefined||selectedValue===null||selectedValue.length<=0) {
            // No value selected, return only previous other filters. 
            newFilterValues = otherFilters;
        } else {
            // Value selected, add with previous data (except this id), and add this filter to it. 
            newFilterValues = [
                { 
                    filterId: filterId,                                     // filterId    
                    filterValue: selectedValue,                             // The Selected value as it exists coming from React-Select
                    column: filterRules.find(x => x.id===filterId)?.column,     // The column name we are filtering
                    columnValues: selectedValue.map(opt => (opt?.value)),   // Array of the values in the Selected Options
                },
                ...otherFilters                                          // <- include existing filter values
            ];
        }
       // console.log('newFilterValues',newFilterValues)
        setFilterValues(newFilterValues);
        onChange(newFilterValues);
    }

    const handleClearAllFilters = () => {
        setFilterValues([]);
        onChange([]);
    }

    const handleToggleVisibility = (visible) => {
        setIsVisible(visible)
        onToggleVisibility(visible);
    }

    const filterRules = useMemo( () => {

        var newFilters = [ ...filters ];
       
        newFilters = newFilters.map( filter => ({
            // keep good stuff
            ...filter,
            // make sure the filters have id props, if not implant the columns prop value to the id prop. 
            filterId: (filter?.id!==undefined&&filter?.id!==null ? filter.id : filter.column),
            id: (filter?.id!==undefined&&filter?.id!==null ? filter.id : filter.column),
            // stuff the values
            value: filterValues[filter.id],
            options: getUniquePropertyValuesFromObjects(dataSource, filter?.column, true).map((item) => ({ value:item, label:item}))
        }));

        return newFilters;

    },[dataSource, filters, filterValues])


   

    return (
        <div className="mdg-filters">

            <div className="mdg-filters-toolbar"  style={{marginTop:'0px'}}>
                <Container fluid className="p-0 m-0 row">
                    <Col className="p-0 m-0">
                        
                        {(filterValues?.length>0) &&                            
                            <Button disabled={isDisabled} variant="secondary" className="float-end mx-1 px-1 py-0 btn-sm" onClick={handleClearAllFilters}>Clear All Filters</Button>
                        }
                        
                        {(isVisible)&&<Button disabled={isDisabled} variant="secondary" className="float-end mx-1 px-1 py-0 btn-sm" onClick={() => handleToggleVisibility(false)}>Hide Filters</Button>}
                        {(!isVisible)&&<Button disabled={isDisabled} variant="secondary" className="float-end mx-1 px-1 py-0 btn-sm" onClick={() => handleToggleVisibility(true)}>Show Filters {(filterValues?.length>0) && '['+filterValues?.length+']'}</Button>}
                    </Col>
                </Container>
            </div>

            {(isVisible)&&

                <div className="mdg-filters-controls">
                    <Container fluid className="p-1 pe-0 row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 g-1">
                        {filterRules.map( (filter,idx) => (
                                <Col key={idx}>
                                <FloatingLabel label={filter.label}>
                                    {(filter.type==='multiSelect') && <MultiSelect 
                                        disabled={isDisabled}
                                        filterId={filter.id} 
                                        options={filter.options} 
                                        defaultValue={[]} 
                                        value={ filterValues.find(x => x.filterId === filter.id)?.filterValue }
                                        onChange={ (value, action) => handleFilterMultiSelectChange(filter.id, value, action)} 
                                        closeMenuOnSelect={true}
                                    />}
                                </FloatingLabel>
                            </Col>
                        ))}
                        
                    </Container>
                </div>

            }
        </div>
    );
}
SearchFilters.defaultProps = {
    disabled: false,
    visible: true,
	filters: [],
}
SearchFilters.propTypes = {
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
	filters: PropTypes.array,
}

export default SearchFilters;
