import React from "react";
import PrivatePageTemplate from "_core/components/pages/private/_templates/PrivatePageTemplate";
import { CardGrid } from "_core/components/core-library";
import { useNavigate } from "react-router-dom";
import { PRIVATE_PATHS } from "_core/config";

const IGSDB_Admin = () => {
    const navigate = useNavigate(); // Get navigate func from router hook.
    return (
        <PrivatePageTemplate
            pageTitle="IGSDB Admin"
            breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
                { caption: "Global Admin", to: PRIVATE_PATHS.GLOBAL.HOME },
            ]}
        >
            <CardGrid
                cols_sm={1}
                cols_md={2}
                cols_lg={3}
                cols_xl={3}
                data={[
                    {
                        title: "IGSDB API Connection",
                        description: "Adjust & test the main IGSDB API connection settings.",
                        btnVariant: "outline-primary",
                        btnText: "Go",
                        btnOnClick: () => {
                            navigate(PRIVATE_PATHS.GLOBAL.IGSDB.SETTINGS);
                        },
                    },
                    {
                        title: "IGSDB Local Data Manager",
                        description: "Manage the local data for the IGSDB.",
                        btnVariant: "outline-primary",
                        btnText: "Go",
                        btnOnClick: () => {
                            navigate(PRIVATE_PATHS.GLOBAL.IGSDB.MANAGER);
                        },
                    },
                ]}
            />
        </PrivatePageTemplate>
    );
};
export default IGSDB_Admin;
