
/**
 * 
 *    This hook is to ceate a fun that suimlulates compoenntDidMount that runs ONLY ONCE at Mount.  React hook functions
 *     use  useEffect which can run once with empty array as second param [], but ES-lint alway throws errors.
 * 
 *     This func below will achieve the same thing and also can set a return variable to indicated it ran like so:    
 *          
 *      useOnceCall(() => {
            console.log('called');
        })

        -oro

        useOnceCall(()=>{
            console.log('Fetched Data');
        }, isFetched);
 *      
 *      
 * 
 */



import React from 'react';

export const useOnMountRunOnce = (cb, condition = true) => {
    const isCalledRef = React.useRef(false);
  
    React.useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
    }, [cb, condition]);
}