import React, { useEffect, useState } from "react";

import hero_beauty_shot from "_custom/assets/img/glass-data-beauty-shot/PNG/Glass-Data-st@0.5x.png";


const DIRECTION = {
    FORWARD: "forward",
    REVERSE: "reverse",
}

const FadingBackgroundImage = () => {

    const debug = false;

    const opacity_default = 0.1;  // starting opacity
    const direction_default = DIRECTION.FORWARD;  // starting opacity
    const refresh_rate = 100; // lower = smoother  (milliseconds between refreshes)
    const opacity_increment = 0.01; // How much to change per refresh
    const opacity_low_limit = 0.1;
    const opacity_high_limit = 1;
    const pause_time = 2000;

    const [opacity, setOpacity] = useState(opacity_default);
    const [direction, setDirection] = useState(direction_default);
    const [paused, setPaused] = useState(true);
    const [timeOnPause, setTimeOnPause] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {

            if (debug) {
                console.log("opacity", opacity);
                console.log("direction", direction);
                console.log("paused", paused);
            }

            // check for pause cycle
            if (paused) setTimeOnPause(timeOnPause + refresh_rate); // increment pause time
            if (timeOnPause >= pause_time) {
                // if pause time is greater than pause limit
                setPaused(false); // turn off pause
                setTimeOnPause(0); // reset pause time
            }
            if (paused) return; // if paused, do not continue (return

            if (direction === DIRECTION.FORWARD && opacity >= opacity_high_limit) {
                // FORWARD LIMIT
                setOpacity(1);
                setPaused(true); // turn on pause
                setDirection(DIRECTION.REVERSE); // reverse direction
            } else if (direction === DIRECTION.REVERSE && opacity <= opacity_low_limit) {
                // REVERSE LIMIT
               // setOpacity(0);
                setPaused(true); // turn on pause
                setDirection(DIRECTION.FORWARD); // reverse direction
            } else {
                // INCREMENT/DECREMENT
                if (direction === DIRECTION.FORWARD) {
                    setOpacity(opacity + opacity_increment);
                } else {
                    setOpacity(opacity - opacity_increment);
                }
            }
        }, refresh_rate);

        return () => clearInterval(interval);
    }, [debug, paused, opacity, direction, opacity_low_limit, opacity_high_limit, timeOnPause]);

    return (
            <div
                id="hero-beauty-shot"
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    minHeight: "200px",
                    opacity: opacity,
                    transition: "opacity 0.5s ease-in-out",
                    backgroundImage: `url(${hero_beauty_shot})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            ></div>
    );
};

export default FadingBackgroundImage;
