
import React from 'react';
import PropTypes from 'prop-types';
const DataField = ({ children, label, data, overflow, style, className }) => {
    if (overflow) {
        let overflowStyle = {
            width: "100%",
            wordWrap: "break-word",
            display: "inline-block",
        }; // allows longs strings to wrap, even if now spaces in the string.
        style = Object.assign({}, style, overflowStyle);
    }

    return (
        <div className="data-field">
            <div className="data-field-label">{label + ":"}</div>

            {!overflow && (
                <div className="data-field-data ellipsis" style={style}>
                    {data}
                    {children}
                </div>
            )}
            {overflow && (
                <div className={"data-field-data "+ (className!==undefined ? className : '')} style={style}>
                    {data}
                    {children}
                </div>
            )}
        </div>
    );
};
DataField.propTypes = {
    label: PropTypes.string,
    data: PropTypes.any,
    overflow: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,

}
DataField.defaultProps = {
    label: "",
    data: "",
    overflow: false,
    style: {},
    className: null,
}
export default DataField;