import React from 'react';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';

const ADMIN_Local = () => {
	return (
		<PrivatePageTemplate 
			pageTitle="Admin"
			breadcrumbs={[
				{ caption: "My Home", to: "/my" },
			]}
		>
			<div className="d-flex justify-content-center">
				
			</div>

		</PrivatePageTemplate>
	);
}
export default ADMIN_Local;