import _ from "lodash";

export const isObjectEqual = (x, y) => {
    return _.isEqual(x, y);
};

export const isObject = (obj) => {
    return obj != null && obj.constructor.name === "Object";
};

export const isObjectEmpty = (obj) => {
    var empty = false;
    if (obj === undefined) return true;
    if (obj === null) return true;
    if (!isObject(obj)) return true;
    if (Object.keys(obj).length === 0) return true;
    return empty;
};

export const mergeObjects = (obj1, obj2) => {
    return {      //  use regular spread as lodash seems to remove 
        ...obj1,
        ...obj2,
    };
};
// export const mergeObjectsDeep = (obj1, obj2) => {
//     return _.merge(obj1, obj2);
// };

export const OLD_mergeObjects = (obj1, obj2) => {
    // from: https://stackoverflow.com/a/76258707

    const clone1 = structuredClone(obj1);
    const clone2 = structuredClone(obj2);

    for (let key in clone2) {
        if (clone2[key] instanceof Object && clone1[key] instanceof Object) {
            clone1[key] = mergeObjects(clone1[key], clone2[key]);
        } else {
            clone1[key] = clone2[key];
        }
    }

    return clone1;
};

export const cloneObject = (obj, isStrictlySafe = false) => {
    /* Clones an object. First attempt is safe. If it errors (e.g. from a circular reference),
          'isStrictlySafe' determines if error is thrown or an unsafe clone is returned. */
    try {
      return JSON.parse(JSON.stringify(obj));
    } catch(err) {
      if (isStrictlySafe) { throw new Error(err) }
      console.warn(`Unsafe clone of object`, obj);
      return {...obj};
    }
  }
export const mergeObjectsDeep = (target, source, {isMutatingOk = false, isStrictlySafe = false} = {}) => {
    /* Returns a deep merge of source into target.
          Does not mutate target unless isMutatingOk = true. */
    target = isMutatingOk ? target : cloneObject(target, isStrictlySafe);
    for (const [key, val] of Object.entries(source)) {
      if (val !== null && typeof val === `object`) {
        if (target[key] === undefined) {
          target[key] = new val.__proto__.constructor();
        }
        /* even where isMutatingOk = false, recursive calls only work on clones, so they can always
              safely mutate --- saves unnecessary cloning */
        target[key] = mergeObjectsDeep(target[key], val, {isMutatingOk: true, isStrictlySafe}); 
      } else {
        target[key] = val;
      }
    }
    return target;
  }
