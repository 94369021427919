/**                                                         
 *    a REDUX SLICE for:
 * 
 *      'Glazing' Nominal Sizes Table Data  (local to the System)
 * 
 *          - Nominal Size Reference Tables for Glass and Poly
 * 
 *      - handles the response from our service that gathers Sizing Data from our API. 
 * 
 *      - Puts Nominal Sizing data in the Redux Store for reference in App
 * 
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isArray } from "_core/helpers/data-types/arrays";

import GlazingDataService from "_custom/services/glazing-data.service";

// initial state of Message
const initialState = {
    
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',

    nominal_glass_thicknesses: [],
    nominal_poly_thicknesses: [],

};

export const fetch_nominal_thicknesses = createAsyncThunk(
    "glazing/nominal_thicknesses/fetch",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await GlazingDataService.get_nominal_thicknesses_lookup( search_filters , access_token ); 
            //console.log('GLAZING-NOM_THICKS--FETCH  - SLICE - RESPONSE RECEIVED:',response);

            if (response.status === 200 &&  response.data?.success) {
                //console.log('GLAZING-NOM_THICKS  - VALID SUCCESS - DATA SHOULD BE THERE!');
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const glazingNominalThicknessesDataSlice = createSlice({
    name: "glazing",
    initialState,
    reducers: {
        clearState: (state) => {
            state = JSON.parse(JSON.stringify(initialState));   // deep copy initialState t state. 
            return state;
        },
    },

    extraReducers: (builder) => {
        builder
            /****************************************************** */
            /*  fetch_nominal_thicknesses                                   */
            /****************************************************** */
            .addCase(fetch_nominal_thicknesses.pending, (state, action) => {
                let glassState= state?.nominal_glass_thicknesses;
                if (glassState===undefined || !isArray(glassState)) state.nominal_glass_thicknesses = initialState.nominal_glass_thicknesses;
                let polyState= state?.nominal_poly_thicknesses;
                if (polyState===undefined || !isArray(polyState)) state.nominal_poly_thicknesses = initialState.nominal_poly_thicknesses;
        
                state.isFetching = true;
            })
            .addCase(fetch_nominal_thicknesses.fulfilled, (state, action) => {
                const result = action.payload;                            /// this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;  
                //console.log('data',data);
                state.nominal_glass_thicknesses = data?.nominal_glass_thicknesses;
                state.nominal_poly_thicknesses  = data?.nominal_poly_thicknesses;
                state.isFetching    = false;
                state.isSuccess     = true;
                state.isError       = false;
                state.errorMessage  = 'No errors.';
            })
            .addCase(fetch_nominal_thicknesses.rejected, (state, action) => {
                const result = action.payload;                           
                //const data = result?.data;  
                const message = result?.message;  
                //console.log('IGDB fetch payload', action.payload);
                state.isFetching    = false;
                state.isSuccess     = false;
                state.isError       = true;
                state.errorMessage  = 'Nom Thicknesses fetch failed! ' + message;
    
                state.nominal_glass_thicknesses = initialState.nominal_glass_thicknesses;
                state.nominal_poly_thicknesses  = initialState.nominal_poly_thicknesses;
            })
            /****************************************************** */
            /*  DEFAULT                                            */
            /****************************************************** */
            .addDefaultCase((state) => {
                // Do something when no actions identified.
                state.isFetching    = false;
            });
    },
});
export default glazingNominalThicknessesDataSlice;

export const { clearState } = glazingNominalThicknessesDataSlice.actions;