import React, { useMemo, useState } from "react";

import BarChartTwoLine from "_core/components/core-library/charts/BarChartTwoLine";
import { data as externalData } from "./chart-data";

const HomeChart = React.forwardRef(({chartRef, width, height, style}, ref) => {
    
    const [chartData, setChartData] = useState(externalData);   
    useMemo(() => {
        setChartData(externalData);
    },[])

    return (
        <div ref={ref}>
            <BarChartTwoLine width={width} height={height} style={{}} data={chartData} xAxisLabelKey="name" barOneDataKey="pv" barTwoDataKey="uv" />
        </div>
    );
});
export default HomeChart;
