
/*
    CUSTOM_GLOBAL_Admin_Index_Menu_Data

    This index file will add items to the CORE Global Admin Index Menu.

    This file MUST return an array of Objects in the format the index.js file at CORE's Global Admin Index expects.  See that file. 

*/

import { useNavigate } from "react-router-dom";
import { PRIVATE_PATHS } from "_core/config";

export const CUSTOM_GLOBAL_Admin_Index_Menu_Data = () => {
    const navigate = useNavigate(); // Get navigate func from router hook.
    return [
        {
            header: "IGSDB",
            title: "IGSDB Admin",
            description: "Manage the interactivity with the IGSDB servers.",
            btnVariant: "outline-primary",
            btnText: "Go",
            btnOnClick: () => navigate(PRIVATE_PATHS.GLOBAL.IGSDB.HOME),
        },
    ]
    
};

