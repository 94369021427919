import { showToastObj, dismissAllToast  } from "_core/components/core-library/messaging/Toast";
class ToastService {
    
    show(toastobject) {
        //console.log('TOAST SERVICE - new toast:');
       // console.log(toastobject);

        let response = showToastObj( toastobject )
        return {
            success:true,
            data: {
                response:response,
              //  toast: toastobject  TODO: is this needed?  Caused serial action issue if toast has functions assigned in options. 
            }   
        }
    }
    dismissAllToast() {
        console.log('TOAST SERVICE - dismiss all toast:');
        let response = dismissAllToast();
        return {
            success:true,
            data: {
                response:response,
            }   
        }
    }

   
}
const newToastService  = new ToastService();
export default newToastService;