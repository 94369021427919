/* borrowed from 
https://github.com/nicolas-van/react-bootstrap-confirmation/

-- 1 Dependency:  react-confirm


See Confirm.js too. 

HOW TO USE:

    import {confirm} from 'react-bootstrap-confirmation';

    const display = async () => {
        const result = await confirm('Are you really sure?');
        console.log('True if confirmed, false otherwise:', result);
    };

    Parameter name:	    Type:	Description:	                                                                                Default:
    title	            string	A facultative title to use for the modal.	                                                    undefined
    okText	            string	The text of the "OK" button	                                                                    "OK"
    okButtonStyle	    string	The style of the "OK" button. Must correspond to one of the 6 button styles of Bootstrap.	    "primary"
    cancelText	        string	The text of the "Cancel" button	                                                                "Cancel"
    cancelButtonStyle	string	The style of the "Cancel" button. Must correspond to one of the 6 button styles of Bootstrap.	"secondary"


*/

import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";

const Alert = ({ show, proceed, dismiss, cancel, confirmation, title, okText, okButtonStyle, ...options }) => {
    const header = title ? (
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
    ) : undefined;
    return (
        <Modal size="small" show={show} onHide={() => proceed()} keyboard={true} backdrop="static" centered>
            {header}
            <Modal.Body>{confirmation}</Modal.Body>
            <Modal.Footer>
                <Button variant={okButtonStyle} onClick={() => proceed()}>
                    {okText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

Alert.propTypes = {
    /** header title */
    title: PropTypes.string,
    confirmation: PropTypes.string, // arguments of your confirm function
    okText: PropTypes.string,
    okButtonStyle: PropTypes.oneOf(["primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "link"]),
    show: PropTypes.bool, // from confirmable.
    proceed: PropTypes.func, // from confirmable.
    cancel: PropTypes.func, // from confirmable.
    dismiss: PropTypes.func, // from confirmable.
};

Alert.defaultProps = {
    title: undefined,
    confirmation: undefined,
    okText: "OK",
    okButtonStyle: "primary",
    show: undefined,
    proceed: undefined,
    cancel: undefined,
    dismiss: undefined,
};

const alertLow = createConfirmation(confirmable(Alert));

export const alert = (message, options = {}) => {
    return alertLow(Object.assign({ confirmation: message }, options));
};