import React, { useEffect } from 'react';
import { CopyBlock, CodeBlock, dracula, github } from "react-code-blocks";
import { Icon } from '_core/components/core-library';
import { ToastTypes, useMessaging } from '_core/hooks/provider-hooks/useMessaging.provider';
import { useTheme } from '_core/hooks/provider-hooks/useTheme.provider';

const CodeFormatter = ({children, code="", language="jsx", showLineNumbers=true, wrapLines=true }) => {

    const { toast } = useMessaging();

    const { THEME } = useTheme();

    const [ codeTheme, setCodeTheme ] = React.useState(THEME?.name);
    useEffect(() => {
        setCodeTheme((THEME.name==='dark' ? dracula : github))
    }, [THEME]);

    return (
        <CopyBlock
            text={(children) ? children : code}
            language={language}
            showLineNumbers={showLineNumbers}
            wrapLines={wrapLines}
            theme={codeTheme}
            codeBlock
            icon={<Icon name="copy" />}
            onCopy={() => {toast.show('Code was copied to clipboard!', ToastTypes.info ,{autoClose:1000})}}
        />
    );
};

export default CodeFormatter;

