/**                                                         
 *    a REDUX SLICE for:
 * 
 *      IGSDB Connection Status & Testing  states
 * 
 *      - handles the response from our service that gathers IGSDB test calls from our API. 
 * 
 *      - Puts IGSDB connection status data in the Redux Store
 * 
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import IGSDBDataService from "_custom/services/igsdb-data.service";

// initial state of Message
const initialState = {
   
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',

    data: null,

    testLastTS: null,  // timestamp of last connection test
    testIsFetching: false,
    testIsSuccess: false,
    testIsError: false,
    testErrorMsg: '',
    testData: null,

    api: {
        key: null,
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
    }
};
export const igsdb_get_api_key = createAsyncThunk(
    "igsdb/get/apikey",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await IGSDBDataService.get_api_key( search_filters , access_token ); 
            //console.log('IGSDB-GET_APIKEY  - SLICE - RESPONSE RECEIVED:',response);
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            console.log('IGSDB api key get error');
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const igsdb_get_settings = createAsyncThunk(
    "igsdb/get/settings",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await IGSDBDataService.get_settings( search_filters , access_token ); 
            //console.log('IGSDB-GET_SETTINGS  - SLICE - RESPONSE RECEIVED:',response);
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            console.log('IGSDB settings get error');
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const igsdb_test_connection = createAsyncThunk(
    "igsdb/test/connection",
    async (search_filters, thunkAPI) => {
        try {
            console.log('igsdb_test_connection redux running');
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await IGSDBDataService.test_connection( search_filters , access_token ); 
            console.log('IGDB-TEST_CONNECTION  - SLICE - RESPONSE RECEIVED:',response);

            if (response.status === 200 &&  response.data?.success) {
                //console.log('GDB-TEST_CONNECTION  - VALID SUCCESS !');
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            console.log('IGSDB connection error');
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const igsdbDataSlice = createSlice({
    name: "igsdb",
    initialState,
    reducers: {
        clearState: (state) => {
            state = JSON.parse(JSON.stringify(initialState));   // deep copy initialState t state. 
            return state;
        },
    },

    extraReducers: (builder) => {
        builder
            /****************************************************** */
            /*  igsdb_get_api_key                                   */
            /****************************************************** */
            .addCase(igsdb_get_api_key.pending, (state, action) => {
                if (state.api===undefined) state.api = {...initialState.api};
                state.api.isFetching = true;
            })
            .addCase(igsdb_get_api_key.fulfilled, (state, action) => {
                const result = action.payload;                            
                const data = result?.data;      //console.log('data',data);
                state.api.key          = data;
                state.api.isFetching   = false;
                state.api.isSuccess    = true;
                state.api.isError      = false;
                state.api.errorMessage = 'No errors.';
            })
            .addCase(igsdb_get_api_key.rejected, (state, action) => {
                state.api.key          = null;
                state.api.isFetching   = false;
                state.api.isSuccess    = false;
                state.api.isError      = true;
                state.api.errorMessage = 'IGSDB API KEY fetch failed.';
            })
            /****************************************************** */
            /*  test_conn                                   */
            /****************************************************** */
            .addCase(igsdb_test_connection.pending, (state, action) => {
                if (state.test_conn===undefined) state.test_conn = initialState.test_conn
                state.testIsFetching = true;
            })
            .addCase(igsdb_test_connection.fulfilled, (state, action) => {
                const result = action.payload;                            
                const data = result?.data;  
                //console.log('data',data);
                state.testData          = data;
                state.testLastTS        = moment().format('DD/MM/YYYY HH:mm:ss')
                state.testIsFetching    = false;
                state.testIsSuccess     = true;
                state.testIsError       = false;
                state.testErrorMsg      = 'No errors.';
            })
            .addCase(igsdb_test_connection.rejected, (state, action) => {
                state.testData          = null;
                state.testLastTS        = moment().format('DD/MM/YYYY HH:mm:ss')
                state.testIsFetching    = false;
                state.testIsSuccess     = false;
                state.testIsError       = true;
                state.testErrorMsg      = 'IGDB Test Connection fetch failed.';

            })
            /****************************************************** */
            /*  igsdb_get_settings                                  */
            /****************************************************** */
            .addCase(igsdb_get_settings.pending, (state, action) => {
                if (state.settings===undefined) state.settings = {...initialState.settings};
                state.settings.isFetching = true;
            })
            .addCase(igsdb_get_settings.fulfilled, (state, action) => {
                const result = action.payload;                            
                const data = result?.data;      //console.log('data',data);
                state.settings.data         = data;
                state.settings.isFetching   = false;
                state.settings.isSuccess    = true;
                state.settings.isError      = false;
                state.settings.errorMessage = 'No errors.';
            })
            .addCase(igsdb_get_settings.rejected, (state, action) => {
                state.settings.key          = null;
                state.settings.isFetching   = false;
                state.settings.isSuccess    = false;
                state.settings.isError      = true;
                state.settings.errorMessage = 'IGSDB Settings fetch failed.';
            })
    },
});
export default igsdbDataSlice;

export const { clearState } = igsdbDataSlice.actions;