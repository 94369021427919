import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { CardGrid }from '_core/components/core-library'
import { PRIVATE_PATHS } from '_core/config';

function DEV_Home() {
	const navigate = useNavigate(); // Get navigate func from router hook.
	
	const dev_menu_data = [
		{
			header: 'TOAST',
			title:'Toast Test', 
			description:'Test App Toasts that utilize react-toastify',
			btnVariant: 'outline-primary',
			btnText:'Test Now',
			btnOnClick:() => navigate(PRIVATE_PATHS.DEV.TOAST_TEST)
		},
		{
			header: 'FORMS',
			title:'React Hook Form Test', 
			description:'',
			btnVariant: 'outline-primary',
			btnText:'Go', 
			btnOnClick:() => navigate(PRIVATE_PATHS.DEV.REACT_HOOK_FORM_TEST)
		},
		{
			header: 'DATA DISPLAY',
			title:'Data Display Fields Test',
			description:'',
			btnVariant: 'outline-primary',
			btnText:'Go', 
			btnOnClick:() => navigate(PRIVATE_PATHS.DEV.DATA_FIELDS_TEST)
		},
	];


	return (
		<PrivatePageTemplate 
			pageTitle="Developer Home"
			breadcrumbs={[
				{ caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
			]}
		>
			<div className="d-flex justify-content-center">
				<CardGrid cols_sm={1} cols_md={2} cols_lg={3} cols_xl={3}
					data={dev_menu_data}
				/>
			</div>

		</PrivatePageTemplate>
	);
}
export default DEV_Home;
