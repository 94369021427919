import React, { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";

const GridFilteredRecords = ({dataSourceOriginal,dataSource,searchResults, searchLimit}) => {

    const [totalRecordsOrig, setTotalRecordsOrig] = useState(0);
    useEffect(() => setTotalRecordsOrig(dataSourceOriginal?.length),[dataSourceOriginal]);
    
    const [totalRecordsNow, setTotalRecordsNow] = useState(0);                  /* eslint-disable-line no-unused-vars */
    useEffect(() => setTotalRecordsNow(dataSource?.length),[dataSource]);

    const [totalSearchResults, setTotalSearchResults] = useState('');
    useEffect(() => setTotalSearchResults(searchResults?.length),[searchResults]);

    const [theSearchLimit, setTheSearchLimit] = useState('');
    useEffect(() => setTheSearchLimit(searchLimit),[searchLimit]);

    return (
        <Container fluid className="mdg-meta-text mdg-filtered-records-text my-0 p-1 text-center small">
           {(totalSearchResults !== undefined && totalSearchResults !== totalRecordsOrig) 
            &&
                <>
                    <>Filtered results to {totalSearchResults} records. </>

                    {(theSearchLimit!== undefined && theSearchLimit!==null && theSearchLimit!=='')
                        &&
                        <>Result limit is {theSearchLimit}. </>
                    }
                    
                </> 
            }
                  
        </Container>

        
    );
}
export default GridFilteredRecords;
