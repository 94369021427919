import React from "react";
import { Route } from "react-router-dom";
import {
    CustomPublicHome, CustomPublicRegister,
} from "_custom/components/pages-public";
import { PUBLIC_PATHS } from "_core/config";
import { unique_key as key } from "_core/helpers/guid_generator";

const CustomPublicRoutes =  [
    /*  
        ADD CUSTOM PUBLIC ROUTES HERE!! (Add to array below):
        -- Be sure to Use Paths Defined in CONSTANTS  
        -- Must include a unique key prop for React.  Use the guid_generator helper.
    */

    // Override the CORE HOME Module Path Route with a Custom home page for this App.
    <Route key={key} index path={PUBLIC_PATHS.HOME} element={<CustomPublicHome />} />, 
    <Route key={key} path={PUBLIC_PATHS.REGISTER} element={<CustomPublicRegister />} />,
];
export default CustomPublicRoutes;

    
