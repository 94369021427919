



export const compare_for_sort = (value1, value2) => {
    value1 = String(value1).toLowerCase();
    value2 = String(value2).toLowerCase();
    try {
        var tmpvalue1 = parseFloat(value1);
        if (isNaN(tmpvalue1)) {
            if (value1 < value2) { return -1; }
            if (value1 > value2) { return 1; }
        }
        else {
            var tmpvalue2 = parseFloat(value2);
            if (tmpvalue1 < tmpvalue2) { return -1; }
            if (tmpvalue1 > tmpvalue2) { return 1; }
        }
    }
    catch (error) {
        var er = error;
        console.error(er.message,er)
    }
    return 0;
};