import React from 'react';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { PRIVATE_PATHS } from "_core/config";

import { BootstrapSampleCode } from './parts/bootstrap_html_samples';



const ADMIN_Global_Themes_BootstrapSampler = () => {
   
	return (
		<PrivatePageTemplate 
			pageTitle="Bootstrap Sampler"
			breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
                { caption: "Global Admin", to: PRIVATE_PATHS.GLOBAL.HOME },
                { caption: "Themes Home", to: PRIVATE_PATHS.GLOBAL.THEMES.HOME },
            ]}
		>
			<div className="d-flex justify-content-center">
				{BootstrapSampleCode()}
			</div>

		</PrivatePageTemplate>
	);
}
export default ADMIN_Global_Themes_BootstrapSampler;
    