import React, { useState, useEffect} from "react";
import MyDataGrid from "_core/components/core-library/tables/MyDataGrid/MyDataGrid";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import { useAdminGlobal } from "_core/hooks/data-hooks/useAdminGlobal";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";
import { useModal, Modal, DataField, JSONPretty, Button } from "_core/components/core-library";
import { Accordion, Col, Container, Row, Table } from "react-bootstrap";
import { isArray } from "_core/helpers/data-types/arrays";


const defaultGridWrapperStyle={
    borderRadius: 4,
   // backgroundColor: '$APP-colors-info',
}
const defaultGridStyle={
   // backgroundColor: defaultGridWrapperStyle.backgroundColor,
}

const GlobalCompaniesTable = ({style}) => {
    const { breakpoints} = useUI();


    // GET PRIMARY DATA for TABLE:
    const { companies } = useAdminGlobal();
    useOnMountRunOnce(() => companies.fetch());


    // TABLE COLUMNS DEFINITIONS
    const columns = [
        // {
        //     id: "mobile",
        //     header: "RESULTS",
        //     type: "number",
        //     textAlign: "start",
        //     headerAlign: "center",
        //     visible: breakpoints.xs === true,
        //     showColumnMenuTool: false,
        //     flex: 1,
        //     render: ({ data }) => {
        //         return (
        //             <div>
        //                 <div className="d-flex justify-content-between small">
        //                     <div className="text-start text-uppercase">{data?.manufacturer_name}</div>
        //                     <div>NFRC #{data?.nfrc_id}</div>
        //                 </div>
        //                 <div style={{ textAlign: "center" }}>{data?.name}</div>
        //                 <div className="d-flex justify-content-between small">
        //                     <div>VLT: {format_percent(data?.vlt, "unk", 0)}</div>
        //                     <div>{data?.subtype}</div>
        //                     <div>{data?.appearance}</div>
        //                     <div>{format_number(data?.thick_exact_mm, "unk", 1," mm")}</div>
        //                 </div>
        //             </div>
        //         );
        //     },
        // },
    
        {
            name: "name",
            header: "Name",
            type: "string",
            textAlign: "start",
            headerAlign: "start",
            visible: breakpoints.xs !== true,
            flex: 1,
            showColumnMenuTool: false,
            render: ({ value }) => {
                return <span className="fw-bold">{value}</span>;
            },
        },
        
       

        { name: "id", defaultWidth: 55, header: "uid", type: "number", visible: true },
    ];


    const defaultSortInfo = {
        name: "name",
        dir: -1,
    };

    const [selected, setSelected] = useState(null);                 /* eslint-disable-line no-unused-vars */       
    const [selectedRowIDs, setSelectedRowIds] = useState(null);     /* eslint-disable-line no-unused-vars */
    const [selectedData, setSelectedData] = useState(null); 
   

    const { modalVisible, showModal, hideModal } = useModal(false);

    const showCompanyModal = (selectedData,selectedRowIDs) => {
       // console.log('showCompanyModal selectedData: ', selectedData)
       // console.log('showCompanyModal selectedRowIDs: ', selectedRowIDs)
        if (selectedData===undefined||selectedData==null) return;
        if (isArray(selectedData)) return;
        showModal();
    }

    const handleOnSelectionChange = ({selected, selectedRowIDs, selectedData, changedData}) => {
        //console.log('handleOnSelectionChange triggered');console.log('selected',selected);console.log('selectedRowIDs',selectedRowIDs);console.log('selected data',selectedData);
        setSelected(selected);
        setSelectedRowIds(selectedRowIDs);
        setSelectedData(selectedData);

        showCompanyModal(selectedData,selectedRowIDs)
    };

    const handleRenderRowContextMenu = (menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Row ' + rowProps.rowIndex
            },
            {
                label: 'Want to visit ' + rowProps.data?.name + '?'
            }
        ]
    }

    const [isLoading, setIsLoading] = useState(true);   
    useEffect( () => setIsLoading(companies?.isFetching),[companies?.isFetching] );

    return (
        <>
            <MyDataGrid
                className="global-admin-companies-datagrid"

                // Debugging
                debug={false}
                showGridAsList={false}
                showGridAsListColumn={"displayname"}
                showGridAsListJSON={false}

                // Data & Loading
                loading={isLoading}
                //loading={true}
                dataSource={companies?.data}
                idProperty="id"
                
                // Style
                style={{...defaultGridWrapperStyle, ...style}}
                gridStyle={{...defaultGridStyle}}
                gridAutoHeight={true}
                gridAutoHeightOffset={-70}

                // Columns & Groups
                columns={columns}
                enableColumnAutosize={true}
                groups={null}

                // Rows
                rowHeight={75}  //{breakpoints.xs === true ? 75 : 75}

                // Sort / Filter / Search
                defaultSortInfo={defaultSortInfo}
                enableFiltering={false}
                defaultFilterValue={null}

                searchFiltersEnabled={false}
                searchFiltersVisible={false}
                // searchFilters={[
                //     {column:'displayname', label:'FILTER BY GLAZING TYPE:', type:'multiSelect'},
                //     {column:'manufacturer_name', label:'FILTER BY MANUFACTURER:', type:'multiSelect'},
                //     {column:'appearance', label:'FILTER BY APPEARANCE/COLOR:', type:'multiSelect'},
                // ]}
                
                searchBoxEnabled={true}
                searchBoxAutofocus={false}

                searchRules={{                              //<-- Rules are fuse.js search options.  See https://fusejs.io/api/options.html
                    keys: ["name", "id"],
                    matchAllOnEmptyQuery: true,
                    threshold: 0.15,                        //<--  A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
                    // limit: 9999999,                      //<--  Limits records shown. 
                }}

                // Labels
                labels = {{
                    records: 'companies',
                }}
                
                // Paging  
                pagination={false}
                perPage={50}
                livePagination={false}
                scrollThreshold={0.7}

                // Indexing and Selecting
                activeIndex={null}
                enableSelection={true}
                defaultSelected={null}      // Ex) 24, or '24', or {24:true,25:true}
                checkboxColumn={true}
                multiSelect={true}
                    
                onGridLoad={(gridRef) => {}}
                onGridSelectionChange={handleOnSelectionChange}
                onGridRenderRowContextMenu={handleRenderRowContextMenu}
            />
            <Modal 
                className="global-admin-companies-datagrid-modal" 
                title={"USER ACCOUNT"}
                size={"md"}
                visible={modalVisible} 
                closeButton={true} 
                handleClose={()=>hideModal()} 
                showConfirm={false} 
                confirmButtonText={"?"} 
                handleConfirm={()=>hideModal()}

                footerButtons={<Button variant="primary">Close</Button>}
            >
                <Container fluid>
                    <div className="text-center ">UID # {selectedData?.id}</div>
                    <div className="h5 text-center">{selectedData?.displayname}</div>
                    <div className="text-center">{selectedData?.companyname}</div>
    
                    <hr />
                    <Table striped responsive size="sm" className="m-0">
                        <tbody>
                            <tr><th>Username:</th><td>{selectedData?.username}</td></tr>
                            <tr><th>Email:</th><td>{selectedData?.email}</td></tr>
                            <tr><th>Mobile Ph:</th><td>{selectedData?.mobile}</td></tr>
                            <tr><th>Role:</th><td>{selectedData?.role}</td></tr>
                            <tr><th>Last Login:</th><td>{selectedData?.lastlogin}</td></tr>
                            <tr><th>Status:</th><td>{selectedData?.status}</td></tr>
                        </tbody>
                    </Table>
                
                    
                    
                    
                    <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>All DATA</Accordion.Header>
                            <Accordion.Body>
                                <JSONPretty json={selectedData} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>     
                    <hr />
                    <Row>
                        <Col>
                            <DataField label="Created">
                                {selectedData?.created}
                                {(selectedData?.created_by ? " by " + selectedData?.created_by : "")}
                            </DataField>
                        </Col>
                        <Col>
                            <DataField label="Modified">
                                {selectedData?.modified}
                                {(selectedData?.modified_by ? " by " + selectedData?.modified_by : "")}
                            </DataField>
                        </Col>
                    </Row>
                   
                </Container>
            </Modal>
        </>
    );
};
export default GlobalCompaniesTable;
