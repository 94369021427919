import React from "react"
import PropTypes from "prop-types";
import { Card, Col, Container} from 'react-bootstrap';  
import { Button } from '_core/components/core-library';

import './select-cards-style.css';

const SelectManufacturer = ({manufacturers,oncancel,onselect}) => {
    return <>
    
        <Container className='select-cards row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-6 g-4'>
            <Col>
                <Card>
                    <Button variant={"primary"} size={"lg"} className={"fw-bold"} 
                        text={"<-- CANCEL"} 
                        onClick={() => {
                            oncancel();
                        }}
                    />
                </Card>
            </Col>
            
            {manufacturers.map(function(manuf, i){
                return (
                    <Col key={i}>
                        <Card key={i}>
                            <Button key={i} variant={"primary-outline"} size={"lg"} className={"fw-bold"} 
                                text={(manuf.name==='' ? 'BLANK or No Manufacturer Specified' : manuf.name)} 
                                onClick={() => {
                                    onselect(manuf);
                                }}
                            />
                        </Card>
                    </Col>
                );
            })}
        </Container>
    </>
}
export default SelectManufacturer;
SelectManufacturer.propTypes = {
    manufacturers: PropTypes.arrayOf(PropTypes.object).isRequired,
    oncancel: PropTypes.func,
    onselect: PropTypes.func
};
SelectManufacturer.defaultProps = {
    valueList: [],
    oncancel: (filterID) => { console.log('Manufacturer Select Canceled!'); },
    onselect: (filterID) => { console.log('Selected Manufacturer = ',filterID); },
};