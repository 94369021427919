import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import Grid from "./parts/Grid";
import DebugOutput from "./parts/DebugOutput";
import GridSearchInput from "./parts/GridSearchInput";
import GridShowAsList from "./parts/GridShowAsList";
import GridSearchFilters from "./parts/GridSearchFilters";
import GridTotalRecords from "./parts/GridTotalRecords";
import GridFilteredRecords from "./parts/GridFilteredRecords";
import { useFuseSearch } from "_core/hooks/useFuseSearch";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import { prepare_grid_columns } from "./helpers/prepare_grid_columns";


// For export usage.
export { default as DateFilter } from "@inovua/reactdatagrid-community/DateFilter";
export { default as NumberFilter } from "@inovua/reactdatagrid-community/NumberFilter";
export { default as SelectFilter } from "@inovua/reactdatagrid-community/SelectFilter";

// Default Style of entire wrapper
const defaultWrapperStyle = {
    backgroundColor: 'lightblue'
}
// Default Style of Grid
const defaultGridStyle = {
    backgroundColor: 'lightblue',
}

export const MyDataGrid = (props) => {
    // ------------------------------------------------------------------------------------------------------------------------------
    // REF to the Grid - Gets Set onGridRefReady - A react Ref tot he Grid.  See ReactDataGrid docs. 
    const [gridRef, setGridRef] = useState(null);  /* eslint-disable-line no-unused-vars */
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // LOADING
    const [loading, setLoading] = useState(props?.loading);     // State Var for showing loading progress in THIS component,
    useEffect(() => {
        console.log('MDG props?.loading = '+props?.loading)
        setLoading(props?.loading)
    }, [props?.loading]);

    // GRID LOADED - gridLoaded
    const [gridLoaded, setGridLoaded] = useState(false);        // State Var for showing loaded or not.
    
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // DATA SOURCE --- LOAD DATA FROM PROPS on props update (useEffect)
    const [dataSourceOriginal, setDataSourceOriginal] = useState([]); // Holds the Data
    const [dataSource, setDataSource] = useState(null); // Holds the Data
    useEffect(() => {
       // setLoading(true);                           // Show loading spinner.
        const newDataSource = props?.dataSource;    // Grab the data source.
        setDataSourceOriginal(newDataSource);       // Hold copy of original data.  Used during filtering.
    }, [props?.dataSource]);
    useEffect(() => {
       // setLoading(true);                           // Show loading spinner.              
        setDataSource(dataSourceOriginal);          // Set copy of data used to show grid. 
    }, [dataSourceOriginal]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // DATA - GRID: idProperty   - The data columns thats makes the row id.  Usually 'id' for the db id. 
    const [idProperty, setIdProperty] = useState(props?.idProperty); 
    useEffect(() => setIdProperty(props?.idProperty), [props?.idProperty]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: COLUMNS - Column definitions
    const [columns, setColumns] = useState(props?.columns); 
    useEffect(() => setColumns(prepare_grid_columns(props?.columns, dataSource)), [props?.columns,dataSource]);
    // Grid: COLUMNS - Column GROUPS - groups
    const [groups, setGroups] = useState(props?.groups); 
    useEffect(() => setGroups(props?.groups), [props?.groups]);
    // Grid: COLUMNS - enableColumnAutosize
    const [enableColumnAutosize, setEnableColumnAutosize] = useState(props?.enableColumnAutosize); 
    useEffect(() => setEnableColumnAutosize(props?.enableColumnAutosize), [props?.enableColumnAutosize]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: ROWS - rowHeight
    const [rowHeight, setRowHeight] = useState(props?.rowHeight); 
    useEffect(() => setRowHeight(props?.rowHeight), [props?.rowHeight]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    //
    // Wrapper: CLASS - className
    const [className, setClassName] = useState(props?.className);
    useEffect(() => setClassName(props?.className), [props?.className]); 
    // Wrapper: STYLE - style
    const [style, setStyle] = useState(props?.style);
    useEffect(() => setStyle({...defaultWrapperStyle, ...props?.style}), [props?.style]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: HEIGHT - gridHeight - Use SetHeight to set it's height./ 
    const [gridHeight, setGridHeight] = useState(props?.gridHeight); 
    useEffect(() => setGridHeight(props?.gridHeight), [props?.gridHeight]);
    // Grid: HEIGHT - gridAutoHeight - Use SetHeight to set it's height./ 
    const [gridAutoHeight, setGridAutoHeight] = useState(props?.gridAutoHeight); 
    useEffect(() => setGridAutoHeight(props?.gridAutoHeight), [props?.gridAutoHeight]);
    // Grid:HEIGHT - gridAutoHeightOffset - Amount to offset gridAutoHeight./ 
    const [gridAutoHeightOffset, setGridAutoHeightOffset] = useState(props?.gridAutoHeightOffset); 
    useEffect(() => setGridAutoHeightOffset(props?.gridAutoHeightOffset), [props?.gridAutoHeightOffset]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: STYLE - 
    const [gridStyle, setGridStyle] = useState(props?.gridStyle); 
    useEffect(() => {
        var newStyle;
        if (gridHeight!==undefined && gridHeight!==null && gridHeight!=='' ) { 
            newStyle = {...defaultGridStyle, ...props?.gridStyle, height:gridHeight }
        } else {
            newStyle = {...defaultGridStyle, ...props?.gridStyle }
        }   
        setGridStyle(newStyle)
    }, [props?.gridStyle, gridHeight]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: SORTING - defaultSortInfo
    const [defaultSortInfo, setDefaultSortInfo] = useState(props?.defaultSortInfo); 
    useEffect(() => setDefaultSortInfo(props?.defaultSortInfo), [props?.defaultSortInfo]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: FILTERING - enableFiltering
    const [enableFiltering, setEnableFiltering] = useState(props?.enableFiltering); 
    useEffect(() => setEnableFiltering(props?.enableFiltering), [props?.enableFiltering]);
    // Grid: FILTERING - defaultFilterValue
    const [defaultFilterValue, setDefaultFilterValue] = useState(props?.defaultFilterValue); 
    useEffect(() => setDefaultFilterValue(props?.defaultFilterValue), [props?.defaultFilterValue]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: PAGINATION - pagination
    const [pagination, setPagination] = useState(props?.pagination); 
    useEffect(() => setPagination(props?.pagination), [props?.pagination]);
    // Grid: PAGINATION - limit / perpage  (number per page)
    const [limit, setLimit] = useState(props?.perPage); 
    useEffect(() => setLimit(props?.perPage), [props?.perPage]);
    // Grid: PAGINATION - livePagination
    const [livePagination, setLivePagination] = useState(props?.livePagination); 
    useEffect(() => setLivePagination(props?.livePagination), [props?.livePagination]);
    // Grid: PAGINATION - livePagination - scrollThreshold
    const [scrollThreshold, setScrollThreshold] = useState(props?.scrollThreshold); 
    useEffect(() => setScrollThreshold(props?.scrollThreshold), [props?.scrollThreshold]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: ACTIVE ROW INDEX - is the highlighted row, thats not selected yet. Can be changed with keyboard arrow, etc..
    const [activeIndex, setActiveIndex] = useState(props?.activeIndex); 
    useEffect(() => setActiveIndex(props?.activeIndex), [props?.activeIndex]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // Grid: SELECTIONS - enableSelection - can be propped in
    const [enableSelection, setEnableSelection] = useState(props?.enableSelection); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setEnableSelection(props?.enableSelection), [props?.enableSelection]);
    // Grid: SELECTIONS - checkboxColumn - can be propped in
    const [checkboxColumn, setCheckboxColumn] = useState(props?.checkboxColumn); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setCheckboxColumn(props?.checkboxColumn), [props?.checkboxColumn]);
    // Grid: SELECTIONS - multiSelect - can be propped in
    const [multiSelect, setMultiSelect] = useState(props?.multiSelect); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setMultiSelect(props?.multiSelect), [props?.multiSelect]);
    // Grid: SELECTIONS - defaultSelected - can be propped in
    const [defaultSelected, setDefaultSelected] = useState(props?.defaultSelected); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setDefaultSelected(props?.defaultSelected), [props?.defaultSelected]);
    // Grid: SELECTIONS - selected - [USUALLY INTERNALLY SET ONLY]
    const [selected, setSelected] = useState(props?.selected); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setSelected(props?.selected), [props?.selected]);

    const [selectedRowIDs, setSelectedRowIDs] = useState([]);   // Array of selected row id's only.  ["id1","id2",...]
    const [selectedData, setSelectedData] = useState(null);  /* eslint-disable-line no-unused-vars */  //- [OBJECT or OBJECT of Objects] A copy of the row data that's selected.
    //  
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // DEBUG: debug
    const [debug, setDebug] = useState(props?.debug); 
    useEffect(() => setDebug(props?.debug), [props?.debug]);
    // DEBUG: showGridAsList
    const [showGridAsList, setShowGridAsList] = useState(props?.showGridAsList); 
    useEffect(() => setShowGridAsList(props?.showGridAsList), [props?.showGridAsList]);
    // DEBUG: showGridAsListColumn
    const [showGridAsListColumn, setShowGridAsListColumn] = useState(props?.showGridAsListColumn); 
    useEffect(() => setShowGridAsListColumn(props?.showGridAsListColumn), [props?.showGridAsListColumn]);
    // DEBUG: showGridAsListJSON
    const [showGridAsListJSON, setShowGridAsListJSON] = useState(props?.showGridAsListJSON); 
    useEffect(() => setShowGridAsListJSON(props?.showGridAsListJSON), [props?.showGridAsListJSON]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    //
    // GRID EVENT - handleGridOnLoad  - The Grid is loaded and ready with data, if data.
    const handleGridOnLoad = (gridRef) => {
        setGridLoaded(gridRef);

        if (defaultSelected!==undefined && defaultSelected!==null) {
            const grid = gridRef.current;  
            if (grid!==undefined && grid!==null) {
                grid.scrollToId(defaultSelected, {direction:'top', duration:750})
            }
        }
        if (props.onGridLoad!==undefined && props.onGridLoad!==null) props.onGridLoad(gridRef);
    }; 
    // GRID EVENT - handleGridOnRefReady  - The Grid is Ready in the React DOM and has a REF. It may not have data yet though here!
    const handleGridOnRefReady = (grid) => {
        setGridRef(grid.ref); //- Create ref to the Grid in this state.
        if (props.onGridRefReady!==undefined && props.onGridRefReady!==null) props.onGridRefReady(grid);
    };
    // GRID EVENT - handleOnGridActiveIndexChange - runs every time the grid's row highlight changes (not selected, just highlighted)
    const handleGridOnActiveIndexChange = (idx) => {
        setActiveIndex(idx);
        if (props.onGridActiveIndexChange!==undefined && props.onGridActiveIndexChange!==null) props.onGridActiveIndexChange(idx);
    }
    // GRID EVENT - handleOnFilterValueChange
    const handleGridOnFilterValueChange = (filterData) => {
        if (props.onGridFilterValueChange!==undefined && props.onGridFilterValueChange!==null) props.onGridFilterValueChange(filterData);
    }
    // GRID EVENT - handleOnSelectionChange
    const handleGridOnSelectionChange = ({selected, selectedRowIDs, selectedData}) => {
        //console.log('handleGridOnSelectionChange triggered');console.log('selected',selected);console.log('selectedRowIDs',selectedRowIDs);console.log('selected data',data);
        setSelected(selected);
        setSelectedRowIDs(selectedRowIDs);
        setSelectedData(selectedData);
        if (props.onGridSelectionChange!==undefined && props.onGridSelectionChange!==null) props.onGridSelectionChange({selected, selectedRowIDs, selectedData});
    }
   // GRID EVENT - handleOnGridDataSourceCacheChange - runs every time the grid's data changes
    const handleGridOnDataSourceCacheChange = () => {
        if (props.onGridDataSourceCacheChange!==undefined && props.onGridDataSourceCacheChange!==null) props.onGridDataSourceCacheChange(dataSource);
    };
    // GRID EVENT - handleOnRenderRowContextMenu - runs eto generate custom context menu
    const handleGridOnRenderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
        if (props.onGridRenderRowContextMenu!==undefined && props.onGridRenderRowContextMenu!==null) {
            props.onGridRenderRowContextMenu(menuProps, { rowProps, cellProps });
        }
    };

    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // SEARCH FILTERS - searchFiltersEnabled - turns on/off the search filters.
    const [searchFiltersEnabled, setSearchFiltersEnabled] = useState(props?.searchFiltersEnabled);
    useEffect(() => setSearchFiltersEnabled(props?.searchFiltersEnabled), [props?.searchFiltersEnabled]);

     // SEARCH FILTERS - searchFiltersVisible- visible state of the search filters, which must be enabled.
     const [searchFiltersVisible, setSearchFiltersVisible] = useState(props?.searchFiltersVisible);
     useEffect(() => setSearchFiltersVisible(props?.searchFiltersVisible), [props?.searchFiltersVisible]);

    // SEARCH FILTERS - searchFilters - -Array of search filter definitions for our custom filters. 
    const [searchFilters, setSearchFilters] = useState(props?.searchFilters);
    useEffect(() => setSearchFilters(props?.searchFilters), [props?.searchFilters]);

    const handleSearchFilterOnChange = (filterValues) => {
        // runs every time the search filters are used. 
        console.log('handleSearchFilterOnChange', filterValues)

        if (filterValues===undefined||filterValues===null||filterValues.length<=0) {
            return setDataSource(dataSourceOriginal);
        } else {
            
            // filter dataSource with our Custom Filters UI filterValues returned. 
            /*
                { 
                filterId: filterId,                                     // filterId    
                filterValue: selectedValue,                             // The Selected value as it exists coming from React-Select
                column: filters.find(x => x.id===filterId)?.column,     // The column name wqe are filtering
                columnValues: selectedValue.map(opt => (opt?.value)),   // Array of the values in the Selected Options
                },
            */
            var newDataSource = [...dataSourceOriginal]


            filterValues.forEach( filterValue => {
                newDataSource = newDataSource.filter( rec => (filterValue.columnValues.indexOf(rec[filterValue?.column])>=0))
            })
            setDataSource(newDataSource);
        }
        
    };
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // SEARCH BOX - searchBoxEnabled - turns on/off the search filters.
    const [searchBoxEnabled, setSearchBoxEnabled] = useState(props?.searchBoxEnabled);
    useEffect(() => setSearchBoxEnabled(props?.searchBoxEnabled), [props?.searchBoxEnabled]);

    // SEARCH BOX - searchBoxAutofocus - 
    const [searchBoxAutofocus,setSearchBoxAutofocus] = useState(props?.searchBoxAutofocus);
    useEffect(() => setSearchBoxAutofocus(props?.searchBoxAutofocus), [props?.searchBoxAutofocus]);
    //
    //--------------------------------------------------------------------------------------------------------------------------------
    //
    // SEARCH RULES - searchRules - stores the rules for text search and all search results, such as fuse.js params. 
    const [searchRules, setSearchRules] = useState(props?.searchRules);
    useEffect(() => setSearchRules(props?.searchRules), [props?.searchRules]);

    // SEARCH BOX FILTER HOOK 
    // -Use our custom useFuseSearch hook. Connects to the text box above grid and filter grid data. 
    // Uses fuse.js search algorithms.
    const { 
        handleSearchInputOnChange, 
        clearSearch, 
        searchTerm, 
        searchResults, 
        searchLimit 
    } = useFuseSearch( dataSource, {
        // fuse.js search options here:
        keys: searchRules?.keys,   //  ex) ["name", "manufacturer_name", "nfrc_id", "vlt"], // <-- Array of columns to search.  (can be nested objects w/keys too --see fuse.js)
        matchAllOnEmptyQuery: searchRules?.matchAllOnEmptyQuery, //  ex) true, // <-- If true, all results show when no search matches.
        limit: searchRules?.limit,       // Limits the total records shown. 
        threshold: searchRules?.threshold, // 0.15,  //Default: 0.6 ; 0.15=PERFECT! At what point does the match algorithm give up. A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
    });
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // LABELS - labels - labels or text to customize any text output
    const [labels, setLabels] = useState(props?.labels);
    useEffect(() => setLabels(props?.labels), [props?.labels]);
    // 
    //--------------------------------------------------------------------------------------------------------------------------------
    // 
    // GRID AUTO-HEIGHT MECHANISM  - When window height or Grid Ref changes, calculate grid height 
    const { dimensions } = useUI();
   
    useEffect(() => { 
        if (gridAutoHeight===undefined||gridAutoHeight===null||gridAutoHeight===false) return;

        //console.log('height changed = '+dimensions?.WINDOW_HEIGHT)
        //console.log('gridRef = ',gridRef?.current?.domRef?.current?.offsetTop);
        //console.log('gridAutoHeightOffset = ',gridAutoHeightOffset);
        // console.log('searchFiltersVisible',searchFiltersVisible)
        //return;

        const gridTop = gridRef?.current?.domRef?.current?.offsetTop;



        if (gridTop===undefined || gridTop===null || gridTop==='') return;

        const winHeight = dimensions?.WINDOW_HEIGHT;
        if (winHeight===undefined || winHeight===null || winHeight==='') return;

        const newGridHeight = winHeight - gridTop + (gridAutoHeightOffset===undefined||gridAutoHeightOffset===null ? 0 : gridAutoHeightOffset);
        return setGridHeight( newGridHeight );

    }, [dimensions, gridRef, gridAutoHeight, gridAutoHeightOffset, searchFiltersVisible, searchFiltersEnabled, searchFilters, gridLoaded]);

    
    return (
        <div className={className} style={style}>

        <GridTotalRecords dataSource={dataSourceOriginal} labels={labels} />
        
        {/* <GridToolbar /> */}
       
        {(searchFiltersEnabled) &&
            <GridSearchFilters 
                visible={searchFiltersVisible}
                onToggleVisibility={ (visible) =>{setSearchFiltersVisible(visible)} }
                onChange={handleSearchFilterOnChange} 
                filters={searchFilters} 
                dataSource={dataSourceOriginal} 
                disabled={loading}
            />
        }

        {(searchBoxEnabled && gridLoaded) &&
            <GridSearchInput 
                searchTerm={searchTerm} 
                onChange={handleSearchInputOnChange} 
                onClear={() => clearSearch(null)} 
                autoFocus={searchBoxAutofocus} 
                disabled={loading}
            />
        }

        <GridFilteredRecords 
            labels={labels}
            dataSourceOriginal={dataSourceOriginal} 
            dataSource={dataSource} 
            searchResults={searchResults} 
            searchLimit={searchLimit} 
        />

        <Container fluid className="p-1">
            {(showGridAsList===true) 
            ?
                <GridShowAsList searchResults={searchResults} searchTerm={searchTerm} columnToDisplay={showGridAsListColumn} showAsJSON={showGridAsListJSON} />
            :
                <Grid
                    // Data & Loading
                    loading={loading}
                    dataSource={(searchResults === undefined || searchResults === null ? [] : searchResults)}
                    idProperty={idProperty}
                    
                    
                    // style
                    style={gridStyle}  

                    // columns & groups
                    columns={columns}
                    enableColumnAutosize={enableColumnAutosize}
                    groups={groups}

                    // rows & row index
                    rowHeight={rowHeight}

                    // sort & filter 
                    defaultSortInfo={defaultSortInfo}
                    enableFiltering={enableFiltering}
                    defaultFilterValue={defaultFilterValue}
            
                    // paging  
                    pagination={pagination}
                    limit={limit}
                    livePagination={livePagination}
                    scrollThreshold={scrollThreshold}

                    // Indexing and Selecting
                    activeIndex={activeIndex}                  
                    enableSelection={enableSelection}
                    defaultSelected={defaultSelected}
                    selected={selected}                         
                    checkboxColumn={checkboxColumn}
                    multiSelect={multiSelect}

                    //  EVENTS 
                    onGridLoad={handleGridOnLoad}
                    onGridDataSourceCacheChange={handleGridOnDataSourceCacheChange}
                    onGridRefReady={handleGridOnRefReady}
                    onGridActiveIndexChange={handleGridOnActiveIndexChange}  
                    onGridFilterValueChange={handleGridOnFilterValueChange}
                    onGridSelectionChange={handleGridOnSelectionChange}
                    // RENDER EVENT OVER-RIDES
                    onGridRenderRowContextMenu={handleGridOnRenderRowContextMenu}
                />
            }
        </Container>

        {(debug === true) 
            && 
            <DebugOutput 
                activeIndex={activeIndex} 
                defaultSelected={defaultSelected} 
                selectedRowIDs={selectedRowIDs} 
                selected={selected} 
            />
        }
    </div>
    );
};
MyDataGrid.defaultProps = {
    // Debugging
    debug: false,
    showGridAsList: false, // will output a search result as list instead of grid. good for debugging data, or searches.
    showGridAsListColumn: 0, // When showGridAsList true. Which column to show for data? Integer or string. 
    showGridAsListJSON: false,   // When showGridAsList is true, output all data as JSON stringify for each list item. 

    // Data & Loading
    loading: true,
    dataSource: [],
    idProperty: 'id', // the id property in the data that makes a unique row id.

    // Style
    className: '',    // the wrapper className
    style: {},        // the wrapper Style
    gridStyle: {},    // the grid's Style
    autoHeight: true,   // Grid Auto-height - Will Make grid height to fill Viewport automatically
    autoHeightOffset: -70, // Grid Auto-height Offset.  Will offset the auto calculated height. Useful to account for footer.

    // columns & groups
    columns: [],
    groups: [],
    enableColumnAutosize: true,

    // rows & row index
    rowHeight: 50,    // null = natural rowHeight, or use an Integer for fixed rowHeight.

    // Grid: sort & filter 
    enableFiltering: false, //- Shows/hides column filter controls.
    defaultSortInfo: {},
    filterValue: [],

    // Search Filters - Our custom search filters added at top of grid. 
    searchFiltersEnabled: false,
    searchFiltersVisible: false,     //- The default visible state of the filters, if they are enabled. . 
    searchFilters: [],     //- Array of column filter definitions objects. EXAMPLE) [{column:'subtype', label:'Filter by Glazing Type:', type:'multiSelect'},{column:'manufacturer_name', label:'Filter by Manufacturer:', type:'multiSelect'},{column:'appearance', label:'Filter by Appearance/Color:', type:'multiSelect'},] 

    // Search Text Box
    searchBoxEnabled: false,
    searchBoxAutofocus: false,
    // Search Result Rules
    searchRules: {   //- Search Rules are fuse.js search options.  All grid results fed through it.  Text search fine searches displayed. m  See https://fusejs.io/api/options.html
        keys: ["id"],    //<-- 'keys' to search in the data for the text box search. Can be nested.  See fuse.js options.  Example) ["name", "manufacturer_name"]
        matchAllOnEmptyQuery: true,         
        threshold: 0.15,               //<--  0.15 Perfect. A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
        limit: 10000,                  //<--  Limits records shown.   
    },          
    
    labels: {
        records: 'records',             //<-- 3444 total 'records' etc..
    },

    // Grid: paging  
    pagination: true,
    limit: 50,              //- default number per page
    livePagination: false,  //- pages are loaded as you scroll. Use livePagination=true, and you can use it together with limit to control the page size.
    scrollThreshold: 0.7,   //- number, pixel height or percent. If scrollThreshold is specified, the next page will be loaded when the user reaches the scroll max height minus scrollThreshold of the page

    // Grid: Indexing and Selecting
    activeIndex: null,          
    enableSelection: false, // Enables/disables ability of user to select.
    defaultSelected: null,  // Object of row property Id's and boolean value of true. These will default selected. Ex) 24, or '24', or {24:true,25:true}
    selected: null,    
    checkboxColumn: false,
    multiSelect: false,

    //  Events 
    onGridLoad: (gridRef) => {},                          //- GRID EVENT: When the ReactDataGrid is ready and Data Loaded (if data)
    onGridRefReady: (grid) => {},                           //- GRID EVENT: When the ReactDataGrid is ready in the ReactDOM and has a ref. Gte its REF here. 
    onGridDataSourceCacheChange: (data) => {},              //- GRID EVENT: When the ReactDataGrid data changes.
    onGridActiveIndexChange: (data) => {},                  //- GRID EVENT: 
    onGridFilterValueChange: (filterData) => {},            //- GRID EVENT: 
    onGridSelectionChange: (selected, selectedRowIDs) => {},//- GRID EVENT: 
    // onGridRowDataUpdated={console.log('onGridRowDataUpdated')}   - GRID EVENT: 
    // onGridUpdateRowHeights={console.log('onGridUpdateRowHeights')}   - GRID EVENT: 
    onGridRenderRowContextMenu: (menuProps, { rowProps, cellProps }) => {},
};
MyDataGrid.propTypes = {
    // Debugging
    debug: PropTypes.bool,
    showGridAsList: PropTypes.bool,
    showGridAsListColumn: PropTypes.any,
    showGridAsListJSON: PropTypes.bool,

    // Data & Loading
    loading: PropTypes.bool,
    dataSource: PropTypes.array,
    idProperty: PropTypes.string.isRequired,

    // Style
    className: PropTypes.string,
    style: PropTypes.object,
    gridStyle: PropTypes.object,
    gridAutoHeight: PropTypes.bool,
    gridAutoHeightOffset: PropTypes.number,

    // Columns & Groups
    columns: PropTypes.array.isRequired,
    groups: PropTypes.array,
    enableColumnAutosize: PropTypes.bool,

    // Rows & Row Index
    rowHeight: PropTypes.number,
            
    // Sort & Filter 
    enableFiltering: PropTypes.bool,
    defaultSortInfo: PropTypes.object,
    filterValue: PropTypes.array,

    // Search Filters
    searchFiltersEnabled: PropTypes.bool,
    searchFiltersVisible: PropTypes.bool,
    searchFilters: PropTypes.array,

    // Search Box
    searchBoxEnabled: PropTypes.bool,
    searchBoxAutofocus: PropTypes.bool,
    // Search Rules
    searchRules: PropTypes.object,

    // Labels
    labels: PropTypes.object,

    // Paging
    pagination: PropTypes.bool,
    perPage: PropTypes.number,   // is 'limit' in ReactDataGrid (I renamed it for MyDataGrid)
    livePagination: PropTypes.bool,
    scrollThreshold: PropTypes.any,

    // Indexing and Selecting
    activeIndex: PropTypes.any,        
    enableSelection: PropTypes.bool,
    defaultSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),  // ex) 24, or '24', or {24:true,25:true}
    selected: PropTypes.any,   
    checkboxColumn: PropTypes.bool,
    multiSelect: PropTypes.bool,

    //  Event 
    onGridLoad: PropTypes.func,
    onGridRefReady: PropTypes.func,
    onGridDataSourceCacheChange: PropTypes.func,
    onGridActiveIndexChange: PropTypes.func,
    onGridFilterValueChange: PropTypes.func,
    onGridSelectionChange: PropTypes.func,
    onGridRowDataUpdated: PropTypes.func,
    onGridUpdateRowHeights: PropTypes.func,

    onGridRenderRowContextMenuF: PropTypes.func,
};
export default MyDataGrid;
