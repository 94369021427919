import { SECURE_API } from "_core/services/_API_bridge";
import { verify_FormData } from "_core/helpers/FormData/FormData-verify";

class InventoryDataService {
    fetch_inventory = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     // call our helper that converts object data to FormData if needed.
            let response = await SECURE_API( "POST" , "thermolite/inventory/" , search_filters, access_token ); 
            console.log("Inventory Service fetch_inventory response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
   
}
const newInventoryDataService  = new InventoryDataService();
export default newInventoryDataService;