import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const MyMultiSelect = ({ disabled, options, defaultValue, value, onChange,onInputChange, closeMenuOnSelect }) => {
    
    const [isDisabled, setIsDisabled] = useState(disabled);     // State Var for showing loading progress in THIS component,
    useEffect(() => {
        setIsDisabled(disabled)
    }, [disabled]);    
    
    const selectStyles = {
        control: (styles) => ({ ...styles, padding: 0 }),
        option: (styles) => ({ ...styles }),
        input: (styles) => ({ ...styles, padding: 0 }),
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles, { data }) => ({ ...styles }),
    };
    const selectAnimatedComponents = makeAnimated();

    return (
        <Select
            disabled={isDisabled}
            className="react-select form-control"
            styles={selectStyles}
            isMulti={true}
            closeMenuOnSelect={closeMenuOnSelect}
            options={options}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onInputChange={onInputChange}
            components={selectAnimatedComponents}

            isClearable={true}
            isSearchable={true}
        />
    );
};
MyMultiSelect.defaultProps = {
    disabled: false,
    value: [],
    defaultValue: [],

    closeMenuOnSelect: false,
    onInputChange: (inputValue, { action, prevInputValue }) => {},
};
MyMultiSelect.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,

    value: PropTypes.arrayOf(PropTypes.object),
    defaultValue: PropTypes.arrayOf(PropTypes.object),

    closeMenuOnSelect: PropTypes.bool,

    onInputChange:PropTypes.func,
};

export default MyMultiSelect;