/**                                                         
 *    a REDUX SLICE for:
 * 
 *      'Glazing' Table Data 
 *      - handles the response from our service that gathers Glazing Products Data from our API. 
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isArray } from "_core/helpers/data-types/arrays";

import GlazingDataService from "_custom/services/glazing-data.service";

// initial state of Message
const initialState = {

    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',

    
    products: [],   // array of product objects. 
    products_found_count: [],  // total returned by fetch
    products_total_count: [],  // total products in DB

};

export const fetch_products = createAsyncThunk(
    "glazing/fetch_products",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await GlazingDataService.get_products( search_filters , access_token ); 
            console.log('GLAZING-FETCH  - SLICE - RESPONSE RECEIVED:',response);

            if (response.status === 200 &&  response.data?.success) {
                //console.log('GLAZING-FETCH  - VALID SUCCESS - DATA SHOULD BE THERE!');
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const glazingDataSlice = createSlice({
    name: "glazing",
    initialState,
    reducers: {
        clearState: (state) => {
            state = JSON.parse(JSON.stringify(initialState));   // deep copy initialState t state. 
            return state;
        },
    },

    extraReducers: (builder) => {
        builder
            /****************************************************** */
            /*  fetch_products                                   */
            /****************************************************** */
            .addCase(fetch_products.pending, (state, action) => {
                if (state.products===undefined || !isArray(state.products)) {
                    state.products = initialState.products
                }
                state.isFetching = true;
            })
            .addCase(fetch_products.fulfilled, (state, action) => {
                const result = action.payload;                            /// this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;  
                console.log('data',data);
                state.products          = data?.products;
                state.products_found_count    = data?.products_found;
                state.products_total_count    = data?.products_total;

                state.isFetching        = false;
                state.isSuccess         = true;
                state.isError           = false;
                state.errorMessage      = 'No errors.';
            })
            .addCase(fetch_products.rejected, (state, action) => {
                //console.log('Glazing Products fetch payload', action);
                const error = action?.error;  
                state.isFetching        = false;
                state.isSuccess         = false;
                state.isError           = true;
                state.errorMessage      = 'Glazing fetch failed! Error= '+JSON.stringify(error);
    
                state.products          = initialState.products;
                state.products_found    = initialState.products_found;
                state.products_total    = initialState.products_total;
            })
            /****************************************************** */
            /*  DEFAULT                                            */
            /****************************************************** */
            .addDefaultCase((state) => {
                // Do something when no actions identified.
                state.isFetching    = false;
            });
    },
});
export default glazingDataSlice;

export const { clearState } = glazingDataSlice.actions;