import React from "react";
import PropTypes from 'prop-types';
import { useController } from "react-hook-form";
import { Form } from 'react-bootstrap';



function RangePicker(props) {
    const { field: { onChange, onBlur, name, value, ref },
    } = useController({                    
        name: props.name,                                                      
        control: props.control,                                               
        defaultValue: props.value,
    });

    return (
        <Form.Range 
            id={name} name={name}
            onChange={onChange} onBlur={onBlur} value={value} ref={ref}    // <-- Binds the BS Control to react-hook-form Controller
            as={props.as}  type={props.type}
            className={props.className}
            disabled={props.disabled}
        />
    );
};
export default RangePicker;

RangePicker.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,

    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array
    ]),
    
    control: PropTypes.object,   /* Pass in the react-hook-form controller to this. */
    ref: PropTypes.object,  // React element ref
}
RangePicker.defaultProps = {
    value: "",
    disabled: false
};