
import { getAllowedPrivateRoutes } from "./PrivateRoutes.core.config";
import { PRIVATE_PATHS as PATHS} from "./Paths.core.config";
import { isArrayWithLength, searchArrayRecursively } from "_core/helpers/data-types";
import { CUSTOM_MENU_LEFT_DEFINITION } from '_custom/config/MENUS.config';

const getRouteDefinitionByPath = (path) => {
    const routes = getAllowedPrivateRoutes();
    return searchArrayRecursively(routes, 'path', path, 'children')
}; 
export const gr = (path) => {return getRouteDefinitionByPath(path)};

// Misc common MENU ITEMS:
export const separator = {divider: true,title: ""}
const RELOAD_APP_BTN = {path: "", title: "RELOAD APP", icon: "nav-reload", menu: "left", onclick: () => {window.location.reload(true)}}

// Placeholder Items
const CUSTOM_MENU_ITEMS_PLACEHOLDER = {id:'CUSTOM_MENU_ITEMS_PLACEHOLDER'};

// ----------------------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------------------
//
export const CORE_MENU_LEFT_DEFINITION = {
    description: "Left flyout Menu",
    title: "MAIN MENU",
    items: [
        gr(PATHS.MY.HOME),      // My Home
        separator,     
        CUSTOM_MENU_ITEMS_PLACEHOLDER,
        gr(PATHS.ADMIN.HOME),   // Admin Home
        gr(PATHS.GLOBAL.HOME),  // Global Admin Home
        gr(PATHS.DEV.HOME),     // Developer Home
        separator,
        RELOAD_APP_BTN,         // Reload App BUTTON
    ]
}
// ----------------------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------------------
//
export const CORE_MENU_RIGHT_DEFINITION = {
    description: "Right flyout Menu",
    title: "MY ACCOUNT MENU",
    items: [
        gr(PATHS.MY.ACCOUNT),      // My Account         
        gr(PATHS.MY.PROFILE),      // My Profile
        gr(PATHS.MY.PASSWORD),     // My Password
    ]
}


export const MENU_LEFT_DEFINITION = () => {
    let coreDef = {...CORE_MENU_LEFT_DEFINITION};
    let customDef = {...CUSTOM_MENU_LEFT_DEFINITION};
    let newDef = {};
    newDef.items = [];

    if (customDef?.title) newDef.title = customDef.title;
    if (customDef?.description) newDef.description = customDef.description;
   

    // find the CUSTOM_MENU_ITEMS_PLACEHOLDER in the core items list
    coreDef.items.forEach((item, index) => {
        if (item?.id==='CUSTOM_MENU_ITEMS_PLACEHOLDER') {
            // replace the placeholder with the custom items
            customDef?.items.forEach((item, index) => {
                // now insert the custom items into the core items list
                newDef.items.push(item);
            });

            // push in another separator after custom items, if custom items length is 1 or greater
            if (isArrayWithLength(customDef?.items)) newDef.items.push(separator);

        } else {
            newDef.items.push(item);
        }
    });

    return newDef
}




