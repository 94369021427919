import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup as ReactBootstrapButtonGroup } from 'react-bootstrap';

const ButtonGroup = (props) => {
    let output = "";
    output = (
        <ReactBootstrapButtonGroup 
            as={props.as}    
            role={props.role}
            size={props.size}
            vertical={props.vertical}
            bsPrefix={props.bsPrefix}
            className={props.className}
        >
            {props.children}
        </ReactBootstrapButtonGroup>
    )
	return output;
};
ButtonGroup.propTypes = {
	as: PropTypes.string,
    role: PropTypes.string,
    size: PropTypes.string,
    vertical: PropTypes.bool,
    bsPrefix: PropTypes.string,
    className: PropTypes.string,
}
ButtonGroup.defaultProps = {
    as: "div",
    role: "group",      // 'group'
    size:"button"   ,   // 'sm' | 'lg'
	vertical: false,
    bsPrefix: "btn-group",  //btn-group
    className: "",
};
export default ButtonGroup;