import { createContext, useContext, useMemo,useRef } from "react";
import PullToRefresh from "_core/components/core-library/pull-to-refresh/PullToRefresh";

const PullToRefreshContext = createContext();

export const PullToRefreshProvider = ({ children }) => {
    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    const enabled = useRef(true); // disable pull to refresh.
    const reloadApp = useRef(true); // default behavior - refreshed entire app.
    const onRefresh = useRef(null); // override onPull
   

    const setEnabled = (val) => {
        enabled.current = val;
    };
    const setReloadApp = (val) => {
        reloadApp.current = val;
    };
    const overrideOnRefresh = (func) => {
        onRefresh.current = func;
    };

    const handleOnRefresh = () => {
        // handleOnPullToRefresh of pull-to-refresh.js api must return a promise!

        console.log("onPullToRefresh", onRefresh.current);

        if (onRefresh.current !== null) {
            // use custom function passed into hook.  MUST return a promise!
            return onRefresh.current();
        } else if (reloadApp.current === true) {
            // if enabled, else just refresh the page.
            return new Promise(function (resolve, reject) {
                window.location.reload(true);
                resolve("Refresh new Promise!"); // have to resolve it.
            });
        } else {
            return new Promise(function (resolve, reject) {
                console.log("Pull to Refresh!  No Action Specified!");
                resolve("new promise"); // have to resolve it.
            });
        }
    };

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    const value = useMemo(
        () => ({
            onRefresh,
            overrideOnRefresh,
            enabled,
            setEnabled,
            reloadApp,
            setReloadApp,
        }), 
        []
    );

    return (
        <PullToRefreshContext.Provider value={value}>
            <PullToRefresh onRefresh={handleOnRefresh} isPullable={enabled.current}>
                {children}
            </PullToRefresh>
        </PullToRefreshContext.Provider>
    );
};
export const usePullToRefresh = () => {
    return useContext(PullToRefreshContext);
};
