import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar } from "react-bootstrap";
import { CONSTANTS } from "_core/config";
import { Link } from "_core/components/core-library";



const AppNavbarBrand = ({className, style, to=null, onClick=null}) => {
    
   
    if (!to && onClick) {
        return (
            <Link onClick={onClick} href="#">
                <Navbar.Brand className={className} style={style} >{CONSTANTS.APP_HEADER_LOGO}</Navbar.Brand>
            </Link>
        );
    } else {
        return (
            <LinkContainer to={(to ? to : "/")} onClick={onClick}>
                <Navbar.Brand className={className} style={style} >{CONSTANTS.APP_HEADER_LOGO}</Navbar.Brand>
            </LinkContainer>
        );
    }
    
}
export default AppNavbarBrand;
