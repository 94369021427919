

import { CUSTOM_PRIVATE_PATHS as PATHS} from "./PATHS.config"
import { CUSTOM_PRIVATE_ROUTES as ROUTES } from "./ROUTES.config";
import { searchArrayRecursively } from "_core/helpers/data-types";
const getRouteDefinitionByPath = (path) => {return searchArrayRecursively(ROUTES, 'path', path, 'children')}; 
export const gr = (path) => {return getRouteDefinitionByPath(path)};

// Misc common MENU ITEMS:
export const separator = {divider: true,title: ""}

//
// ----------------------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------------------
//
export const CUSTOM_MENU_LEFT_DEFINITION = {
    title: "MAIN MENU",
    description: "Left flyout Menu",
    items: [
        gr(PATHS.IGDB),                 // Temporary component in dev
        gr(PATHS.IGDB_BROWSE),          // Temporary component in dev
        gr(PATHS.GLOBAL.IGSDB.HOME),    // IGSDB Global Admin Home
       
    ]
}
//
// ----------------------------------------------------------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------------------------------------------------------
//
export const CUSTOM_MENU_RIGHT_DEFINITION = {
    title: "MY ACCOUNT MENU",
    description: "Right flyout Menu",
    items: [
       // add custom items here
    ]
}