
export const unique_key = () => {return generate_guid()};

export const generate_guid = () => {
    var n = function () {
        return ((65536 * (1 + Math.random())) | 0).toString(16).substring(1);
    };
    return (
        n() + n() + "-" + n() + "-" + n() + "-" + n() + "-" + n() + n() + n()
    );
}

export default generate_guid;