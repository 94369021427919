import React from "react"
import PropTypes from "prop-types";
import {default as RbsTab} from 'react-bootstrap/Tab';
import {default as RbsTabs} from 'react-bootstrap/Tabs';


export const Tabs = ({children, ...rest}) => {
    return (
        <RbsTabs {...rest}>
            {children}
        </RbsTabs>
    )
}
Tabs.propTypes = {
    fill: PropTypes.bool,
};
Tabs.defaultProps = {
    fill: false,
};


export const Tab = ({children, ...rest}) => {
    return (
        <RbsTab {...rest}>
            {children}
        </RbsTab>
    )
}
Tab.propTypes = {
    
};
Tab.defaultProps = {
    
};

