/**
 *  Made from:
 *  https://betterprogramming.pub/create-a-custom-usemodal-react-hook-449b5909cc09
 *  https://github.com/CITGuru/custom-react-modal-hook
 *
 */

import { useState } from "react";

export const useModal = (initialMode = false) => {
    const [modalVisible, setModalVisible] = useState(initialMode);
    const toggleModal = () => setModalVisible(!modalVisible);
    const showModal = () => {setModalVisible(true)}
    const hideModal = () => {setModalVisible(false)}
    return {modalVisible, setModalVisible, toggleModal, showModal, hideModal};
};

export const useModalWithData = (initialMode = false, initialSelected = null) => {
    const [modalVisible, setModalVisible] = useModal(initialMode);
    const [selected, setSelected] = useState(initialSelected);
    const setModalState = (state) => {
        setModalVisible(state);
        if (state === false) {
            setSelected(null);
        }
    };
    return {
        modalVisible,
        setModalVisible,
        selected,
        setSelected,
        setModalState,
    };
};
