import Icon from "_core/components/core-library/icons/Icon"
import React from "react"


const PullingContent = () => {
  return (
    <div className="d-flex flex-row justify-content-center ">
      <Icon name="arrow-down" className="me-2 align-self-center " />
      <div className=" align-self-center">PULL DOWN TO REFRESH</div>
      <Icon name="arrow-down" className="ms-2  align-self-center" />
    </div>
  )
}

export default PullingContent
