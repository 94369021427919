import { createContext, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_theme } from "_core/redux/slices/theme-redux-slice";
import { UI_THEMES } from '_core/config/Theme.config';   // for use for easy export, to reference any of the system themes. 
import { Helmet } from "react-helmet-async";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const dispatch = useDispatch();

    //-- Theme State, for export below
    const { 
        name: theme_name,
        def: theme_definition,
        default: theme_default,
    } = useSelector((state) => state.theme);


    const value = useMemo(
        () => ({
            THEMES: UI_THEMES,          // Just a reference to ALL themes. 
            THEME: theme_definition,    // quick handle to the def, for easier ref in code
            theme : {                   // full theme control object
                name : theme_name,      
                def: theme_definition,
                default: theme_default,
                set: ( async (pTheme) => { return dispatch( set_theme(pTheme) ) }),
                set_default: ( async (theme_default) => { return dispatch( set_theme(theme_default) ) }),
            },
        }),
        // Variable Dependencies required for exports above to have values
        [ 
            dispatch,
            theme_name, 
            theme_definition, 
            theme_default, 
        ] 
    );

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */
    return (
        <ThemeContext.Provider value={value}>
             {/* 
                THEME SUPPORT:
                - Using HELMET and BOOTSTRAP 5.3 "Color Modes"
                Here we modify the HTML tag to add the theme name to the Bootstrap Theme tag.
                Bootstrap by default supports 'dark' and 'light' themes (color modes). We'll use those. 
                
                See: https://getbootstrap.com/docs/5.3/customize/color-modes/#dark-mode 
            */}
            <Helmet>
                <html data-bs-theme={theme_definition?.html?.bsTheme} />
            </Helmet>
            
            {children}
        </ThemeContext.Provider>
    );
};
export const useTheme = () => {
    return useContext(ThemeContext);
};
