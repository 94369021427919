import { SECURE_API } from "_core/services/_API_bridge";
import { verify_FormData } from "_core/helpers/FormData/FormData-verify";

class IGDBDataService {  
    
    synchronize_igsdb = async (search_filters=null, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     // call our helper that converts object data to FormData if needed.
            let response = await SECURE_API( "POST" , "igdb/sync/" , search_filters, access_token ); 
         //   console.log("IGDB synchronize_igsdb response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    get_products = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     // call our helper that converts object data to FormData if needed.
            let response = await SECURE_API( "POST" , "igdb/read/" , search_filters, access_token ); 
         //   console.log("IGDB (current) Service get_products response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    get_product_detail = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     // call our helper that converts object data to FormData if needed.
            let response = await SECURE_API( "POST" , "igdb/detail/" , search_filters, access_token ); 
          //  console.log("IGDB (current) Service get_product_detail response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
   
}
const newIGDBDataService  = new IGDBDataService();
export default newIGDBDataService;