import React from "react";
import { Accordion, Col, Container, Row, Table } from "react-bootstrap";
import { useModal, Modal, DataField, JSONPretty, Button } from "_core/components/core-library";
import { format_number, format_percent } from "_core/helpers/format";
import { useEffect } from "react";

import "./modal.scss";
import { useIGSDB } from "_custom/hooks/useIGSDB";
import { useMessaging } from "_core/hooks/provider-hooks/useMessaging.provider";

const IgsdbGlazingProductsModal = ({show=false, productData, close=()=>{}}) => {

    const { confirm, toast } = useMessaging();

    const { local_glazing_products } = useIGSDB();

    const { modalVisible, setModalVisible, hideModal } = useModal(show);
    useEffect(() => {
        setModalVisible(show);
    },[show,setModalVisible]);

    const handleDelete = async () => {
        const confirmed = await confirm('Are you really sure?');
        if (confirmed) {
            let deleted = await local_glazing_products.delete(productData.product_id);
            if (deleted) {
                close();
                local_glazing_products.fetch();
                toast.show('IGSDB Product deleted successfully!',toast.types.success)
            } else {
                alert('Error deleting product');
            }
        }
    }

    const modalFooterButtons = () => {
        return(
            <div>
                <Button className="me-4" variant="danger" onClick={handleDelete}>Delete</Button>
                <Button className="me-2" variant="primary" onClick={()=>{alert(1)}}>Import to GRDB</Button>
            </div>
        );
    }

    return (
        <Modal
            className="igsdb-glazing-datagrid-modal"
            title={"IGSDB GLAZING PRODUCT"}
            size={"lg"}
            visible={modalVisible}
            closeButton={true}
            handleClose={close}
            showConfirm={false}
            confirmButtonText={"?"}
            handleConfirm={() => hideModal()}
            footerButtons={modalFooterButtons}
        >
            <Container fluid>
                <div className="text-center ">NFRC # {productData?.nfrc_id}</div>
                <div className="h5 text-center">{productData?.name}</div>
                <div className="text-center">{productData?.manufacturer_name}</div>

                <hr />
                <Table striped responsive size="sm" className="m-0">
                    <tbody>
                        <tr>
                            <th>Subtype:</th>
                            <td>{productData?.subtype}</td>
                        </tr>
                        <tr>
                            <th>Exact Thickness:</th>
                            <td>
                                <div>{format_number(productData?.thick_exact_inch, "?", 4, '"')}</div>
                                <div>{productData?.thick_exact_imp}</div>
                                <div>{format_number(productData?.thick_exact_mm, "?", 1, " mm")}</div>
                            </td>
                        </tr>
                        <tr>
                            <th>VLT:</th>
                            <td>{format_percent(productData?.vlt, "?", 0)}</td>
                        </tr>
                        <tr>
                            <th>NFRC ID:</th>
                            <td>{productData?.nfrc_id}</td>
                        </tr>
                        <tr>
                            <th>IGDB Version:</th>
                            <td>{productData?.igdb_database_version}</td>
                        </tr>
                        <tr>
                            <th>IGSDB Product ID:</th>
                            <td>{productData?.product_id}</td>
                        </tr>
                        <tr>
                            <th>Local DB ID:</th>
                            <td>{productData?.id}</td>
                        </tr>
                    </tbody>
                </Table>

                <Accordion className="mt-4">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>measured_data</Accordion.Header>
                        <Accordion.Body>
                            <JSONPretty json={productData?.measured_data === undefined ? null : JSON.parse(productData?.measured_data)} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>integrated_results_summary</Accordion.Header>
                        <Accordion.Body>
                            <JSONPretty json={productData?.integrated_results_summary === undefined ? null : JSON.parse(productData?.integrated_results_summary)} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>All DATA</Accordion.Header>
                        <Accordion.Body>
                            <JSONPretty json={productData} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />
                <Row>
                    <Col>
                        <DataField label="Created">
                            {productData?.created}
                            {productData?.created_by ? " by " + productData?.created_by : ""}
                        </DataField>
                    </Col>
                    <Col>
                        <DataField label="Modified">
                            {productData?.modified}
                            {productData?.modified_by ? " by " + productData?.modified_by : ""}
                        </DataField>
                    </Col>
                </Row>
            </Container>
        </Modal>
    );
};
export default IgsdbGlazingProductsModal;

