/*
* These are the placeholder roles you can replace these with yours
*/
export const ROLES = {
	DEV:'DEV',
	SUPER_ADMIN: 'SUPER_ADMIN',
	ADMIN: 'ADMIN',
	MANAGER: 'MANAGER',
	EMPLOYEE: 'EMPLOYEE',
	CUSTOMER: 'CUSTOMER',
	GUEST: 'GUEST'
};


export function AllRoles(ROLES) {
	// returns an array of all roles names. 
	const arr=[];
	for (const key in ROLES) {
		arr.push(ROLES(key));
	}
	return arr;
}

