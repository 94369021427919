import React, { useState, useMemo, useEffect  } from "react";
import MyDataGrid from "_core/components/core-library/tables/MyDataGrid/MyDataGrid";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
//import { useGlazing } from "hooks/app-hooks/useGlazing";
import { useIGSDB } from "_custom/hooks/useIGSDB";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";
import { format_number, format_percent } from "_core/helpers/format";
import { compare_for_sort } from "_core/helpers/compare";
import { usePullToRefresh } from "_core/hooks/provider-hooks/usePullToRefresh.provider";
import IgsdbGlazingProductsModal from "./IgsdbGlazingProductsModal";

const defaultGridWrapperStyle={
    borderRadius: 4,
   // backgroundColor: '$APP-colors-info',
}
const defaultGridStyle={
   // backgroundColor: defaultGridWrapperStyle.backgroundColor,
}

const IgsdbGlazingProductsTable = ({style}) => {
    const { dimensions, breakpoints} = useUI();

    // GET PRIMARY PRODUCT DATA for TABLE:
    const { local_glazing_products } = useIGSDB();

    useOnMountRunOnce(() => local_glazing_products.fetch());

    // Table Style - Dynamic based on Breakpoints and UI.  Must be set in use Memo to avoid rerenders loop. 
    const dynamicGridStyles = useMemo(() => ({
        //  DESKTOP:{ height: "calc(100vh - 430px)" },
        DESKTOP: { height: "400px" },    
        MOBILE: { height: "400px"},
    }),[] );
    const [gridStyle, setGridStyle] = useState(dynamicGridStyles.DESKTOP);
    useEffect( () => {
        //console.log('width change = '+dimensions.WINDOW_WIDTH)
        if (breakpoints.xs===true||breakpoints.sm===true) {
            setGridStyle(dynamicGridStyles.MOBILE)  
        } else {
            setGridStyle(dynamicGridStyles.DESKTOP)  
        }

    },[dimensions.WINDOW_WIDTH, breakpoints, gridStyle, dynamicGridStyles])


    // TABLE COLUMNS DEFINITIONS
    const columns = [
        {
            id: "mobile",
            header: "RESULTS",
            type: "number",
            textAlign: "start",
            headerAlign: "center",
            visible: breakpoints.xs === true,
            showColumnMenuTool: false,
            flex: 1,
            render: ({ data }) => {
                return (
                    <div>
                        <div className="d-flex justify-content-between small">
                            <div className="text-start text-uppercase">{data?.manufacturer_name}</div>
                            <div>NFRC #{data?.nfrc_id}</div>
                        </div>
                        <div style={{ textAlign: "center" }}>{data?.name}</div>
                        <div className="d-flex justify-content-between small">
                            <div>VLT: {format_percent(data?.vlt, "unk", 0)}</div>
                            <div>{data?.subtype}</div>
                            <div>{data?.appearance}</div>
                            <div>{format_number(data?.thick_exact_mm, "unk", 1," mm")}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            id: "product",
            header: "PRODUCT",
            type: "string",
            textAlign: "start",
            headerAlign: "start",
            visible: breakpoints.xs === false,
            showColumnMenuTool: false,
            flex: 1,
            minWidth: 400, 
            render: ({ data }) => {
                return (
                    <div>
                        
                        <div className="d-flex justify-content-between ">
                            <div className="text-start small text-uppercase">{data?.manufacturer_name}</div>
                            <div className="small">NFRC #{data?.nfrc_id}</div>
                        </div>    
                        <div className="fw-bold" style={{ textAlign: "left" }}>{data?.name}</div>     
                        <div className="d-flex justify-content-between ">
                            <div className="small">{data?.subtype}</div>
                            <div className="text-start small text-uppercase"></div>                           
                        </div>                   
                    </div>
                );
            },
        },
       
        {
            name: "vlt",
            header: "VLT",
            group: "other",
            type: "number",
            textAlign: "center",
            headerAlign: "center",
            visible: breakpoints.xs !== true,
            defaultWidth: 100,
            showColumnMenuTool: false,
            render: ({ value }) => {
                // Round the long precision number
                let val = value === undefined || value == null || value === "" ? "" : parseFloat(value).toFixed(2);
                // If (val!==null&&val!=='') val = (val * 100) + '%';
                
                return format_percent(val,'',0);
            },
        },
        {
            id: "thick_exact",
            header: "EXACT Thick",
            type: "number",
            textAlign: "center",
            headerAlign: "center",
            visible: breakpoints.xs !== true,
            defaultWidth: 150,
            render: ({ data }) => { return (
                <div className="small">
                    <div>{data?.thick_exact_imp}</div>          
                    <div>{format_number(data?.thick_exact_inch, '?', 3, '"')}</div>  
                    <div>{format_number(data?.thick_exact_mm, '?', 1, ' mm')}</div>
                </div>
            );},
            sort: (d1, d2) => compare_for_sort(d1?.thick_exact_inch, d2?.thick_exact_inch),
        },
        {
            id: "thick_nominal",
            header: "NOM Thick",
            type: "number",
            textAlign: "center",
            headerAlign: "center",
            visible: breakpoints.xs !== true,
            defaultWidth: 150,
            render: ({ data }) => { return (
                <div className="small">
                    <div>{data?.thick_nominal_imp}</div>          
                    <div>{format_number(data?.thick_nominal_inch, '?', 3, '"')}</div>  
                    <div>{format_number(data?.thick_nominal_mm, '?', 1, ' mm')}</div>
                </div>
            );},
        },
       
        //{ name: "igdb_database_version", header: "IGDB Version", group: "other", type: "string", visible: breakpoints.xs !== true, defaultWidth: 120 },
        { name: "nfrc_id", header: "NFRC ID", group: "other", type: "number", visible:false, defaultWidth: 120 },
        { name: "id", header: "id", group: "other", type: "number", visible: false },
        { name: "product_id", header: "Product ID", group: "other", type: "number", visible: false },
    ];

    const groups = [
        // { name: "other", header: "GLAZING" },
        // { name: "thickness_exact", header: "EXACT Thickness", headerAlign: "center" },
        // { name: "thickness_nominal", header: "NOMINAL Thickness", headerAlign: "center" },
    ];

    const defaultFilterValue = [
        // { name: "product_id", type: "string", operator: "startsWith", value: "" },
        // { name: "nfrc_id", type: "string", operator: "startsWith", value: "" },
        // { name: "subtype", type: "select", operator: "inlist", value: "" },
        // { name: "name", type: "string", operator: "startsWith", value: "" },
        // { name: "manufacturer_name", type: "select", operator: "inlist", value: "" },
        // { name: "appearance", type: "select", operator: "inlist", value: "" },
    ];

    const defaultSortInfo = {
        name: "nfrc_id",
        dir: -1,
    };

    const [selected, setSelected] = useState(null);                     /* eslint-disable-line no-unused-vars */                   
    const [selectedRowIDs, setSelectedRowIds] = useState(null);         /* eslint-disable-line no-unused-vars */
    const [selectedData, setSelectedData] = useState(null);             /* eslint-disable-line no-unused-vars */

    const [productModalVisible, setProductModalVisible] = useState(false);

    const showProductModal = (selectedData) => {
        //console.log(selectedData);
        if (selectedData===undefined||selectedData==null) return;
        if (typeof selectedData !== 'object') return;
        if (Object.keys(selectedData).length<=0) return;
        setProductModalVisible(true);
    }

    const handleOnSelectionChange = ({selected, selectedRowIDs, selectedData}) => {
        //console.log('handleOnSelectionChange triggered');console.log('selected',selected);console.log('selectedRowIDs',selectedRowIDs);console.log('selected data',selectedData);
        setSelected(selected);
        setSelectedRowIds(selectedRowIDs);
        setSelectedData(selectedData);

        showProductModal(selectedData)
    };

    const handleRenderRowContextMenu = (menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Row ' + rowProps.rowIndex
            },
            {
                label: 'Want to visit ' + rowProps.data?.name + '?'
            }
        ]
    }

    // const handleOnPullToRefresh = () => {
    //    // setIsLoading(true);
    //    console.log('fetching again');
    //    return local_glazing_products.fetch();
    // //    return new Promise(function(resolve, reject) {
    // //         console.log('fetching again');
            
    // //         resolve('start of new Promise');
    // //     });
    // };

    const [isLoading, setIsLoading] = useState(true);   
    useEffect( () => {
        setIsLoading(local_glazing_products?.isFetching);
        //setIsLoading(true);
        console.log('isFetching',local_glazing_products?.isFetching)
       // console.log('isLoading',isLoading)
    },[local_glazing_products?.isFetching, isLoading] );


    // Hijack Pull to Refresh Provider, and to reload grid data. 
    const { overrideOnRefresh } = usePullToRefresh();
    useOnMountRunOnce( () => {
        overrideOnRefresh( () => {
            return local_glazing_products?.fetch();
        })
    })


    return (
        <>
            <MyDataGrid
                className="igsdb-glazing-datagrid mb-3"

                // Debugging
                debug={false}
                showGridAsList={false}
                showGridAsListColumn={"name"}
                showGridAsListJSON={false}

                // Data & Loading
                loading={isLoading}
                //loading={true}
                dataSource={local_glazing_products?.data}
                idProperty="id"
                
                // Style
                style={{...defaultGridWrapperStyle, ...style}}
                gridStyle={{...defaultGridStyle, ...gridStyle}}
                gridAutoHeight={true}
                gridAutoHeightOffset={-150}

                // Columns & Groups
                columns={columns}
                enableColumnAutosize={true}
                groups={groups}

                // Rows
                rowHeight={75}  //{breakpoints.xs === true ? 75 : 75}

                // Sort / Filter / Search
                defaultSortInfo={defaultSortInfo}
                enableFiltering={false}
                defaultFilterValue={defaultFilterValue}

                searchFiltersEnabled={true}
                searchFiltersVisible={false}
                searchFilters={[
                    {column:'subtype', label:'FILTER BY GLAZING TYPE:', type:'multiSelect'},
                    {column:'manufacturer_name', label:'FILTER BY MANUFACTURER:', type:'multiSelect'},
                    {column:'appearance', label:'FILTER BY APPEARANCE/COLOR:', type:'multiSelect'},
                ]}
                
                searchBoxEnabled={true}
                searchBoxAutofocus={false}

                searchRules={{                              //<-- Rules are fuse.js search options.  See https://fusejs.io/api/options.html
                    keys: ["name", "manufacturer_name", "nfrc_id", "vlt"],
                    matchAllOnEmptyQuery: true,
                    threshold: 0.15,                        //<--  A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
                    // limit: 9999999,                      //<--  Limits records shown. 
                }}

                // Labels
                labels = {{
                    records: 'products',
                }}
                
                // Paging  
                pagination={false}
                perPage={50}
                livePagination={false}
                scrollThreshold={0.7}

                // Indexing and Selecting
                activeIndex={null}
                enableSelection={true}
                defaultSelected={null}      // Ex) 24, or '24', or {24:true,25:true}
                checkboxColumn={false}
                multiSelect={false}
                    
                onGridLoad={(gridRef) => {}}
                onGridSelectionChange={handleOnSelectionChange}
                onGridRenderRowContextMenu={handleRenderRowContextMenu}
            />
            
            <IgsdbGlazingProductsModal productData={selectedData} show={productModalVisible} close={() => setProductModalVisible(false)} />
        </>
    );
};
export default IgsdbGlazingProductsTable;
