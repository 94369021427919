import React from "react";
import { Container } from "react-bootstrap";
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { PRIVATE_PATHS } from "_core/config";

const Subscription = () => {
                                                        
    return (
        <PrivatePageTemplate 
			pageTitle="My Account Subscription"
			breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
                { caption: "My Account", to: PRIVATE_PATHS.MY.ACCOUNT },
            ]}
		>
            <Container fluid className="d-flex justify-content-center">
                
            </Container>

		</PrivatePageTemplate>
    );
};
export default Subscription;
