
/*
    THEME CONFIG is a collection of theme definitions that are applied during 
    redux theme changes. The theme definitions are applied to the THEME state
    and then the THEME state is used to apply the theme to the UI elements where needed.

    Since Bootstrap 5.3, color modes were introduced, which allow for a light and dark mode set globally at the <html> tag level.
    We still apply styles here, but the color modes are applied at the <html> tag level.

    The THEME state is used to apply the theme to the UI elements where needed.

    
*/


const BASE_CONSTRUCT = {
    name:"THEME NAME?",
    colors: {               // colors : used only for the theme selector menu.  All theme colors come from the css and bootstrap theme colors.
        primary: "",
        secondary: "",
    },
    meta:{themeColor: ""},          // <meta name="theme-color" content=? >  Browser Tab Color
    html:{bsTheme:""},      // <html data-bs-theme=? > Bootstrap Color Mode GLOBAL setting.  dark or light
    wrapper: {
        className:"app-theme-default",      // The main Theme DIV wrapper class name.  This is used to apply the theme to the App's Content tag.
    },
    navbar: {
        bg: "",             // A convenience prop for adding bg-* utility classes since they are so commonly used here. light and dark are common choices but any bg-* class is supported, including any custom ones you might define.  Pairs nicely with the variant prop.
        variant: "",        // Either light or dark
        className:"",
        style:{},
    },
    toast: {
        theme: "",   // colored, light, or dark. React Toastify theme setting for our toasts.  See react-toastify for more info.
    }
}

// DEFAULT
const default_def = Object.assign({}, BASE_CONSTRUCT, {
    name: "default",
    colors: {primary: "#8ab1ec",secondary: ""},
    meta:{themeColor: "#8ab1ec"},
    html: {bsTheme:""},
    wrapper: {
        className:"app-theme-default",
    },
    navbar: {
        bsTheme : "light",
        bg: "yellow",
        variant: "light",
        
        className:"",
        style:{},
    },
    toast: {theme:"colored"},
});

// DARK
const dark_def = Object.assign({}, BASE_CONSTRUCT, {
    name: "dark",
    colors: {primary: "black",secondary: ""},
    meta:{themeColor: "black"},
    html: { bsTheme:"dark" },
    wrapper: {
        className:"app-theme-dark",
    },
    navbar: {
        bsTheme : "dark",
        bg: "",
        variant: "dark",
        className:"",
        style:{},
    },
    toast: {theme:"dark"},
});

//LIGHT
const light_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "light",
    colors: {primary: "lightgray",secondary: ""},
    meta:{themeColor: "lightgray"},
    html: { bsTheme:"light" },
    wrapper: {
        className:"app-theme-light",
    },
    navbar: {
        bsTheme : "light",
        bg: "light",
        variant: "light",
        className:"",
        style:{},
    },
    toast: {theme:"light"},
});

// YELLOW
const yellow_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "yellow",
    colors: {primary: "yellow",secondary: ""},
    meta:{themeColor: "yellow"},
    html: { bsTheme:"" },
    wrapper: {
        className:"app-theme-yellow",
    },
    navbar: {
        bsTheme : "light",
        bg: "yellow",
        variant: "light",
        className:"",
        style:{},
    },
    toast: {theme:"colored"},
});

// GREEN
const green_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "green",
    colors: {primary: "DarkSeaGreen",secondary: ""},
    meta:{themeColor: "DarkSeaGreen"},
    html: { bsTheme:"" },
    wrapper: {
        className:"app-theme-green",
    },
    navbar: {
        bsTheme : "light",
        bg: "yellow",
        variant: "light",
        className:"",
        style:{},
    },
    toast: {theme:"colored"},
});

// BLUE
const blue_def = Object.assign({}, BASE_CONSTRUCT, {
    name:    "blue",
    colors: {primary: "DodgerBlue",secondary: ""},
    meta:{themeColor: "DodgerBlue"},
    html: { bsTheme:"" },
    wrapper: {
        className:"app-theme-blue",
    },
    navbar: {
        bsTheme : "light",
        bg: "blue",
        variant: "light",
        className:"",
        style:{},
    },
    toast: {theme:"colored"},
});

export const UI_THEMES={
    default: { name:default_def.name,   def : default_def},
    dark: { name:dark_def.name,   def : dark_def},
    light: { name:light_def.name,   def : light_def},
    yellow: { name:yellow_def.name,   def : yellow_def},
    green: { name:green_def.name,   def : green_def},
    blue: { name:blue_def.name,   def : blue_def},
}; 

export const get_default_theme_definition = () => {return {...default_def}}