
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import "@inovua/reactdatagrid-community/index.css";

import { prepare_grid_columns } from '../helpers/prepare_grid_columns.js';
import { isInteger, isNumber, isObject } from '_core/helpers/data-types';

const Grid = (props) => {
    // REF to the Grid.
    const [gridRef, setGridRef] = useState(null);  /* eslint-disable-line no-unused-vars */

    

    
    // LOADING
    const [loading, setLoading] = useState(props?.loading);       // State Var for showing loading progress in THIS component,
    useEffect(() => {
        console.log('GRID loading ='+props?.loading)
        setLoading(props?.loading)
    }, [props?.loading]);


    const [gridLoaded, setGridLoaded] = useState(false);       // State Var for showing loading progress in THIS component,
    useMemo(() => {
       if (loading && gridLoaded) setGridLoaded(false);
    }, [loading,gridLoaded,setGridLoaded]);

    

    // DATA - dataSource
    const [dataSource, setDataSource] = useState(props?.dataSource); // Holds the Data
    useEffect(() => {
        //setLoading(true);                               // Show loading spinner.
        setDataSource(props?.dataSource);                  // Hold copy of original data.  Used during filtering.
    }, [props?.dataSource]);

    // DATA - idProperty   - The data columns thats makes the row id.  Usually 'id' for the db id. 
    const [idProperty, setIdProperty] = useState(props?.idProperty); 
    useEffect(() => setIdProperty(props?.idProperty), [props?.idProperty]);

    // COLUMNS - Column definitions
    const [columns, setColumns] = useState(props?.columns); 
    useEffect(() => setColumns(prepare_grid_columns(props?.columns, dataSource)), [props?.columns,dataSource]);
    // COLUMNS - Column GROUPS - groups
    const [groups, setGroups] = useState(props?.groups); 
    useEffect(() => setGroups(props?.groups), [props?.groups]);
    // COLUMNS - enableColumnAutosize
    const [enableColumnAutosize, setEnableColumnAutosize] = useState(props?.enableColumnAutosize); 
    useEffect(() => setEnableColumnAutosize(props?.enableColumnAutosize), [props?.enableColumnAutosize]);

    // ROWS - rowHeight
    const [rowHeight, setRowHeight] = useState(props?.rowHeight); 
    useEffect(() => setRowHeight(props?.rowHeight), [props?.rowHeight]);

    // STYLE - style
    const [style, setStyle] = useState(props?.style); 
    useEffect(() => setStyle(props?.style), [props?.style]);

    // SORTING - defaultSortInfo
    const [defaultSortInfo, setDefaultSortInfo] = useState(props?.defaultSortInfo); 
    useEffect(() => setDefaultSortInfo(props?.defaultSortInfo), [props?.defaultSortInfo]);

    // FILTERING - enableFiltering
    const [enableFiltering, setEnableFiltering] = useState(props?.enableFiltering); 
    useEffect(() => setEnableFiltering(props?.enableFiltering), [props?.enableFiltering]);
    // FILTERING - defaultFilterValue
    const [defaultFilterValue, setDefaultFilterValue] = useState(props?.defaultFilterValue); 
    useEffect(() => setDefaultFilterValue(props?.defaultFilterValue), [props?.defaultFilterValue]);

    // PAGINATION - pagination
    const [pagination, setPagination] = useState(props?.pagination); 
    useEffect(() => setPagination(props?.pagination), [props?.pagination]);
    // PAGINATION - limit  (number per page)
    const [limit, setLimit] = useState(props?.limit); 
    useEffect(() => setLimit(props?.limit), [props?.limit]);
    // PAGINATION - livePagination
    const [livePagination, setLivePagination] = useState(props?.livePagination); 
    useEffect(() => setLivePagination(props?.livePagination), [props?.livePagination]);
    // PAGINATION - livePagination - scrollThreshold
    const [scrollThreshold, setScrollThreshold] = useState(props?.scrollThreshold); 
    useEffect(() => setScrollThreshold(props?.scrollThreshold), [props?.scrollThreshold]);

    // ACTIVE ROW INDEX - is the highlighted row, thats not selected yet. Can be changed with keyboard arrow, etc..
    const [activeIndex, setActiveIndex] = useState(props?.activeIndex); 
    useEffect(() => setActiveIndex(props?.activeIndex), [props?.activeIndex]);


    // SELECTIONS - enableSelection - can be propped in
    const [enableSelection, setEnableSelection] = useState(props?.enableSelection); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setEnableSelection(props?.enableSelection), [props?.enableSelection]);

    // SELECTIONS - checkboxColumn - can be propped in
    const [checkboxColumn, setCheckboxColumn] = useState(props?.checkboxColumn); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setCheckboxColumn(props?.checkboxColumn), [props?.checkboxColumn]);
    // SELECTIONS - multiSelect - can be propped in
    const [multiSelect, setMultiSelect] = useState(props?.multiSelect); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setMultiSelect(props?.multiSelect), [props?.multiSelect]);
    // SELECTIONS - defaultSelected - can be propped in
    const [defaultSelected, setDefaultSelected] = useState(props?.defaultSelected); // Default selected Rows at load  -- EX) {4:true,3:true}  // use id of 'idProperty' parameter
    useEffect(() => setDefaultSelected(props?.defaultSelected), [props?.defaultSelected]);
    // SELECTIONS - selected, selectedRowIDs - [INTERNALLY SET ONLY]
    const [selected, setSelected] = useState(); // An Object of Selected Rows { id1: {id1 row data}, id2: {id2 row data},...}       
    const [selectedRowIDs, setSelectedRowIDs] = useState([]); /* eslint-disable-line no-unused-vars */  // Array of selected row id's only.  ["id1","id2",...]                
    const [selectedData, setSelectedData] = useState(null);   /* eslint-disable-line no-unused-vars */  //[OBJECT or OBJECT of Objects] A copy of the row data that's selected. 

    
    // EVENT - handleOnGridLoaded - After the Grid is Loaded With Data and ready. 
    const handleOnGridLoaded = () => {
        if (props?.loading===true) {
        //    setLoading(false);
        } else {
        //    setLoading(true);
        }
        
        setGridLoaded(true);
        if (props.onGridLoad!==undefined && props.onGridLoad!==null) {
            props.onGridLoad(gridRef);
        }
    }

    // EVENT - handleOnGridRefReady - After Grid is ready n the React Dom.  It May not have it's data yet. But will have a REF. 
    const handleOnGridRefReady = (gridRef) => {
        setGridRef(gridRef); //- Create ref to the Grid.
        //console.log("grid ready");
        if (props.onGridRefReady!==undefined && props.onGridRefReady!==null) {
            props.onGridRefReady({
                ref:gridRef
            });
        }
    };
    // EVENT - handleOnActiveIndexChange
    const handleOnActiveIndexChange = useCallback( (idx) => {
            // runs every time the grid's row highlight changes (not selected, just highlighted)
            setActiveIndex(idx);
            if (props.onActiveIndexChange!==undefined && props.onActiveIndexChange!==null) {
                props.onActiveIndexChange(idx);
            }
        },
        [props]
    );
    // EVENT - handleOnFilterValueChange
    const handleOnFilterValueChange = useCallback(
        (filterData) => {
            // TODO: do something
            if (props.onFilterValueChange!==undefined && props.onFilterValueChange!==null) {
                props.onFilterValueChange(filterData);
            }
        },
        [props]
    );
    // EVENT - handleOnSelectionChange
    const handleOnSelectionChange = useCallback(
        ({ selected: RDG_selected, data:RDG_data }) => {

            /*  DOCS 
                from:  https://reactdatagrid.io/docs/api-reference#props-onSelectionChange
                -data - an ARRAY that holds all the selected items.
                -selected - an object with the selected items, keyed using their id properties as specified by idProperty.
                -unselected - [DON'T USE] an object with unselected items, keyed just like the selected property. This is only used when remote dataSource is used, since in this situation there are cases when the<ReactDataGrid /> needs to store unselected items rather than selected ones (eg: user selects all items, and then unselects one or two rows - selected becomes true, while unselected stores the two unselected ids).
            */

            const DEBUG = false;

            if (DEBUG) console.log('----------------------------------------------------------')
            if (DEBUG) console.log('-- start GRID.js--------------------------------------------')
            if (DEBUG) console.log('----------------------------------------------------------')
            if (DEBUG) console.log('GRID SELECT MODE = '+(multiSelect===true ? 'multi-select' : 'single-select'))
            if (DEBUG) console.log('func - selected', RDG_selected)
            if (DEBUG) console.log('func - data', RDG_data)
            if (DEBUG) console.log('--------------')
            
            function extractRowIds(sel) {
                // extract the row id's from the selected object(s)
                var rowIds = [];
                if (isObject(sel)) {
                    var keys = Object.keys(sel).map( item => (item))  // gte array of keys (which are id's in multi-select mode)
                    // traverse keys and convert to integers if they are numbers
                    keys = keys.map( item => (isInteger(item) ? parseInt(item) : item))    
                    rowIds = keys;
                } else if (isNumber(sel) && isInteger(sel)) {
                    rowIds = [parseInt(sel)];
                } else {
                    rowIds = [sel];
                }
                return rowIds
            }

            var dataOUT = RDG_data;         // array of all selected data
            var rowIds = extractRowIds(RDG_selected)

           
            if (DEBUG) console.log('BEFORE: dataOUT', dataOUT)
            if (DEBUG) console.log('BEFORE: rowIds', rowIds)
            if (DEBUG) console.log('--------------')
            

            if (multiSelect===true) {
                // MULTI SELECT MODE - can select one or many
                // single select mode. IN this mode a single selection returns an object. 

                if (Object.keys(RDG_selected).length<=0) {
                    // MULTISELECT - NO SELECTION
                    if (DEBUG) console.log('MULTISELECT - NO SELECTION')
                    dataOUT = null;
                }
                else if (Object.keys(RDG_selected).length===1) {
                    // MULTI SELECT - SINGLE SELECTION
                    if (DEBUG) console.log('MULTISELECT - SINGLE SELECTION')
                    dataOUT = RDG_data[0];
                } else {
                    // MULTI SELECT - MANY SELECTED
                    if (DEBUG) console.log('MULTISELECT - MANY SELECTED')
                    dataOUT = Object.entries(RDG_selected).map((e) => ( e[1] ));
                } 
            }
            else {
                // SINGLE SELECT MODE
                // -In  this mode a single selection from ReactDataGrid returns just an id string or num.  
                // The builtin funcs data returns an object selected, use that. 
                // not an object. So convert to object of data.
                if (DEBUG) console.log('SINGLE - ONE SELECTION')
                dataOUT =  RDG_data;  
            }

            if (DEBUG) console.log('AFTER: dataOUT', dataOUT)
            if (DEBUG) console.log('AFTER: rowIds', rowIds)
            if (DEBUG) console.log('--------------')
           
            setSelected(RDG_selected);          // needs to be set to the grid 'selected' so selections state stick. 
            setSelectedRowIDs(rowIds);      // NEW prop used for APP
            setSelectedData(dataOUT);       // NEW prop used for APP -will be single object for one result, or array for multiple
            
            if (DEBUG) console.log('----------------------------------------------------------')
            if (DEBUG) console.log('-- end GRID.js--------------------------------------------')
            if (DEBUG) console.log('----------------------------------------------------------')

            // run props.onSelectionChange if needed.
            if (props.onGridSelectionChange!==undefined && props.onGridSelectionChange!==null) {
                props.onGridSelectionChange({selected:RDG_selected, selectedRowIDs:rowIds, selectedData:dataOUT });
            }
        },
        [props, multiSelect]
    );
    // EVENT - handleOnGridDataSourceCacheChange     
    const handleOnGridDataSourceCacheChange = () => {
        setGridLoaded(false);

        // runs every time the grid's data changes
       //  console.log('handleOnGridDataSourceCacheChange'); console.log('dataSource.length = ', dataSource?.length);
        if (dataSource?.length===undefined || dataSource?.length==null || dataSource?.length===0) {
           // setLoading(true);
        } else {
            handleOnGridLoaded();
        }

        if (props.onGridDataSourceCacheChange!==undefined && props.onGridDataSourceCacheChange!==null) {
            props.onGridDataSourceCacheChange(dataSource);
        }
    };
    // RENDER EVENT - handleRenderRowContextMenu     
    const handleRenderRowContextMenu = useCallback((menuProps, { rowProps, cellProps }) => {
        if (props.onGridRenderRowContextMenu!==undefined && props.onGridRenderRowContextMenu!==null) {
            props.onGridRenderRowContextMenu(menuProps, { rowProps, cellProps });
        }
    },[props]);




    return (
        <ReactDataGrid
            className={"mdg-grid"}
            // Data & Loading
            loading={loading}
            dataSource={dataSource}
            idProperty={idProperty}
            
            // Style
            style={style}

            // Columns & Groups
            columns={columns}
            enableColumnAutosize={enableColumnAutosize}
            groups={groups}

            // Rows & Row index
            rowHeight={rowHeight}
            
            // Sort & Filter 
            defaultSortInfo={defaultSortInfo}
            enableFiltering={enableFiltering}
            defaultFilterValue={defaultFilterValue}
            
            // Paging  
            pagination={pagination}
            limit={limit}
            livePagination={livePagination}
            scrollThreshold={scrollThreshold}
      
            // Indexing and Selecting
            activeIndex={activeIndex}                  
            enableSelection={enableSelection}
            defaultSelected={defaultSelected}
            selected={selected}                         
            checkboxColumn={checkboxColumn}
            multiSelect={multiSelect}
            
            //  EVENTS 
            onDataSourceCacheChange={handleOnGridDataSourceCacheChange}
            onReady={handleOnGridRefReady}
            onActiveIndexChange={handleOnActiveIndexChange}  
            onFilterValueChange={handleOnFilterValueChange}
            onSelectionChange={handleOnSelectionChange}
            //  onRowDataUpdated={console.log('onRowDataUpdated')}
            //  onUpdateRowHeights={console.log('onUpdateRowHeights')}

            //  RENDER EVENTS
            renderRowContextMenu={handleRenderRowContextMenu}
        />
    );
}

Grid.defaultProps = {
    debug: false,

    // Data & Loading
    loading: true,
    dataSource: [],
    idProperty: 'id', // the id property in the data that makes a unique row id.

    // style
    style: {},    // the wrapper Style

    // columns & groups
    columns: [],
    groups: [],
    enableColumnAutosize: true,

    // rows & row index
    rowHeight: null,      // null = natural rowHeight, or use an Integer for fixed rowHeight.
            
    // sort & filter 
    enableFiltering: false, //- Shows/hides column filter controls.
    defaultSortInfo: {},
    filterValue: [],
    
    // paging  
    pagination: true,
    limit: 50,              //- default number per page
    livePagination: false,  //- pages are loaded as you scroll. Use livePagination=true, and you can use it together with limit to control the page size.
    scrollThreshold: 0.7,   //- number, pixel height or percent. If scrollThreshold is specified, the next page will be loaded when the user reaches the scroll max height minus scrollThreshold of the page

    // Indexing and Selecting
    activeIndex: null,          
    enableSelection: false, // Enables/disables ability of user to select.
    defaultSelected: null,  // Object of row property Id's and boolean value of true. These will default selected. Ex) 24, or '24', or {24:true,25:true}
    selected: null,    
    checkboxColumn: false,
    multiSelect: false,
    
    //  Events 
    onRefReady: (grid) => {},
    onDataSourceCacheChange: (data) => {},
    onActiveIndexChange: (data) => {},
    onFilterValueChange: (filterData) => {},
    onSelectionChange: (selected, selectedRowIDs) => {},
    //  onRowDataUpdated={console.log('onRowDataUpdated')}
    //   onUpdateRowHeights={console.log('onUpdateRowHeights')}
    renderRowContextMenu: (menuProps, { rowProps, cellProps }) => {},

};
Grid.propTypes = {
    // Debugging
    debug: PropTypes.bool,

    // Data & Loading
    loading: PropTypes.bool,
    dataSource: PropTypes.array,
    idProperty: PropTypes.string.isRequired,
   
    // Style
    style: PropTypes.object,

    // Columns & Groups
    columns: PropTypes.array.isRequired,
    groups: PropTypes.array,
    enableColumnAutosize: PropTypes.bool,

    // Rows & Row Index
    rowHeight: PropTypes.number,
            
    // Sort & Filter 
    enableFiltering: PropTypes.bool,
    defaultSortInfo: PropTypes.object,
    filterValue: PropTypes.array,
    
    // Paging
    pagination: PropTypes.bool,
    limit: PropTypes.number,
    livePagination: PropTypes.bool,
    scrollThreshold: PropTypes.any,

    // Indexing and Selecting
    activeIndex: PropTypes.any,        
    enableSelection: PropTypes.bool,
    defaultSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),  
    selected: PropTypes.any,   
    checkboxColumn: PropTypes.bool,
    multiSelect: PropTypes.bool,
    
    //  Events
    onRefReady: PropTypes.func,
    onDataSourceCacheChange: PropTypes.func,
    onActiveIndexChange: PropTypes.func,
    onFilterValueChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onRowDataUpdated: PropTypes.func,
    onUpdateRowHeights: PropTypes.func,

    renderRowContextMenu: PropTypes.func,
};
export default Grid;
