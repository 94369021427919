import React, { useState } from "react";
import PrivatePageTemplate from "_core/components/pages/private/_templates/PrivatePageTemplate";
import { Container, Nav } from "react-bootstrap";
import { PRIVATE_PATHS } from "_core/config";
import IgsdbGlazingProductsTable from "./GlazingProducts/IgsdbGlazingProductsTable";

const IGSDB_Admin_LocalDataManager = () => {

    // TABS state
    const [activeTable, setActiveTable] = useState("glazing");
    return (
        <PrivatePageTemplate
            pageTitle={"IGSDB Local Data Manager"}
            breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
                { caption: "Global Admin", to: PRIVATE_PATHS.GLOBAL.HOME },
                { caption: "IGSDB Admin", to: PRIVATE_PATHS.GLOBAL.IGSDB.HOME },
            ]}
        >
            <Container fluid className="p-0"  style={{maxWidth:"1200px"}}>
                <Nav variant="underline" defaultActiveKey="glazing" className="justify-content-end , ">
                    <Nav.Item>
                        <Nav.Link eventKey="glazing" className="pb-1 mb-2 pt-0 mt-0" onClick={() => setActiveTable("glazing")}>
                            GLAZING DB
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="shading" className="pb-1 mb-2 pt-0 mt-0" onClick={() => setActiveTable("shading")}>
                            SHADING DB
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <div>
                    {activeTable === "glazing" && <IgsdbGlazingProductsTable />}
                    {activeTable === "shading" && <>Shading table here.</>}
                </div>
            </Container>
        </PrivatePageTemplate>
    );
};
export default IGSDB_Admin_LocalDataManager;