// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

import { mergeArraysDeepWithUniqueKey } from "./data-types";

// Route Merging Utility Helpers BELOW:

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


export const routeHasChildren = (route) => (route?.children !== undefined || route?.children !== null || route?.children?.length > 0);

// Create a reusable function to loop an array of route objects recursively based on their children property 
// and perform a callback on each route in the loop.
const loopRoutesRecursively = (routes, callback) => {
    if (routes === undefined || routes === null || routes.length === 0) return;
    routes.forEach((route,idx) => {
        callback(route, idx, routes);
        if (routeHasChildren(route)) {
            loopRoutesRecursively(route.children, callback);
        }
    });
};

// search routes array recursively for a path match, if found , return the object.  (Assuming path is unique in routes and their children)
export const findRouteByPath = (routes, path) => {
    let foundRoute = null;
    loopRoutesRecursively(routes, (route) => { if (route?.path === path) foundRoute = route; });
    return foundRoute;
};

export const markRoutes = (routes, property, value) => {
    loopRoutesRecursively(routes, (route) => {
        route[property] = value;
    });
}
export const mergeRouteArrays = (coreRoutes = [], customRoutes = []) => {
    
    // markRoutes - adds a property to each route object to indicate if it's a core route or custom route.
    markRoutes(coreRoutes, "isCoreRoute", true);
    markRoutes(customRoutes, "isCustomRoute", true);
    
    const mergedRouteArrays = mergeArraysDeepWithUniqueKey(coreRoutes, customRoutes, 'path');
    //console.log("mergeRouteArrays", mergeRouteArrays);
    return mergedRouteArrays;
};
