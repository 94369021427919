


import APP_LOGO_PNG from "_custom/assets/site-logo/Glassref-Logo.png";


// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
export const CUSTOM_CONSTANTS = {
    /*  
        Custom Constants will overwrite CORE constants.
    */
    APP_NAME: "GLASS REF",
    APP_HEADER_LOGO: <img src={APP_LOGO_PNG} alt="GLASS REF" />,
    APP_COPYRIGHT: "\u00A9 2023",

    AUTH_BASE_PATH: "/my", // The route path to use behind all authentication protected areas of the app (password protected)
    PUBLIC_HOME_PATH: "/home", // Default "home" route for unauthenticated access.

    REGISTER_ENABLED: false, // Enable App's User Registration System?

    INACTIVITY_TIMER: false, // Inactivity Timer Enabled?
    INACTIVITY_TIMER_TIMEOUT: 10, // minutes of inactivity before auto logout.   Set
    INACTIVITY_TIMER_PROMPT: true, // will prompt before logout.
};
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
export const CUSTOM_PUBLIC_PATHS = {
   // LEARN_MORE: "/learn-more",",   
}
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
export const CUSTOM_PRIVATE_PATHS = {
    INVENTORY: "/inv",
    IGDB: "/igdb",
    IGDB_BROWSE: "/igdb/browse",
    GLOBAL: {
        IGSDB: {
            HOME: "/glb/igsdb",
            SETTINGS: "/glb/igsdb/settings",
            MANAGER: "/glb/igsdb/mgr",
        },
    },
}
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//



