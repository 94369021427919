import React from "react";
import { Button } from "_core/components/core-library";
import { PUBLIC_PATHS } from "_core/config";
import FadingBackgroundImage from "../../_common/FadingBackgroundImage";
import FloatingGlassCenteredDiv from "../../_common/FloatingGlassCenteredDiv";

const PublicHomeWelcomePanelTwo = () => {
    return (
        <>
            <FadingBackgroundImage />

            <FloatingGlassCenteredDiv>
                <h1 className="display-6 fw-normal text-primary">COMING SOON!</h1>
                <p className="lead fw-bold">A revolutionary app to help you see through the industry.</p>
                <div className="d-grid gap-2 d-md-flex justify-content-center">
                    <Button variant="primary" size="lg" to={PUBLIC_PATHS.REGISTER}>
                        Get Notified When We Launch
                    </Button>
                </div>
            </FloatingGlassCenteredDiv>
        </>
    );
};
export default PublicHomeWelcomePanelTwo;
