import React from "react"

export const DataContainer = ({children, className="", style={}, labelClass=""}) => {
    const childrenWithProps = React.Children.map(children, child => {               //You should always use React.Children.map to traverse children in your app. Use children.map throws an error when children is not an array.
        return React.cloneElement(child, { labelClass: labelClass });
    });
    return <>
        <div className={"data-container row "+className} style={{style}}>
            {childrenWithProps}
        </div>
    </>
}
export const DataDisplayField = ({label="Label?", data=null, labelClass="", labelStyle={}, dataClass="", dataStyle={}}) => {
    return <>
        <div className={"col-sm-2 "+labelClass} style={{labelStyle}}>
            {label}:
        </div>
        <div className={"col-sm-10 "+dataClass} style={{dataStyle}}>
            {data}
        </div>
    </>
}

