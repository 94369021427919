import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { PRIVATE_PATHS } from "_core/config";
import { Button } from '_core/components/core-library';


const ADMIN_Global_Themes = () => {
    const navigate = useNavigate();
	return (
		<PrivatePageTemplate 
			pageTitle="Theme Settings"
			breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
                { caption: "Global Admin", to: PRIVATE_PATHS.GLOBAL.HOME },
            ]}
		>
			<div className="d-flex justify-content-center">
				<Button variant="link" onClick={() => { navigate(PRIVATE_PATHS.GLOBAL.THEMES.COLORS) } }>Color Browser</Button>
				<Button variant="link" onClick={() => { navigate(PRIVATE_PATHS.GLOBAL.THEMES.BOOTSTRAP) } }>Bootstrap Samples</Button>
			</div>

		</PrivatePageTemplate>
	);
}
export default ADMIN_Global_Themes;