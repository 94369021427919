import React, {useEffect} from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth } from '_core/hooks/provider-hooks/useAuth.provider';

function Logout(props) {
    const { logout } = useAuth();

    useEffect(() => {
        logout(); 
    },[logout])

	return (
		<Spinner animation="border" role="status">
            <span className="visually-hidden">SIGNING OUT...</span>
        </Spinner>
	);
}
export default Logout;

