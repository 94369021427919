import { createContext, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast_show, toast_dismissAll } from "_core/redux/slices/ui-redux-slice";
import { ToastContainer, ToastPosition, ToastTypes} from "_core/components/core-library/messaging/Toast";
import { ErrorBoundary  } from "_core/components/core-library";
import { useTheme } from "./useTheme.provider";

import { alert, confirm } from "_core/components/core-library";

export{ToastTypes,ToastPosition} from "_core/components/core-library/messaging/Toast";


const MessagingContext = createContext();

export const MessagingProvider = ({ children }) => {

    const toastContainerId = "messaging";

    const { THEME } = useTheme();

    const dispatch = useDispatch();

    const value = useMemo(
        () => ({

            alert: ( async (msg) => { return alert(msg) }),
            confirm: ( async (message, options) => { return confirm(message, options) }),
            
            toast : {
                types: ToastTypes,
                show:  ( async (msg, type=null, options={}) => { 
                    //- Designate the ToastContainer to show the toast in. Use "messaging" (this providers containerId - see below) , or one passed in (unlikely).
                    options.containerId = (options.containerId!==undefined && options.containerId!==null) ? options.containerId :toastContainerId; 
                    //- Call our Redux function to show the toast through our apps toast service. 
                    return dispatch( toast_show({ msg, type, ...options })
                )}),
                dismissAll: ( async () => { return dispatch( toast_dismissAll() ) }),
            },
        }),
        // Variable Dependencies required for exports above to have values
        [ dispatch ] 
    );

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    return (
        <MessagingContext.Provider value={value}>
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
            <ToastContainer 
                containerId={toastContainerId} 
                position={ToastPosition.TOP_RIGHT} 
                enableMultiContainer={true}  
                newestOnTop={true} 
                theme={THEME?.toast?.theme}
            />  
        </MessagingContext.Provider>
    );
};
export const useMessaging = () => {
    return useContext(MessagingContext);
};
