import React from "react";

import { Container, Navbar } from "react-bootstrap";
import { PRIVATE_PATHS, PUBLIC_PATHS } from "_core/config";
import AppNavbarBrand from "./common/AppNavbarBrand";
//import useNetworkStatus from "hooks/useNetworkStatus";

import { Button } from "_core/components/core-library";
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";
import { useLocation } from "react-router-dom";

function AppHeaderNavbarPublic(props) {
   // const { isOnline } = useNetworkStatus();
    const { THEME} = useTheme();

    const { pathname } = useLocation();
    const isLoginPath = pathname === PUBLIC_PATHS.LOGIN;


    return (
        <>
            <Navbar expand="lg" fixed="top" 
                data-bs-theme={THEME?.navbar?.bsTheme}
                bg={THEME?.navbar?.bg} 
                variant={THEME?.navbar?.variant} 
                className={THEME?.navbar?.className} 
                style={THEME?.navbar?.style}
            >        
                <Container fluid>
                    <div className="d-flex flex-row justify-content-start">
                        <AppNavbarBrand to={PRIVATE_PATHS.HOME} />                        
                    </div>
                    <div className="d-flex flex-row justify-content-end">

                    {(!isLoginPath)
                        &&
                        <Button to="/login" replace={true} variant="outline-secondary" >
                            Login
                        </Button>
                    }
                    </div>
                </Container>
                
            </Navbar>
        </>
    );
}
export default AppHeaderNavbarPublic;
