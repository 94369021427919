import { useDispatch, useSelector } from "react-redux";
import { useErrorHandler } from "_core/hooks/provider-hooks/useErrorHandler.provider";

import { igsdb_test_connection, igsdb_get_settings } from "_custom/redux/slices/igsdb-conn-redux-slice";
import { fetch_local_igsdb_glazing_products, delete_local_igsdb_glazing_products } from "_custom/redux/slices/igsdb-local-data-redux-slice";
import { fetch_remote_igsdb_glazing_products } from "_custom/redux/slices/igsdb-remote-data-redux-slice";

export const useIGSDB = () => {
    const { handleError } = useErrorHandler();
    const dispatch = useDispatch();

    const { glazing_products: local_glazing_products, shading_products: local_shading_products } = useSelector((state) => state.igsdbLocal);
    const { glazing_products: remote_glazing_products, shading_products: remote_shading_products } = useSelector((state) => state.igsdbRemote);
    const { isFetching, isSuccess, isError, errorMessage } = useSelector((state) => state.igsdb);
    const { testData, testIsFetching, testIsSuccess, testLastTS, testIsError, testErrorMsg } = useSelector((state) => state.igsdb);
    const { settings } = useSelector((state) => state.igsdb);

    const testConnection = async () => {
        if (testIsFetching === true) return false;

        //console.log("testConnection");
        try {
            return dispatch(igsdb_test_connection())
                .then((reducedAction) => {
                    const result = reducedAction.payload; // extract data from the returned reduced Action promise Object. The payload property holds the data the reducer used.
                    // console.log('IGSDB connection test result! '+JSON.stringify(result));
                    if (result.error) {
                        // console.log('IGSDB connection test failed! '+JSON.stringify(result.error));
                        return false; // return error string.  This func returns error strong or null.
                    } else {
                        return true; // null = success // successful connection test.  State values were saved.
                    }
                })
                .catch((error) => {
                    handleError(error, true, true); //handle & show our internal error toast.
                    return error;
                });
        } catch (err) {
            console.error(err);
            return err;
        }
    };
    const getSettings = async () => {
        if (settings !== undefined && settings.isFetching !== undefined && settings.isFetching === true) return false;

        console.log("getSettings");
        try {
            return dispatch(igsdb_get_settings())
                .then((reducedAction) => {
                    const result = reducedAction.payload; // extract data from the returned reduced Action promise Object. The payload property holds the data the reducer used.
                    // console.log('IGSDB connection test result! '+JSON.stringify(result));
                    if (result.error) {
                        // console.log('IGSDB connection test failed! '+JSON.stringify(result.error));
                        return false; // return error string.  This func returns error strong or null.
                    } else {
                        return true; // null = success // successful connection test.  State values were saved.
                    }
                })
                .catch((error) => {
                    handleError(error, true, true); //handle & show our internal error toast.
                    return error;
                });
        } catch (err) {
            console.error(err);
            return err;
        }
    };
    // const fetch_local_glazing_products = async () => {
    //     try {
    //         return dispatch( fetch_local_igsdb_glazing_products() )
    //         .then((reducedAction) => {
    //             const result = reducedAction.payload;
    //             if (result.error) return false;
    //             else return true;
    //         })
    //         .catch((error) => {
    //             handleError(error, true, true); //handle & show our internal error toast.
    //             return error;
    //         });
    //     } catch (err) {
    //         console.error(err);
    //         return err;
    //     }
    // }
    //
    
    const fetch_local_glazing_products = async () => dispatch(fetch_local_igsdb_glazing_products());

    const fetch_remote_glazing_products = async () => {
        try {
            return dispatch(fetch_remote_igsdb_glazing_products())
                .then((reducedAction) => {
                    const result = reducedAction.payload;
                    if (result.error) return false;
                    else return true;
                })
                .catch((error) => {
                    handleError(error, true, true); //handle & show our internal error toast.
                    return error;
                });
        } catch (err) {
            console.error(err);
            return err;
        }
    };

    const delete_local_glazing_products = async (productIDs) => {
        try {
            return dispatch(delete_local_igsdb_glazing_products(productIDs))
                .then((reducedAction) => {
                    const result = reducedAction.payload;
                    //console.log("delete_local_glazing_products result: ", result);
                    return result?.success
                })
                .catch((error) => {
                    handleError(error, true, true); 
                    return error;
                });
        } catch (err) {
            console.error(err);
            return err;
        }
    };

    return {
        test_conn: {
            init: testConnection,
            data: testData,
            lastTest: testLastTS,
            isFetching: testIsFetching,
            isSuccess: testIsSuccess,
            isError: testIsError,
            errorMessage: testErrorMsg,
        },

        settings: {
            get: getSettings,
            ...settings?.data,
        },

        local_glazing_products: {
            delete: delete_local_glazing_products,  
            fetch: fetch_local_glazing_products,
            data: local_glazing_products.data,
            isFetching: local_glazing_products.isFetching,
            isError: local_glazing_products.isError,
            isSuccess: local_glazing_products.isSuccess,
            errorMessage: local_glazing_products.errorMessage,
        },
        local_shading_products: {
            //   fetch: fetch_local_shading_products,
            data: local_shading_products.data,
            isFetching: local_shading_products.isFetching,
            isError: local_shading_products.isError,
            isSuccess: local_shading_products.isSuccess,
            errorMessage: local_shading_products.errorMessage,
        },
        remote_glazing_products: {
            fetch: fetch_remote_glazing_products,
            data: remote_glazing_products.data,
            isFetching: remote_glazing_products.isFetching,
            isError: remote_glazing_products.isError,
            isSuccess: remote_glazing_products.isSuccess,
            errorMessage: remote_glazing_products.errorMessage,
        },

        remote_shading_products: {
            //   fetch: async () => fetch_remote_shading_products,
            data: remote_shading_products.data,
            isFetching: remote_shading_products.isFetching,
            isError: remote_shading_products.isError,
            isSuccess: remote_shading_products.isSuccess,
            errorMessage: remote_shading_products.errorMessage,
        },

        isFetching,
        isSuccess,
        isError,
        errorMessage,
    };
};
