import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import NavLink from "./common/navlink";
import { MENU_LEFT_DEFINITION } from "_core/config/Menus.core.config";
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";

const MainMenu = (props) => {

    // ROUTING HOOKS
    const { pathname } = useLocation();

    // STATE HOOKS
    const [show, setShow] = useState(props.show); // for  showing / hiding the menu

    // UI HOOKS  (only for nav click event)
    const { main_menu } = useUI();

    // THEME HOOKS  
    const { THEME } = useTheme();

    // set the components state to the props passed in
    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const handleClose = () => {
        setShow(false);

        if (props.onHide !== undefined) {
            props.onHide();
        }
    };

    const hide_this_menu = () => {
        main_menu.hide();
        console.log("hiding menu");
    };

    return (
        <>
            <Offcanvas className={"main-menu-offcanvas bg-body-tertiary"} placement="start" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton closeVariant={THEME?.offcanvas?.header?.closeVariant} className={"pb-0 mb-0 " + THEME?.offcanvas?.header?.className} style={THEME?.offcanvas?.header?.style}>
                    <Offcanvas.Title>{MENU_LEFT_DEFINITION()?.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={"pt-0 mt-0 " + THEME?.offcanvas?.body?.className} style={THEME?.offcanvas?.body?.style}>
                    <hr className="my-2 p-0"/>
                    <div className="d-grid gap-2">
                        <Nav activeKey={pathname} className={"d-flex flex-column"}>
                            {/* Filter array of allowed route that should only show in left menu, and loop them. 

                                TODO: Add another filter to filter out based on role permissions?

                            */}
                            {MENU_LEFT_DEFINITION()?.items
                                .filter((item) => item !== null)  // Filter out any routes that are NULL (didn't have permission)
                                .map(({ path, title, icon, divider, onclick }, array_key) =>
                                divider && divider === true ? (
                                    <hr key={array_key} className="my-2 p-0" />
                                ) : onclick ? (
                                    <NavLink 
                                        key={array_key} 
                                        icon={icon ? icon : ""} 
                                        className={THEME?.offcanvas?.body?.navlink?.className} 
                                        style={THEME?.offcanvas?.body?.navlink?.style} 
                                        onClick={onclick}
                                    >
                                        {title}
                                    </NavLink>
                                ) : (
                                    <NavLink
                                        key={array_key}
                                        to={path}
                                        icon={icon ? icon : ""}
                                        className={THEME?.offcanvas?.body?.navlink?.className}
                                        style={THEME?.offcanvas?.body?.navlink?.style}
                                        onClick={hide_this_menu}
                                    >
                                        {title}
                                    </NavLink>
                                ))
                            }
                                       
                        </Nav>
                    </div>
                    <hr />
                    <div className="d-grid gap-2">More info here...</div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
MainMenu.propTypes = {
    theme: PropTypes.string,
    show: PropTypes.bool,
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            //shape() takes an object and validates the types inside the object.
            path: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
    className: PropTypes.string,
};

MainMenu.defaultProps = {
    theme: "dark",
    show: false,
    basePath: "",
    className: "",
};
export default MainMenu;
