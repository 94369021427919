import React from "react";
import PublicPageTemplate from "_core/components/pages/public/_templates/PublicPageTemplate";
import { CONSTANTS } from "_core/config";

const PublicHome = () => {
    return (
        <PublicPageTemplate pageTitle={"Welcome to " + CONSTANTS.APP_NAME} breadcrumbs={null} showTitle={false} showBreadcrumbs={false} showLine={false}>
            <p className='text-center'>CORE: Public Home</p>
        </PublicPageTemplate>
    );
};
export default PublicHome;
