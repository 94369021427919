import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { HelmetHtmlTitle } from "_core/components/core-library";     
import PageHeader from "_core/components/layouts/parts/PageHeader";                 
                                                                                       
const PublicPageTemplate = ({pageTitle, breadcrumbs, showTitle, showBreadcrumbs, showLine, children}) => {
                                                    
    return (
        <>
            <HelmetHtmlTitle pageName={pageTitle} />

            <Container fluid>
                <PageHeader
                    title={pageTitle}
                    breadcrumbs={breadcrumbs}

                    showTitle={showTitle}
                    showBreadcrumbs={showBreadcrumbs}
                    showLine={showLine}
                />
                {children}
            </Container>
        </>
    );
};
export default PublicPageTemplate;
PublicPageTemplate.propTypes = {
    pageTitle: PropTypes.string,
    breadcrumbs: PropTypes.array,

    showTitle: PropTypes.bool,
    showBreadcrumbs: PropTypes.bool,
    showLine: PropTypes.bool,
    
};
PublicPageTemplate.defaultProps = {
    pageTitle: "Page Title Here",
    breadcrumbs: [],

    showTitle: true,
    showBreadcrumbs: true,
    showLine: true,
};