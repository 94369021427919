import { useDispatch, useSelector  } from "react-redux";
import { fetch_global_users, fetch_global_companies } from "_core/redux/slices/admin-global-data-slice"
import { useErrorHandler } from "_core/hooks/provider-hooks/useErrorHandler.provider";

export const useAdminGlobal = () => {
    const { handleError } = useErrorHandler();
    const dispatch = useDispatch();

    const { users, companies } = useSelector((state) => state.globalAdmin);
   
    const fetch_users = async () => {
        try {
            return dispatch( fetch_global_users() )
            .then((reducedAction) => {
                const result = reducedAction.payload; 
                if (result.error) return false; 
                else return true; 
            })
            .catch((error) => {
                handleError(error, true, true); //handle & show our internal error toast.
                return error;
            });
        } catch (err) {
            console.error(err);
            return err;
        }
    }

    const fetch_companies = async () => {
        try {
            return dispatch( fetch_global_companies() )
            .then((reducedAction) => {
                const result = reducedAction.payload; 
                if (result.error) return false; 
                else return true; 
            })
            .catch((error) => {
                handleError(error, true, true); //handle & show our internal error toast.
                return error;
            });
        } catch (err) {
            console.error(err);
            return err;
        }
    }
    
    return { 
    
        users : {
            fetch: fetch_users,
            data: users.data,
            isFetching: users.isFetching,
            isError: users.isError,
            isSuccess: users.isSuccess,
            errorMessage: users.errorMessage,
        },
       
        companies : {
            fetch: fetch_companies,
            data: companies.data,
            isFetching: companies.isFetching,
            isError: companies.isError,
            isSuccess: companies.isSuccess,
            errorMessage: companies.errorMessage,
        },
                     
    };
}