import { AUTH_API } from "./_API_bridge"
import { verify_FormData } from "_core/helpers/FormData/FormData-verify";

class AuthService {
    fetchUserByToken = async (form_data, access_token) => {     // can pass in a token either in form_data OR in second parameter.  Either works.                       
        
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "verify/" , form_data, access_token );
            return response;
        } catch (error) {
            return error;
        }
    };
    login = async ( {form_data} ) => {
        try {
            form_data = verify_FormData( form_data );                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "login/" , form_data );
            //console.log(response);
            return response;
        } catch (error) {
            return error;
        }
    };
    logout = async ( {form_data, access_token} ) => {           // can pass in a token either in form_data OR in second parameter.  Either works. 
        try {
            form_data = verify_FormData( {form_data, access_token} );                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "logout/" , form_data, access_token );
            return response;
        } catch (error) {
            return error;
        }
    };
    change_pass = async ( {form_data, access_token} ) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "pass/" , form_data , access_token );  
            return response;
        } catch (error) {
            return error;
        }
    };
    update_current_user = async ( {form_data, access_token} ) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "update/" , form_data , access_token );  
            console.log("Auth Service update_current_user response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_find = async (form_data) => {
        try {
            
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/find/" , form_data ); 
            //console.log("Auth Service ForgotFind response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_send = async (form_data, access_token) => {
        try {
            form_data = verify_FormData(form_data);                                     // Call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/send/" , form_data , access_token );  
            //console.log("Auth Service ForgotSend response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_verify = async (form_data, access_token) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/verify/" , form_data , access_token );  
            //console.log("Auth Service ForgotVerify response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    forgot_pass = async (form_data, access_token) => {
        try {
            form_data = verify_FormData(form_data);                                     // call our helper that converts object data to FormData if needed.
            let response = await AUTH_API( "POST" , "forgot/pass/" , form_data , access_token );  
            //console.log("Auth Service ForgotPassChange response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };
    register({username, email, password}) {
        return AUTH_API.post("signup", {
            username,
            email,
            password,
        });
    }
}
 // eslint-disable-next-line
export default new AuthService();
