

import { CUSTOM_PUBLIC_PATHS, CUSTOM_PRIVATE_PATHS } from "_custom/config/CONSTANTS.config";
import { mergeObjectsDeep } from "_core/helpers/data-types";
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
const CORE_PUBLIC_PATHS = {
    ROOT: "/",
    HOME: "/",
    REGISTER: "/register",
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGOT: "/forgot",
};
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
const CORE_PRIVATE_PATHS = {
    MY: {
        HOME: "/my",
        ACCOUNT: "/my/account",
        SUBSCRIPTION: "/my/subscription",
        PROFILE: "/my/profile",
        PROFILE_UPDATE: "/my/profile/update",
        PASSWORD: "/my/pass",
    },
    ADMIN: {
        HOME: "/admin",
    },
    GLOBAL: {
        HOME: "/glb",
        COMPANY: "/glb/comp",
        THEMES: {
            HOME: "/glb/themes",
            COLORS: "/glb/themes/colors",
            BOOTSTRAP: "/glb/themes/bs",
        },
        USERS: "/glb/users",
    },
};

const CORE_DEVELOPER_PATHS = {
    DEV: {
        HOME: "/dev",
        REACT_HOOK_FORM_TEST: "/dev/rhf",
        TOAST_TEST: "/dev/toast",
        DATA_FIELDS_TEST: "/dev/data-fields",
    }
}
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
// EXPORTS - combine core and custom paths into single objects:
export const PUBLIC_PATHS = mergeObjectsDeep(CORE_PUBLIC_PATHS, CUSTOM_PUBLIC_PATHS);
export const PRIVATE_PATHS = mergeObjectsDeep(mergeObjectsDeep(CORE_PRIVATE_PATHS, CORE_DEVELOPER_PATHS),CUSTOM_PRIVATE_PATHS);
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//    quick paths reference:
export const ROOT_PATH = PUBLIC_PATHS.ROOT;
export const LOGOUT_PATH = PUBLIC_PATHS.LOGOUT;
export const LOGIN_PATH = PUBLIC_PATHS.LOGIN;
export const REGISTER_PATH = PUBLIC_PATHS.REGISTER;
export const FORGOT_PATH = PUBLIC_PATHS.FORGOT;