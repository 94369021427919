/**
 * 
 *		CORE PUBLIC ROUTING ARRAY
		--  An Array of React Router <Routes /> that are the routes 
		for the core system public unprotected pages. 

		LOGOUT must remain an unprotected page/component.
 * 
 * 
 */

import React from 'react';
import { Route } from 'react-router-dom';
import { Home, Login, Logout,
		 Forgot, ForgotSend, ForgotVerify, ForgotNewPass,ForgotSuccess, Register } from '_core/components/pages/public';
import { FORGOT_PATH, LOGIN_PATH, LOGOUT_PATH, REGISTER_PATH } from '_core/config';
import { unique_key as key } from '_core/helpers/guid_generator';


// MUST RETURN AN ARRAY OF ROUTES HERE
const CorePublicRoutes = [
	<Route key={key} index element={<Home />} />,
	<Route key={key} path="/" element={<Home />} />,
	<Route key={key} path={REGISTER_PATH} element={<Register />} />,
	<Route key={key} path={LOGIN_PATH} element={<Login />} />,
	<Route key={key} path={LOGOUT_PATH} element={<Logout />} />,
	<Route key={key} path={FORGOT_PATH} element={<Forgot />} />,
	<Route key={key} path={FORGOT_PATH + "/send"} element={<ForgotSend />} />,
	<Route key={key} path={FORGOT_PATH + "/verify"} element={<ForgotVerify />} />,
	<Route key={key} path={FORGOT_PATH + "/pass"} element={<ForgotNewPass />} />,
	<Route key={key} path={FORGOT_PATH + "/success"} element={<ForgotSuccess />} />,	
]
export default CorePublicRoutes;