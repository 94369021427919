// Get all public routes from the custom App Design  (an array of route components). 
import CustomPublicRoutes from "_custom/routing/CustomPublicRoutes";
import CorePublicRoutes from "./CorePublicRoutes";


/* include array of CORE & CUSTOM App route components 
    - must be array of <Route /> components.  See https://stackoverflow.com/a/62539211 
	- must directly return a n array

	- The order MUST be as follows:  Custom BEFORE Core, so the routes are seen first when overriding Core routes.
*/
const PublicRoutes = [
	...CustomPublicRoutes,
	...CorePublicRoutes	
]
export default PublicRoutes;



// import React from 'react';
// import MapRoutes from '_core/routing/MapRoutes';
// import { getRoutes } from "_core/config/PublicRoutes.core.config";
// import { CONSTANTS } from '_core/config';

// const PublicRoutes = () => {
// 	const routes = getRoutes();
// 	return (		
// 		<MapRoutes routes={routes} basePath={ CONSTANTS.AUTH_BASE_PATH } isAddNotFound />
// 	);
// }
// export default PublicRoutes;