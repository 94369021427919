
// data slices:
import igsdbDataSlice from "./slices/igsdb-conn-redux-slice";
import igsdbLocalDataSlice from "./slices/igsdb-local-data-redux-slice";
import igsdbRemoteDataSlice from "./slices/igsdb-remote-data-redux-slice";
import igdbDataSlice from "./slices/igdb-data-redux-slice";

import glazingNominalThicknessesDataSlice from "./slices/glazing-nominal-thicknesses-data-redux-slice";
import glazingDataSlice from "./slices/glazing-data-redux-slice";


// Reducers for the Store.  They will persist in the store by default.  If you don't want them to persist, add them to the blacklist below.
export const custom_reducers_to_persist = {
    igsdb: igsdbDataSlice.reducer,              //- see useIGSDB() for access
    igsdbLocal: igsdbLocalDataSlice.reducer,    //- see useIGSDB() for access
    igsdbRemote: igsdbRemoteDataSlice.reducer,  //- see useIGSDB() for access
    
    igdb: igdbDataSlice.reducer,
    glazing_nominal_thicknesses: glazingNominalThicknessesDataSlice.reducer,
    glazing: glazingDataSlice.reducer,
}

// List Reducers you DON'T want to persist in the store. 
export const custom_persist_blacklist = [
    "igsdbLocal",
    "igsdbRemote"
]