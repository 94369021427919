import React from 'react';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { PRIVATE_PATHS } from "_core/config";
import GlobalUsersTable from './parts/GlobalUsersTable';
import { Button } from '_core/components/core-library';


const ADMIN_Global_Users = () => {
    
	return (
		<PrivatePageTemplate 
			pageTitle="Users Setup"
			breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
                { caption: "Global Admin", to: PRIVATE_PATHS.GLOBAL.HOME },
            ]}
		>
			<div className="text-end">
				<Button variant="link" to={PRIVATE_PATHS.GLOBAL.COMPANY}>Go to Companies</Button>
			</div>
			<GlobalUsersTable />

		</PrivatePageTemplate>
	);
}
export default ADMIN_Global_Users;