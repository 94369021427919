
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'];

export const data= () => {
    const data = [];
    for (let i = 0; i < 12; i++) {
        const item = {
            name: months[i],
            uv: Math.floor(Math.random() * 1000),
            pv: Math.floor(Math.random() * 1000),
            //  amt: Math.floor(Math.random() * 1000),
        };
        data.push(item);
    }
    return data;
};
