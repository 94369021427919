import React from "react";
const FloatingGlassCenteredDiv = ({ children, glassOpacity=0.5, rgb=[255,255,255] }) => {
    const glassMorphismStyle = {
        background: "rgba("+rgb.join(',')+", "+glassOpacity+")",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)",
        backdropFilter: "blur(5px)",
        WebkitBackdropFilter: "blur(5px)",
    }
    return (
        <>
            <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center rounded-2 ">
                <div className={"col-md-8 p-5 mx-auto my-5 rounded-4"} style={glassMorphismStyle}>
                    {children}
                </div>
            </div>
        </>
    );
};
export default FloatingGlassCenteredDiv;
