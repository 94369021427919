// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
export const CUSTOM_PUBLIC_PATHS = {
    HOME: "/",      // override CORE HOME Module Path.
   // LEARN_MORE: "/learn-more",",   
}
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//
export const CUSTOM_PRIVATE_PATHS = {
    MY: {
        HOME: "/my",
    },
    INVENTORY: "/inv",
    IGDB: "/igdb",
    IGDB_BROWSE: "/igdb/browse",
    GLOBAL: {
        IGSDB: {
            HOME: "/glb/igsdb",
            SETTINGS: "/glb/igsdb/settings",
            MANAGER: "/glb/igsdb/mgr",
        },
    },
}
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
//