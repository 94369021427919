import React from "react";
import PropTypes from "prop-types";





export const RadioGroup = (props) => {
    /* func gets the first available option. used for default value if noone specified */
    const getInitialCheckedIndex = (children) => { let checkedIndex; for (let i = 0; i < children.length; i++) { if (!children[i].props.disabled) { checkedIndex = i; break;}} return checkedIndex;  }
    
    const getCheckedIndex = () => {
        const index = props.children.findIndex((c) => c.props.value === props.value);
        let checkedIndex;
        
        if (props.value === undefined)
            // This is the case where it is not specified
            checkedIndex = -1;
        else {
            if (index > -1 && !props.children[index].props.disabled)
                checkedIndex = index;
            else checkedIndex = getInitialCheckedIndex(props.children);
        }
        //console.log('checkedIndex '+checkedIndex)
        return checkedIndex
    }
    let idx = getCheckedIndex();
    const [checkedIndex, setCheckedIndex] = React.useState(idx)

   
    React.useEffect(() => {
        const index = props.children.findIndex((c) => c.props.value === props.value);
        let checkedIndex;
        
        if (props.value === undefined)
            // This is the case where it is not specified
            checkedIndex = -1;
        else {
            if (index > -1 && !props.children[index].props.disabled)
                checkedIndex = index;
            else checkedIndex = getInitialCheckedIndex(props.children);
        }
        setCheckedIndex(checkedIndex)
    }, [props.children, props.value]);
    


    const onChange_handler = (index) => {
        const { onChange, children } = props;
        const child = children[index];
        if (!child) return;

        setCheckedIndex(index)
       
        onChange && onChange(child.props.value || "");
    }

    const renderChild = (child, index, checked) => {
        const { children, horizontal } = props;
       // console.log('child rendered checked=='+checked.toString())
        return React.cloneElement(child, {
            horizontal,
            index,
            checked,
            key: index,
            last: index === children.length - 1,
            onChange: onChange_handler,
            ...child.props,
        });
    }

  
    
    const { horizontal, children } = props;
    const style = horizontal
        ? { display: "inline-flex", width: "100%" }
        : {};
    return (
        <div style={style}>
            {/*console.log('checkedIndex at render = ' + checkedIndex)*/}
            {children.map((c, i) =>
                renderChild(c, i, i === checkedIndex)
            )}
        </div>
    );
    
}
RadioGroup.propTypes = {
    horizontal: PropTypes.bool,
    children: PropTypes.node,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

/************************************************************************************************************************************************************* */
/************************************************************************************************************************************************************* */


export const RadioButton = (props) => {
    const getStyles = () => {
        const {
            horizontal,
            last,
            padding,
            rootColor,
            pointColor,
            disabled,
            disabledColor,
            label,
        } = props;

        return {
            root: {
                cursor: disabled ? "not-allowed" : "pointer",
                color: disabled
                    ? disabledColor || "#e1e1e1"
                    : rootColor || "#E0E0E0",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: disabled
                    ? disabledColor || "#e1e1e1"
                    : rootColor || "#E0E0E0",
                borderRadius: 1,
                padding: padding || 16,
                flex: 1,
                marginBottom: horizontal
                    ? 0
                    : label
                    ? (padding || 16) / 2
                    : padding || 16,
                marginRight: horizontal && !last ? (padding || 16) / 2 : 0,
            },
            label: {
                color: pointColor || "#8CB9FD",
                borderStyle: "none",
                padding: padding || 8,
                marginBottom: horizontal ? 0 : padding || 8,
                marginRight: horizontal && !last ? (padding || 8) / 2 : 0,
            },
            checked: {
                borderColor: pointColor || "#8CB9FD",
                color: pointColor || "#8CB9FD",
                fontWeight: 'bold',
            },
        };
    }

    const onClick_handler = (e) => {
        const { onChange, index, disabled } = props;
        !disabled && onChange && onChange(index);
    }

   
    const { checked, iconSize, iconInnerSize, rootColor, pointColor, children, disabled, disabledColor, label } = props;

    const style = getStyles();
    const buttonStyle = Object.assign( {}, style.root, checked ? style.checked : {});
    const labelStyle = Object.assign({}, style.root, style.label);

    return (
        <div style={buttonStyle} onClick={onClick_handler}>
            <div style={{ display: "inline-flex", width: "100%" }}>
                <div style={{ flex: 1 }}>{children}</div>
                <RadioIcon
                    size={iconSize}
                    innerSize={iconInnerSize}
                    checked={checked}
                    rootColor={rootColor}
                    pointColor={pointColor}
                    disabled={disabled}
                    disabledColor={disabledColor}
                />
            </div>
            {label ? (
                <div style={labelStyle}>
                    <div>{label}</div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
    
}
RadioButton.propTypes = {
    iconSize: PropTypes.number,
    iconInnerSize: PropTypes.number,
    padding: PropTypes.number,
    rootColor: PropTypes.string,
    pointColor: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    checked: PropTypes.bool,
    children: PropTypes.node,
    horizontal: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    disabledColor: PropTypes.string,
    label: PropTypes.string,
};


/************************************************************************************************************************************************************* */
/************************************************************************************************************************************************************* */



export const ReversedRadioButton = (props) => {
    const getStyles = () => {
        const {
            horizontal,
            last,
            padding,
            rootColor,
            pointColor,
            disabled,
            disabledColor,
            label,                                          /* eslint-disable-line */
        } = props;

        return {
            root: {
                cursor: disabled ? "not-allowed" : "pointer",
                color: disabled
                    ? disabledColor || "#e1e1e1"
                    : rootColor || "#B1B1B1",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: disabled
                    ? disabledColor || "#e1e1e1"
                    : rootColor || "#B1B1B1",
                borderRadius: 8,
                padding: padding || 16,
                flex: 1,
                marginBottom: horizontal ? 0 : padding || 8,
                marginRight: horizontal && !last ? (padding || 16) / 2 : 0,
            },
            label: {
                color: pointColor || "#8CB9FD",
                borderStyle: "none",
                padding: padding || 8,
                marginBottom: horizontal ? 0 : padding || 8,
                marginRight: horizontal && !last ? (padding || 8) / 2 : 0,
            },
            checked: {
                borderColor: pointColor || "#336699",
                color: pointColor || "#336699",
                fontWeight: 'bold',
            },
        };
    }

    const onClick_handler = () => {
        const { onChange, checked, index, disabled } = props;                   /* eslint-disable-line */
        !disabled && onChange && onChange(index);
    }

   
    const {
        checked,
        iconSize,
        iconInnerSize,
        rootColor,
        pointColor,
        children,
        disabled,
        disabledColor,
        padding,
        label,
    } = props;
    const style = getStyles();
    const buttonStyle = Object.assign(
        {},
        style.root,
        checked ? style.checked : {}
    );
    const labelStyle = Object.assign({}, style.root, style.label);

    return (
        <div style={buttonStyle} onClick={onClick_handler}>
            <div style={{ display: "inline-flex", width: "100%" }}>
                <RadioIcon
                    size={iconSize}
                    innerSize={iconInnerSize}
                    checked={checked}
                    rootColor={rootColor}
                    pointColor={pointColor}
                    disabled={disabled}
                    disabledColor={disabledColor}
                    marginRight={padding || 16}
                />
                <div style={{ flex: 1 }}>{children}</div>
            </div>
            {label ? ( 
                        <div style={labelStyle}>
                            <div>{label}</div>
                        </div>
                     ) : ( "")}
        </div>
    );
    
}
ReversedRadioButton.propTypes = {
    iconSize: PropTypes.number,
    iconInnerSize: PropTypes.number,
    padding: PropTypes.number,
    rootColor: PropTypes.string,
    pointColor: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    checked: PropTypes.bool,
    children: PropTypes.node,
    horizontal: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    disabledColor: PropTypes.bool,
    label: PropTypes.string,
};


/************************************************************************************************************************************************************* */
/************************************************************************************************************************************************************* */


export const RadioIcon = ( props ) => {
    const getStyles = () => {
        const {
            size,
            innerSize,
            rootColor,
            pointColor,
            disabled,
            disabledColor,
            marginRight,
        } = props;

        return {
            root: {
                width: size || 10,
                height: size || 10,
                padding: 3,
                backgroundColor: "#FFF",
                borderWidth: 2,
                borderRadius: "50%",
                borderStyle: "solid",
                borderColor: disabled
                    ? disabledColor || "#e1e1e1"
                    : rootColor || "#B1B1B1",
                marginRight: marginRight || 0,
            },
            checked: {
                borderColor: pointColor || "#336699",
            },
            inner: {
                width: innerSize || 10,
                height: innerSize || 10,
                borderRadius: "50%",
                background: pointColor || "#336699",
            },
        };
    }

    
    const { checked } = props;
    const style = getStyles();
    const iconStyle = Object.assign(
        style.root,
        checked ? style.checked : {}
    );

    return (
        <div style={iconStyle}>
            {checked && <div style={style.inner} />}
        </div>
    );
}
RadioIcon.propTypes = {
    size: PropTypes.number,
    innerSize: PropTypes.number,
    rootColor: PropTypes.string,
    pointColor: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledColor: PropTypes.string,
    marginRight: PropTypes.number,
};


/************************************************************************************************************************************************************* */
/************************************************************************************************************************************************************* */