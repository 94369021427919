import React from "react";
import PropTypes from "prop-types";

import AuthCode from "./_my_modules/my-OTP-Input";
import { Controller } from "react-hook-form";

import { set_input_value_by_id } from "_core/helpers/native_input";

const OneTimePasscodeInput = (props) => {

  //  const [otpValue, setOtpValue] = useState("");

    const handleOnKeyUp = (res) => {
        // Store STate of value for the hell of it -dont really need though.
        // set value to state which transfers value to the hidden input, which triggers onchange for input
  //      setOtpValue(res);

        // Call our Custom Native Input setter fucton which sets the value and triggers the onchnage event, for the react-hook-form onchgnage gets called.  
        // That way form validation with react-hook-form works nromally.. etc..
        set_input_value_by_id(props.name, res); // call custom
        
    };
    
    const handleHiddenInputOnChange = (reactHookFormFieldObject,e) => {
        console.log('onchnage snagged');
        
        reactHookFormFieldObject.onChange(e);   // must pass this back to hook form object. 

        // call any onchange props passed in. 
        if (props.onChange!==undefined) props.onChange(e);
    }
    

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <AuthCode
                length={props.length}
                allowedCharacters="numeric"
                onKeyUp={handleOnKeyUp}
                disabled={props.disabled}
                containerClassName="app-opt-input-wrapper"
                inputClassName="app-opt-inputs form-control form-control-lg "
            />
        
            <Controller
                name={ props.name }
                control={ props.control }
                defaultValue=""
                rules={ props.rules }
                render={({ field }) => (
                    <input readOnly={true}   type="text"  style={{height:"0px", visibility:"hidden"}} //type="hidden" (cant use hidden or onchnage wont fire)
                        id={props.name} 
                        {...field} 
                        onChange={(e) => handleHiddenInputOnChange(field, e)}
                    />
                )}
            />
        </div>
    );
};
OneTimePasscodeInput.propTypes = {
    name: PropTypes.string.isRequired,
    control:
        PropTypes.object
            .isRequired /* Pass in the react-hook-form controller to this. */,

    length: PropTypes.number, // length of OTP

    rules: PropTypes.object,
    
    maxLength: PropTypes.number,  //  The maximum length of the value to accept for this input.
    minLength: PropTypes.number,  //  The minimum length of the value to accept for this input.
    pattern: PropTypes.any,        //  The regex pattern for the input.    pattern: /[A-Za-z]{3}/
    
    validate: PropTypes.func,
};
OneTimePasscodeInput.defaultProps = {
    name: "client_otp",
    length: 6,
    maxLength: 6,
    minLength: 6,
    pattern: /([0-9]{6},)*[0-9]{6}/,
    
    rules:{
        required: true,
        validate: (val) => {
            //console.log('validated ran')
            return val.length===6;
            
        },
    }
};
export default OneTimePasscodeInput;
