import { SECURE_API } from "./_API_bridge";
import { verify_FormData } from "_core/helpers/FormData/FormData-verify";

class GlobalAdminDataService {  
    get_global_users = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     
            let response = await SECURE_API( "POST" , "_core/global/user/read/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
    get_global_companies = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters);                                     
            let response = await SECURE_API( "POST" , "_core/global/company/read/" , search_filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };

   
}
const newIGSDBDataService  = new GlobalAdminDataService();
export default newIGSDBDataService;