import React from "react"
import { Table } from "react-bootstrap"

export const DataDisplayTable = ({children, title, size="sm", striped=true, bordered=true, hover=true, className="", labelClass=""}) => {
    const childrenWithProps = React.Children.map(children, child => {               //You should always use React.Children.map to traverse children in your app. Use children.map throws an error when children is not an array.
        return React.cloneElement(child, { labelClass: labelClass });
    });
    return (
        <Table className={className} size={size} striped={striped} bordered={bordered} hover={hover}>
            <thead><tr><th colSpan={2}>{title}</th></tr></thead>
            <tbody>{childrenWithProps}</tbody>
        </Table>
    );
}
export const DataRow = ({label="Label?", data=null, labelClass="", labelStyle={}, dataClass="", dataStyle={}}) => {
    return (
        <tr>
            <td className={labelClass} style={labelStyle}>{label}</td>
            <td className={dataClass} style={dataStyle}>{data}</td>
        </tr>
    );
}

