import { createContext, useContext, useMemo, useState } from "react";
import { useModal, Modal, Icon, ErrorBoundary } from "_core/components/core-library";
import { Container } from "react-bootstrap";
import { ToastContainer, showToast, ToastTypes, ToastPosition } from "_core/components/core-library/messaging/Toast";
import { useTheme } from "./useTheme.provider";

const ErrorHandlerContext = createContext();
export const ErrorHandlerProvider = ({ children }) => {

    const { THEME } = useTheme();

    const toastContainerId = "errors";
   
    // MODAL HOOKS
    const { modalVisible, showModal, hideModal } = useModal(false);

    // STATE
    const [errorTitle, setErrorTitle] = useState("ERROR");
    const [errorMessage, setErrorMessage] = useState(null);

    const handleError = (error) => handleInternalError;
    const handleInternalError = (error, show = true) => {
        let msg = deriveMessageFromError(error);
        console.error(msg);
        if (show === true) showErrorToast(msg, true);
    };
    const showError = (error, title = null) => showErrorModal(error, title);
    const showErrorModal = (error, title = null) => {
        let msg = deriveMessageFromError(error);
        if (title !== undefined && title !== null) setErrorTitle(title);
        setErrorMessage(msg);
        showModal();
    };
    const showErrorToast = (error, internalFlag = false) => {
        let msg = deriveMessageFromError(error);
        setErrorMessage(msg);

        if (internalFlag === true) msg += "INTERNAL ERROR!  :: ";
        msg += deriveMessageFromError(error);

        showToast(msg, {
            containerId :toastContainerId,
            type: ToastTypes.error,
            autoClose: false,
        });
    };
    const deriveMessageFromError = (err) => {
        /**
         *  Function takes in error object or string and returns a message
         *
         */
        let message = "";

        if (typeof err === "object" && err !== null && "message" in err) {
            message = err.message;
        } else if (typeof err === "object" && err !== null && "toString" in err) {
            message = err.toString();
        } else if (typeof err === "string" && err !== null) {
            message = err;
        } else if (Array.isArray(err)) {
            message = err.join("<br />");
        }

        return message;
    };

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */

    const value = useMemo(
        () => ({
            handleError,
            handleInternalError,
            showError,
            showErrorModal,
            showErrorToast,
            deriveMessageFromError,
        }),
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <ErrorHandlerContext.Provider value={value}>
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
            <div>
                <Modal size={"md"} 
                    title={errorTitle} 
                    visible={modalVisible} 
                    closeButton={true} 
                    handleClose={() => hideModal()} 
                    showConfirm={false} 
                    confirmButtonText={""} 
                    handleConfirm={() => hideModal()}
                >  
                    <Container className="p-0 m-0">
                        <Icon name="exclamationTriangle" size="1x" className="text-danger pe-3" />
                        {errorMessage}
                    </Container>
                </Modal>

                <ToastContainer 
                    containerId={toastContainerId} 
                    position={ToastPosition.BOTTOM_CENTER} 
                    enableMultiContainer={true}  
                    newestOnTop={true} 
                    theme={THEME?.toast?.theme}
                />
            </div>
        </ErrorHandlerContext.Provider>
    );
};
export const useErrorHandler = () => {
    return useContext(ErrorHandlerContext);
};
