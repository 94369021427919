import React from "react";
import { Button, Icon } from "_core/components/core-library";

import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";



function UserProfileButton(props) {
    const { isLoggedIn, current_user } = useAuth();
    return ( 
        <Button variant="outline" className="ms-2" disabled={(!isLoggedIn)}
            onClick={() => {
                if (props?.onClick) props.onClick();
            }}
        >
            <Icon name="circleUser" className="me-1" size="1x" />
            {current_user?.firstname}
        </Button>
    );
}
export default UserProfileButton;