import { useOutlet,useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { AppHeaderNavbarPublic, AppFooterNavbarPublic } from "_core/components/layouts";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";
import { Helmet } from "react-helmet-async";

//import { ROUTES } from "_core/config";

export const PublicLayout = () => {
    const { current_user } = useAuth();
    const outlet = useOutlet();

     // ROUTING HOOKS       
     // eslint-disable-next-line                                                            
     const navigate = useNavigate(); // Get navigate func from router hook.

    // THEME HOOKS
    const { THEME } = useTheme(); 

    if (current_user) {
      //  navigate(ROUTES.MY.HOME);
    }

    return (
        // <>
        //     <AppHeaderNavbarPublic />
        //     <Container fluid>{outlet}</Container>
        //     <AppFooterNavbarPublic />
        // </>
        <>
            <Helmet>
                <meta name="theme-color" content={THEME?.meta?.themeColor}/>
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
            </Helmet>
       
            <div className={'theme-wrapper ' + THEME?.wrapper?.className}>
                <header>
                    <AppHeaderNavbarPublic />
                </header>
                <section>
                    <Container fluid>{outlet}</Container>
                </section>
                <footer>
                    <AppFooterNavbarPublic />
                </footer>
            </div>
        </>
    );
};
export default PublicLayout;
