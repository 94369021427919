import { SECURE_API } from "_core/services/_API_bridge";
import { verify_FormData } from "_core/helpers/FormData/FormData-verify";

class GlazingDataService {  

    get_nominal_thicknesses_lookup = async (search_filters, access_token) => {
        try {
            search_filters = verify_FormData(search_filters); // call our helper that converts object data to FormData if needed.
            let response = await SECURE_API( "POST" , "glazing/nominal_thicknesses/read/" , search_filters, access_token ); 
           // console.log("Glazing Nominal Thicknesses Lookup Data Service get_nominal_thicknesses_lookup response:", response);
            return response;
        } catch (error) {
            return error;
        }
    };

    
    get_products = async (search_filters, access_token) => {
        try {
            let search_filters = [
                {name:"id", predicate:"=", value:"2942" },
                {name:"name", predicate:"LIKE", value:"Hardy" }
            ];

            let form_data = new FormData();
            form_data.append('column_filters',[...search_filters]);

        
            let filters = verify_FormData(form_data); // call our helper that converts object data to FormData if needed.
            let response = await SECURE_API( "POST" , "glazing/products/read/" , filters, access_token ); 
            return response;
        } catch (error) {
            return error;
        }
    };
}
const newGlazingDataService  = new GlazingDataService();
export default newGlazingDataService;