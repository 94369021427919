import React from 'react';
import PublicPageTemplate from "./_templates/PublicPageTemplate";

import { CONSTANTS } from '_core/config';

function Register(props) {
	return (
		<PublicPageTemplate 
			pageTitle={"Register a New Account for " + CONSTANTS.APP_NAME}
			breadcrumbs={[
				{ caption: "Home", to: "/" },
			]}
		>
			<p className='text-center'>CORE: Public Register</p>
		</PublicPageTemplate>
	);
}
export default Register;


