import React from 'react';
import { useSelector } from 'react-redux';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { Button } from '_core/components/core-library';

//import { IGDBProductsTable } from 'components/app-library'

//import { useMessaging } from 'hooks/core-hooks/useMessaging';

import igdbDataService from '_custom/services/igdb-data.service';

const IGDB_Home = () => {
	//const { user: current_user } = useSelector((state) => state.auth);
	//if (!current_user) {return <Navigate to={ROUTES.LOGIN} />}
	const { access_token } = useSelector((state) => state.auth);

	//const { toast } = useMessaging();

	return (
		<PrivatePageTemplate 
			pageTitle="IGDB Maintenance"
			breadcrumbs={[
				{ caption: "My Home", to: "/my" },
			]}
		>
			{/* <Button onClick={() => {toast.show('test',toast.types.info)}} >Test Toast</Button> */}

			<div className="d-flex justify-content-center">
				<div className="text-center my-3 mx-2">
					<Button to="/igdb/browse" variant="primary">
						BROWSE
					</Button>
				</div>

				<div className="text-center my-3 mx-2">
					<Button onClick={async () => {
						const response = await igdbDataService.synchronize_igsdb( null, access_token );
						console.log('RESPONSE RECEIVED:',response);

					}}>SYNC with IGSDB</Button>
				</div>	
			</div>

			{/* <IGDBProductsTable /> */}
				
		</PrivatePageTemplate>
	);
}
export default IGDB_Home;