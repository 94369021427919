import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Container, Offcanvas, Row } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { Button, Icon } from "_core/components/core-library";
import { unique_key } from "_core/helpers/guid_generator";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import NavLink from "./common/navlink";
import { CORE_MENU_RIGHT_DEFINITION } from "_core/config/Menus.core.config";
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";
import { capitalize } from "_core/helpers/data-types/strings";

const UserMenu = (props) => {
    const { pathname } = useLocation();

    // AUTH HOOKS
    const { logout, current_user } = useAuth();

    // UI HOOKS
    const { user_menu } = useUI();
    const { theme, THEME, THEMES } = useTheme();

    // STATE HOOKS
    const [show, setShow] = useState(props.show); // for  showing or hiding the menu

    // set the components state to the props passed in
    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const handleClose = () => {
        setShow(false);

        if (props.onHide !== undefined) {
            props.onHide();
        }
    };
    const handle_logout_click = (goto_url) => {
        user_menu.hide(); // close this menu by using our UI controller hook
        logout();
    };
    const hide_this_menu = () => {
        user_menu.hide();
        console.log("hiding menu");
    };

    return (
        <>
            <Offcanvas className={"user-menu-offcanvas bg-body-tertiary"} placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Header
                    closeButton
                    className="pb-0 mb-0 "
                >
                    <Offcanvas.Title>MY ACCOUNT MENU</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex flex-column pt-0 mt-0">
                    <hr className="my-2 p-0" />
                    <div className="d-grid gap-0">
                        <div className="small text-center fst-italic m-0 p-0">{current_user?.displayname}</div>
                        <hr className="mb-1 mt-2" />

                        <Nav activeKey={pathname} className={"d-flex flex-column"}>
                            {/* Filter array of allowed route that should only show in left menu, and loop them. */}
                            {CORE_MENU_RIGHT_DEFINITION?.items
                            .filter((item) => item !== null)  // Filter out any routes that are NULL (didn't have permission)
                            .map(({ path, title, icon }) => (
                                <NavLink key={unique_key()} to={path} icon={icon ? icon : ""} className={THEME?.offcanvas?.body?.navlink?.className} style={THEME?.offcanvas?.body?.navlink?.style} onClick={hide_this_menu}>
                                    {title}
                                </NavLink>
                            ))}
                                
                        </Nav>

                        <hr className="my-2" />

                        <Container>
                            <Row>
                                UI THEME:
                                <Nav className={"d-flex flex-column"}>
                                    {Object.entries(THEMES).map(([key, oTheme], index) => {
                                        return (
                                            <NavLink active={(theme.name===oTheme.name)} key={index} to={null} icon="circle" iconColor={oTheme?.def?.colors?.primary} onClick={() => theme.set(oTheme?.name)}>
                                                {capitalize(oTheme.name)}
                                                {(theme.name===oTheme.name) && <Icon name="check" className="ms-2" /> }
                                            </NavLink>
                                        );
                                    })}

                                    {/* <NavLink to={null} icon="circle" iconColor="black" onClick={() => theme.set('default')}>Default</NavLink>
                                    <NavLink to={null} icon="circle" iconColor="black" onClick={() => theme.set('yellow')}>Dark</NavLink>
                                    <NavLink to={null} icon="circle" iconColor="gray" onClick={() => theme.set('light')}>Light</NavLink>
                                    <NavLink to={null} icon="circle" iconColor="yellow" onClick={() => theme.set('yellow')}>Yellow</NavLink> */}
                                </Nav>
                            </Row>
                        </Container>
                    </div>

                    <hr className="my-2" />

                    <Button className="mt-auto mb-0" variant="secondary" onClick={handle_logout_click}>
                        Sign Out
                    </Button>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
UserMenu.propTypes = {
    theme: PropTypes.string,
    show: PropTypes.bool,
    className: PropTypes.string,
};
UserMenu.defaultProps = {
    theme: "dark",
    show: false,
    className: "",
};
export default UserMenu;
