
export const isNumber = (value) => {
    const num = parseFloat(value);
    
    return !Number.isNaN(num);
};

export const isInteger = (value) => {
    if (!isNumber(value)) return false;
    
    // makes sure its a number format. 
    const num = parseFloat(value)

    return Number.isInteger(num)
};