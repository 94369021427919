import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/auth-redux-slice"; // Authentication redux slice of actions, and reducers for auth.
import messageSlice from "./slices/message-redux-slice";
import themeSlice from "./slices/theme-redux-slice";
import uiSlice from "./slices/ui-redux-slice";
import networkSlice from "./slices/network-redux-slice";
import globalAdminDataSlice from "./slices/admin-global-data-slice";

// Needed for redux-persist integration.   See :  https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import { custom_persist_blacklist, custom_reducers_to_persist} from "_custom/redux/store.custom";

const persistConfig = {
    key: "root",
    timeout: 2000, //Set the timeout function to 2 seconds
    storage,
    blacklist: [  // List Store Nodes to ignore persisting 
        "theme",
        "ui",       
        "network",
        "globalAdmin",  // Don't persist Admin!
        
        ...custom_persist_blacklist,    /// Bring in from CUSTOM app
    ],
};

const reducers_to_persist = combineReducers({
    auth: authSlice.reducer,
    message: messageSlice.reducer,
    theme: themeSlice.reducer, // TODO:Do we want this to persist?
    ui: uiSlice.reducer, // TODO:Do we want this to persist?
    network: networkSlice.reducer,
    globalAdmin: globalAdminDataSlice.reducer, //

    ...custom_reducers_to_persist,    /// Bring in from CUSTOM app
});

const persistedReducer = persistReducer(persistConfig, reducers_to_persist);

const store = configureStore({
    reducer: persistedReducer,

    // See :  https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false, // TODO : <-- Added this because I ws getting warning state is large, but it wasn't. See https://stackoverflow.com/questions/65217815/redux-handling-really-large-state-object
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),

    // do not forget this
    devTools: process.env.NODE_ENV !== "production",
});

export default store; // old way,