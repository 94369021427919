import React from "react";
import PrivatePageTemplate from "_core/components/pages/private/_templates/PrivatePageTemplate";

const MyHome = () => {
    return (
        <PrivatePageTemplate pageTitle="My Home Dashboard" showTitle={false} className={"p-0 m-0"}>
            <p>Core: My Home</p>
        </PrivatePageTemplate>
    );
};
export default MyHome;
