import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Icon from "_core/components/core-library/icons/Icon";
import { Button, Container, FloatingLabel, Form, InputGroup } from "react-bootstrap";

function GridSearchInput({searchTerm, autoFocus, onChange, onClear, disabled}) {

    const [isDisabled, setIsDisabled] = useState(disabled);     // State Var for showing loading progress in THIS component,
    useEffect(() => {
        setIsDisabled(disabled)
    }, [disabled]);

    return (
        <Container fluid className="my-0 p-1" style={{ borderBottom: 0 }}>
            <InputGroup>
                <InputGroup.Text>
                    <Icon name="search" />
                </InputGroup.Text>
                <FloatingLabel label="SEARCH FOR:">
                    <Form.Control
                        disabled={isDisabled}
                        autoFocus={autoFocus}
                        size="lg"
                        type="text"
                        // style={{ padding: 5 }}
                        placeholder="Enter search term..."
                        value={searchTerm === null ? "" : searchTerm}
                        onChange={onChange}
                    />
                </FloatingLabel>
                {searchTerm !== null && searchTerm !== "" && (
                    <Button 
                        disabled={isDisabled}
                        variant="secondary" className="button m-0" onClick={onClear}>
                        <Icon name="x" />
                    </Button>
                )}
            </InputGroup>
        </Container>
    );
}
GridSearchInput.defaultProps = {
    disabled: false,
	searchTerm: '',
	autoFocus: false,
	onChange: (e) => {},
	onClear: () => {},
}
GridSearchInput.propTypes = {
    disabled: PropTypes.bool,
	searchTerm: PropTypes.string,
	autoFocus: PropTypes.bool,
	onChange: PropTypes.func,
	onClear: PropTypes.func,
}

export default GridSearchInput;
