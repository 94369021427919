

import { DEV_Home, DEV_ToastTest } from "_core/components/pages/private";
import { DEV_ReactHookFormTest } from "_core/components/pages/private";
import { DEV_DataFieldsTest } from "_core/components/pages/private";

import { ADMIN_Global } from "_core/components/pages/private";
import { ADMIN_Global_Themes, ADMIN_Global_Themes_ColorBrowser, ADMIN_Global_Themes_BootstrapSampler } from "_core/components/pages/private";
import { ADMIN_Global_Companies, ADMIN_Global_Users } from "_core/components/pages/private";

import { ADMIN_Local } from "_core/components/pages/private";

import { MyHome, MyAccount, MyProfile, MyProfileUpdate, MyPassword, MySubscription } from "_core/components/pages/private";

import { CUSTOM_PRIVATE_ROUTES } from "_custom/config/ROUTES.config";
import { PRIVATE_PATHS } from "./Paths.core.config";
import { AllRoles, ROLES } from "./Roles.config";  /* eslint-disable-line no-unused-vars */

import { intersection } from "lodash";
import { isArrayWithLength } from "_core/helpers/data-types";
import { mergeRouteArrays } from "_core/helpers/routes";

// TODO:
/*
 * 1. Make title optional
 * 2. Make title multi type support ie: (string, node, react element)
 * 3. Add child route support
 * */

/*
 * Route config object supports all react-router's route component props with some additional props ie: (title, permission, children)
 * you can add or remove props from config object it's means it is super customizable and support upto N nesting, child routes must follow the same parent shape,
 * it means the config object is same for both there is no additional key for child nor for parent.
 * you can find route props from here https://reactrouter.com/web/api/Route let's take a look at some additional props
 * 1. permission: permission is an optional prop and it's type is an array of roles, permission is used for allowing certain users/roles
 *  	to have access of that route and if you skip/omit the permission or it's an empty array that means every authenticated user/role have access to that route.
 * 2. title: title is an optional prop and it's type is a string|node it is used for mapping route link into a navigation
 * 3. children: children is also an optional prop and it's type is an array of route config objects, children are used for nested routes
 * */

export const CORE_PRIVATE_ROUTES = [
    //----------------------------------------------------------------------------------------------------------------------------------
    {
        title: "My Home",
        description: "User's home landing page.",
        icon: "nav-my-home",
        path: PRIVATE_PATHS.MY.HOME,
        component: MyHome,
        permission: AllRoles(),
    },
    //----------------------------------------------------------------------------------------------------------------------------------
    {
        title: "My Account",
        description: "User's Account Root",
        icon: "nav-my-profile",
        path: PRIVATE_PATHS.MY.ACCOUNT,
        component: MyAccount,
        permission: AllRoles(),
        children: [
            {
                title: "My Profile",
                description: "User's Profile",
                icon: "nav-my-profile",
                path: PRIVATE_PATHS.MY.PROFILE,
                component: MyProfile,
            },
            {
                title: "Update My Profile",
                description: "User's Profile Update",
                icon: "nav-my-profile",
                path: PRIVATE_PATHS.MY.PROFILE_UPDATE,
                component: MyProfileUpdate,
            },
            {
                title: "My Password",
                description: "User's Password Update",
                icon: "nav-my-password",
                path: PRIVATE_PATHS.MY.PASSWORD,
                component: MyPassword,
            },
            {
                title: "My Subscription",
                description: "User's Subscription",
                icon: "nav-my-subscription",
                path: PRIVATE_PATHS.MY.SUBSCRIPTION,
                component: MySubscription,
            },
        ],
    },
    //----------------------------------------------------------------------------------------------------------------------------------
    {
        title: "Global Admin",
        description: "Global Admin",
        icon: "nav-global",
        path: PRIVATE_PATHS.GLOBAL.HOME,
        component: ADMIN_Global,
        permission: AllRoles(), // permission: [ROLES.SUPER_ADMIN, ROLES.GLOBAL_ADMIN],
        children: [
            {
                title: "Global Company Admin",
                description: "Global Administration of Companies in the App.",
                icon: "nav-company",
                path: PRIVATE_PATHS.GLOBAL.COMPANY,
                component: ADMIN_Global_Companies,
            },
            {
                title: "Global User Admin",
                description: "Global Administration of Users in the App.",
                icon: "nav-users",
                path: PRIVATE_PATHS.GLOBAL.USERS,
                component: ADMIN_Global_Users,
            },
            {
                title: "Global Theme Manager",
                description: "Global Administration of Themes in the App.",
                icon: "nav-theme",
                path: PRIVATE_PATHS.GLOBAL.THEMES.HOME,
                component: ADMIN_Global_Themes,
            },
            {
                title: "Global Theme Colors",
                description: "Global Administration of Theme Colors in the App.",
                icon: "theme",
                path: PRIVATE_PATHS.GLOBAL.THEMES.COLORS,
                component: ADMIN_Global_Themes_ColorBrowser,
            },
            {
                title: "Global Bootstrap Sampler",
                description: "Global bootstrap sampler.",
                icon: "theme",
                path: PRIVATE_PATHS.GLOBAL.THEMES.BOOTSTRAP,
                component: ADMIN_Global_Themes_BootstrapSampler,
            },
        ],
    },
    //----------------------------------------------------------------------------------------------------------------------------------
    {
        title: "Admin",
        description: "Local Admin",
        icon: "nav-admin",
        path: PRIVATE_PATHS.ADMIN.HOME,
        component: ADMIN_Local,
        permission: AllRoles(),
    },
    //----------------------------------------------------------------------------------------------------------------------------------
    {
        title: "Developer",
        description: "Developer Home Page,",
        icon: "nav-dev",
        path: PRIVATE_PATHS.DEV.HOME,
        component: DEV_Home,
        permission: AllRoles(), //[ ROLES.DEV ], //
        children: [
            {
                title: "React Hook Forms Test",
                description: "Developer Page - Test React Hook Forms Implementation,",
                path: PRIVATE_PATHS.DEV.REACT_HOOK_FORM_TEST,
                component: DEV_ReactHookFormTest,
            },
            {
                title: "Toast Test",
                description: "Developer Page - Test Toast Implementation,",
                path: PRIVATE_PATHS.DEV.TOAST_TEST,
                component: DEV_ToastTest,
            },
            {
                title: "Data Fields Test",
                description: "Developer Page - Data Fields Display Components,",
                path: PRIVATE_PATHS.DEV.DATA_FIELDS_TEST,
                component: DEV_DataFieldsTest,
            },
        ]
    },
    //----------------------------------------------------------------------------------------------------------------------------------
];

export const getAllowedPrivateRoutes = () => {
    //TODO: Are roles working? -how's this working?

    // MERGE CORE AND CUSTOM ROUTES, before filtering to whats allowed.

    const routes = mergeRouteArrays(CORE_PRIVATE_ROUTES, CUSTOM_PRIVATE_ROUTES);

    let roles = JSON.parse(localStorage.getItem("roles"));

    if (roles === undefined || roles === null) roles = [];

    if (roles.includes("DEV") || roles.includes("GLOBAL_ADMIN")) return routes; // pass all back.

    return routes.filter(({ permission }) => {
        if (!permission) return true;
        else if (!isArrayWithLength(permission)) return true;
        else return intersection(permission, roles).length;
    });
};

