import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { HelmetHtmlTitle } from "_core/components/core-library";     
import PageHeader from "_core/components/layouts/parts/PageHeader";                 
                                                                                       
const PrivatePageTemplate = ({pageTitle, breadcrumbs, showBreadcrumbs, showTitle, showLine, children, className}) => {
                                                    
    return (
        <>
            <HelmetHtmlTitle pageName={pageTitle} />
        
            <Container fluid className={className}>
                <PageHeader
                    title={pageTitle}
                    breadcrumbs={breadcrumbs}

                    showTitle={showTitle}
                    showBreadcrumbs={showBreadcrumbs}
                    showLine={showLine}
                />
                {children}
            </Container>
        </>
    );
};
export default PrivatePageTemplate;
PrivatePageTemplate.propTypes = {
    pageTitle: PropTypes.string,
    breadcrumbs: PropTypes.array,

    showTitle: PropTypes.bool,
    showBreadcrumbs: PropTypes.bool,
    showLine: PropTypes.bool,
    
};
PrivatePageTemplate.defaultProps = {
    pageTitle: "Page Title Here",
    breadcrumbs: [],

    showTitle: true,
    showBreadcrumbs: true,
    showLine: true,
};