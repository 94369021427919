import React from "react";
import PropTypes from "prop-types";
import { Link } from "_core/components/core-library"


function PageHeader({breadcrumbs, showBreadcrumbs, showTitle, showLine, title}) {
    return (
        <div className="layout-page-header">

            {(breadcrumbs!==null && breadcrumbs.length>0 && showBreadcrumbs===true) &&
                
                    <nav>
                        <ol className="breadcrumb">
                            {breadcrumbs.map( (crumb, index) => {
                                return (
                                    <li key={index} className="breadcrumb-item">
                                        <Link to={crumb.to} className="breadcrumb-link">{crumb.caption}</Link>
                                    </li>
                                )
                            })}
                            &nbsp;/&nbsp;
                        </ol>
                    </nav>
                
            }
            {(!breadcrumbs) &&
                <>&nbsp;</>
            }
            
            {(showTitle===true) &&
                <div className="page-title">{title}</div>
            }

            {(showLine===true) &&
                <hr />
            }
            
        </div>
    );
}
export default PageHeader;
PageHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    breadcrumbs: PropTypes.array,

    showTitle: PropTypes.bool,
    showBreadcrumbs: PropTypes.bool,
    showLine: PropTypes.bool,
};
PageHeader.defaultProps = {
    title: "Page Title Here",
    description: null,
    breadcrumbs: null,

    showTitle: true,
    showBreadcrumbs: true,
    showLine: true,
};
