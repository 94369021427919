import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider"; /* eslint-disable-line no-unused-vars */
import AuthService from "_core/services/auth.service";

/*
const user          = JSON.parse(localStorage.getItem("user"));
const access_token   = JSON.parse(localStorage.getItem("access_token"));
const access_expires = JSON.parse(localStorage.getItem("access_expires"));
const refresh_token  = JSON.parse(localStorage.getItem("refresh_token"));
*/

// initial state of Auth
const initialState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",

    isLoggedIn: false,

    access_token: null,
    access_expires: null,
    refresh_token: null,
    user: null,
    email: "",
    username: "",
    roles: null,

    // update my password
    change_pass: {
        error: false,
        message: null,
    },

    update_current_user: {
        error: false,
        message: null,
    },

    // props for Forgot Pass Process
    forgot: {
        find: {
            complete: false,
            success: false,
            error: false,
            // token: null,        // Unique token for the OTP send/receive process.  The VERIFY process checks this token on backend to move forward verifying a OTP sent to client.
            // expires: null,      // when OTP code sent token expires.
            message: null,
        },
        send: {
            complete: false,
            success: false,
            error: false,
            //  token: null,        // Unique token for the OTP send/receive process.  The VERIFY process checks this token on backend to move forward verifying a OTP sent to client.
            //  expires: null,      // when OTP code sent token expires.
            message: null,
            target: null, // Target address or phone (masked) code was sent to.
            method: null, // The method of send (phone or email)
        },
        verify: {
            complete: false,
            success: false,
            error: false,
            //   token: null,        // Another Unique token for the PASS CHANGE send/receive process. The PASS process checks this token on backend to move forward saving pass.
            //   expires: null,      // when OTP code sent token expires.
            message: null,
            trysLeft: 3, // how many tries does user get to enter code and submit before bail?
        },
        pass: {
            //   token: null,        // Unique token for the OTP -> pass change submit  process
            //  expires: null,      // when password change access token expires.
        },
    },
};

export const verify_user = createAsyncThunk(
    "auth/verify_user",
    async ( access_token , thunkAPI) => {
        try {
            const response = await AuthService.fetchUserByToken({
                token: access_token,
                access_token: access_token,
            }, access_token);

            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (e) {
            console.error(e);
            console.log("Error", e.response.data);
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async ( { form_data }, thunkAPI) => {
        try {
            const response = await AuthService.login({
                form_data
            });   
            
            //console.log('LOGIN - SLICE - RESPONSE RECEIVED:',response)

            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const logout = createAsyncThunk(
    "auth/logout",
    async ({ form_data, access_token }, thunkAPI) => {
        try {
            const response = await AuthService.logout({ 
                form_data, 
                access_token 
            });                                                     // console.log('LOGOUT - SLICE - RESPONSE RECEIVED:',response)
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const change_pass = createAsyncThunk(
    "auth/change_pass",
    async ({ form_data, access_token }, thunkAPI) => {
        try {
            const response = await AuthService.change_pass({ 
                form_data, 
                access_token 
            });
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const update_current_user = createAsyncThunk(
    "auth/update_current_user",
    async ({ form_data, access_token }, thunkAPI) => {
        try {
            const response = await AuthService.update_current_user({
                form_data,
                access_token,
            });
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const forgot_reset = createAsyncThunk("auth/forgot/reset", async () => {
    return {};
});
export const forgot_find = createAsyncThunk(
    "auth/forgot/find",
    async (data_to_post, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await AuthService.forgot_find(
                data_to_post,
                access_token
            );
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const forgot_send = createAsyncThunk(
    "auth/forgot/send",
    async (data_to_post, thunkAPI) => {
        try {
            const response = await AuthService.forgot_send(data_to_post);
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const forgot_verify = createAsyncThunk(
    "auth/forgot/verify",
    async (form_data, thunkAPI) => {
        try {
            const response = await AuthService.forgot_verify(form_data);
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const forgot_pass = createAsyncThunk(
    "auth/forgot/pass",
    async (form_data, thunkAPI) => {
        try {
            const response = await AuthService.forgot_pass(form_data);
            if (response.status === 200 &&  response.data?.success) {
                return response.data;     // valid response
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const register = createAsyncThunk(
    "auth/register",
    async ({ username, email, password }, thunkAPI) => {
        try {
            const response = await AuthService.register({
                username,
                email,
                password,
            });
            /* debug */ //console.log('response', response);

            let data = JSON.stringify(response.data);

            if (response.status === 200) {
                // search the response for a 'access_token' or 'jwt', or 'access_token', and use it
                let access_token = data.access_token
                    ? data.access_token
                    : data.jwt
                    ? data.jwt
                    : data.access_token
                    ? data.access_token
                    : null;
                if (
                    access_token === undefined ||
                    !access_token ||
                    access_token === ""
                ) {
                    console.log(
                        "APP LOGIN ERROR - No access_token found in response!",
                        response
                    );
                    throw new Error("No access_token found in response!");
                }

                // store access_token and data in local storage
                localStorage.setItem(
                    "access_token",
                    JSON.stringify(access_token)
                );

                // we wont store roles in local storage, rather in the state only.

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            console.error(e);
            console.log("Error", e.response.data);
            thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearStateByKey: (state, key1 = null, key2 = null) => {
            if (key1 && key2) {
                state[key1][key2] = Object.assign({}, initialState[key1][key2]);
            } else if (key1) {
                state[key1] = Object.assign({}, initialState[key1]);
            } else {
                state = Object.assign({}, initialState);
            }
        },
    },

    extraReducers: (builder) => {
        builder

            /****************************************************** */
            /*  register                                            */
            /****************************************************** */
            .addCase(register.pending, (state, action) => {
                state.isFetching = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                // const result = action.payload;                            /// this is axios's data object in the response, which is our actual returned payload
                // const data = result?.data;
                // clearStateByKey(state);
                // state.isSuccess = true;
            })
            .addCase(register.rejected, (state, action) => {
                state.isFetching = false;
                state.isError = true;
            })

            /****************************************************** */
            /*  verify_user                                            */
            /****************************************************** */
            .addCase(verify_user.pending, (state, action) => {
                // console.log("VERIFY_USER - REDUX - PENDING")
                state.isFetching = true;
            })
            .addCase(verify_user.fulfilled, (state, action) => {
                //  console.log("VERIFY_USER - REDUX - FULFILLED")
                const result = action.payload;                            /// this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;
                state.errorMessage = null;

                state.isLoggedIn = true;

                state.access_token = data?.access_token;
                state.access_expires = data?.access_expires;
                state.refresh_token = data?.refresh_token;

                state.user = data?.user;
                state.email = data?.email;
                state.username = data?.name;
                state.roles = data?.roles;
            })
            .addCase(verify_user.rejected, (state, action) => {
                //  console.log("VERIFY_USER - REDUX - REJECTED")
                const result = action.payload;                            /// this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;
                state.errorMessage = data?.message;

                state.isLoggedIn = false;

                state.access_token = null;
                state.access_expires = null;
                state.refresh_token = null;
                state.user = null;
                state.email = null;
                state.username = null;
                state.roles = null;
            })

            /****************************************************** */
            /*  login                                               */
            /****************************************************** */
            .addCase(login.pending, (state, action) => {
                // console.log("LOGIN - REDUX - PENDING")
                state.isFetching = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                //console.log("LOGIN - REDUX - FULFILLED")
                const result = action.payload;                            /// this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;
                state.errorMessage = null;

                state.isLoggedIn = true;

                state.access_token = data?.access_token;
                state.access_expires = data?.access_expires;
                state.refresh_token = data?.refresh_token;

                state.user = data?.user;
                state.email = data?.email;
                state.username = data?.name;
                state.roles = data?.roles;
            })
            .addCase(login.rejected, (state, action) => {
                //console.log("LOGIN - REDUX - REJECTED");
                const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;

                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;
                state.errorMessage = result?.message;

                state.isLoggedIn = false;

                state.access_token = null;
                state.access_expires = null;
                state.refresh_token = null;
                state.user = null;
                state.email = null;
                state.username = null;
                state.roles = null;
            })
            /* ---------------------------------------------------------------------------------------------------------------------------------------
            /****************************************************** */
            /*  logout                                               */
            /****************************************************** */
            .addCase(logout.pending, (state, action) => {
                state.isFetching = true;
            })
            .addCase(logout.fulfilled, (state, action) => {
                //  console.log("LOGOUT - REDUX - FULFILLED");
                const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;
                state.errorMessage = result?.message;

                state.isLoggedIn = false;

                state.access_token = null;
                state.access_expires = null;
                state.refresh_token = null;
                state.user = null;
                state.email = null;
                state.username = null;
                state.roles = null;
            })
            .addCase(logout.rejected, (state, action) => {
                //console.log("LOGOUT - REDUX - REJECTED")
                const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
            
                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;
                state.errorMessage = result?.error;

                state.isLoggedIn = false;

                state.access_token = null;
                state.access_expires = null;
                state.refresh_token = null;
                state.user = null;
                state.email = null;
                state.username = null;
                state.roles = null;
            })
            /* ---------------------------------------------------------------------------------------------------------------------------------------
            /****************************************************** */
            /*  Change My Password                         */
            /****************************************************** */
            .addCase(change_pass.pending, (state, action) => {
                // console.log("CHANGE_PASS - REDUX - PENDING");
                //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                state.isFetching = true;
            })
            .addCase(change_pass.fulfilled, (state, action) => {
                // console.log("CHANGE_PASS - REDUX - FULFILLED");
                const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;

                state.access_token = null; // clear access_token as user HAS to login now
                state.access_expires = null;

                state.change_pass = { ...initialState.change_pass }; 
                state.change_pass.message = result?.message;

            })
            .addCase(change_pass.rejected, (state, action) => {
                //   console.log("CHANGE_PASS - REDUX - REJECTED");

                const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;

                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;

                state.access_token = null;
                state.access_expires = null;

                state.change_pass = { ...initialState.change_pass }; // reset to the VERIFY process state to initialState

                state.change_pass.error = true;
                state.change_pass.message = result?.error;
            })

            /* ---------------------------------------------------------------------------------------------------------------------------------------
            /****************************************************** */
            /*  Update User                        */
            /****************************************************** */
            .addCase(update_current_user.pending, (state, action) => {
                // console.log("UPDATE_USER - REDUX - PENDING");
                //const data = action.payload;
                state.isFetching = true;
            })
            .addCase(update_current_user.fulfilled, (state, action) => {
                //   console.log("UPDATE_USER - REDUX - FULFILLED");
                const result = action.payload;    // our server API returned payload ("result").                           
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;

                state.user = data?.user;
                state.email = data?.email;
                state.username = data?.name;
                state.roles = data?.roles;

                state.update_current_user = { ...initialState.update_current_user }; // reset to the initialState
                state.update_current_user.error = false;
                state.update_current_user.message = data?.message;

            })
            .addCase(update_current_user.rejected, (state, action) => {
                //console.log("UPDATE_USER - REDUX - REJECTED");
                
                const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                

                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;

                state.update_current_user = { ...initialState.update_current_user }; // reset to the initialState
                state.update_current_user.error = false;
                state.update_current_user.message = result?.error;
            })


            /* ---------------------------------------------------------------------------------------------------------------------------------------
            /**
             *
             *    FORGOT PASSWORD RECOVERY PROCESS STEPS
             *       0 - forgot_reset -  reset the process
             *       1 - forgot_find  -  find the account
             *       2 - forgot_send  -  send a authorizartion code to sms or email
             *       3 - forgot_verify-  verify the code recieved by client against backend, verifying identity
             *       4 - forgot_pass  -  set new password process
             *       5 - forgot_success
             */

            /******************************************************        */
            /*  FORGOT 0 - reset  (initialize forgot pass recovery process to default state.)  */
            /******************************************************        */
            .addCase(forgot_reset.pending, (state, action) => {
                // do nothing
            })
            .addCase(forgot_reset.fulfilled, (state, action) => {
                //  console.log('FORGOT_RESET FULFILLED')
                state.forgot = { ...initialState.forgot }; // reste the state
            })
            .addCase(forgot_reset.rejected, (state, action) => {
                // do nothing
            })

            /****************************************************** */
            /*  FORGOT 1 - Find Account                              */
            /****************************************************** */
            .addCase(forgot_find.pending, (state, action) => {
                // console.log("FORGOT_FIND - REDUX - PENDING");
                //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                state.isFetching = true;
            })
            .addCase(forgot_find.fulfilled, (state, action) => {
                //    console.log("FORGOT_FIND - REDUX - FULFILLED");
                const result = action.payload;    // our server API returned payload ("result").                           
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;

                state.access_token = data?.token;
                state.access_expires = data?.expires;

                // Initialize FIND process with data.
                state.forgot.find = {
                    complete: true,
                    success: true,
                    error: false,
                    message: data?.found_msg,
                    methods: data?.methods,
                };

                // RESET vals
                state.forgot.send = { ...initialState.forgot.send }; // reset to the SEND process state to initialState
                state.forgot.verify = { ...initialState.forgot.verify }; // reset to the VERIFY process state to initialState
                state.forgot.pass = { ...initialState.forgot.pass }; // reset to the PASS process state to initialState
            })
            .addCase(forgot_find.rejected, (state, action) => {
                //   console.log("FORGOT_FIND - REDUX - REJECTED");
                //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;

                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;

                state.access_token = null;
                state.access_expires = null;

                // RESET vals
                state.forgot.find = { ...initialState.forgot.find }; // reset to the FIND process state to initialState
            })

            /****************************************************** */
            /*  FORGOT 2 - Send Auth Code                            */
            /****************************************************** */
            .addCase(forgot_send.pending, (state, action) => {
                //console.log("FORGOT_SEND - REDUX - PENDING");
                 //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                state.isFetching = true;
            })
            .addCase(forgot_send.fulfilled, (state, action) => {
                //   console.log("FORGOT_SEND - REDUX - FULFILLED");
                const result = action.payload;    // our server API returned payload ("result").                           
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;

                state.access_token = data?.token;
                state.access_expires = data?.expires;

                state.forgot.send = {
                    complete: true,
                    success: true,
                    error: false,
                    message: data?.sent_msg,
                    target: data?.target,
                    method: data?.sendby,
                };
                // RESET vals
                state.forgot.verify = { ...initialState.forgot.verify }; // reset to the VERIFY process state to initialState
            })
            .addCase(forgot_send.rejected, (state, action) => {
                //    console.log("FORGOT_SEND - REDUX - REJECTED");
                const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;

                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;

                state.access_token = null;
                state.access_expires = null;

                state.forgot.send = { ...initialState.forgot.send }; // reset to the SEND process state to initialState

                state.forgot.send.success = false;
                state.forgot.send.error = true;
                state.forgot.send.message = result?.error;

                state.forgot.verify = { ...initialState.forgot.verify }; // reset to the VERIFY process state to initialState
                state.forgot.pass = { ...initialState.forgot.pass }; // reset to the PASS process state to initialState
            })

            /****************************************************** */
            /*  FORGOT 3 - Verify Code                               */
            /****************************************************** */
            .addCase(forgot_verify.pending, (state, action) => {
                //   console.log("FORGOT_VERIFY - REDUX - PENDING");
                //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                state.isFetching = true;
            })
            .addCase(forgot_verify.fulfilled, (state, action) => {
                //    console.log("FORGOT_VERIFY - REDUX - FULFILLED");
                const result = action.payload;    // our server API returned payload ("result").                           
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;

                state.access_token = data?.token;
                state.access_expires = data?.expires;

                state.forgot.verify = {
                    complete: true,
                    success: true,
                    error: false,
                    message: data?.message,
                };

                state.forgot.pass = { ...initialState.forgot.pass }; // reset to the PASS process state to initialState

                state.forgot.pass.token = data?.token;
                state.forgot.pass.expires = data?.expires;
            })
            .addCase(forgot_verify.rejected, (state, action) => {
                // console.log("FORGOT_VERIFY - REDUX - REJECTED");
                //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;

                state.access_token = null;
                state.access_expires = null;

                state.forgot.verify = { ...initialState.forgot.verify }; // reset to the VERIFY process state to initialState

                state.forgot.verify.error = true;
                state.forgot.verify.message = action.payload?.error;

                state.forgot.pass = { ...initialState.forgot.pass }; // reset to the PASS process state to initialState
            })
            
            /****************************************************** */
            /*  FORGOT 4 - Set New Password                         */
            /****************************************************** */
            .addCase(forgot_pass.pending, (state, action) => {
                //   console.log("FORGOT_VERIFY - REDUX - PENDING");
                //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                state.isFetching = true;
            })
            .addCase(forgot_pass.fulfilled, (state, action) => {
                //   console.log("FORGOT_VERIFY - REDUX - FULFILLED");
                const result = action.payload;    // our server API returned payload ("result").                           
                const data = result?.data;

                state.isFetching = false;
                state.isSuccess = true;
                state.isError = false;

                state.access_token = null; // clear accesssToken as user HAS to login now
                state.access_expires = null;

                state.forgot.pass.message = data?.message;

                // wipe the rest out;
                state.forgot.find = { ...initialState.forgot.find }; // reset to the SEND process state to initialState
                state.forgot.send = { ...initialState.forgot.send }; // reset to the SEND process state to initialState
                state.forgot.verify = { ...initialState.forgot.verify }; // reset to the VERIFY process state to initialState
            })
            .addCase(forgot_pass.rejected, (state, action) => {
                //   console.log("FORGOT_VERIFY - REDUX - REJECTED");
                //const result = action.payload;    // our server API returned payload ("result").                           
                //const data = result?.data;
                state.isFetching = false;
                state.isSuccess = false;
                state.isError = true;

                state.access_token = null;
                state.access_expires = null;

                state.forgot.pass = { ...initialState.forgot.pass }; // reset to the VERIFY process state to initialState

                state.forgot.pass.error = true;
                state.forgot.pass.message = action.payload?.error;
            })

            /****************************************************** */
            /*  DEFAULT                                            */
            /****************************************************** */
            .addDefaultCase((state) => {
                // Do something when no actions identified.
            });
    },
});
export default authSlice;

export const { clearStateByKey } = authSlice.actions;
