import React from "react";
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faCircleQuestion, faCircleUser, faCircleUp, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faBars, faCalendar, faCheck, faCircle, faCloud, faCode, faCopy, faEye, faExclamation, faExclamationTriangle, faFileExport, 
        faKey, faGlobe, faHouse, faList, faLongArrowRight, faPalette, faPaperclip, faMagnifyingGlass, faRightFromBracket, 
        faRotateRight, faShare, faScrewdriverWrench ,faUserLarge, faX } from "@fortawesome/free-solid-svg-icons";

// add imported icons to the fa library which makes them available to FontAwesomeIcon
library.add(
        faArrowDown, faArrowUp, faArrowLeft, faArrowRight,    
        faBars,
        faCalendar,
        faCheck, 
        faCircle,
        faCircleQuestion,
        faCircleUp,
        faCircleUser,
        faCloud,
        faCode,
        faCopy,
        faEnvelope,
        faEye,
        faExclamation,
        faExclamationTriangle,
        faFileExport,
        faGlobe,
        faKey,
        faHouse,
        faList,
        faLongArrowRight,
        faPalette,
        faPaperclip,
        faMagnifyingGlass,
        faRightFromBracket,
        faRotateRight,
        faScrewdriverWrench,
        faShare,
        faUserLarge,
        faX
);


const Icon = (props) => {
    const {name, className, size, fixedWidth, spin, pulse, border, listItem, style} = props;

    const get_fa_icon = (name) => {
        return <FontAwesomeIcon icon={name} size={size} fixedWidth={fixedWidth} className={className} style={style} spin={spin} pulse={pulse} border={border} listItem={listItem} />
    }

    switch (name) {
        case 'arrow-down'   : return get_fa_icon('fa-solid fa-arrow-down'); 
        case 'arrow-left'   : return get_fa_icon('fa-solid fa-arrow-left'); 
        case 'arrow-up'     : return get_fa_icon('fa-solid fa-arrow-up'); 
        case 'arrow-right'  : return get_fa_icon('fa-solid fa-arrow-right'); 
        case 'bars'         : return get_fa_icon('fa-solid fa-bars'); 
        case 'calendar'     : return get_fa_icon('fa-solid fa-calendar'); 
        case 'circle'       : return get_fa_icon('fa-solid fa-circle'); 
        case 'circleQuestion': return get_fa_icon('fa-regular fa-circle-question'); 
        case 'circleUp'     : return get_fa_icon('fa-regular fa-circle-up'); 
        case 'circleUser'   : return get_fa_icon('fa-regular fa-circle-user'); 
        case 'check'        : return get_fa_icon('fa-solid fa-check'); 
        case 'cloud'        : return get_fa_icon('fa-solid fa-cloud'); 
        case 'copy'        : return get_fa_icon('fa-solid fa-copy'); 
        case 'exclamation'  : return get_fa_icon('fa-solid fa-exclamation'); 
        case 'exclamationTriangle' : return get_fa_icon('fa-solid fa-triangle-exclamation'); 
        case 'eye'          : return get_fa_icon('fa-solid fa-eye'); 
        case 'envelope'     : return get_fa_icon('fa-regular fa-envelope'); 
        case 'export'       : return get_fa_icon('fa-solid fa-file-export'); 
        case 'globe'        : return get_fa_icon('fa-solid fa-globe'); 
        case 'help'         : return get_fa_icon('fa-regular fa-circle-question'); 
        case 'home'         : return get_fa_icon('fa-solid fa-house'); 
        case 'key'          : return get_fa_icon('fa-solid fa-key'); 
        case 'list'         : return get_fa_icon('fa-solid fa-list'); 
        case 'palette'      : return get_fa_icon('fa-solid fa-palette'); 
        case 'paperclip'    : return get_fa_icon('fa-solid fa-paperclip'); 
        case 'report'       : return get_fa_icon('fa-solid fa-paperclip'); 
        case 'search'       : return get_fa_icon('fa-solid fa-magnifying-glass'); 
        case 'share'        : return get_fa_icon('fa-solid fa-share'); 
        case 'screwdriverWrench' : return get_fa_icon('fa-solid fa-screwdriver-wrench'); 
        case 'theme'        : return get_fa_icon('fa-solid fa-palette'); 
        case 'userLarge'    : return get_fa_icon('fa-solid fa-user-large'); 
        case 'x'            : return get_fa_icon('fa-solid fa-x'); 

        case 'nav-circle-question' : return get_fa_icon('fa-regular fa-circle-question'); 
        case 'nav-hamburger-menu'  : return get_fa_icon('fa-solid fa-bars'); 
        case 'nav-my-home'         : return get_fa_icon('fa-solid fa-house'); 
        case 'nav-my-profile'      : return get_fa_icon('fa-solid fa-user-large'); 
        case 'nav-my-password'     : return get_fa_icon('fa-solid fa-key'); 

        case 'nav-admin'    : return get_fa_icon('fa-solid fa-screwdriver-wrench'); 
        case 'nav-dev'      : return get_fa_icon('fa-solid fa-code'); 
        case 'nav-global'   : return get_fa_icon('fa-solid fa-globe'); 

        case 'nav-reload'   : return get_fa_icon('fa-solid fa-rotate-right'); 

        case 'signout'   : 
        case 'logout'   : 
        case 'nav-logout'   : 
        case 'nav-signout'  : 
            return get_fa_icon('fa-solid fa-right-from-bracket'); 

        default             : return null; 
    }
};
export default Icon

Icon.propTypes = {
	name: PropTypes.string.isRequired,
    size: PropTypes.string,        // can be  controlled with the CSS font-size attribute, and FontAwesomeIcon's size prop
                                // one of:  ["2xs","xs","sm","lg","xl","2xl","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"]

    fixedWidth: PropTypes.bool,

    listItem: PropTypes.bool,   // Icons in  List?
    border: PropTypes.bool,     // Adds a border
    spin: PropTypes.bool,       // FontAwesome Animation 
    pulse: PropTypes.bool,      // FontAwesome Animation 

}
Icon.defaultProps = {
    name: null,
    size: "1x",
    fixedWidth: false,

    listItem: false,
    border: false,
    spin: false,
    pulse: false,

};