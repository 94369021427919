import React from "react";
import { Button, Icon } from "_core/components/core-library";

function MainMenuButton({className, onClick , style}) {
    return (
        <Button
            variant="outline"
            className={className}
            style={{ ...style, border: "none" }}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <Icon name="nav-hamburger-menu" size="xl" />
        </Button>
    );
}
export default MainMenuButton;
