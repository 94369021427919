import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useController } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import {
    RadioGroup,
    RadioButton,
    ReversedRadioButton,
} from "./_my_modules/my-Radio-Button-Group";

//   props = {name, options, value, rules, iconSize, reversedButton, horizontal, iconSize: groupIconSize}
const RadioButtonGroup = (props) => {
    const [value, setValue] = useState();                   /* eslint-disable-line */

    const {
        field: { onChange, name, ref },                     /* eslint-disable-line */
        fieldState: { invalid, isTouched },                 /* eslint-disable-line */
    } = useController({
        name: props.name,
        control: props.control,
        defaultValue: props.value,
        rules: {
            disabled: props.rules?.disabled,
            required:
                props.rules?.required && props.feedback !== ""
                    ? props.feedback
                    : props.rules.required,
            validate: props.rules?.validate,
        },
    });

    const onChange_handler = (value) => {
        console.log('RadioButtonGroup onchange called')
        console.log('value',value)
        onChange(value); //react-hook-form onchange
        props.onChange && props.onChange(value); // call props onchange if provided
    };

    const get_radio_button = (
        index,
        name,
        label,
        value,
        disabled,
        optionIconSize
    ) => {
        
        let iconSize =
            optionIconSize !== undefined && optionIconSize !== null
                ? optionIconSize
                : props.iconSize !== undefined && props.iconSize !== null
                ? props.iconSize
                : 20;
        //console.log("icon is " + iconSize);
        if (props.reversedButton) {
            return (
                <ReversedRadioButton
                    key={name + "_" + index}
                    id={name + "_" + index}
                    value={value}
                    disabled={props.disabled || disabled}         // group or single button
                    iconSize={iconSize}
                >
                    {label}
                </ReversedRadioButton>
            );
        } else {
            return (
                <RadioButton
                    key={name + "_" + index}
                    id={name + "_" + index}
                    value={value}
                    disabled={disabled}
                    iconSize={iconSize}
                >
                    {label}
                </RadioButton>
            );
        }
    };

    return (
        <Form.Group className={props.className} controlId={props.name + "_group"} >

            <Form.Label className={props.labelClass}>{props.label}</Form.Label>
            
            <InputGroup hasValidation>
                <RadioGroup value={props.value} onChange={onChange_handler} horizontal={props.horizontal} >

                    {props.options.map(({ label, value, disabled, iconSize: optionIconSize }, index ) => {
                        return get_radio_button( index, name, label, value, disabled, optionIconSize );
                    })}
                    
                </RadioGroup>
                <Form.Control.Feedback type="invalid" className={props.feedbackClass}>
                    {props.feedback}
                </Form.Control.Feedback>
            </InputGroup>

            <Form.Text className={props.helpTextClass} muted>
                {props.helpText}
            </Form.Text>

        </Form.Group>
    );
};
RadioButtonGroup.propTypes = {
    label:   PropTypes.string.isRequired,
    name:    PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ),

    horizontal:     PropTypes.bool, // radio set layout orientation
    iconSize:       PropTypes.number, // dot icon size in pixels.
    reversedButton: PropTypes.bool, // shows dot on left when reversed, or on right normally

    disabled:       PropTypes.bool,
    helpText:       PropTypes.string,

    className:      PropTypes.string, // overall group class
    labelClass:     PropTypes.string,
    helpTextClass:  PropTypes.string,

    control:        PropTypes.object /* Pass in the react-hook-form controller to this. */,
    ref:            PropTypes.object, // React element ref
    rules:          PropTypes.object,

    validate: PropTypes.func,
    feedback: PropTypes.string, // invalid feedback
    feedbackClass: PropTypes.string,
};
RadioButtonGroup.defaultProps = {
    as: "input" /*  input or textarea */,
    label: null,
    type: "text",
    required: false,
    autoFocus: false,
    size: null /* normal */,
    value: "",
    readOnly: false,
    disabled: false,

    horizontal: false,
    iconSize: 20, // pixels
    reversedButton: true,

    labelClass: "",
    helpTextClass: "",
    feedbackClass: "",
};
export default RadioButtonGroup;
