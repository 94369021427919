import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import JSONPretty from "_core/components/core-library/JSONPretty";

function DebugOutput({activeIndex, defaultSelected, selectedRowIDs, selected }) {
    return (
        <Container fluid className="InovuaReactDataGrid--theme-default-light my-0 p-1">
            <p>Active Index : {activeIndex == null ? "null" : JSON.stringify(activeIndex)}.</p>
            <p>Default Selected : {defaultSelected == null ? "null" : JSON.stringify(defaultSelected)}.</p>
            <p>Selected Rows: {selectedRowIDs == null ? "null" : JSON.stringify(selectedRowIDs)}.</p>
            <p>Selected Rows Data JSON:</p>
            <JSONPretty json={(selected===undefined ? null : selected)} />
        </Container>
    );
}
DebugOutput.defaultProps = {
	activeIndex: null,
    defaultSelected: null,
    selectedRowIDs: null,
    selected: null,
}
DebugOutput.propTypes = {
	activeIndex: PropTypes.any,
    defaultSelected: PropTypes.any,
    selectedRowIDs: PropTypes.any,
    selected: PropTypes.any,
   
}
export default DebugOutput;
