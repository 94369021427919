import React from "react"
import PropTypes from "prop-types";
import { Container} from 'react-bootstrap';  
import { Button } from '_core/components/core-library';

const FilterSelector = ({filteredProducts, availableFilters, onselect}) => {
    return <>
        <Container className='p-0'>
            <Container className={"text-center p-2 rounded-top fw-bold"} style={{backgroundColor:"lightgrey"}}>
                {filteredProducts.length} Products To Choose From:
            </Container>
            <Container className={"d-grid gap-4 p-4 rounded-bottom"} style={{backgroundColor:"lightblue"}}>
                {availableFilters.map(function(filter, i){
                    return <Button key={i} variant={"primary-outline"} size={"lg"} className={"fw-bold"} text={filter?.label} onClick={() => {        
                        onselect(filter?.id);//
                    }}/>;
                })}
            </Container>
        </Container>
    </>
}
export default FilterSelector;
FilterSelector.propTypes = {
    filteredProducts: PropTypes.arrayOf(PropTypes.object),
    availableFilters: PropTypes.arrayOf(PropTypes.object),
    onselect: PropTypes.func
};
FilterSelector.defaultProps = {
    filteredProducts: [],
    availableFilters: [],
    onselect: (filterID) => { console.log('Selected filter ID = '+filterID); },
};