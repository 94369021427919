import { Button, Icon } from "_core/components/core-library";
import { PRIVATE_PATHS, LOGOUT_PATH  } from "_core/config";
import React, { useRef, useState } from "react";

import "./MyHomeDashboard.scss";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import { useEffect } from "react";
import HomeChart from "./HomeChart";



const MyHomeDashboard = () => {
    const { dimensions } = useUI();
    const [chartHeight, setChartHeight] = useState(500);   

    const chartRef = useRef();

    useEffect(() => {

        const heightOffset = -250;
        const minChartHeight = 300;

        const chartTop = chartRef.current.offsetTop;

        //console.log('chartRef',chartRef.current)

        const winHeight = dimensions?.WINDOW_HEIGHT;
        if (winHeight===undefined || winHeight===null || winHeight==='') return;

        var newChartHeight = winHeight - chartTop + heightOffset;
        if (newChartHeight < minChartHeight) newChartHeight = minChartHeight;
        

        setChartHeight( newChartHeight );

    },[dimensions,chartRef]);

    return (
        <div className="my-dashboard container-fluid">
            <div className="row">
                <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
                    <div className="offcanvas-md offcanvas-end bg-body-tertiary" tabIndex="-1" id="sidebarMenu">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="sidebarMenuLabel">
                                Company name
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Button to={PRIVATE_PATHS.GLOBAL.HOME} variant="link" className="">
                                        <Icon name="nav-global" className="me-1" /> Global Admin
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <Button to={PRIVATE_PATHS.ADMIN.HOME} variant="link" className="">
                                        <Icon name="nav-admin" className="me-1" /> Local Admin
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <Button to={PRIVATE_PATHS.GLOBAL.IGSDB.HOME} variant="link" className="">
                                        <Icon name="list" className="me-1" /> IGSDB
                                    </Button>
                                </li>
                            </ul>
                            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
                                <span>Saved reports</span>
                            </h6>
                            <ul className="nav flex-column mb-auto">
                                <li className="nav-item">
                                    <Button onClick={() => alert(1)} variant="link">
                                        <Icon name="report" className="me-1" /> Current month
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <Button onClick={() => alert(1)} variant="link" className="">
                                        <Icon name="report" className="me-1" /> Last quarter
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <Button onClick={() => alert(1)} variant="link" className="">
                                        <Icon name="report" className="me-1" /> Last month
                                    </Button>
                                </li>
                                <li className="nav-item">
                                    <Button onClick={() => alert(1)} variant="link" className="">
                                        <Icon name="report" className="me-1" /> Last year
                                    </Button>
                                </li>
                            </ul>
                            <hr className="my-3 mx-2" />
                            <ul className="nav flex-column mb-auto">
                                <li className="nav-item">
                                    <Button to={LOGOUT_PATH} variant="link" className="">
                                        <Icon name="logout" className="me-1" /> Sign out
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">My Home</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-secondary">
                                    <Icon name="share" className="me-1"/>    
                                    Share
                                </button>
                                <button type="button" className="btn btn-sm btn-outline-secondary">
                                    <Icon name="export" className="me-1"/>        
                                    Export
                                </button>
                            </div>
                            <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle d-flex align-items-center gap-1">
                                <Icon name="calendar" className="me-1"/>       
                                This week
                            </button>
                        </div>
                    </div>
                   
                   
                    <HomeChart ref={chartRef} width={"100%"} height={chartHeight} style={{ display: "block", boxSizing: "border-box" }} />                   
                   
                   
                    <h2>Recently Added Products</h2>
                    <div className="table-responsive small">
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Header</th>
                                    <th scope="col">Header</th>
                                    <th scope="col">Header</th>
                                    <th scope="col">Header</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1,001</td>
                                    <td>random</td>
                                    <td>data</td>
                                    <td>placeholder</td>
                                    <td>text</td>
                                </tr>
                                <tr>
                                    <td>1,002</td>
                                    <td>placeholder</td>
                                    <td>irrelevant</td>
                                    <td>visual</td>
                                    <td>layout</td>
                                </tr>
                                <tr>
                                    <td>1,003</td>
                                    <td>data</td>
                                    <td>rich</td>
                                    <td>dashboard</td>
                                    <td>tabular</td>
                                </tr>
                                <tr>
                                    <td>1,003</td>
                                    <td>information</td>
                                    <td>placeholder</td>
                                    <td>illustrative</td>
                                    <td>data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </main>
            </div>
        </div>
    );
};
export default MyHomeDashboard;
