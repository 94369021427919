import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Offcanvas } from "react-bootstrap";

const MessageMenu = (props) => {
    const [show, setShow] = useState(props.show); // for  showing or hiding the menu

    // set the components state to the props passed in
    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    const handleClose = () => {
        setShow(false);
        if (props.onHide !== undefined) {
            props.onHide();
        }
    };
   
    return (
        <>
            <Offcanvas className={"messages-menu-offcanvas  bg-body-tertiary"} placement="top" show={show} onHide={handleClose}>
                <Offcanvas.Header
                    closeButton
                    className="pb-0 mb-0 "
                  
                >
                    <Offcanvas.Title>MY MESSAGES</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex flex-column pt-0 mt-0">
                    <p>My Messages</p>
                    <p>My Messages</p>
                    <p>My Messages</p>
                    <p>My Messages</p>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
MessageMenu.propTypes = {
    theme: PropTypes.string,
    show: PropTypes.bool,
    className: PropTypes.string,
};
MessageMenu.defaultProps = {
    theme: "dark",
    show: false,
    className: "",
};
export default MessageMenu;
