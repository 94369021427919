/**
 *      my/ Password.js
 *
 *       A user's password change page.  Accessed while already logged in.
 */

import React, { useState } from "react";

import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { Input, SubmitButton, Form, Icon } from "_core/components/core-library";
import { Spinner, Alert } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { validatePassword, default_password_criteria } from "_core/helpers/form-validation";

const MyPassword = () => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
   // const navigate = useNavigate();

    // STATE HOOKS
    const [loading, setLoading] = useState(false); // for spinner
    const [passValid, setPassValid] = useState(false); // A flag that confirmPass uses.
    const [passError, setPassError] = useState(null); // for error message on form
    const [passCriteria, setPassCriteria] = useState({
        ...default_password_criteria,
    }); // grab our default_pass_crit object from our form password helper

    // AUTH State hooks
    const { change_pass } = useAuth();

    // UI HOOK
    const { toast } = useUI();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        getValues,
        control,
        trigger,
        formState: { isValid },
        reset: resetForm,
    } = useForm({ mode: "all" });


    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

    //  future useEffect / onmount funcs here



    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */
    const validateFieldByEvent = async (e) => {
        return await trigger(e.target.id); // call react-hook-form's useForm.trigger, with event passed in, to validate just this field
    };
    const validateFieldById = async (id) => {
        return await trigger(id); // call react-hook-form's useForm.trigger, with id passed in, to validate just this field
    };

    const validatePasswordConfirmation = async (val) => {
        let pass = getValues("password");

        let valid = true;

        //console.log("pass= " + pass);
        //console.log("cfrm= " + val);

        if (
            pass === undefined ||
            pass === null ||
            pass === "" ||
            pass !== val
        ) {
            valid = false;
        }
        return valid;
    };

    const handlePassOnChange = async (e) => {
        await validateFieldByEvent(e);
        let x = await validateFieldById("password_confirm");
        console.log("x=" + x);
        let value = e.target.value;

        let snapshot = { ...passCriteria };
        let newCriteria = { ...snapshot };

        newCriteria.pc1.ok = newCriteria.pc1.pattern.test(value);
        newCriteria.pc2.ok = newCriteria.pc2.pattern.test(value);
        newCriteria.pc3.ok = newCriteria.pc3.pattern.test(value);
        newCriteria.pc4.ok = newCriteria.pc4.pattern.test(value);
        newCriteria.pc5.ok = newCriteria.pc5.pattern.test(value);

        setPassCriteria(newCriteria);
    };
    const handlePassConfirmOnChange = async (e) => {
        await validateFieldByEvent(e);
        // let value = e.target.value;
        // console.log("value is=" + value);
    };

    const onSubmit = async (form_data) => {
        setLoading(true);
        setPassError(null);

        await change_pass(form_data).then((result) => {
            if (result.success) {
                resetForm();
                // navigate(ROUTES.FORGOT + "/success", {
                //     replace: true,
                // });

                toast.show('Your password was successfully updated!',toast.types.success);

            } else if (result.error) {
                setPassError(result.error);
            }
            setLoading(false);
        });
    };

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <PrivatePageTemplate 
			pageTitle="Change My Password"
			breadcrumbs={[
                { caption: "My Home", to: "/my" },
                { caption: "My Account", to: "/my/account" },
            ]}
		>
            <Form
                className="text-center mt-5 ms-auto me-auto"
                style={{ maxWidth: "500px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <p className="mb-3 fw-bold">
                    To change your password, enter a new password below.
                    <br />The new password must pass the following criteria.
                </p>

                <div className="d-flex justify-content-center">
                    <ul style={{ textAlign: "left" }} className="fa-ul ms-0">
                        {Object.keys(passCriteria).map((key, index) => {
                            let crit = passCriteria[key];
                            return (
                                <li key={"pCrit_" + index}>
                                    {crit.ok === true ? (
                                        <Icon
                                            name="check"
                                            className="text-success me-1"
                                            fixedWidth={true}
                                        />
                                    ) : (
                                        <Icon
                                            name="exclamationTriangle"
                                            className="text-danger me-1"
                                            fixedWidth={true}
                                        />
                                    )}
                                    {crit.text}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="d-flex justify-content-center">
                    <div
                        className="d-flex flex-column justify-content-center"
                        style={{ maxWidth: "300px" }}
                    >
                        <Input
                            name={"password"}
                            control={control}
                            label={"New Password"}
                            floatingLabel={true}
                            helpText={null}
                            className={"mb-1"}
                            type={"password"}
                            size={"lg"}
                            autoFocus={true}
                            onKeyUp={handlePassOnChange}
                            onBlur={handlePassOnChange}
                            // disabled={}
                            rules={{
                                required: true,
                                validate: (val) => {
                                    let valid = validatePassword(val);
                                    setPassValid(valid); // set the state flag confirm uses to enable/disable
                                    return valid;
                                },
                            }}
                            feedback="Please enter a new password that meets the criteria above."
                            feedbackClass="text-start"
                        />

                        <Input
                            name={"password_confirm"}
                            control={control}
                            label={"Confirm New Password"}
                            floatingLabel={true}
                            helpText={null}
                            className={"mb-1"}
                            type={"password"}
                            size={"lg"}
                            autoFocus={false}
                            onKeyUp={handlePassConfirmOnChange}
                            onBlur={handlePassConfirmOnChange}
                            disabled={!passValid}
                            rules={{
                                required: true,
                                validate: (val) => {
                                    return validatePasswordConfirmation(val);
                                },
                            }}
                            feedback="Please confirm the new password you entered above."
                            feedbackClass="text-start"
                        />

                        {passError && (
                            <Alert
                                variant={"warning"}
                                onClose={() => setPassError(null)}
                                dismissible
                            >
                                <div className="fst-italic small">
                                    {passError}
                                </div>
                            </Alert>
                        )}
                    </div>
                </div>

                {loading && <Spinner animation="border" />}

                <SubmitButton className="mt-3" disabled={!isValid}>
                    Submit
                </SubmitButton>
            </Form>

		</PrivatePageTemplate>
    );
};
export default MyPassword;
