import React from 'react';
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { CardGrid } from '_core/components/core-library';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_PATHS } from '_core/config';
import { useUI } from '_core/hooks/provider-hooks/useUI.provider';

import { CUSTOM_GLOBAL_Admin_Index_Menu_Data } from '_custom/components/pages-private/admin-global/index_menu_data.custom';


const ADMIN_Global = () => {
	const navigate = useNavigate(); // Get navigate func from router hook.

	const { debugger_panel, debugger_modal } = useUI();

	const core_global_admin_menu_data = [
		{
			header: 'CORE :: COMPANYS',
			title:'Company Setup', 
			description:'Manage the companies setup in the system.', 
			btnVariant: 'outline-primary',
			btnText:'Go', 
			btnOnClick:() => navigate(PRIVATE_PATHS.GLOBAL.COMPANY)
		},
		{
			header: 'CORE :: USERS',
			title:'User Accounts Setup', 
			description:'Manage the users accounts setup in the system.', 
			btnVariant: 'outline-primary',
			btnText:'Go', 
			btnOnClick:() => navigate(PRIVATE_PATHS.GLOBAL.USERS)
		},
		
		{
			header: 'CORE :: UI DEBUG',
			title:'UI Info Panel', 
			description:'Toggle the visibility of the UI debugging info panel.', 
			btnVariant: 'outline-primary',
			btnText:'show / hide',
			btnOnClick:() => {
				debugger_panel.toggle();
			}
		},
		{
			header: 'CORE :: UI DEBUG',
			title:'UI Info Modal', 
			description:'Toggle the visibility of the UI debugging modal.', 
			btnVariant: 'outline-primary',
			btnText:'show / hide', 
			btnOnClick:() => {
				debugger_modal.show();
			}
		},
		{
			header: 'CORE :: UI THEME',
			title:'UI Theme Management', 
			description:'Manage the APP\'s Theme settings. View the colors.', 
			btnVariant: 'outline-primary',
			btnText:'Go',
			btnOnClick:() => navigate(PRIVATE_PATHS.GLOBAL.THEMES.HOME)
		},
	];

	const menu_data = [
		...core_global_admin_menu_data,
		...CUSTOM_GLOBAL_Admin_Index_Menu_Data(),
	];
	

	return (
		<PrivatePageTemplate 
			pageTitle="Global Admin"
			breadcrumbs={[
				{ caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
			]}
		>
			<div className="d-flex justify-content-center">
				
					{/* <Button variant="secondary"
                        onClick={() => {
                            navigate(PRIVATE_PATHS.GLOBAL.IGDB);
                        }}
                    >
                        IGDB Management
                    </Button> */}
				
				<CardGrid 
					cols_sm={1} cols_md={2} cols_lg={3} cols_xl={3}
					data={menu_data}
				/>
			</div>

		</PrivatePageTemplate>
	);
}
export default ADMIN_Global;