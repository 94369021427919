import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Button } from '_core/components/core-library';

const CardGridCard = ({ style, className, header, title, description, content, btnVariant, btnSize, btnClassName, btnText, btnOnClick, showButton }) => {
    const permStyle = {minHeight:'100%'};
    return (
        <Card style={{...style, ...permStyle}} className={className}>
            {(header!==undefined&&header!==null&&header.length>0) 
            &&
                <Card.Header as="h5">{header}</Card.Header>
            }
            
            
            <Card.Body className='d-flex flex-column justify-content-between' style={permStyle}>
                <div>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text style={permStyle}>{description}</Card.Text>
                    {content}
                </div>
               <Card.Footer className="px-0 pb-0" style={{backgroundColor:'unset', borderTop:'unset'}}>
                {(showButton===true) && 
                <Button variant={btnVariant} size={btnSize} className={btnClassName} style={{width:"100%"}}
                        text={btnText} 
                        onClick={btnOnClick}
                    />
                }
               </Card.Footer>
                
                
            </Card.Body>
        </Card>
    );
};
CardGridCard.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,

    header: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.any,
    
    showButton: PropTypes.bool,
    btnText: PropTypes.string,
    btnOnClick: PropTypes.func,
    btnVariant: PropTypes.string,
    btnSize: PropTypes.string,
    btnClass: PropTypes.string,
    btnStyle: PropTypes.object,
}
CardGridCard.defaultProps = {
    className:  '', 
    style:      {}, 

    header:     '', 
    title:      'Card Title', 
    description:'Card description.', 
    content:    null,

    showButton: true,
    btnText:    'Do something', 
    btnOnClick: () => {}, 
    btnVariant: 'primary', 
    btnSize:    null, 
    btnClass:   'fw-bold',
    btnStyle:   {}, 
};

export default CardGridCard;