/**                                                                                                                 by Paul Hardy 7/20/22
 *    Login.js  ------  Public Module route destination/page.
 *          -  Main Login Page
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";


import PublicPageTemplate from "./_templates/PublicPageTemplate";
import { Button, SubmitButton, Form, Input } from "_core/components/core-library";
import { Container, Spinner, Alert } from "react-bootstrap";

import { validatePhoneOrEmail } from "_core/helpers/form-validation";
import { CONSTANTS, LOGOUT_PATH } from "_core/config";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { useCookie, getCookie } from "_core/hooks/useCookie";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";

//import { getValue } from "@testing-library/user-event/dist/utils";

const Login = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
    const navigate = useNavigate(); // Get navigate func from router hook.

    // STATE HOOKS
    const [loading, setLoading] = useState(false); // For spinner.
    const [loginError, setLoginError] = useState(null); // For error message on form.

    // COOKIE HOOKS
    const [usernameCookie, setUsernameCookie] = useCookie(
        "login-username",
        getCookie("login-username")
    );

    // AUTH HOOKS
    const { current_user, login, forgot_reset, verify_user } = useAuth();

    // UI HOOKS
    const { ui_reset_menus } = useUI();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        control,
        trigger,
        formState: { isValid },
        setFocus, // eslint-disable-line no-unused-vars
        setValue,
        getValues,
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

    // redirect at render finish.  If logged in.  Constantly watch
    React.useEffect(() => {
        if (current_user) {

            // check user's token's and verify before forwarding in
            verify_user().then((verified) => {
            if (!verified || verified===false) {
                navigate(LOGOUT_PATH);
            }
        });
            navigate(CONSTANTS.AUTH_BASE_PATH, { replace: true });
        }
            
    }, [current_user, verify_user, navigate]);

    // Reset the forgot pass process if any lingering.  Only run once at mount.
    useOnMountRunOnce(() => {
        forgot_reset();

        window.scrollTo(0,0);
    });

    // focus on mount, or prefill username
    useOnMountRunOnce(() => {
        if (
            usernameCookie !== undefined &&
            usernameCookie !== null &&
            usernameCookie !== ""
        ) {
            setValue("username", usernameCookie);
            setFocus("password");
        } else {
            setFocus("username");
        }
    });

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */

    //  FUNC:  validateThisField - called
    const validateThisField = (e) => {
        // call useForm.trigger, with id passed in, to validate just this field
        const id = e.target.id;
        trigger(id);
    };

    const onSubmit = async (oFormFields) => {
        console.log('submitted');
        setLoading(true);
        setLoginError(null);
        setUsernameCookie(getValues("username"));
        await login(oFormFields).then((error) => {
           // console.log("login return " + JSON.stringify(error));
           
            ui_reset_menus();

            if (error) {
                setLoginError(error); // error object has 'message'
                let u = getValues("username");
                resetForm();
                setValue("username", u);
                //setFocus('password');
            }
            setLoading(false);
        });
    };

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <PublicPageTemplate pageTitle={"Login"} breadcrumbs={null} showTitle={false} showLine={false}>
            <Form
                className="form-signin text-center mt-5 ms-auto me-auto"
                onSubmit={handleSubmit(onSubmit)}
            >
                <h1 className="h4 mb-3 font-weight-normal">Please sign in.</h1>

                {loading && <Spinner animation="border" />}
                <div className="text-start">
                    <Input
                        name={"username"}
                        control={control}
                        label={"Email or Mobile#"}
                        floatingLabel={true}
                        autoComplete={"email phone mobile"}
                        helpText={null}
                        className={"mb-1 text-start"}
                        type={"text"}
                        size={"lg"}
                        autoFocus={false}
                        onKeyPress={validateThisField}
                        onKeyUp={validateThisField}
                        onBlur={validateThisField}
                        rules={{
                            required: true,
                            validate: (val) => {
                                return validatePhoneOrEmail(val);
                            },
                        }}
                        feedback={
                            "A valid email address or mobile # is required."
                        }
                        feedbackClass="text-start"
                    />

                    <Input
                        name={"password"}
                        control={control}
                        label={"Password"}
                        floatingLabel={true}
                        helpText={null}
                        className={"mb-1 text-left"}
                        type={"password"}
                        autoComplete={"password"}
                        size={"lg"}
                        autoFocus={false}
                        onKeyPress={validateThisField}
                        onKeyUp={validateThisField}
                        onBlur={validateThisField}
                        rules={{
                            required: true,
                            validate: (val) => {
                                return val.length >= 3;
                            },
                        }}
                        feedback={"Please enter a password"}
                        feedbackClass="text-start"
                    />
                </div>
                {loginError && (
                    <Alert
                        variant={"warning"}
                        onClose={() => setLoginError(null)}
                        dismissible
                    >
                        <div className="fst-italic small">{loginError}</div>
                    </Alert>
                )}

                <SubmitButton className="mt-3" disabled={!isValid} />

                <Container className="mt-3 d-flex justify-content-evenly">
                    <Button to="/" replace={true} variant="link">
                        Cancel
                    </Button>
                    {CONSTANTS.REGISTER_ENABLED && (
                        <Button to="/register" variant="link" disabled={true}>
                            Register
                        </Button>
                    )}
                    <Button to="/forgot" replace={true} variant="link">
                        Forgot?
                    </Button>
                </Container>
            </Form>
        
        </PublicPageTemplate>
    );
};
export default Login;
