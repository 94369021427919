import React from 'react';
import {
    ToastContainer as ReactToastifyToastContainer, toast,
    Slide, Zoom, Flip, Bounce
} from 'react-toastify';

function generate_guid() { var n = function () { return (65536 * (1 + Math.random()) | 0).toString(16).substring(1) }; return n() + n() + "-" + n() + "-" + n() + "-" + n() + "-" + n() + n() + n() }

// exportable transitions to use in options when calling show(). 
export const Transitions = { Slide, Zoom, Flip, Bounce };

// toast types (style)
export const ToastTypes = { info:"info", success:"success", warning:"warning", error:"error" };

// toast position 
export const ToastPosition = {...toast.POSITION};

// shows more than one toast by passsing array in 
export const showToasts = (toasts_array) => {
    if (toasts_array===undefined || toasts_array===null || toasts_array.length<=0) return;
    toasts_array.map( toastObj => { 
        return showToast(toastObj.msg, toastObj.options);
    })
}
export const showToastObj = (toastObj) => {showToast(toastObj.msg, toastObj)}
export const showToast = (msg, options) => {
    if (options === undefined || options === null) options = {};

    // Give the Toast an Id.
    options.toastId = options?.id !== undefined ? options.id : generate_guid();

    let type = options.type !== undefined && options.type !== null ? options.type : '';
    switch (type) {
        case ToastTypes.info:
            toast.info(msg, options);
            break;
        case ToastTypes.success:
            toast.success(msg, options);
            break;
        case ToastTypes.warning:
            toast.warning(msg, options);
            break;
        case ToastTypes.error:
            toast.error(msg, options);
            break;
        default:
            toast(msg, options);
            break
    }

    return options.toastId;
};

export const dismissToast = (toastId) => {
    toast.dismiss(toastId.current);
}

export const dismissAllToast = () => {
    toast.dismiss();
}

export const ToastContainer = ({
    limit=0,
    containerId, 
    position="top-right",
    enableMultiContainer=true, 
    theme="colored",
    autoClose=5000,
    hideProgressBar=false,
    newestOnTop=false,
    closeOnClick=true,
    rtl=false,
    pauseOnFocusLoss=true,
    draggable=true,
    pauseOnHover=true,
}) => {
    
    return (
        <ReactToastifyToastContainer
            limit={limit}
            enableMultiContainer={enableMultiContainer}
            containerId={containerId}
            position={position}
            autoClose={autoClose}
            hideProgressBar={hideProgressBar}
            newestOnTop={newestOnTop}
            closeOnClick={closeOnClick}
            rtl={rtl}
            theme={theme}
            pauseOnFocusLoss={pauseOnFocusLoss}
            draggable={draggable}
            pauseOnHover={pauseOnHover}
        />
    );
};

export default function Toast() {
    return ({
        ToastContainer,
        Transitions,
        showToast, showToasts, showToastObj,
        dismissToast,
        dismissAllToast,
    });
}



