/**                                                                                                                 by Paul Hardy 7/20/22
 *    Forgot_3_Verify.js  ------  Public Module route destination/page.
 *          -  STEP 3/5 of the Password Recovery Process.
 *          -  User presented input to enter One-Time-Passcode that was sent to them in previous step..  If Matches, on the STEP 4 (Forgot_4_NewPass.js)
 *          -  When verified, the App's store auth.forgot state is populated with data found, and access tokens that the next step.
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Spinner, Alert } from "react-bootstrap";
import {
    OneTimePasscodeInput,
    Button,
    SubmitButton,
    Form,
    HelmetHtmlTitle,
    CountdownTimer,
} from "_core/components/core-library";
import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { PUBLIC_PATHS } from "_core/config";

const ForgotVerify = (props) => {
    /****************************************************************************************************
    // HOOK & VARIABLE DECLARATIONS
    **************************************************************************************************** */

    // ROUTING HOOKS
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false); // for spinner
    const [verifyError, setVerifyError] = useState(null); // for error message on form
    const [timedOut, setTimedOut] = useState(false); //

    // AUTH & AUTH-STATE HOOKS
    const { access_token, access_expires, forgot, forgot_verify } = useAuth();

    // FORM HANDLER HOOKS (react-hook-form)
    const {
        handleSubmit,
        control,
        trigger,
        formState: { isValid },
        reset: resetForm,
    } = useForm({ mode: "all" });

    /****************************************************************************************************
    // ON MOUNT / USE EFFECT / WATCHERS :
    **************************************************************************************************** */

    //  future useEffect / onmount funcs here

    /****************************************************************************************************
    // RUNTIME FUNCS :
    **************************************************************************************************** */

    const onSubmit = async (formdata) => {
        formdata.token = access_token;

        // console.log('formdata',formdata);
        // return;

        setLoading(true);
        setVerifyError(null);

        await forgot_verify(formdata).then((result) => {
            if (result.success) {
                resetForm();
                navigate(PUBLIC_PATHS.FORGOT + "/pass", {
                    replace: true,
                });
            } else if (result.error) {
                setVerifyError(result.error);
            }
            setLoading(false);
        });
    };

    const handleOtpOnChange = async (e) => {
        // call react-hook-form's useForm.trigger, with id passsd in, to validate just this field
        const validated = await trigger(e.target.id); // eslint-disable-line no-unused-vars

        // auto-submit when valid
        if (validated && !timedOut) {
            handleSubmit(onSubmit)(); // braces after function call is immediate execution.
        }
    };

    /****************************************************************************************************
    // RENDER : 
    **************************************************************************************************** */
    return (
        <>
            <HelmetHtmlTitle pageName="Forgot My Password - Verification" />

            <Form
                className="form-forgot text-center mt-5 ms-auto me-auto"
                style={{ maxWidth: "500px" }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <h1 className="h4 mb-3 font-weight-normal">
                    PASSWORD RESET AUTHENTICATION
                </h1>
                <p className="text-start">
                    We just sent you a message. {forgot.send.message} Check your{" "}
                    {forgot.send.method} for the code and enter it below before
                    the expiration to continue.
                </p>
                <CountdownTimer
                    autostart
                    timestamp_utc={access_expires}
                    label={"The authorization code expires in"}
                    labelExpired="Code Expired!"
                    format={"mm:ss"}
                    className={"mb-3"}
                    labelClass={"me-1"}
                    timeClass={"fw-bold"}
                    onTimeout={() => {
                        setTimedOut(true);
                        setVerifyError(
                            "The authorization code has expired. Try again."
                        );
                    }}
                />

                <div className="d-flex justify-content-center">
                    <OneTimePasscodeInput
                        length={6}
                        control={control}
                        name="client_otp"
                        onChange={handleOtpOnChange}
                        disabled={timedOut}
                    />
                </div>

                {verifyError && (
                    <Alert
                        variant={"warning"}
                        onClose={() => setVerifyError(null)}
                        dismissible
                    >
                        <div className="fst-italic small">{verifyError}</div>
                    </Alert>
                )}

                {loading && <Spinner animation="border" />}

                {!timedOut && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="mt-3 d-grid gap-2 d-md-flex justify-content-md-end">
                            <Button
                                to={PUBLIC_PATHS.LOGIN}
                                variant="secondary"
                            >
                                Cancel
                            </Button>

                            {!loading && (
                                <SubmitButton disabled={!isValid || timedOut}>
                                    Verify
                                </SubmitButton>
                            )}
                        </div>
                    </div>
                )}

                <Container className="mt-3 d-flex justify-content-evenly">
                    {!timedOut && (
                        <>
                            <Button
                                to={PUBLIC_PATHS.FORGOT}
                                variant="link"
                            >
                                Start Over
                            </Button>
                        </>
                    )}
                    {timedOut && (
                        <>
                            <Button
                                to={PUBLIC_PATHS.LOGIN}
                                variant="link"
                            >
                                Back to login
                            </Button>
                            <Button
                                to={PUBLIC_PATHS.FORGOT}
                                variant="link"
                            >
                                Try again
                            </Button>
                        </>
                    )}
                </Container>

                <Container className="my-2  d-flex justify-content-center ">
                    {/** Another countdown here to auto-redirect to login if user doesn't respond.
                     *      It's set for 3 minutes beyond code expiration.   So form never stays here.
                     *
                     */}
                    <CountdownTimer
                        autostart
                        timestamp_utc={access_expires}
                        offset_seconds={
                            60 * 3
                        } /** Seconds to add to the timestamp target time. (negative to subtract) **/
                        label="Auto redirecting in"
                        labelExpired="Redirecting NOW"
                        format="mm:ss"
                        className="mb-3 invisible" /** MAKE INVISIBLE  **/
                        labelClass="small me-1"
                        timeClass="fw-bold"
                        onTimeout={() => {
                            navigate(PUBLIC_PATHS.LOGIN);
                        }}
                    />
                </Container>
            </Form>
        </>
    );
};
export default ForgotVerify;
