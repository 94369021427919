/**                                                         
 *    a REDUX SLICE for:
 * 
 *      Global Admin Data Retrieval
 * 
 *      - handles the response from our services that gathers data the Global Admin uses
 * 
        - Places Data in the redux store. 
 * 
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GlobalAdminDataService from "_core/services/global_admin.service";
import { isArray } from "_core/helpers/data-types/arrays";


const initialState = {
    companies : {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        data: null,
    },
    users : {
        isFetching: false,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        data: [],
    },
};

export const fetch_global_users = createAsyncThunk(
    "global-admin/users/fetch",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await GlobalAdminDataService.get_global_users( search_filters , access_token ); 
            if (response.status === 200 &&  response.data?.success) {
                //console.log('fetch_global_users-FETCH  - VALID SUCCESS - DATA SHOULD BE THERE!');
                return response.data;     // valid response 
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetch_global_companies = createAsyncThunk(
    "global-admin/companies/fetch",
    async (search_filters, thunkAPI) => {
        try {
            const access_token = thunkAPI.getState().auth.access_token;
            const response = await GlobalAdminDataService.get_global_companies( search_filters , access_token ); 
            if (response.status === 200 &&  response.data?.success) {
                //console.log('fetch_global_companies-FETCH  - VALID SUCCESS - DATA SHOULD BE THERE!');
                return response.data;     // valid response 
            } else {
                return thunkAPI.rejectWithValue(response.data);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const globalAdminDataSlice = createSlice({
    name: "globalAdmin",
    initialState,
    reducers: {
        clearState: (state) => {
            state = JSON.parse(JSON.stringify(initialState));   // deep copy initialState t state. 
            return state;
        },
    },

    extraReducers: (builder) => {
        builder
            /************************************************************************************************************************************************************ */
            /*  fetch_global_users                                   */
            /************************************************************************************************************************************************************ */
            .addCase(fetch_global_users.pending, (state, action) => {
                if (state.users.data===undefined || !isArray(state.users.data)) {
                    state.users.data = initialState.users.data
                }
                state.users.isFetching = true;
            })
            .addCase(fetch_global_users.fulfilled, (state, action) => {
                const result = action.payload;   //- this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;  
                //console.log('data',data);
                state.users.data           = data?.users;
                state.users.count_total    = data?.count_total;
                state.users.isFetching     = false;
                state.users.isSuccess      = true;
                state.users.isError        = false;
                state.users.errorMessage   = 'No errors.';
            })
            .addCase(fetch_global_users.rejected, (state, action) => {
                //console.log('fetch_global_users fetch payload', action.payload);
                //const result = action.payload;                           
                //const data = result?.data;  
                state.users.isFetching     = false;
                state.users.isSuccess      = false;
                state.users.isError        = true;
                state.users.errorMessage   = 'Global Admin - Users fetch failed.';
                state.users.data           = initialState.users.data;
                state.users.count_total    = initialState.users.count_total;
            })

            /************************************************************************************************************************************************************ */
            /*  fetch_global_companies                                   */
            /************************************************************************************************************************************************************ */
            .addCase(fetch_global_companies.pending, (state, action) => {
                if (state.companies.data===undefined || !isArray(state.companies.data)) {
                    state.companies.data = initialState.companies.data
                }
                state.companies.isFetching = true;
            })
            .addCase(fetch_global_companies.fulfilled, (state, action) => {
                const result = action.payload;   //- this is axios's data object in the response, which is our actual returned payload
                const data = result?.data;  
                //console.log('data',data);
                state.companies.data           = data?.companies;
                state.companies.count_total    = data?.count_total;
                state.companies.isFetching     = false;
                state.companies.isSuccess      = true;
                state.companies.isError        = false;
                state.companies.errorMessage   = 'No errors.';
            })
            .addCase(fetch_global_companies.rejected, (state, action) => {
                //console.log('fetch_global_users fetch payload', action.payload);
                //const result = action.payload;                           
                //const data = result?.data;  
                state.companies.isFetching     = false;
                state.companies.isSuccess      = false;
                state.companies.isError        = true;
                state.companies.errorMessage   = 'Global Admin - Companies fetch failed.';
                state.companies.data           = initialState.companies.data;
                state.companies.count_total    = initialState.companies.count_total;
            })
           
            /************************************************************************************************************************************************************ */
            /*  DEFAULT case       -  Do something when no actions identified.                                                                                             */
            /************************************************************************************************************************************************************ */
            .addDefaultCase((state) => {
                state.users.isFetching = false;
                state.companies.isFetching = false;
            });
    },
});
export default globalAdminDataSlice;

export const { clearState } = globalAdminDataSlice.actions;