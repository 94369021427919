import _ from "lodash";

export const isArray = (obj) => {
    return _.isArray(obj);
};

export const isArrayWithLength = (arr) => {
    return Array.isArray(arr) && arr.length;
};

export const isArrayEqual = (x, y) => {
    return _.isEmpty(_.xorWith(x, y, _.isEqual));
};

export const sortArrayOfObjects = (array, property) => {
    return _.sortBy(array, (o) => o[property]);
};

export const getUniquePropertyValuesFromObjects = (array, property, skipBlankValues = false) => {
    if (!isArray(array)) return [];
    if (array === undefined) return [];
    if (array === null) return [];
    if (array.length <= 0) return [];

    function uniqueBy(arr, prop) {
        return arr.reduce((a, d) => {
            if (!a.includes(d[prop])) {
                a.push(d[prop]);
            }
            return a;
        }, []);
    }

    let unique_vals_array = uniqueBy(array, property);

    if (skipBlankValues === true) {
        return unique_vals_array.filter(function (el) {
            return el != null;
        });
    }

    return unique_vals_array;
};

export const removeObjectsFromArrayByKey = (array, property, value_to_find) => {
    console.log("looking for prop " + property + " = " + value_to_find);

    let new_array = [...array.filter((obj) => obj[property] !== value_to_find)];

    return new_array;
};

/**
 * nestedFilterArray - Is a Function to filter an array of objects with limitless filters and or values.  Lifted from : https://stackoverflow.com/questions/44330952/filter-array-of-objects-by-multiple-properties-and-values
 * 
 * @param {*} targetArray - the array
 * @param {*} filters - an object of filters constructed like so:
 * 
 *                          var filters = {
                                "id": ["3"],
                                "term_id": ["6"],
                                "type": ["car","bike"]
                            }
 * 
 * 
 * @returns Array() - a filtered array of objects.
 */
export const nestedFilterArray = (targetArray, filters) => {
    var filterKeys = Object.keys(filters);
    return targetArray.filter(function (eachObj) {
        return filterKeys.every(function (eachKey) {
            if (!filters[eachKey].length) {
                return true;
            }
            return filters[eachKey].includes(eachObj[eachKey]);
        });
    });
};

export const mergeArraysDeep = (array1, array2) => {
    let merged = [];
    _.merge(merged, [...array1, ...array2]);
    return merged;
};
export const mergeArraysDeepWithUniqueKey = (array1, array2, key="id") => {
    const merged = _.merge(_.keyBy(array1, key), _.keyBy(array2, key));
    const values = _.values(merged);
    return values;
};

export const searchArrayRecursively = (list, keyName = "id", keyValue, subListName = "children", callback) => {
    for (let i = 0; i < list.length; i++) {
        const x = list[i];
        if (x[keyName] === keyValue) {
            if (callback) {
                callback(list, keyName, keyValue, subListName, i);
            }
            return x;
        }
        if (x[subListName] && x[subListName].length > 0) {
            const item = searchArrayRecursively(x[subListName], keyName, keyValue, subListName, callback);
            if (!item) continue;
            return item;
        }
    }
    return null;
};
