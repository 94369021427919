import React from "react";
import PublicPageTemplate from "_core/components/pages/public/_templates/PublicPageTemplate";
import { CONSTANTS } from "_core/config";
import PublicHomeWelcomePanel from "./parts/PublicHomeWelcomePanel";

const CustomPublicHome = () => {
    return (
        <PublicPageTemplate pageTitle={"Welcome to " + CONSTANTS.APP_NAME} breadcrumbs={null} showTitle={false} showBreadcrumbs={false} showLine={false}>
            <PublicHomeWelcomePanel />
        </PublicPageTemplate>
    );
};
export default CustomPublicHome;
