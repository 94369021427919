import React from "react";
import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { Button, DataField, Icon, JSONPretty } from "_core/components/core-library";
import { Row, Col } from "react-bootstrap";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";
//import { useIGDB } from "hooks/app-hooks/useIGDB";
import { useIGSDB } from "_custom/hooks/useIGSDB";
import { PRIVATE_PATHS } from "_core/config";


const IGSDB_Admin_Settings = () => {

	const { test_conn, settings } = useIGSDB();

	useOnMountRunOnce(() => {
		test_conn.init();
		settings.get();
	})

    return (
        <PrivatePageTemplate 
			pageTitle="IGDB Settings"
			breadcrumbs={[
                { caption: "My Home", to: PRIVATE_PATHS.MY.HOME },
				{ caption: "Global Admin", to: PRIVATE_PATHS.GLOBAL.HOME },
				{ caption: "IGSDB Admin", to: PRIVATE_PATHS.GLOBAL.IGSDB.HOME },
            ]}
		>
                <Row>
                    <Col>
                        <DataField
                            label="IGSDB API Connection Status"
                            data={
								(test_conn?.isSuccess!==undefined&&test_conn.isSuccess===true) 
								? 
								<><Icon name="cloud" className="text-success me-1" /> CONNECTED</>
								: 
								<><Icon name="cloud" className="text-danger me-1" /> NOT-CONNECTED!</>
							}
                        />
                    </Col>
                </Row>
				<Row>
                    <Col>
                        <DataField
                            label="IGSDB API Connection Last Checked"
                            data={test_conn?.lastTest}
                        />
                    </Col>
                </Row>
				<Row>
                    <Col>
                        <DataField
                            label="API Tools"
                            data={(
								<Button variant="link" onClick={ () => { test_conn.init(); } } className="p-0 m-0">Test Connection</Button>
							)}
                        />
                    </Col>
                </Row>
				<Row>
                    <Col>
                        <DataField
                            label="IGSDB API Key"
                            data={settings?.igsdb_api_key}
							overflow={true}
                        />
                    </Col>
                </Row>
				<Row>
                    <Col>
                        <DataField
                            label="IGSDB API host"
                            data={settings?.api_host}
							overflow={true}
                        />
                    </Col>
                </Row>
				<Row>
                    <Col>
                        <DataField
                            label="IGSDB API Base Url"
                            data={settings?.api_base}
							overflow={true}
                        />
                    </Col>
                </Row>
				<Row>
                    <Col>
                        <DataField
                            label="IGSDB API Endpoint - Products"
                            data={settings?.api_ep_products}
							overflow={true}
                        />
                    </Col>
                </Row>
				<Row>
                    <Col>
                        <DataField
                            label="IGSDB API Endpoint - Updates"
                            data={settings?.api_ep_updates}
							overflow={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataField
                            label="IGSDB API Endpoint - CONNECTION TEST"
                            data={settings?.api_ep_test}
							overflow={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataField
                            label="IGSDB TEST DATA RECEIVED"
                            data={<JSONPretty json={test_conn?.data} />}
							overflow={true}
                        />
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <DataField label="Email" data={current_user?.email} />
                    </Col>
                    <Col>
                        <DataField label="Mobile Phone" data={current_user?.mobile} />
                    </Col>
                </Row> */}
                {/* <div className="d-flex justify-content-end">
                    <Button variant="secondary"
                        onClick={() => {
                            navigate(PRIVATE_PATHS.MY.PROFILE_UPDATE);
                        }}
                    >
                        Update
                    </Button>
                </div> */}
           
    
		</PrivatePageTemplate>
    );
};
export default IGSDB_Admin_Settings;
