import React from "react";
import { Container, Navbar } from "react-bootstrap"; 
import { AppFooterCopyright } from './common';
import { useTheme } from "_core/hooks/provider-hooks/useTheme.provider";

function AppFooterNavbarPublic(props) {
    const { THEME} = useTheme();
    return (
        <Navbar 
            data-bs-theme={THEME?.navbar?.bsTheme}
            bg={THEME?.navbar?.bg} 
            variant={THEME?.navbar?.variant} 
            className={"app-footer " + THEME?.navbar?.className} 
            style={THEME?.navbar?.style}

            expand="lg" 
            fixed="bottom" 
        >
            <Container fluid className="d-flex justify-content-center">
                <AppFooterCopyright showVersion={false} />
            </Container>
        </Navbar>
    );
}
export default AppFooterNavbarPublic;