import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    calculate_ui_dimensions,

    reset_all,
    reset_menus,

    main_menu_reset, 
    main_menu_show, 
    main_menu_hide,
    main_menu_toggle,

    user_menu_reset,
    user_menu_show, 
    user_menu_hide,
    user_menu_toggle,

    message_menu_reset,
    message_menu_show, 
    message_menu_hide,
    message_menu_toggle,

    toast_show,
    toast_dismissAll,

    debugger_panel_show, 
    debugger_panel_hide, 
    debugger_panel_toggle, 
    debugger_panel_float_toggle,
    debugger_modal_show, 
    debugger_modal_hide, 
    debugger_modal_toggle, 

} from "_core/redux/slices/ui-redux-slice";
import { ToastContainer, ToastPosition, ToastTypes} from "_core/components/core-library/messaging/Toast";
import { Container,  } from "react-bootstrap";
import { Icon, JSONPretty, ScrollUp, Modal, ErrorBoundary  } from "_core/components/core-library";
import { useOnMountRunOnce } from "_core/hooks/useOnMount";
import { useTheme } from "./useTheme.provider";

const UIContext = createContext();

export const UiProvider = ({ children }) => {

    const { THEME } = useTheme();

    const dispatch = useDispatch();

    const toastContainerId = "ui";

    // Window Dimensions, Body Dimensions, and Breakpoints.
    const onresize_timerId = useRef(null);
    const onresize_timer_lag_time = useRef(100);  // milliseconds of lag for resize.
    useEffect(() => {
        dispatch( calculate_ui_dimensions() );  // run it once at first
    },[dispatch]);

    useOnMountRunOnce(() => {

        function handleResize() {
           if (onresize_timerId.current) clearTimeout(onresize_timerId.current);
           return dispatch( calculate_ui_dimensions() );   // calculates dimensions AND checks BREAKPOINTS
        }
        window.addEventListener('resize', () => {
            if (onresize_timerId.current) clearTimeout(onresize_timerId.current);
            onresize_timerId.current = setTimeout(() => {
                handleResize()
           }, onresize_timer_lag_time.current);
        }); 
    },[dispatch,calculate_ui_dimensions, onresize_timerId,onresize_timer_lag_time])
    
    
    // GET STATE VALUES FOR UI, we'll export below. 
    //-- Window Dimension States
    const {
        WINDOW_WIDTH, WINDOW_HEIGHT,
        BODY_WIDTH, BODY_HEIGHT,
    } = useSelector((state) => state.ui.dimensions);
    const {
        xs, sm, md, lg, xl, xxl,
        name: breakpoint_name, descript: breakpoint_descript
    } = useSelector((state) => state.ui.breakpoint);
     
    //-- Menu States
    const {
        main_menu : { visible:main_menu_visible } = { main_menu_visible:false },       /// deep destructuring of object, renaming variables, and setting default values to the variables. 
        user_menu : { visible:user_menu_visible } = { user_menu_visible:false },        
        message_menu : { visible:message_menu_visible } = { message_menu_visible:false },        
    } = useSelector((state) => state.ui);

    //-- DEBUGGER States, for export and use in modal, and panel
    const {
        debugger_panel : { visible: debugger_panel_visible } = { debugger_panel_visible:false },       /// deep destructuring of object, renaming variables, and setting default values to the variables. 
        debugger_panel : { floated: debugger_panel_floated } = { debugger_panel_floated:false },
        debugger_panel : { floatClassApplied: debugger_panel_floatClassApplied } = { debugger_panel_floatClassApplied:'' },
        debugger_modal : { visible: debugger_modal_visible } = { debugger_modal_visible:false },
    } = useSelector((state) => state.ui);


    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */


    const value = useMemo(
        () => ({
            ui_reset_all: async () => { return dispatch(reset_all()) },
            ui_reset_menus: async () => { return dispatch(reset_menus()) },

            dimensions : {
                WINDOW_WIDTH: WINDOW_WIDTH,
                WINDOW_HEIGHT: WINDOW_HEIGHT,
                BODY_WIDTH: BODY_WIDTH,
                BODY_HEIGHT: BODY_HEIGHT,
            },
            breakpoints : {
                xs: xs, sm: sm, md: md, lg: lg, xl: xl, xxl: xxl, any: true,
                breakpoint: breakpoint_name, 
                breakpoint_descript: breakpoint_descript
            },

            main_menu : {
                visible: main_menu_visible,
                reset: ( async () => { return dispatch( main_menu_reset() ) }),
                show:  ( async () => { return dispatch( main_menu_show() ) }),
                hide:  ( async () => { return dispatch( main_menu_hide() ) }),
                toggle:( async () => { return dispatch( main_menu_toggle() ) }),
            },
            user_menu : {
                visible: user_menu_visible,
                reset: ( async () => { return dispatch( user_menu_reset() ) }),
                show:  ( async () => { return dispatch( user_menu_show() ) }),
                hide:  ( async () => { return dispatch( user_menu_hide() ) }),
                toggle:( async () => { return dispatch( user_menu_toggle() ) }),
            },
            message_menu : {
                visible: message_menu_visible,
                reset: ( async () => { return dispatch( message_menu_reset() ) }),
                show:  ( async () => { return dispatch( message_menu_show() ) }),
                hide:  ( async () => { return dispatch( message_menu_hide() ) }),
                toggle:( async () => { return dispatch( message_menu_toggle() ) }),
            },

            toast : {
                types: ToastTypes,
                show:  ( async (msg, type=null, options={}) => { 
                    //- Designate the ToastContainer to show the toast in. Use "ui" (this providers containerId - see below) , or one passed in (unlikely).
                    options.containerId = (options.containerId!==undefined && options.containerId!==null) ? options.containerId :toastContainerId; 
                    //- Call our Redux function to show the toast through our apps toast service. 
                    return dispatch( toast_show({ msg, type, ...options })
                )}),
                dismissAll: ( async () => { return dispatch( toast_dismissAll() ) }),
            },

            /* Debugger output Vars */
            debugger_panel : {
                visible: debugger_panel_visible,
                floated: debugger_panel_floated,
                show:  ( async () => { return dispatch( debugger_panel_show() ) }),
                hide:  ( async () => { return dispatch( debugger_panel_hide() ) }),
                toggle:( async () => { return dispatch( debugger_panel_toggle() ) }),
                float: ( async () => { return dispatch( debugger_panel_float_toggle() ) }),
            },
            debugger_modal : {
                visible: debugger_modal_visible,
                show:  ( async () => { return dispatch( debugger_modal_show() ) }),
                hide:  ( async () => { return dispatch( debugger_modal_hide() ) }),
                toggle:( async () => { return dispatch( debugger_modal_toggle() ) }),
            },

            /**
             * UI_DEBUGGER_PANEL
             * @returns a component showing the UI Dimensions for debugging or development. 
             */
            UI_DEBUGGER_PANEL: () => { 
                
                if (debugger_panel_visible!==true) return null;

                let clsArray = [
                    'ui-debugger-panel',
                    'small',
                    'd-flex',
                    'justify-content-center',
                ];
                // if (debugger_panel_floated) {
                //     clsArray.push('ui-debugger-panel-floated');
                // }
        
                return (
                    <Container fluid className={clsArray.join(' ') + ' '+ debugger_panel_floatClassApplied}>
                        <span className="mx-1 fw-bold">UI:</span>
                        <span className="mx-1">{'BP: '+breakpoint_name}</span>
                        <span className="mx-1">&bull;</span>
                        <span className="mx-1">{'WIN: '+WINDOW_WIDTH+' x '+WINDOW_HEIGHT}</span>   
                        <span className="mx-1">&bull;</span>                     
                        <a href="!#" onClick={(e) => {e.preventDefault(); dispatch(debugger_modal_show()) }} className="mx-1">more</a>
                        <span className="mx-1">&bull;</span>
                        <a href="!#" onClick={(e) => {e.preventDefault(); dispatch(debugger_panel_float_toggle()) }} className="mx-1">float</a>
                        <span className="mx-1">&bull;</span>
                        <a href="!#" onClick={(e) => {e.preventDefault(); dispatch(debugger_panel_hide()) }} className="mx-1">hide</a>
                    </Container> 
                );                
            }
            
            
        }),
        // Variable Dependencies required for exports above to have values
        [ 
            dispatch,
            main_menu_visible, 
            user_menu_visible, 
            message_menu_visible, 
            WINDOW_WIDTH, WINDOW_HEIGHT, 
            BODY_WIDTH, BODY_HEIGHT, 
            breakpoint_name, breakpoint_descript, xs, sm, md, lg, xl, xxl, 
            debugger_panel_visible, 
            debugger_panel_floated, 
            debugger_panel_floatClassApplied,
            debugger_modal_visible,
        ] 
    );

    /************************************************************************************************************************************************************* */
    /************************************************************************************************************************************************************* */


    return (
        <UIContext.Provider value={value}>
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
           
            <div>
                <ToastContainer 
                    containerId={toastContainerId} 
                    position={ToastPosition.TOP_CENTER} 
                    enableMultiContainer={true}  
                    newestOnTop={true} 
                    theme={THEME?.toast?.theme}
                />

                <ScrollUp
                    startPosition={0}
                    showAtPosition={300}
                    position='right'
                    className='scroll-up'
                    style={{right: '40px'}}
                >
                    <Icon name="circleUp" size="3x" className="text-primary" />
                </ScrollUp>
                <ScrollUp
                    startPosition={0}
                    showAtPosition={300}
                    position='left'
                    className='scroll-up'
                    style={{left: '40px'}}
                >
                    <Icon name="circleUp" size="3x" className="text-primary" />
                </ScrollUp>
            </div>
            <Modal title="UI Debugger - Info" size="md" centered={true}
                visible={debugger_modal_visible} 
                closeButton={ true } 
                handleClose={ () => {dispatch(debugger_modal_hide())} } 
            >
                <div>
                    <div className="text-center">
                        <a href="!#" onClick={(e) => {e.preventDefault(); dispatch(debugger_panel_toggle()) }} className="mx-1">toggle panel</a>
                    </div>
                    <div>
                        DIMENSIONS: 
                        <JSONPretty json={{WINDOW_WIDTH: WINDOW_WIDTH,WINDOW_HEIGHT: WINDOW_HEIGHT,BODY_WIDTH: BODY_WIDTH, BODY_HEIGHT: BODY_HEIGHT}} />
                    </div>
                    <div>
                        BREAKPOINTS: 
                        <JSONPretty json={{xs: xs, sm: sm, md: md, lg: lg, xl: xl, xxl: xxl, any: true, breakpoint: breakpoint_name, breakpoint_descript: breakpoint_descript }} />
                    </div>
                </div>
            </Modal>

            <div className="breakpoint-check" style={{cssText:"none"}}>
                <span className="xs d-block d-sm-inline"></span>
                <span className="sm d-sm-block d-md-inline"></span>
                <span className="md d-md-block d-lg-inline"></span>
                <span className="lg d-lg-block d-xl-inline"></span>
                <span className="xl d-xl-block"></span>
                <span className="xxl d-xxl-block"></span>
            </div>
          
        </UIContext.Provider>
    );
};
export const useUI = () => {
    return useContext(UIContext);
};
