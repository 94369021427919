const settings = {
    background: 'rgb(248, 248, 248)',
    brace: 'rgb(51, 51, 51)',
    keyQuotes: 'rgb(51, 51, 51)',
    valueQuotes: 'rgb(221, 17, 68)',
    colon: 'rgb(51, 51, 51)',
    comma: 'rgb(51, 51, 51)',
    key: 'rgb(51, 51, 51)',
    value: {
        string: 'rgb(221, 17, 68)',
        null: 'rgb(0, 128, 128)',
        number: 'rgb(0, 128, 128)',
        boolean: 'rgb(0, 128, 128)',
    },
    bracket: 'rgb(51, 51, 51)',
};
export default settings;

// old default.
/*
const settings = {
    background: 'rgb(39, 40, 34)',
    brace: 'rgb(129, 211, 204)',
    keyQuotes: 'rgb(129, 211, 204)',
    valueQuotes: 'rgb(129, 211, 204)',
    colon: 'rgb(129, 211, 204)',
    comma: 'rgb(129, 211, 204)',
    key: 'rgb(201, 70, 56)',
    value: {
        string: 'rgb(242, 152, 59)',
        null: 'rgb(201, 70, 56)',
        number: 'green',
        boolean: 'rgb(129, 211, 204)',
    },
    bracket: 'rgb(129, 211, 204)',
};
*/