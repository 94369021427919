import React from 'react';
import MapRoutes from '_core/routing/MapRoutes';
import { getAllowedPrivateRoutes } from "_core/config/PrivateRoutes.core.config";
import { CONSTANTS } from '_core/config';

const PrivateRoutes = () => {
	const allowedRoutes = getAllowedPrivateRoutes();
	return (		
		<MapRoutes routes={allowedRoutes} basePath={ CONSTANTS.AUTH_BASE_PATH } isAddNotFound />
	);
}
export default PrivateRoutes;