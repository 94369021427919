import React from "react";
import { Button, Icon } from "_core/components/core-library";

import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";

function UserMessagesButton(props) {
    const { isLoggedIn } = useAuth();
    return (
        <Button
            variant="outline"
            className="ms-2"
            disabled={!isLoggedIn}
            onClick={() => {
                if (props?.onClick) props.onClick();
            }}
        >
            <Icon name="envelope" size="1x" />
        </Button>
    );
}
export default UserMessagesButton;
