import React from "react";
import PrivatePageTemplate from "_core/components/pages/private/_templates/PrivatePageTemplate";
import { Button } from "_core/components/core-library";
import { useMessaging, ToastTypes } from "_core/hooks/provider-hooks/useMessaging.provider";
import { useErrorHandler } from "_core/hooks/provider-hooks/useErrorHandler.provider";
import { useUI } from "_core/hooks/provider-hooks/useUI.provider";

const DEV_ToastTest = () => {
    const { toast: uiToast } = useUI();
    const { toast: messagingToast } = useMessaging();
    const { showErrorToast } = useErrorHandler();

    return (
        <PrivatePageTemplate
            pageTitle="DEV: Toast Test"
            breadcrumbs={[
                { caption: "My Home", to: "/my" },
                { caption: "Developer Home", to: "/dev" },
            ]}
        >
            <div className="d-flex justify-content-center pt-5">

                <div className="d-flex flex-column">

					<div className="d-flex flex-column mb-5">
                    	<Button className="mb-2" variant="danger" onClick={() => showErrorToast("This is an error!")}>Error Handler Toast</Button>
					</div>

					<div className="d-flex flex-column mb-5">
						{Object.entries(ToastTypes).map(([key,type], index) => {
							return (
								<Button className="mb-2" key={index} variant={type} onClick={() => uiToast.show("test", type)}>
									UI Toast ({type})
								</Button>
							);
						})}
					</div>

					<div className="d-flex flex-column mb-5">
						{Object.entries(ToastTypes).map(([key,type], index) => {
							return (
								<Button className="mb-2" key={index} variant={type} onClick={() => messagingToast.show("test", type)}>
									Messaging Toast ({type})
								</Button>
							);
						})}
					</div>
                </div>
            </div>
        </PrivatePageTemplate>
    );
};
export default DEV_ToastTest;
