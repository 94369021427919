import React from "react";
import { useNavigate } from "react-router-dom";

import PrivatePageTemplate from '_core/components/pages/private/_templates/PrivatePageTemplate';
import { Button, DataField } from "_core/components/core-library";
import { Row, Col, Container } from "react-bootstrap";

import { useAuth } from "_core/hooks/provider-hooks/useAuth.provider";
import { utc_date_string_to_local_date_string } from "_core/helpers/datetime";
import { PRIVATE_PATHS } from "_core/config";

const Profile = () => {
    // AUTH HOOKS
    const { current_user, access_token, access_expires } = useAuth();
    // ROUTING HOOKS
    const navigate = useNavigate(); // Get navigate func from router hook.
    return (
        <PrivatePageTemplate 
			pageTitle="My Profile"
			breadcrumbs={[
                { caption: "My Home", to: "/my" },
                { caption: "My Account", to: "/my/account" },
            ]}
		>
            
            <Container style={{maxWidth:"800px"}}>
                <Row>
                    <Col>
                        <DataField
                            label="Name"
                            data={current_user?.displayname}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataField label="Email" data={current_user?.email} />
                    </Col>
                    <Col>
                        <DataField label="Mobile Phone" data={current_user?.mobile} />
                    </Col>
                </Row>
                <div className="d-flex justify-content-end">
                    <Button variant="secondary"
                        onClick={() => {
                            navigate(PRIVATE_PATHS.MY.PROFILE_UPDATE);
                        }}
                    >
                        Update
                    </Button>
                </div>
                <hr />
                <hr />
                <Row>
                    <Col>
                        <DataField label="uid" data={current_user?.uid} />
                    </Col>
                    <Col>
                        <DataField label="cid" data={current_user?.cid} />
                    </Col>
                    <Col>
                        <DataField label="pcid" data={current_user?.pcid} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataField
                            label="Token Expires"
                            data={utc_date_string_to_local_date_string(
                                access_expires,
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                            )}
                        />
                    </Col>
                    <Col>
                        <DataField
                            label="Token Expires"
                            data={access_expires}
                        />
                    </Col>
                </Row>
                <Row>
                    <DataField
                        label="token"
                        data={access_token}
                        overflow={true}
                        className={"small"}

                    />
                </Row>
            </Container>
		</PrivatePageTemplate>
    );
};
export default Profile;
