import React from "react";
import PropTypes from "prop-types";
import {LinkContainer} from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import { Icon } from "_core/components/core-library";
const NavLink = (props) => {
   
    const handle_onClick = () => {
        if (props.onClick !==undefined ) props.onClick()
    };

    const iconStyle={};
    iconStyle.color =props.iconColor;

    return (     
        (props.to!==undefined && props.to!==null)
        ?
        <LinkContainer to={props.to} onClick={handle_onClick}  className="px-0">
            <Nav.Link active={props?.active} className={props.className} style={props.style}>
                <Icon name={props.icon} size="1x" fixedWidth={true} className="me-1" style={iconStyle} />
                {props.children}
            </Nav.Link>
        </LinkContainer>
        :  
        <Nav.Link active={props?.active} className={"px-0 "+props.className} style={props.style} onClick={handle_onClick}>
            <Icon name={props.icon} size="1x" fixedWidth={true} className="me-1"  style={iconStyle} />
            {props.children}
        </Nav.Link>
    );
};
NavLink.propTypes = {
    to : PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    theme: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
};
NavLink.defaultProps = {
    theme: "",
    icon: "userLarge",    // must be an icon in our icon library.   See <Icon />
    iconColor: null,
};
export default NavLink;
