import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col } from 'react-bootstrap';
import CardGridCard from './CardGridCard';



const CardGrid = ({ data, gap, containerClass, cols_sm, cols_md, cols_lg, cols_xl }) => {
    let cls=[];
    cls.push(containerClass);
    cls.push('row');    // bs row
   // cls.push('row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-6');   // bs grid behavior
    cls.push('row-cols-'+cols_sm+' row-cols-md-'+cols_md+' row-cols-lg-'+cols_lg+' row-cols-xl-'+cols_xl);   // bs grid behavior
    cls.push('g-'+gap);  // gap between bs grids
    return <>
        <Container className={cls.join(' ')}>
            {data.map(function(item, i){
                return (
                    <Col key={i}  >
                        <CardGridCard
                            style={{minHeight:'100%'}}
                            key={i}
                            header={item?.header}
                            title={item?.title}
                            description={item?.description}
                            content={item?.content}
                            showButton={item?.showButton}    
                            btnVariant={item.btnVariant}
                            btnSize={item.btnSize}
                            btnText={item.btnText}
                            btnOnClick={item.btnOnClick}
                        />
                    </Col>
                );
            })}
        </Container>
    </>;
};
CardGrid.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
    containerClass: PropTypes.string,
    gap: PropTypes.number,


    cols_sm: PropTypes.number,
    cols_md: PropTypes.number,
    cols_lg: PropTypes.number,
    cols_xl: PropTypes.number,

   

}
CardGrid.defaultProps = {
    data: [
        {header: 'Header 1', title:'Card 1', description:'Card one description.', btnText:'Go', btnOnClick:() => {}, btnVariant:'primary', btnSize:'lg', btnClass:'fw-bold'},
        {header: 'Header 2', title:'Card 2', description:'Card two description.', btnText:'Go', btnOnClick:() => {}, btnVariant:'primary', btnSize:'lg', btnClass:'fw-bold'},
        {header: 'Header 3', title:'Card 3', description:'Card three description.', btnText:'Go', btnOnClick:() => {}, btnVariant:'primary', btnSize:'lg', btnClass:'fw-bold'},
    ],
    containerClass: 'card-grid-container',
    gap: 4,

    cols_sm: 1, // number of cols for col default bs grid
    cols_md: 2, // number of cols for md bs grid
    cols_lg: 4, // number of cols for lg bs grid
    cols_xl: 6, // number of cols for xl bs grid

};

export default CardGrid;